import React from 'react'
import Chartmain from './Chartmain'
function Chart(coin) {
  return (
    <>
      <Chartmain coin={coin} />
    </>
  )
}

export default Chart
