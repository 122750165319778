import { Button } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Forgetusername() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate()
  const [isLabelAbove, setIsLabelAbove] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFocus = () => {
    setIsLabelAbove(true);
  };

  // const handleBlur = () => {
  //   setIsLabelAbove(value !== "");
  // };

 const handleSubmit = async (e) => {
  
   const formData = new FormData();
   formData.append("project", "FinGenius");
   formData.append("email", email);
   const response = await axios({
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     url: process.env.REACT_APP_API_PATH_USER + "forgot_username",
     data: formData,
   })
     .then(async function (response) {
       const res = await response?.data;
       console.log(response);
       if (res?.Success) {
         toast.success(res.Success);
         navigate("/submit", {
           state: {
             email: email,
           },
         });
       } else {
         toast.error(res.Error);
       }
     })
     .catch((err) => {
       toast.error(err.response.data.Error);
     });
 };

  

  
  return (
    <>
      <div className="container mx-auto mt-10 px-2 flex justify-center items-center">
        <div className="w-full md:bg-white  relative rounded-2xl py-3 md:shadow-lg overflow-hidden">
          <div className="text-center">
            <div className="text-lg md:text-3xl font-semibold mb-3 text-colorAccent">
              Forgot your username?
            </div>
            <div>
              <div className="text-gray-100 md:text-gray-500 font-bold hover:underline">
                Don’t worry. We’ll send it to your email address.{" "}
              </div>
            </div>
            <div className="px-5 py-5   rounded-lg mt-5  md:w-9/12 mx-auto">
              {/* <FloatingLabelInput label="Email" /> */}
              <input
                placeholder="Email ID"
                id="email"
                name="email"
                type="email"
                className="py-2 px-4 border border-slate-900 rounded-full focus:outline-none focus:border-colorSecondary w-full"
                value={email}
                onChange={handleChange}
                onFocus={() => handleFocus("email")}
                // onBlur={() => handleBlur("email")}
                autoComplete="off"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
              />
              {/* <input
                type="email"
                id="Email"
                value={email}
                onChange={handleChange}
                className="peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 py-2 px-4 border border-slate-900 rounded-full  focus:border-blue-500 w-full bg-white "
                placeholder="Email"
              /> */}

              {/* <span className="pointer-events-none absolute  left-2 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                Email
              </span> */}

              <button
                onClick={handleSubmit}
                className="rounded-full bg-colorAccent py-1 my-4 cursor-pointer hover:bg-colorAccent/70 px-10 md:w-full text-white"
              >
                Request username
              </button>
              <div className="mt-1 flex justify-between">
                {" "}
                <Link
                  to="/forgetpassword"
                  className="text-colorAccent underline"
                >
                  {" "}
                  Forgot Password?
                </Link>
                <Link to="/" className="text-colorAccent underline">
                  {" "}
                  Sign In Here
                </Link>
              </div>
              <div className="mt-1"> </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgetusername;
