import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import logo1 from "../assets/logo-beta.png";
import one from "../assets/images/loginicone/one.png";
import two from "../assets/images/loginicone/two.png";
import three from "../assets/images/loginicone/three.png";
import { theme } from "antd";
import Lottie from "lottie-react";
import ant from "../Login.json";
import { BiSolidBookBookmark } from "react-icons/bi";
import { GoBook } from "react-icons/go";
import { AiOutlineRead } from "react-icons/ai";

function AuthWrapper() {
  const [hamburgerview, sethamburgerview] = useState("hidden");
  const handlehamburger = () => {
    sethamburgerview("block");
  };

  const navigate = useNavigate();


  const [isStopped, setIsStopped] = useState(true);

  const toggleAnimation = () => {
    setIsStopped(!isStopped);
  };
  return (
    <div>
      <div className="mx-w-[1440px] relative ">
        <div className="h-[100vh]  flex items-center justify-center md:ml-5 overflow-hidden">
          <div className=" w-8/12 justify-start md:flex md:flex-col text-justify md:items-start hidden">
            {/* <div className="flex justify-center"> */}
            <footer className="bottom-10 mt-[408px] lg:mt-72  xl:mt-[408px]">
              <Lottie
                alignItems="center"
                isStopped={true}
                isPaused={true}
                options={{
                  loop: false,
                  autoplay: false,
                }}
                animationData={ant}
              />
            </footer>
          </div>

          <div className="md:w-4/12 w-full bg-colorSecondaryDark h-full flex flex-col items-center justify-center">
            <div className="bg-colorSecondaryDark w-full px-4 flex justify-between items-center">
              <div className="flex md:justify-between justify-center items-center w-full">
                <div className="hidden md:block">
                  <img
                    className="text-center  mr-3 w-44"
                    src={logo}
                    alt="Flowbite Logo"
                  />
                </div>
                <div className="w-full block md:hidden">
                  <div className="flex justify-center mb-4">
                    <header className="fixed top-12">
                      <img
                        className="text-center w-44"
                        src={logo1}
                        alt="Flowbite Logo"
                      />
                    </header>
                  </div>
                </div>
                <div className="hidden md:block">
                  <Link to="/guide" target="_blank">
                    <BiSolidBookBookmark
                      size={30}
                      className="text-colorPrimary ml-2 transform translate-x-0 transition-transform hover:translate-x-2  animate-bounce"
                    />
                    <p className="text-md text-white ">Guide</p>
                  </Link>
                </div>
                {/* <div>
                  <div
                    className=" text-white underline cursor-pointer"
                    onClick={() => navigate("/guide")}
                  >
                    Guide
                  </div>
                </div> */}
              </div>
            </div>
            <Outlet />
            <div className=" w-full hidden md:block">
              <div className="p-3 text-center">
                {/* <div className="bg-colorSecondary md:p-5 rounded-xl "> */}
                <div className="bg-colorAccent  md:p-5 rounded-xl ">
                  <div className="text-xl text-black font-semibold mb-3">
                    Always make sure:{" "}
                  </div>
                  <div className="grid md:grid-cols-3 grid-cols-3 md:gap-10 gap-2">
                    <div className="flex flex-col justify-center">
                      <div>
                        <img
                          className="w-16 mx-auto bg-white p-4 rounded-2xl"
                          src={one}
                        />
                      </div>
                      <div className="mt-3 text-black text-xs">
                        The domain is: www.fingenius.ai
                      </div>
                    </div>
                    <div className="flex flex-col justify-center">
                      <div>
                        {/* <FaLock size={50 }/> */}
                        <img
                          className="w-16 mx-auto bg-white p-4 rounded-2xl"
                          src={two}
                        />
                      </div>
                      <div className="mt-3 text-black text-xs">
                        The page is encrypted with https
                      </div>
                    </div>
                    <div className="flex flex-col justify-center">
                      <div>
                        <img
                          className="w-16 mx-auto bg-white p-4 rounded-2xl"
                          src={three}
                        />
                      </div>
                      <div className="mt-3 text-black text-xs">
                        You use 2FA (multi factor auth)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full fixed top-0 right-0 flex justify-end mx-3 my-4 md:hidden transform translate-x-0 transition-transform hover:translate-x-2  animate-bounce">
              <header>
                <Link to="/guide" target="_blank">
                  <AiOutlineRead size={25} className="text-colorSecondary" />
                  <spam className="text-white md:text-black text-[10px] ">
                    Guide
                  </spam>
                </Link>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthWrapper;
