// LivePriceTracker.js

import React, { useState } from 'react';

const LivePriceTracker = () => {
  const [price, setPrice] = useState(null);

  // Open a WebSocket connection
  const socket = new WebSocket("wss://ws-api-spot.kucoin.com/market/ticker:all");

  // Connection opened
  socket.addEventListener('open', () => {
    // const subscriptionMessage = {
    //   "type":"message",
    //   "topic":"/market/ticker:all",
    //   "subject":"BTC-USDT",
    //   "id":"652f590efed70f14545ad3d7",
    //   "token":"",
    //   "data":{
    //       "sequence":"1545896668986",
    //       "bestAsk":"0.08",
    //       "size":"0.011",
    //       "bestBidSize":"0.036",
    //       "price":"0.08",
    //       "bestAskSize":"0.18",
    //       "bestBid":"0.049",
    //   },

    // };
    const subscriptionMessage = {
      "type":"message",
      "topic":"/market/ticker:all",
      "subject":"BTC-USDT",
      "response": true,
      "id":189392182,
      "token":"",
      "data":{
          "sequence":"1545896668986",
          "bestAsk":"0.08",
          "size":"0.011",
          "bestBidSize":"0.036",
          "price":"0.08",
          "bestAskSize":"0.18",
          "bestBid":"0.049",
      },

    };
    socket.send(JSON.stringify(subscriptionMessage));
    console.log('WebSocket connection opened successfully');
  });

  // Listen for messages from the server
  socket.addEventListener('message', (event) => {
    const data = JSON.parse(event.data);
    console.log(data)
    if (data.type === 'message' && data.topic && data.topic.startsWith('/market/ticker')) {
      const newPrice = data.data.price;
      setPrice(newPrice);
      console.log(`BTCUSDT Price: ${newPrice}`);
    }
  });

  // Connection closed
  // socket.addEventListener('close', () => {
  //   console.log('WebSocket connection closed');
  // });

  // Connection error
  socket.addEventListener('error', (event) => {
    console.error('WebSocket error:', event);
  });

  // Cleanup on component unmount
  return () => {
    socket.close();
    console.log('WebSocket connection closed on component unmount');
  };

  
};

export default LivePriceTracker;
