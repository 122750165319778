import React, { useState } from "react";
import Lottie from "lottie-react";
import ant from "../Lotties.json";
import { Link, useLocation } from "react-router-dom";
import { Button } from "antd";

function EmailVerify() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.state);
  const email = searchParams.get("email");
  const [value, setValue] = useState("");
  const [isLabelAbove, setIsLabelAbove] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleFocus = () => {
    setIsLabelAbove(true);
  };

  const handleBlur = () => {
    setIsLabelAbove(value !== "");
  };
  return (
    <>
      <div>
        <div className="container mx-auto mt-10 px-2 flex justify-center items-center">
          <div className="w-full md:bg-white  relative rounded-2xl py-3 md:shadow-lg overflow-hidden">
            <div className="text-center">
              <div className="text-lg lg:text-3xl font-semibold mb-3 text-colorSecondary md:text-colorSecondaryDark">
                Forgot Username
              </div>
              <div className="flex items-center justify-center">
                <Lottie
                  // className="text-center align-items-center"
                  alignItems="center"
                  style={{ width: "50%" }}
                  animationData={ant}
                />
              </div>
              <div>
                <div className="md:text-black text-white   font-semilight my-4">
                  We have sent you an email on{" "}
                  <span className="md:text-colorSecondaryDark text-colorSecondary  font-black">
                    {email ? email : "Sample@gmail.com"}
                  </span>{" "}
                  about the further procedure to get your username.
                </div>
              </div>
              <div className="flex justify-center items-center">
                <Link to="/">
                  <button className="rounded-full py-1 bg-colorAccent my-2 px-14 cursor-pointer hover:bg-colorAccent/70 md:w-full text-white">
                    Submit
                  </button>
                </Link>
              </div>

              <span className="spam mt-4 text-white md:text-black">
                Already Have An Account?&nbsp;
                <Link to="/" className="text-colorAccent underline ">
                  Sign In Here
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailVerify;
