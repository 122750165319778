import React, { useState } from "react";
import { Button, Tabs, Drawer } from "antd";

import Buy from "./Buy";
import Sell from "./Sell";
import TabPane from "antd/es/tabs/TabPane";
const data = [
  { symbol: "AAPL", last: "AAPL", change: 4 },
  { symbol: "TSLA", last: "TSLA", change: 3 },
  { symbol: "IBM", last: "IBM", change: 5 },
  { symbol: "MSFT", last: "MSFT", change: 2 },
  { symbol: "GOOG", last: "GOOG", change: 4 },
];
function BuySell({ coin, market, showbalance, tradetype }) {

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        type="card"
        tabBarExtraContent={
          <div
            className="px-2 py-1 bg-colorSecondary hover:bg-[#fff] text-[#fff] hover:text-colorSecondary cursor-pointer rounded-md"
            onClick={showDrawer}
          >
            Fav Coin
          </div>
        }
      >
        <TabPane
          className="buy-tab"
          tab={<span className="buy-tab-label">Buy</span>}
          size="small"
          key="1"
        >
          <Buy
            coin={coin}
            market={market}
            showbalance={showbalance}
            tradetype={tradetype}
          />
        </TabPane>
        <TabPane   size="small" className="sell-tab" tab="Sell" key="2">
          <Sell
            coin={coin}
            market={market}
            showbalance={showbalance}
            tradetype={tradetype}
          />
        </TabPane>
      </Tabs>
      <Drawer
        title="Favourate Coin"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div>
          <div className="flex justify-between items-center font-bold mb-2">
            <div>Symbol</div>
            <div>Last</div>
            <div>Chg%</div>
          </div>
          {data.map((item) => (
            <div className="flex justify-between items-center border-b-2 py-1 hover:bg-[#e1e1e1]">
              <div>{item.symbol}</div>
              <div>{item.last}</div>
              <div>{item.change}</div>
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  );
}

export default BuySell;
