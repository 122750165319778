import React, { useEffect, useState } from 'react'
import './App.css';
import Routerpage from './Routes/Routerpage';
import Loader from './Layout/Loader';


const App = () => {

   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
     // Simulate content loading (e.g., fetching data) with a setTimeout
     setTimeout(() => {
       setIsLoading(false);
     }, 100); // Replace with your actual loading logic
   }, []);

  return (
    <>
      <div className="App">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Routerpage />
          </>
        )}
      </div>
    </>
  );
}

export default App
