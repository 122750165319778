import React, { useEffect, useState } from "react";
import { ViewReport } from "../Components/ViewReport";
import { toast } from "react-toastify";
import axios from "axios";
import img1 from "../assets/Api/BINANCEFUTURES.png";
import img2 from "../assets/Api/BINANCESPOT.png";
import img5 from "../assets/Api/BYBITFUTURES.png";
import img6 from "../assets/Api/BYBITSPOT.png";
import img3 from "../assets/Api/KUCOINFUTURES.png";
import img4 from "../assets/Api/KUCOINSPOT.png";
import moment from "moment";
import SkeltorLoader from "../Components/SkeltorLoader";

function Reportcopy() {
  const [filter, setFilter] = useState("All");
  const [filter1, setFilter1] = useState("hightolow");
  const [filter2, setFilter2] = useState("All");
  const [filterData, setfilterData] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [filterBot, setFilterBot] = useState([]);
  const [botData, setBotData] = useState([]);
  const [selectedBotID, setSelectedBotID] = useState("");
  const [createState, setCreateState] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasActiveBots, setHasActiveBots] = useState(true);

  const handleFilterClick = (filterType) => {
    setFilter(filterType);
    setSelectedOption("");
  };

  useEffect(() => {
    const masterfilter = botData?.filter((item) => {
      return (
        (filter === "ALL" || item.Exchange_Type === filter) &&
        (!selectedOption || item.API_Name === selectedOption) &&
        (!selectedBotID || item.Name === selectedBotID)
      );
    });

    setFilterBot(masterfilter);
  }, [filter, selectedOption, selectedBotID, botData]);

  useEffect(() => {
    const updatedData = botData.map((item) => {
      const tradeProfit = parseFloat(
        ((item.TotalProfitL + item.TotalProfitS) /
          (item.InvestedL + item.InvestedS)) *
          100
      ).toFixed(2);
      return {
        ...item,
        tradeProfit: isNaN(tradeProfit) ? 0 : parseFloat(tradeProfit),
      };
    });

    setFilterBot(updatedData);
  }, [botData]);

  useEffect(() => {
    handleSubmit();
    setFilter("ALL");
    setFilter1("hightolow");
  }, []);

  useEffect(() => {
    const updatedData = filterBot.map((item) => {
      const tradeProfit = parseFloat(
        ((item.TotalProfitL + item.TotalProfitS) /
          (item.InvestedL + item.InvestedS)) *
          100
      ).toFixed(2);
      return {
        ...item,
        tradeProfit: isNaN(tradeProfit) ? 0 : parseFloat(tradeProfit),
      };
    });

    setFilterBot(updatedData);
  }, []);

  const sortedFilterBotHighToLow = () => {
    console.log("Sorting High to Low");
    const sortedData = [...filterBot].sort((a, b) => {
      return b.tradeProfit - a.tradeProfit;
    });
    console.log("Sorted Data High to Low: ", sortedData);
    setFilterBot(sortedData);
    setFilter1("hightolow");
  };

  const sortedFilterBotLowToHigh = () => {
    console.log("Sorting Low to High");
    const sortedData = [...filterBot].sort((a, b) => {
      return a.tradeProfit - b.tradeProfit;
    });
    console.log("Sorted Data Low to High: ", sortedData);
    setFilterBot(sortedData);
    setFilter1("lowtohigh");
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event) => {
    setSelectedBotID(event.target.value);
  };

  const uniqueSymbolsSet = new Set();
  const uniqueSymbolsArray = [];
  const AllBotName = [];

  const newArrayWithFilteredExchangeType = [];

  if (botData) {
    botData.forEach((item) => {
      const { API_Name, VarBotID } = item;
      if (!uniqueSymbolsSet.has(API_Name)) {
        uniqueSymbolsSet.add(API_Name);
        uniqueSymbolsArray.push(API_Name);
      }

      // if (item.Exchange_Type === filter) {
      //   newArrayWithFilteredExchangeType.push(item);
      // }

      if (!uniqueSymbolsSet.has(VarBotID)) {
        uniqueSymbolsSet.add(VarBotID);
        AllBotName.push(VarBotID);
      }
    });
  }

  const uniqueEchangeSet = new Set();
  const uniqueEchange = [];

  if (isNaN(newArrayWithFilteredExchangeType)) {
    console.log("null");
    newArrayWithFilteredExchangeType.forEach((item) => {
      const { API_Name } = item;
      if (!uniqueEchangeSet.has(API_Name)) {
        uniqueEchangeSet.add(API_Name);
        uniqueEchange.push(API_Name);
      }
    });
  }

  console.log(
    "newArrayWithFilteredExchangeType",
    newArrayWithFilteredExchangeType
  );
  console.log("uniqueEchange", uniqueEchange);

  const uniqueApiNamesSet = new Set();

  botData
    .filter((item) => filter === "ALL" || item.Exchange_Type === filter)
    .forEach((item) => {
      uniqueApiNamesSet.add(item.API_Name);
    });

  // Convert the Set back to an array
  const createApiList = Array.from(uniqueApiNamesSet);

  // const createApiList = botData
  //   .filter((item) => filter === "ALL" || item.Exchange_Type === filter)
  //   .map((item) => item.API_Name);

  // const createApiList = filter == "ALL" ? uniqueSymbolsArray : uniqueEchange
  // useEffect(() => {
  //   setSelectedOption("")
  // },[createApiList])
  // console.log("createApiList" , createApiList)

  const exchageName = botData.filter((item) => item.Trade_Type == filter);

  const filtrBotNameSet = new Set();
  const filtrBotName = [];

  if (exchageName.length > 0) {
    exchageName.forEach((item) => {
      const { Name } = item;
      if (!filtrBotNameSet.has(Name)) {
        filtrBotNameSet.add(Name);
        filtrBotName.push(Name);
      }
    });
  }

  const images = {
    BINANCEFUTURES: img1,
    BINANCESPOT: img2,
    KUCOINSPOT: img4,
    KUCOINFUTURES: img3,
    BYBITFUTURES: img5,
    BYBITSPOT: img6,
  };

  const handleSubmit = async (e) => {
    const jwt = localStorage.getItem("jwt");
    const postData = {
      project: "FinGenius",
      jwt: jwt,
    };
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "report",
      data: postData,
    })
      .then(async function (response) {
        const res = await response?.data;
        // console.log(response, "hello======>");
        if (res?.Success) {
          // console.log(res?.Success, "hello======>");
          // toast.success(res?.Success);
          setfilterData(res?.Success);
          setBotData(res?.Success);
          setLoading(false);
        } else {
          console.log(res?.Error);
        }
      })
      .catch((err) => {
        console.log(err?.data?.response?.Error);
        setLoading(false);
      });
  };

  const availableApiNames = createApiList.filter((apiName) => {
    return botData.some((item) => {
      return (
        (filter === "ALL" || item.Exchange_Type === filter) &&
        (filter2 === "All" || item.Type === filter2) &&
        item.API_Name === apiName
      );
    });
  });

  useEffect(() => {
    if (filter === "ALL") {
      setFilterBot(botData);
    } else {
      const filteredCards = botData.filter(
        (item) => item.Exchange_Type === filter
      );
      setFilterBot(filteredCards);
    }
  }, [filter, botData]);

  return (
    <div>
      <div className=" mt-5">
        <div className="shadow-neumorphic bg-colorPrimary pt-2 mx-5 mb-2 mt-3 lg:mt-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 lg:mx-5 mb-0 px-2 lg:px-4 sm:grid-cols-4">
            <div className="flex justify-center flex-col items-center lg:space-x-4 ">
              <label htmlFor="selectOption3">Exchange</label>
              <div className="p-2 ">
                <div className="flex mb-4 items-center justify-end">
                  <button
                    className={`mr-2 2xl:px-4 py-1 rounded-full w-20 xl:w-24 shadow-md drop-shadow-xl ${
                      filter === "ALL"
                        ? "bg-colorAccent text-white border-2 border-white "
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={() => handleFilterClick("ALL")}
                  >
                    All
                  </button>
                  <button
                    className={`mr-2 2xl:px-4 py-1 rounded-full w-20 xl:w-24 shadow-md ${
                      filter === "SPOT"
                        ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={() => handleFilterClick("SPOT")}
                  >
                    Spot
                  </button>
                  <button
                    className={`mr-2 xl:px-4 py-1 rounded-full w-20 xl:w-24 shadow-md ${
                      filter === "FUTURES"
                        ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={() => handleFilterClick("FUTURES")}
                  >
                    Future
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center lg:space-x-4 ">
              <label htmlFor="selectOption3">FROM</label>
              <div className="p-2 ">
                <div className="flex mb-4 items-center justify-end">
                  <button
                    className={`mr-2 px-1 2xl:w-28 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${
                      filter1 === "hightolow"
                        ? "bg-colorAccent text-white border-2 border-white "
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={sortedFilterBotHighToLow}
                  >
                    High to Low
                  </button>

                  <button
                    className={`px-1 py-1 rounded-full text-sm w-28 shadow-md ${
                      filter1 === "lowtohigh"
                        ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={sortedFilterBotLowToHigh}
                  >
                    Low to High
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center lg:space-x-4 ">
              <label htmlFor="selectOption3">Type</label>
              <div className="p-2 ">
                <div className="flex mb-4 items-center justify-end">
                  <button
                    className={`mr-2 px-1 2xl:w-28 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${
                      filter2 === "All"
                        ? "bg-colorAccent text-white border-2 border-white "
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={() => setFilter2("All")}
                  >
                    All
                  </button>
                  <button
                    className={`mr-2 px-1 2xl:w-28 text-sm py-1 rounded-full w-[100px] shadow-md drop-shadow-xl ${
                      filter2 === "PAPER"
                        ? "bg-colorAccent text-white border-2 border-white "
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={() => setFilter2("PAPER")}
                  >
                    Paper
                  </button>

                  <button
                    className={`px-1 py-1 rounded-full text-sm w-28 shadow-md ${
                      filter2 === "LIVE"
                        ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                        : "bg-white text-colorAccent"
                    }`}
                    onClick={() => setFilter2("LIVE")}
                  >
                    Live
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-around items-center md:contents">
              <div className="flex flex-col items-center  lg:space-x-4 ">
                <label htmlFor="selectOption">Api Name</label>
                <div className="p-2 ">
                  <select
                    id="selectOption"
                    className="bg-colorPrimary shadow-neumorphicnew mt-1 px-4 py-1 rounded-full"
                    onChange={handleOptionChange}
                    value={selectedOption}
                  >
                    <option value="">All</option>
                    {availableApiNames.map((option, index) => (
                      <option
                        className="rounded-3xl"
                        key={index}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="grid 2xl:grid-cols-4 lg:grid-cols-3 grid-cols-1   md:grid-cols-2  gap-3 lg:gap-5">
            {loading
              ? Array.from({ length: 8 }).map((_, index) => (
                  <div className="bg-colorPrimary h-auto shadow-neumorphic rounded-lg shadow-2xl py-2 ">
                    <div className="mx-4 ">
                      <div className="pt-2">
                        <div className="flex justify-around bg-colorSecondaryDark h-20 items-center text-white shadow-neumorphic   py-[10px]  rounded-[10px]">
                          <div className="flex flex-col justify-around w-1/2 px-2 gap-1"></div>
                        </div>
                        <div className="flex justify-around  h-20 bg-colorPrimary py-1 shadow-neumorphicnew shadow-md text-[#212121] items-center rounded-[10px] pb-2 my-2"></div>
                        <div className="flex flex-col gap-2 h-32 bg-colorPrimary py-1 shadow-neumorphicnew  rounded-md px-1 my-2">
                          <div className="pt-2 w-full ">
                            <SkeltorLoader />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : filterBot
                  .filter((item) => {
                    if (filter === "ALL") {
                      return true;
                    }
                    return item.Exchange_Type === filter;
                  })
                  .filter((item) => {
                    if (filter2 === "PAPER") {
                      return item.Type === "PAPER";
                    } else if (filter2 === "LIVE") {
                      return item.Type === "LIVE";
                    }
                    return true;
                  })
                  .map((item, index) => {
                    const startDate = new Date(
                      moment(item?.StartDate, "DD-MM-YYYY HH:mm")
                    );

                    const currentDate = new Date();
                    const timeDiff = currentDate - startDate;

                    const daysDiff = Math.floor(
                      timeDiff / (1000 * 60 * 60 * 24)
                    );

                    const imageKey = `${item?.Exchange}${item?.Exchange_Type}`;
                    const imageToDisplay = images[imageKey];

                    const tradeProfit = parseFloat(
                      ((item.TotalProfitL + item.TotalProfitS) /
                        (item.InvestedL + item.InvestedS)) *
                        100
                    ).toFixed(2);
                    const profit = isNaN(tradeProfit) ? 0 : tradeProfit;
                    console.log(item.Type, "ddsdsdsdsd");
                    return (
                      <ViewReport
                        key={index}
                        data={{
                          id: index,
                          Api: item?.API_Name,
                          VarBotID: item?.VarBotID,
                          BalanceL: item?.BalanceL,
                          BalanceS: item?.BalanceS,
                          Exchange: item?.Exchange,
                          Exchange_Type: item?.Exchange_Type,
                          HitRateL: item?.HitRateL,
                          HitRateS: item?.HitRateS,
                          InvestedL: item?.InvestedL,
                          InvestedS: item?.InvestedS,
                          LosingTradesL: item?.LosingTradesL,
                          LosingTradesS: item?.LosingTradesS,
                          TotalBrokerageL: item?.TotalBrokerageL,
                          TotalBrokerageS: item?.TotalBrokerageS,
                          TotalProfitL: item?.TotalProfitL,
                          TotalProfitPercentL: item?.TotalProfitPercentL,
                          TotalProfitPercentS: item?.TotalProfitPercentS,
                          TotalProfitS: item?.TotalProfitS,
                          TotalTradesL: item?.TotalTradesL,
                          TotalTradesS: item?.TotalTradesS,
                          WinningTradesL: item?.WinningTradesL,
                          WinningTradesS: item?.WinningTradesS,
                          Symbol: item?.Symbol,
                          imageToDisplay,
                          DaysSinceStart: daysDiff,
                          profit: profit,

                          // tradeProfit,
                        }}
                      />
                    );
                  })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reportcopy;
