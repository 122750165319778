import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Table, Switch, Button, Modal } from 'antd'
import { toast } from 'react-toastify';
import SkeltorLoader from '../Components/SkeltorLoader';
import { TbFilterCog } from 'react-icons/tb';
import { useMediaQuery } from 'react-responsive';


const ActiveBot = () => {
    const [botData, setBotData] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [botData1, setBotData1] = useState([]);
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState()
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOption1, setSelectedOption1] = useState("");
    const [filterBot, setFilterBot] = useState([]);
    const [selectedBotID, setSelectedBotID] = useState("");
    const [loading, setLoading] = useState(true); 
const [selectedSymbols, setSelectedSymbols] = useState([]);
const [showOptions, setShowOptions] = useState(false);
const [showOptions1, setShowOptions1] = useState(false);
const [selectedAPINames, setSelectedAPINames] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [pagesize, setpagesize] = useState(20);



    const [filter, setFilter] = useState({
      type: "All",
      coin: "",
      api:"",
      botid: "",
    });
     const [selectedExchange, setSelectedExchange] = useState("All");
    const [selectedBotType, setSelectedBotType] = useState("All");
    const [VarBotID, setVarBotID] = useState([]);
     const [isModalVisible1, setIsModalVisible1] = useState(false);

     const showModal1 = () => {
       setIsModalVisible1(true);
     };

     const handleCancel1 = () => {
       setIsModalVisible1(false);
     };


    const showModal = (record) => {
        setVisible(true);
        setRecord(record)
    };

    const handleOk = () => {
        setVisible(false);
        handleDelete()
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const simpleData = []


    const simpleColumns = [
      {
        title: "Date Time",
        dataIndex: "DateTime",
        width: 350, 
        
      },
      {
        title: "Bot Name",
        dataIndex: "BotName",
        width: 200,
      },
      {
        title: "API Name",
        dataIndex: "API_Name",
        width: 200,
      },
      {
        title: "Exchange",
        dataIndex: "Exchange",
        width: 200,
      },
      {
        title: "Exchange Type",
        dataIndex: "Exchange_Type",
        width: 200,
      },
      // {
      //   title: "Type",
      //   dataIndex: "Type",
      //   width: 200,
      // },
      {
        title: "Symbol",
        dataIndex: "Symbol",
        width: 200,
      },
      {
        title: "Invest",
        dataIndex: "Invest",
        width: 200,
      },
      {
        title: "Telegram Alert",
        dataIndex: "Telegram_Alert",
        key: "Telegram_Alert",
        editable: true,
        className: "w-7",
        width: 200,
        render: (text, record) => (
          <>
            <div className="justify-center items-center ">
              <div>
                <Switch
                  className={record.Telegram_Alert ? "off" : "on"}
                  checked={record.Telegram_Alert}
                  checkedChildren={<div className="">ON</div>}
                  unCheckedChildren={<div className="text-white">OFF</div>}
                  onChange={(checked) =>
                    handleSwitchChangetele(record, checked)
                  }
                />

                {/* <Switch
                  className={record.Telegram_Alert === true ? "off" : "on"} // Use your condition here
                  checked={record?.Telegram_Alert === true} // Use your condition here
                  checkedChildren={<div className=" "> ON</div>}
                  unCheckedChildren={<div className="text-white  ">OFF</div>}
                  onChange={(checked) =>
                    handleSwitchChangetele(record, checked)
                  }
                /> */}
              </div>
            </div>
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        editable: true,
        className: "w-7",
        width: 200,
        render: (text, record) => (
          <>
            <div className="justify-center items-center ">
              <div>
                <Switch
                  className={record.Status === "ACTIVE" ? "off" : "on"}
                  checked={record?.Status === "ACTIVE"}
                  checkedChildren={<div className=" "> Start</div>}
                  unCheckedChildren={<div className="text-white  ">Pause</div>}
                  onChange={(checked) => handleSwitchChange(record, checked)}
                />
              </div>
            </div>
          </>
        ),
      },
      // {
      //   title: "Delete",
      //   dataIndex: "Delete",
      //   key: "Delete",
      //   width: 100,
      //   render: (text, record) => (
      //     <Button
      //       type="danger"
      //       onClick={() => showModal(record)}
      //       // onClick={() => handleDelete(record)}
      //     >
      //       {/* <MdOutlineDeleteOutline /> */}
      //       <DeleteOutlined className=" text-lg" />
      //     </Button>
      //   ),
      // },
      ,
    ];

 
    if (botData) {
      let filteredRows = botData;

      if (filter?.type && filter?.type !== "All") {
        filteredRows = botData?.filter(
          (item) => item?.Exchange_Type === filter?.type
        );
      }

      if (filter?.coin && filter?.coin?.length > 0) {
        filteredRows = filteredRows?.filter((item) =>
          filter?.coin?.includes(item?.Symbol)
        );
      }

       if (filter?.api && filter?.api?.length > 0) {
         filteredRows = filteredRows?.filter((item) =>
           filter?.api?.includes(item?.API_Name)
         );
       }
    
      //  if (filter?.api) {
      //    filteredRows = filteredRows?.filter(
      //      (item) => item?.API_Name === filter?.api
      //    );
      //  }
     
      if (selectedBotType) {
         filteredRows = filteredRows?.filter((item) => {
          if (selectedBotType === "PAPER") {
            return item?.Type === "PAPER";
          } else if (selectedBotType === "LIVE") {
            return item?.Type === "LIVE";
          }
          return true;
        });
      }
      if (filter?.botid) {
        filteredRows = filteredRows?.filter(
          (item) => item?.VarBotID === filter?.botid
        );
      }

      for (let i = 0; i < filteredRows?.length; i++) {
        let Invest;
        if (filteredRows[i]?.Exchange_Type === "FUTURES") {
          Invest = filteredRows[i]?.InvestL + filteredRows[i]?.InvestS;
        } else {
          Invest = filteredRows[i]?.Invest;
        }

        simpleData?.push({
          key: i,
          DateTime: filteredRows[i]?.DateTime,
          BotName: filteredRows[i]?.BotName,
          Exchange: filteredRows[i]?.Exchange,
          Exchange_Type: filteredRows[i]?.Exchange_Type,
          Type: filteredRows[i]?.Type,
          Symbol: filteredRows[i]?.Symbol,
          Invest: Invest,
          Balance: filteredRows[i]?.Balance,
          ChatID: filteredRows[i]?.ChatID,
          Status: filteredRows[i]?.Status,
          API_Name: filteredRows[i]?.API_Name,
          VarBotID: filteredRows[i]?.VarBotID,
          Telegram_Alert: filteredRows[i]?.Telegram_Alert,
        });
      }
    }

    const onChange = (pagination, filters, sorter, extra) => {
      console.log(
        pagination,
        filters,
        sorter,
        extra,
        "pagination, filters, sorter, extra"
      );
      setpagesize(pagination.pageSize);
      // console.log("params", pagination, filters, sorter, extra);
    };



    useEffect(() => {
      const filteredData = botData?.filter((item) => {
        if (selectedBotType === "PAPER") {
          return item?.Type === "PAPER";
        } else if (selectedBotType === "LIVE") {
          return item?.Type === "LIVE";
        }
        return true;
      });

    const filteredRows = botData.filter((item) => {
      const exchangeFilter =
        filter.type === "All" || item.Exchange_Type === filter.type;
      const botTypeFilter =
        selectedBotType === "All" || item.Type === selectedBotType;
      const symbolFilter =
        selectedSymbols.length === 0 || selectedSymbols.includes(item.Symbol);
        const symbolFilter1 =
          selectedAPINames.length === 0 ||
          selectedAPINames.includes(item.API_Name);
      return exchangeFilter && botTypeFilter && symbolFilter && symbolFilter1;
    });


      setFilterBot(filteredRows);
    }, [filter, selectedBotType, selectedSymbols, botData, selectedAPINames]);

    const handleDelete = () => {
        const token = localStorage.getItem('jwt')
        const postData = {
            project: "FinGenius",
            jwt: token,
            bot_id: record?.VarBotID,
            api_name: record?.API_Name
        }

        const response = axios({
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            url: process.env.REACT_APP_API_PATH + 'stop',
            data: postData
        }).then(res => {
          toast.success(res?.data?.Success);
            get_bot_list()
        })
            .catch(err => console.log(err))
    }

   const handleSwitchChange = (record, checked) => {
     const newStatus = checked ? "ACTIVE" : "INACTIVE";

     if (checked) {
       botStart(record);
     } else {
       botStop(record);
     }

    
     const updatedDataSource = botData?.map((item) =>
       item.VarBotID === record?.VarBotID ? { ...item, Status: newStatus } : item
     );
     setBotData(updatedDataSource);
   };


const handleSwitchChangetele = (record, checked) => {
  const newStatus = checked ? "true" : "false";
  const updatedDataSource1 = dataSource.map((item) =>
    item.API_Name === record.API_Name ? { ...item, Status: newStatus } : item
  );
  setDataSource(updatedDataSource1);
   const jwt = localStorage.getItem("jwt");
  const postData = {
    jwt: jwt,
    api_name: record.API_Name,
    status: newStatus,
    project: "FinGenius",
    bot_id: record.VarBotID,
  };

  axios({
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    url: process.env.REACT_APP_API_PATH_BOT + "telegram_alert_status",
    data: postData,
  })
    .then(async function (response) {
      const res = await response.data;
      if (res?.Success) {
        toast.success(res?.Success);
        get_bot_list();
      } else if (res.Error) {
        toast.error(res.Error);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.Error);
    });
};

 

    const botStart = (record) => {
        const token = localStorage.getItem('jwt')
        const postData = {
            project: "FinGenius",
            jwt: token,
            bot_id: record?.VarBotID,
            api_name: record?.API_Name
        }
        const response = axios({
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            url: process.env.REACT_APP_API_PATH + 'start',
            data: postData
        }).then(res => {
            toast.success(res?.data?.Success)
        })
            .catch(err => console.log(err))
    }

    const botStop = (record) => {
        const token = localStorage.getItem('jwt')
        const postData = {
            project: "FinGenius",
            jwt: token,
            bot_id: record?.VarBotID,
            api_name: record?.API_Name
        }
        const response = axios({
          method: "POST",
          headers: {
            accept: "application/json",
          },
          url: process.env.REACT_APP_API_PATH + "pause",
          data: postData,
        })
          .then((res) => toast.success(res?.data?.Success))
          .catch((err) => toast.error(err?.res?.data?.Error));
    }


    const get_bot_list = () => {
        const token = localStorage.getItem('jwt')
        const response = axios({
          method: "POST",
          headers: {
            accept: "application/json",
          },
          url: process.env.REACT_APP_API_PATH_BOT + "bot_list",
          data: {
            project: "FinGenius",
            jwt: token,
          },
        })
          .then((res) => {
            const response= res?.data?.Success;
             const sortedData = response?.sort(
               (a, b) => new Date(b?.DateTime) - new Date(a?.DateTime)
             );

            setBotData(sortedData);
            setLoading(false);
          })
          .catch((err) => console.log(err));
          setLoading(false);
    }

    
    useEffect(() => {
        get_bot_list()
    }, [])

const handleFilterClick = (type,botid,api) => {
  setFilter({ ...filter, type, botid, api,  });
  setSelectedExchange(type);
  setSelectedSymbols("");
  setSelectedOption1("");
  
};

const handleFilterByBotType = (botType) => {
  setSelectedBotType(botType);
};



const uniqueSymbolsSet = new Set();
const uniqueSymbolsArray = [];
const uniqueAPIArray = [];
const AllBotName = [];

if (botData) {
  botData.forEach((item) => {
    const { Symbol, Name, API_Name, VarBotID } = item;
    if (!uniqueSymbolsSet.has(Symbol)) {
      uniqueSymbolsSet.add(Symbol);
      uniqueSymbolsArray.push(Symbol);
    }

     if (!uniqueSymbolsSet.has(API_Name)) {
       uniqueSymbolsSet.add(API_Name);
       uniqueAPIArray.push(API_Name);
     }

    if (!uniqueSymbolsSet.has(VarBotID)) {
      uniqueSymbolsSet.add(VarBotID);
      AllBotName.push(VarBotID);
    }
  });
}

const exchageName = botData?.filter((item) => item?.Trade_Type == filter);

const filtrBotNameSet = new Set();
const filtrBotName = [];

if (exchageName.length > 0) {
  exchageName.forEach((item) => {
    const { VarBotID } = item;
    if (!filtrBotNameSet.has(VarBotID)) {
      filtrBotNameSet.add(VarBotID);
      filtrBotName.push(VarBotID);
    }
  });
}
 
   const handleOptionChange2 = (event) => {
     setSelectedOption1(event.target.value);
     setFilter({ ...filter, api: event.target.value });
   };


   const availableAPINames = uniqueAPIArray.filter((apiName) => {
     if (selectedExchange === "All") {
       return true;
     } else if (selectedExchange === "SPOT") {
       return botData.some(
         (item) =>
           item.Exchange_Type === selectedExchange &&
           item.API_Name === apiName &&
           (selectedBotType === "All" || item.Type === selectedBotType)
       );
     } else if (selectedExchange === "FUTURES") {
       return botData.some(
         (item) =>
           item.Exchange_Type === selectedExchange &&
           item.API_Name === apiName &&
           (selectedBotType === "All" || item.Type === selectedBotType)
       );
     }
     return true;
   });

    const filterUniqueSymbols = uniqueSymbolsArray?.filter((symbol) => {
      if (selectedExchange === "All") {
        return true;
      } else if (selectedExchange === "SPOT") {
        return botData?.some(
          (item) =>
            item?.Exchange_Type === selectedExchange &&
            item?.Symbol === symbol &&
            (selectedBotType === "All" || item.Type === selectedBotType)
        );
      } else if (selectedExchange === "FUTURES") {
        return botData?.some(
          (item) =>
            item.Exchange_Type === selectedExchange &&
            item.Symbol === symbol &&
            (selectedBotType === "All" || item.Type === selectedBotType)
        );
      }
      return true;
    });



const options = filterUniqueSymbols; 

 const handleOptionSelect = (option) => {
   const updatedOptions = [...selectedOptions];

   if (updatedOptions.includes(option)) {
     updatedOptions.splice(updatedOptions.indexOf(option), 1);
   } else {
     updatedOptions.push(option);
   }

   setSelectedOptions(updatedOptions);
  setFilter({ ...filter, api: updatedOptions });
 };


const handleSymbolFilter = (symbol) => {
  const updatedSelectedSymbols = [...selectedSymbols];

  if (updatedSelectedSymbols.includes(symbol)) {
    updatedSelectedSymbols.splice(updatedSelectedSymbols.indexOf(symbol), 1);
  } else {
    updatedSelectedSymbols.push(symbol);
  }
  setSelectedSymbols(updatedSelectedSymbols);
  setFilter({ ...filter, coin: updatedSelectedSymbols });
};
   

const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef1 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setShowOptions1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

   const isMobile = useMediaQuery({ maxWidth: 1050 });

   const pageSize = isMobile ? 10 : 22;

   const pageSizeOptions = [10, 20, 30, 50, 100];

    return (
      <>
        <header className=" container flex fixed shadow-neumorphic bg-colorPrimary   justify-end z-30  w-full Block lg:hidden ">
          <div className=" xl:hidden block ">
            <div type="primary" onClick={showModal1} className="">
              <TbFilterCog size={25} className="text-colorSecondaryDark" />
            </div>
            <Modal
              title="Status"
              visible={isModalVisible1}
              // onOk={false}
              footer={false}
              onCancel={handleCancel1}
            >
              <div className="shadow-neumorphic bg-colorPrimary pt-2 rounded-3xl mb-2">
                <div className="grid gid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3   2xl:grid-cols-3 lg:mx-5 mb-0 px-2 lg:px-4 ">
                  {/* <div className="mb-4"> */}
                  <div className="flex justify-center flex-col items-center lg:space-x-4 ">
                    <label htmlFor="selectOption3">Exchange</label>
                    <div className="p-2 ">
                      <div className="flex mb-4 items-center justify-end">
                        <button
                          className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                            filter?.type === "All"
                              ? "bg-colorSecondaryDark text-white border-2 border-white"
                              : "bg-white text-colbg-colorSecondaryDark"
                          }`}
                          onClick={() => handleFilterClick("All")}
                        >
                          All
                        </button>
                        <button
                          className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                            filter?.type === "SPOT"
                              ? "bg-colorSecondaryDark text-white border-2 border-white"
                              : "bg-white text-colbg-colorSecondaryDark"
                          }`}
                          onClick={() => handleFilterClick("SPOT")}
                        >
                          Spot
                        </button>
                        <button
                          className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                            filter?.type === "FUTURES"
                              ? "bg-colorSecondaryDark text-white border-2 border-white"
                              : "bg-white text-colbg-colorSecondaryDark"
                          }`}
                          onClick={() => handleFilterClick("FUTURES")}
                        >
                          Future
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="justify-center flex-col items-center lg:space-x-4 hidden">
                    <label htmlFor="selectOption3">Type</label>
                    <div className="p-2 ">
                      <div className="flex mb-4 items-center justify-end">
                        <button
                          className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px] shadow-md ${
                            selectedBotType === "All"
                              ? "bg-colorAccent text-white border-2 border-white"
                              : "bg-white text-colorAccent"
                          }`}
                          onClick={() => handleFilterByBotType("All")}
                        >
                          All
                        </button>
                        {/* <button
                      className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                        selectedBotType === "PAPER"
                          ? "bg-colorAccent text-white border-2 border-white"
                          : "bg-white text-colorAccent"
                      }`}
                      onClick={() => handleFilterByBotType("PAPER")}
                    >
                      Paper
                    </button> */}
                        <button
                          className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                            selectedBotType === "LIVE"
                              ? "bg-colorAccent text-white border-2 border-white"
                              : "bg-white text-colorAccent"
                          }`}
                          onClick={() => handleFilterByBotType("LIVE")}
                        >
                          Live
                        </button>
                      </div>
                    </div>
                  </div>

                  {/*
                   */}

                  <div
                    ref={dropdownRef1}
                    className="flex flex-col items-center  lg:space-x-4 "
                  >
                    <label htmlFor="selectOption text-center">API Name</label>
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectedOption"
                            onClick={() => setShowOptions1(!showOptions1)}
                            className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                          >
                            Select API Name
                          </button>

                          {showOptions1 && (
                            <div className="mt-2 absolute left-0 w-full bg-white  border border-gray-300 rounded-md shadow-md z-40">
                              {availableAPINames?.map((option, index) => (
                                <label
                                  key={index}
                                  className={`block flex items-center p-2 ${
                                    selectedOptions.includes(option)
                                      ? "bg-colorSecondaryDark/60 mt-1 text-white"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedOptions.includes(option)}
                                    onChange={() => handleOptionSelect(option)}
                                    className="mr-2"
                                  />
                                  <span className="whitespace-no-wrap">
                                    {option}
                                  </span>
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    ref={dropdownRef}
                    className="flex flex-col items-center  lg:space-x-4 "
                  >
                    <label htmlFor="selectedOption">Symbols for All</label>
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectedOption"
                            onClick={() => setShowOptions(!showOptions)}
                            className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                          >
                            Select Symbols
                          </button>

                          {showOptions && (
                            <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                              {filterUniqueSymbols?.map((option, index) => (
                                <label key={index} className="block p-2">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleSymbolFilter(option)}
                                    checked={selectedSymbols.includes(option)}
                                    className="mr-2"
                                  />
                                  {option}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </header>

        <div>
          <div className="shadow-neumorphic bg-colorPrimary pt-2 mx-5 mb-2 mt-3 lg:mt-0 hidden md:block">
            <div className="grid gid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3   2xl:grid-cols-3 lg:mx-5 mb-0 px-2 lg:px-4 ">
              {/* <div className="mb-4"> */}
              <div className="flex justify-center flex-col items-center lg:space-x-4 ">
                <label htmlFor="selectOption3">Exchange</label>
                <div className="p-2 ">
                  <div className="flex mb-4 items-center justify-end">
                    <button
                      className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                        filter?.type === "All"
                          ? "bg-colorAccent text-white border-2 border-white"
                          : "bg-white text-colorAccent"
                      }`}
                      onClick={() => handleFilterClick("All")}
                    >
                      All
                    </button>
                    <button
                      className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                        filter?.type === "SPOT"
                          ? "bg-colorAccent text-white border-2 border-white"
                          : "bg-white text-colorAccent"
                      }`}
                      onClick={() => handleFilterClick("SPOT")}
                    >
                      Spot
                    </button>
                    <button
                      className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                        filter?.type === "FUTURES"
                          ? "bg-colorAccent text-white border-2 border-white"
                          : "bg-white text-colorAccent"
                      }`}
                      onClick={() => handleFilterClick("FUTURES")}
                    >
                      Future
                    </button>
                  </div>
                </div>
              </div>

              <div className="justify-center flex-col items-center lg:space-x-4 hidden">
                <label htmlFor="selectOption3">Type</label>
                <div className="p-2 ">
                  <div className="flex mb-4 items-center justify-end">
                    <button
                      className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px] shadow-md ${
                        selectedBotType === "All"
                          ? "bg-colorAccent text-white border-2 border-white"
                          : "bg-white text-colorAccent"
                      }`}
                      onClick={() => handleFilterByBotType("All")}
                    >
                      All
                    </button>
                    {/* <button
                      className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                        selectedBotType === "PAPER"
                          ? "bg-colorAccent text-white border-2 border-white"
                          : "bg-white text-colorAccent"
                      }`}
                      onClick={() => handleFilterByBotType("PAPER")}
                    >
                      Paper
                    </button> */}
                    <button
                      className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md ${
                        selectedBotType === "LIVE"
                          ? "bg-colorAccent text-white border-2 border-white"
                          : "bg-white text-colorAccent"
                      }`}
                      onClick={() => handleFilterByBotType("LIVE")}
                    >
                      Live
                    </button>
                  </div>
                </div>
              </div>

              {/*
               */}

              <div
                ref={dropdownRef1}
                className="flex flex-col items-center  lg:space-x-4 "
              >
                <label htmlFor="selectOption text-center">API Name</label>
                <div className="p-2 ">
                  <div>
                    <div className="relative">
                      <button
                        id="selectedOption"
                        onClick={() => setShowOptions1(!showOptions1)}
                        className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                      >
                        Select API Name
                      </button>

                      {showOptions1 && (
                        <div className="mt-2 absolute left-0 w-full bg-white  border border-gray-300 rounded-md shadow-md z-40">
                          {availableAPINames?.map((option, index) => (
                            <label
                              key={index}
                              className={`block flex items-center p-2 ${
                                selectedOptions.includes(option)
                                  ? "bg-colorSecondaryDark/60 mt-1 text-white"
                                  : ""
                              }`}
                            >
                              <input
                                type="checkbox"
                                checked={selectedOptions.includes(option)}
                                onChange={() => handleOptionSelect(option)}
                                className="mr-2"
                              />
                              <span className="whitespace-no-wrap">
                                {option}
                              </span>
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={dropdownRef}
                className="flex flex-col items-center  lg:space-x-4 "
              >
                <label htmlFor="selectedOption">Symbols for All</label>
                <div className="p-2 ">
                  <div>
                    <div className="relative">
                      <button
                        id="selectedOption"
                        onClick={() => setShowOptions(!showOptions)}
                        className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                      >
                        Select Symbols
                      </button>

                      {showOptions && (
                        <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                          {filterUniqueSymbols?.map((option, index) => (
                            <label key={index} className="block p-2">
                              <input
                                type="checkbox"
                                onChange={() => handleSymbolFilter(option)}
                                checked={selectedSymbols.includes(option)}
                                className="mr-2"
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            title="Delete Confirmation"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={<span className=" text-black">OK</span>}
            cancelText="Cancel"
            footer={false}
          >
            <p>Are you sure you want to delete this Bot?</p>
            <div className=" flex justify-end mt-4">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out"
                onClick={handleDelete}
              >
                OK
              </button>
            </div>
          </Modal>
          <div className=" mx-5 table-newres ">
            {loading ? (
              <div className="w-full h-40 rounded-tr-3xl rounded-tl-3xl bg-colorPrimary shadow-neumorphic ">
                <div className="w-full h-14 bg-colorSecondaryDark ">
                  <SkeltorLoader />
                </div>
              </div>
            ) : (
              <Table
                columns={simpleColumns}
                dataSource={simpleData}
                onChange={onChange}
                pagination={{
                  pageSize: pagesize,
                  pageSizeOptions: pageSizeOptions,
                  // showSizeChanger: true,
                  position: ["topRight", "bottomRight"],
                }}
                className="mt-14 lg:mt-2"
              />
            )}
          </div>
        </div>
      </>
    );
}

export default ActiveBot
