import React from 'react'

const Check = () => {
  return (
    <div className=' text-black'>
      Check
    </div>
  )
}

export default Check
