import { Button, Checkbox, Form, Image, Input, Modal, Switch, Tabs, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaTelegram } from "react-icons/fa";
import { toast } from "react-toastify";
import images11 from "../assets/Exchange/F1.png";
import images21 from "../assets/Exchange/F2.png";
import images31 from "../assets/Exchange/F3.png";
import images41 from "../assets/Exchange/F4.png";
import images51 from "../assets/Exchange/F5.png";
import images61 from "../assets/Exchange/psd.png";
import Swal from "sweetalert2";
import TabPane from "antd/es/tabs/TabPane";
import SignatureCanvas from "react-signature-canvas";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import mypdf from "../assets/sample.pdf";

function ImagePreview({ onUpdateTelegramID, onrefesh,response ,verified}) {
  console.log(verified,"verified")
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [id, setId] = useState("");
  const [idshow, setIdshow] = useState();
  const [editMode, setEditMode] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [checkboxState1, setCheckboxState1] = useState(false);
  const [checkboxState2, setCheckboxState2] = useState(false);
  const [signatureVisible, setSignatureVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const signatureRef = useRef(null);
  const [totp, setTotp] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [telegramIdUpdated, setTelegramIdUpdated] = useState(false);
  const [signatureSubmitted, setSignatureSubmitted] = useState(false);
  const [signatureCompleted, setSignatureCompleted] = useState(false);


  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleTabChange = (key) => {
    if (key !== activeTab) {
      if (
        (activeTab === "tab1" && checkboxState1) ||
        (activeTab === "tab2" && checkboxState2)
      ) {
        setActiveTab(key);
        setSignatureVisible(false);
        setNextButtonDisabled(true);
      } else {
        alert("Please check the checkbox to proceed to the next tab.");
      }
    }
  };

  const handleCheckboxChange1 = (e) => {
    setCheckboxState1(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setCheckboxState2(e.target.checked);
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.currentPage);
    setNextButtonDisabled(e.currentPage !== totalPages - 1);
  };

  //  const handleNextButtonClick = (tab) => {
  //    setSignatureVisible(true); // Show the signature canvas
  //    setActiveTab(tab);
  //  };

  const handleNextButtonClick = (tab) => {
    if (
      (tab === "tab2" && checkboxState1) ||
      (tab === "signature" && checkboxState2)
    ) {
      setActiveTab(tab);
      setSignatureVisible(tab === "signature");
    } else {
      toast.error("Please check the checkbox to proceed to the next tab.");
    }
  };

  const handleBackButtonClick = () => {
    if (activeTab === "tab2") {
      setActiveTab("tab1");
    }
  };

  const handleSuccessButtonClick = (text) => {
    if (!signatureSubmitted) {
      setIsModalVisible(true);
    }
    // Swal.fire({
    //   title: "Success!",
    //   text: text,
    //   icon: "success",
    //   confirmButtonText: "OK",
    // });
  };

  const handleSubmitpdf = () => {
    const jwt = localStorage.getItem("jwt");
    const signatureData = signatureRef.current.toDataURL();
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
      privacy_policy: checkboxState1,
      tnc: checkboxState2,
      sign: signatureData,
    };

    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER + "accept_policy",
      data: postdata,
      body: JSON.stringify({ signatureData }),
    })
      .then(async function (response) {
        const res = await response.data;

        console.log(res);
        // if (res.Success === "Policies accepted successfully!") {
        //   toast.success(res?.Success);
        //   setSignatureSubmitted(true);
        //   setIsModalVisible(false);
        //   setSignatureCompleted(true);
          
        // } else {
        //   // Handle other responses if needed
        //   // Example: toast.error('Policies acceptance failed.');
        // }
        if (res !== "Policies accepted successfully!") {
          handleSuccessButtonClick();
          toast.success(res?.Success);
            setSignatureSubmitted(false);
            setIsModalVisible(false);
        }

      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.Error);
      });
  };

  const totalPages = 10;

  const images = [images11, images21, images31, images41, images51, images61];

  const handleNextClick = () => {
    if (currentStep < images.length) {
      setCurrentStep(currentStep + 1);
      setSelectedImage(null);
      setEditMode(false);
    }
  };

  const handlePrevClick = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setSelectedImage(null);
      setEditMode(false);
    }
  };

  const handleImageSelect = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleSubmit = () => {
    const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("jwt", jwt);
    formData.append("project", "FinGenius");
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER + "view_telegram",
      data: formData,
    })
      .then(async function (response) {
        const res = await response.data.Success;
        setId(res);
        console.log(res);
        console.log(idshow, "ghghg");
        onrefesh();
        // if (res.trim() === "") {
        //   handleSuccessButtonClick();
        // }
        console.log(verified,"verifiedshghdgjhgajhsdgdshagd")
         if (verified === "false") {
          console.log("i am ")
          handleSuccessButtonClick();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          toast.error(err.response.data.Error);
        }
      })
      .finally(() => {
        setIsSubmitDisabled(false);
      });
  };

 

    const [showConsentFormModal, setShowConsentFormModal] = useState(false);

    useEffect(() => {
      if (response?.Success === "Consent Form not verified!!") {
        setShowConsentFormModal(true);
      }
    }, [response]);
 

  const handleEdit = (e) => {
    e?.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("jwt", jwt);
    formData.append("project", "FinGenius");
    formData.append("telegramid", id);
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER + "update_telegram",
      data: formData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res.Success) {
          onrefesh();
          handleSubmit();
          onUpdateTelegramID(id);
          toast.success(res.Success);
          setEditMode(false);
          setTelegramIdUpdated(true);
          // if (firstTimeEdit) {
          //   handleSuccessButtonClick();
          // }
        } else {
          toast.error(res.Error);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          toast.error(err.response.data.Error);
        }
      });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const blurContent = () => {
    const contentDiv = document.querySelector(".h-auto");
    contentDiv.classList.toggle("blur");
  };

  const [toggleSwitch, setToggleSwitch] = useState(false);

  const handleToggleChange = (checked) => {
    setToggleSwitch(checked);
  };

  const [isSignatureDone, setIsSignatureDone] = useState(false);

  const handleSignature = () => {
    if (signatureRef.current && signatureRef.current.isEmpty()) {
      toast.error("Please provide a signature before submitting.");
    } else {
      setIsSignatureDone(true);
    }
  };

  return (
    <>
      <div className="h-auto  rounded-3xl xl:px-5 py-4 mx-auto text-black ">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-evenly ">
            <div>
              <div className="h-full  bg-colorPrimary shadow-neumorphicnew w-full rounded-3xl  p-3  ">
                <div className="w-full ">
                  <div style={{ position: "relative" }}>
                    <Image
                      // width={550}
                      height={230}
                      className="rounded-3xl"
                      src={images[currentStep - 1]}
                      alt={`Step ${currentStep}`}
                      onClick={() => handleImageSelect(images[currentStep - 1])}
                    />
                    <div className="text-white absolute top-1 left-1  p-2 bg-black opacity-80 rounded-full w-9 h-9 text-center">
                      {currentStep}
                    </div>
                  </div>
                  <div className="flex justify-between mt-2">
                    <div
                      onClick={handlePrevClick}
                      className="px-2 py-1 cursor-pointer bg-colorSecondaryDark text-white rounded hover-bg-blue-700"
                    >
                      Previous
                    </div>
                    <p className="text-gray-600">
                      Step {currentStep} of {images.length}
                    </p>
                    {currentStep === images.length ? (
                      <div
                        onClick={handleEditClick} // Add your desired event handler here
                        className="px-2 py-1 cursor-pointer bg-green-500 text-white rounded hover-bg-blue-700"
                      >
                        Finish
                      </div>
                    ) : (
                      <div
                        onClick={handleNextClick}
                        className="px-2 py-1 cursor-pointer bg-colorAccent text-white rounded hover-bg-blue-700"
                      >
                        Next
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full text-center align-middle">
              <div className="flex w-full justify-center items-center my-3 py-4 mx-2">
                {/* <div className="flex flex-row my-4"> */}
                <FaTelegram size={35} className="text-black" fill="#409cd7" />
                <div className="text-black text-2xl ml-2 font-bold italic">
                  Telegram ID
                </div>
              </div>

              {editMode ? (
                <Form>
                  <Input
                    className="w-2/3 shadow-neumorphicnew bg-colorPrimary rounded-full"
                    value={id}
                    type="number"
                    onChange={(e) => setId(e.target.value)}
                  />
                  <div className=" mt-5 justify-center flex items-center">
                    <button
                      type="submit"
                      className="bg-colorSecondaryDark text-white py-1 px-4 rounded-2xl"
                      onClick={handleEdit}
                      disabled={isSubmitDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <div className="text-md text-black w-2/3 h-7 pb-4 pt-1 pl-3 text-center shadow-neumorphicnew bg-colorPrimary rounded-full">
                    {id}
                  </div>
                  <div className="mt-4">
                    <Tooltip
                      title="Complete the process to the left"
                      trigger="hover"
                    >
                      <Button className="bg-colorSecondaryDark text-white">
                        Submit
                      </Button>
                    </Tooltip>
                    {/* <button
                    className="bg-colorSecondaryDark mt-4 text-white px-7 py-2 rounded-full hover-bg-colorSecondary "
                    disabled
                  >
                    Submit
                  </button> */}
                  </div>
                </div>
              )}
            </div>
            {/* </div> */}

            {/* <div className=" mt-5 justify-center">
            <button
              type="submit"
              className="bg-colorPrimaryDark text-white px-7 py-2 rounded-full hover-bg-colorSecondary"
              onClick={handleEdit}
              disabled={isSubmitDisabled}
            >
              Submit
            </button>
          </div> */}
          </div>
        </div>
      </div>
      {/* <Modal
        title="PDF Viewer with Signature"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane key="tab1">
            <div>
              <p>Page {currentPage + 1}</p>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                ...
              </Worker>

              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "500px",
                  width: "100%",
                }}
              >
                <Viewer
                  fileUrl={mypdf}
                  onPageChange={(e) => handlePageChange(e)}
                />
              </div>
            </div>
            <div>
              <Checkbox
                checked={checkboxState1 && currentPage === totalPages - 1}
                onChange={handleCheckboxChange1}
                disabled={currentPage !== totalPages - 1}
              >
                Accept terms and conditions for Tab 1
              </Checkbox>
            </div>
            <div>
              <button
                className="bg-colorSecondaryDark px-4 py-1 rounded-full text-white"
                onClick={() => handleNextButtonClick("tab2")}
                disabled={nextButtonDisabled}
              >
                Next
              </button>
            </div>
          </TabPane>

          <TabPane key="tab2">
            <div>
              <p>Page {currentPage + 1}</p>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                ...
              </Worker>

              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "500px",
                  width: "100%",
                }}
              >
                <Viewer
                  fileUrl={mypdf}
                  onPageChange={(e) => handlePageChange(e)}
                />
              </div>
            </div>
            <div>
              <Checkbox
                checked={checkboxState2 && currentPage === totalPages - 1}
                onChange={handleCheckboxChange2}
                disabled={currentPage !== totalPages - 1}
              >
                Accept terms and conditions for Tab 2
              </Checkbox>
            </div>
            <div className="flex justify-end items-center">
              <button
                className="bg-colorSecondaryDark px-4 py-1 rounded-full text-white gap-2 mr-1"
                onClick={handleBackButtonClick}
              >
                Back
              </button>
              <button
                className="bg-colorSecondaryDark px-4 py-1 rounded-full text-white"
                onClick={() => handleNextButtonClick("signature")}
                disabled={nextButtonDisabled}
              >
                Next
              </button>
            </div>
          </TabPane>

          <TabPane key="signature">
            <div>
              {signatureVisible && (
                <div>
                  <p>Subscriber dislaimer:</p>
                  <p>
                    I declare that I am aware of the provisions of Chapter 6 of
                    the GTC and that I assume full responsibility as defined
                    therein, and that I am aware of and expressly accept that
                    the Service Provider is not responsible in cases provided in
                    Chapter 5 of the GTC and in the Legal Statement, which was
                    previously read by me
                  </p>
                  <SignatureCanvas
                    ref={signatureRef}
                    canvasProps={{
                      width: 400,
                      height: 200,
                      className: "signature-canvas border-2 border-[#ccc] my-2 rounded-lg w-full",
                    }}
                  />

                  {isSignatureDone ? (
                    <button
                      type="primary"
                      className="bg-colorSecondaryDark px-4 py-1 rounded-full text-white"
                      onClick={handleSubmitpdf}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="primary"
                      className="bg-colorSecondaryDark px-4 py-1 rounded-full text-white"
                      onClick={handleSignature}
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}
             
            </div>
          </TabPane>
        </Tabs>
      </Modal> */}
    </>
  );
}

export default ImagePreview;
