import React, { useEffect, useState } from "react";
import { Avatar, Col, Image, Modal, Row, Slider, Tabs } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import Notifications from "../Components/Notifications";
import TelegramID from "../Components/TelegramID";
import ChangePassword from "../Components/ChangePAssword";
import Authcopy from "../Components/Authcopy";
import image1 from "../assets/Profile/1.png";
import image2 from "../assets/Profile/2.png";
import image3 from "../assets/Profile/3.png";
import image4 from "../assets/Profile/4.png";
import image5 from "../assets/Profile/5.png";
import image6 from "../assets/Profile/6.png";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";


const Profile = (props) => {
  const param = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const verified = searchParams.get("verified");
  console.log(verified,"sadhsagdhss");
  // const { response } = props;
  const successValue = props?.location?.state?.successValue;

  console.log(successValue)

  const [modalVisible, setModalVisible] = useState(false);
  const [profile, setProfile] = useState("");
  const [telegramID, setTelegramID] = useState("");
  const [telegramSwitchStatus, setTelegramSwitchStatus] = useState(false);

  const handleTelegramSwitchChange = (value) => {
    setToggle((prevToggle) => ({
      ...prevToggle,
      Telegram: value,
    }));
  };

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        console.log("response", response?.data?.Success);
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };


  const items = [
    // {
    //   key: "62",
    //   label: <div className="w-52">Telegram</div>,
    //   children: (
    //     <TelegramID
    //     verified= {verified}
    //       onUpdateTelegramID={(newID) => setTelegramID(newID)}
    //       onrefesh={View_Profile}
    //     />
    //   ),
    // },
    {
      key: "12",
      label: <div className="w-52">Notifications</div>,
      children: (
        <Notifications
          telegramSwitchStatus={telegramSwitchStatus}
          onTelegramSwitchChange={handleTelegramSwitchChange}
        />
      ),
    },
    {
      key: "32",
      label: <div className="w-52">Password</div>,
      children: <ChangePassword />,
    },
    {
      key: "43",
      label: <div className="w-52">2FA</div>,
      children: <Authcopy />,
    },
  ];

  const [toggle, setToggle] = useState({
    System: false,
    API: false,
    Fuel: false,
    Fund: false,
  });
  
  const closeModal = () => {
    setModalVisible(false);
  };

  const images = [image1, image2, image3, image4, image5, image6];
  const handleImageClick = (image) => {
    setSelectedImage(image); 
    setModalVisible(false); 
  };

  const [selectedImage, setSelectedImage] = useState(image1);
  const [isUploadButtonClicked, setIsUploadButtonClicked] = useState(false);

  const handleUploadButtonClick = () => {
    setIsUploadButtonClicked(true);
    setModalVisible(true); 
  };

  useEffect(() => {
    View_Profile();
  }, []);  

  const View_ProfileImage = async (image) => {
    localStorage.setItem("selectedImage", image);
    setSelectedImage(image);
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
      profile_image: image,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "update_profile_image",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        console.log("response", response?.data?.Success);
        if (response) {
          console.log("response", response?.data?.Success);
          toast.success(response?.data?.Success);
          setModalVisible(false);
          View_Profile();
        } else {
          toast.error(response?.data?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  return (
    <div className="lg:p-10 p-2">
      <div className="flex flex-col lg:flex-row  justify-center lg:justify-start gap-6 items-center lg:items-start h-full w-full bg-colorPrimary shadow-neumorphic py-5 shadow-lg rounded-lg ">
        <div className="px-6">
          <Image
            width={200}
            src={profile.Profile_Image ? profile.Profile_Image : selectedImage}
          />
          <div>
            <>
              <button
              className="py-1"
                icon={<UploadOutlined />}
                onClick={handleUploadButtonClick}
              >
                Change Profile Picture
              </button>
            </>
          </div>
        </div>
        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          centered
        >
          <Row gutter={[16, 16]}>
            {images?.map((image, index) => (
              <Col span={8} key={index}>
                <div className="image-option">
                  <img
                    className="images-avatar"
                    src={image}
                    alt={`Image ${index + 1}`}
                    onCancel={closeModal}
                    onClick={() => View_ProfileImage(image)}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Modal>

        <div className="xl:w-6/12  w-full text-center xl:text-start ">
          <div className="text-3xl my-2 text-colorSecondaryDark">
            {profile.Username}
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 ">
            <div className="xl:py-2">
              <div className="text-xs pt-4">UserID</div>
              <p className="text-lg text-colorSecondaryDark">
                {profile.UserID}
              </p>
              {/* <Slider defaultValue={30} /> */}
            </div>
            <div>
              <div className="text-xs pt-4">Name</div>
              <p className="text-lg text-colorSecondaryDark">{profile.Name}</p>
              {/* <Slider defaultValue={30} /> */}
            </div>
            <div>
              <div className="text-xs pt-4">EmailID</div>
              <p className="text-lg text-colorSecondaryDark">{profile.Email}</p>
              {/* <Slider defaultValue={30} /> */}
            </div>
            <div>
              <div className="text-xs pt-4">TelegramID</div>
              <p className="text-lg text-colorSecondaryDark">
                {/* {profile.TelegramID ? profile.TelegramID : telegramID} */}
                {profile.TelegramID ? profile.TelegramID : ""}
              </p>
              {/* <Slider defaultValue={30} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className=" min-h-[480px] grid items-center text-center  w-full bg-colorPrimary shadow-neumorphic p-4 shadow-lg rounded-lg mt-4">
        <div>
          <Tabs
            className="hidden lg:flex"
            tabPosition="left"
            type="card"
            items={items}
          />
          <Tabs className="block lg:hidden" type="card" items={items} />
        </div>
      </div>
    </div>
  );
};
export default Profile;
