import { Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import Test from "../Layout/Test";
import SubscriptionPackage from "../Components/SubscriptionPackage";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import moment from "moment/moment";
import SkeltorLoader from "../Components/SkeltorLoader";

function Subscirptions() {
  const [packageview, setPackageview] = useState([]);
  const [packageview1, setPackageview1] = useState([]);
  const [filteredData, setFilterData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [buy, setBuy] = useState({
    Name: "",
  });
   const [loading, setLoading] = useState(true);


  console.log("packageview  ===>>>", packageview)
  console.log("filteredData  ===>>>", filteredData)

 
  const color = "primaryColor";

  const ViewPackage = async () => {
    const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("project", "FinGenius");
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER_NEW + "subscription",
      data: formData,
    })
      .then(async function (response) {
        console.log("ViewPackage", response)
        setPackageview(response?.data?.Success);
          setLoading(false);
      })
      .catch((err) => {
          setLoading(false);

        throw err;
      });
  };

  const ViewPackageBuy = async (Name) => {
    const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("project", "FinGenius");
    formData.append("jwt", jwt);
    formData.append("subscription_name", Name);
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_SUB + "buy_subscription",
      data: formData,
    })
      .then(async function (response) {
        // console.log(response, "hello");
        const res = await response?.data;

        if (res?.Success) {
          ViewPackageView();
          setBuy(res?.Success);
          toast.success(res?.Success);
        } else {
          toast.error(res?.Error)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

 
  const ViewPackageView = async () => {
    const jwt = localStorage.getItem("jwt");
    const formData = new FormData();
    formData.append("project", "FinGenius");
    formData.append("jwt", jwt);
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_SUB + "view_subscription",
      data: formData,
    })
      .then(async function (response) {
        if (response?.data?.Success) {
          setPackageview1([response?.data?.Success]);
          setSubscriptionStatus(response?.data?.Status || "");
        }
        if (response?.data?.Success?.Status === "ACTIVE") {
          setIsActive(true);
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    ViewPackage();
    ViewPackageView();
  }, []);

  const responsive = {
    xs: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
    sm: {
      breakpoint: { max: 1000, min: 576 },
      items: 1,
    },
    md: {
      breakpoint: { max: 1050, min: 1000 },
      items: 2,
    },
    lg: {
      breakpoint: { max: 1680, min: 1050 },
      items: 3,
    },
    xl: {
      breakpoint: { max: 3000, min: 1680 },
      items: 4,
    },
  };

  const packageData = packageview || [];

  const handleCardClick = (index) => {
    setActiveCardIndex(index);
    if (isActive) {
      ViewPackageView();
    } else {
      ViewPackage();
    }
  };

   const activeSubscriptions = packageview.filter(
     (subscription) => subscription.Status === "ACTIVE"
   );

    const sortedSubscriptions = activeSubscriptions
      ?.slice()
      .sort((a, b) => a.Price - b.Price);


  return (
    <>
      <div>
        <div>
          <div className="text-[red] text-md md:text-lg lg:text-2xl text-center mt-3 md:mt-0">
            Keep 100% of the profits LIMITED TIME OFFER!
          </div>
          {/* <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            containerClass="carousel-container"
          > */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
            {loading
              ? Array.from({ length: 12 }).map((_, index) => (
                  <div
                    key={index}
                    className={`pt-4 bg-colorPrimary text-black md:h-96 px-6 relative rounded-xl mt-4 shadow-neumorphic`}
                  >
                    <SkeltorLoader />
                    <SkeltorLoader />
                    <SkeltorLoader />
                  </div>
                ))
              : sortedSubscriptions?.map((e, index) => {
                  const isMatch = packageview1?.some(
                    (item) => item?.Subscription_Name === e.Name
                  );

                  return (
                    <div>
                      <SubscriptionPackage
                        key={e?._id}
                        data={{
                          _id: e?._id,
                          Name: e?.Name,
                          Live_Bots: e?.Live_Bots,
                          Paper_Bots: e?.Paper_Bots,
                          Profit_Percent: e?.Profit_Percent,
                          Paper_API: e?.Paper_API,
                          Validity: e?.Validity,
                          Live_API: e?.Live_API,
                          Price: e?.Price,
                          button: "BUY",
                          isMatch: isMatch,
                          Exchange: e?.Exchange,
                          Allotted: e?.Max_Fund_Allotted,
                        }}
                        onBuyClick1={() => ViewPackageBuy(e?.Name)}
                        onBuyClick={() => handleCardClick(index)}
                      />
                    </div>
                  );
                })}
          </div>
          {/* </Carousel> */}
        </div>
      </div>
    </>
  );
}

export default Subscirptions;
