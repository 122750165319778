

import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import DepositTran from "./DepositTran";
import PaymentTran from "./PaymentTran";
import SubTable from "./SubTable";
import Switchbutton from "./Switchbutton";
import axios from "axios";

const SwipeableTabs = () => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (newIndex) => {
    setIndex(newIndex);
  };

    const [dataLog, setDataLog] = useState([]);

    const getLogs = () => {
      const itemValue = localStorage.getItem("jwt");
      const response = axios({
        method: "POST",
        headers: {
          accept: "application/json",
        },
        url: process.env.REACT_APP_API_PATH_PAY + "view_transaction",
        data: {
          project: "FinGenius",
          jwt: itemValue,
        },
      })
        .then((res) => {
          setDataLog(res.data.Success);
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
      getLogs();
    }, []);

  return (
    <>
      <div className="">
        <div className="xl:w-1/2 mx-1 px-2  md:mx-10 mt-5 md:mt-3 w-full bg-colorPrimary shadow-neumorphic">
          <div className="flex px-5 py-1 mobilescroll">
            <div
              className={`w-1/2 py-1 px-3 text-center cursor-pointer ${
                index === 0
                  ? "active-tab bg-colorPrimary shadow-neumorphicnew text-colorSecondaryDark  rounded-3xl px-5 border-t-4 border-colorAccent "
                  : "text-black border-none"
              }`}
              onClick={() => setIndex(0)}
            >
              Deposit
            </div>
            <div
              className={`w-1/2 py-1 px-3 text-center cursor-pointer ${
                index === 1
                  ? "active-tab bg-colorPrimary shadow-neumorphicnew text-colorSecondaryDark  rounded-3xl px-5 border-t-4 border-colorAccent "
                  : "text-black border-none"
              }`}
              onClick={() => setIndex(1)}
            >
              Packages
            </div>

            {/* <div
              className={`w-1/2 py-1 px-3 text-center cursor-pointer ${
                index === 2
                  ? "active-tab bg-colorPrimary shadow-neumorphicnew text-colorSecondaryDark  rounded-3xl px-5 border-t-4 border-colorAccent "
                  : "text-black border-none"
              }`}
              onClick={() => setIndex(2)}
            >
              Fuel
            </div> */}
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          className="mt-5 mx-4 "
        >
          <div>
            <PaymentTran data={dataLog} />
          </div>
          <div>
            <SubTable />
          </div>
          <div>
            <DepositTran />
          </div>
          {/* <div>
            <DepositTran />
          </div> */}
        </SwipeableViews>
      </div>
    
    </>
  );
};

export default SwipeableTabs;
