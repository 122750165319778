// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    key: "installation1",
    category: "A",
    title: "A BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },
  {
    key: "installation2",
    category: "A",
    title: "A BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "4",
    preDecimal: "49",
    postDecimal: "40"
  },
  {
    key: "installation3",
    category: "A",
    title: "A BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "2",
    preDecimal: "29",
    postDecimal: "20"
  },
  {
    key: "installation4",
    category: "A",
    title: "A BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "1",
    preDecimal: "19",
    postDecimal: "10"
  },
  {
    key: "installation5",
    category: "A",
    title: "A BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "4",
    preDecimal: "49",
    postDecimal: "40"
  },
  {
    key: "testimonial1",
    category: "B",
    title: "B BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },
  {
    key: "testimonial2",
    category: "B",
    title: "B BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },
  {
    key: "testimonial3",
    category: "B",
    title: "B BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },
  {
    key: "testimonial4",
    category: "B",
    title: "B BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },

  {
    key: "hardware1",
    category: "C",
    title: "C BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },
  {
    key: "hardware2",
    category: "C",
    title: "C BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },
  {
    key: "hardware3",
    category: "C",
    title: "C BotName",
    desc: "Lorem Ipsum dolor",
    btnName: "Create",
    rating: "3",
    preDecimal: "39",
    postDecimal: "30"
  },
];
