import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Typography, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import ant from "../Lotties.json";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

const EmailVerify = () => {

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [checkboxes, setCheckboxes] = useState([
   
    false, // 8 characters
    false, // 1 lowercase letter
    false, // 1 uppercase letter
    false, // 1 number
    false, // 1 special character
  ]);

  
  const navigate = useNavigate();
  const { email: emailParam } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.state);
  const username = searchParams.get("username");
  const email = searchParams.get("email");

 
  const maskEmail = (email) => {
    if (!email) return "";
    const [username, domain] = email.split("@");
    const maskedUsername =
      username.charAt(0) +
      "*".repeat(username.length - 2) +
      username.charAt(username.length - 1);
    return maskedUsername + "@" + domain;
  };


  const getverifyotp = async (e) => {
    const params = new URLSearchParams(window.location.search);
    const uname = params.get("username");
    const formData = new FormData();
    formData.append("project", "FinGenius");
    formData.append("username", username);
    formData.append("otp", otp);
    formData.append("page", 2);
    formData.append("password", newpassword);
    formData.append("con_password", confirmpassword);
   
    
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "reset_password ",
      data: formData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res.Success) {
          toast.success(res.Success);
          window.location.href = "/";
        } else {
          toast.error(res.Error);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
      });
    setLoading(false);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    const checkPasswordRequirements = () => {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      const requirementsMet = [
        newpassword.length >= 8,
        /[a-z]/.test(newpassword),
        /[A-Z]/.test(newpassword),
        /\d/.test(newpassword),
        /[@$!%*?&]/.test(newpassword),
      ];

      setCheckboxes(requirementsMet); 

      return regex.test(newpassword) && requirementsMet.every((req) => req);
    };

    checkPasswordRequirements();
  }, [newpassword]); 

 const areAllCheckboxesChecked = () => {
   return checkboxes.every((checkbox) => checkbox === true);
 };

  return (
    <>
      <div className="container mx-auto mt-10 px-2 flex justify-center items-center">
        <div className="w-full md:bg-white  relative rounded-2xl py-3 md:shadow-lg overflow-hidden">
          <div className="text-center">
            <div className="text-lg lg:text-3xl  font-semibold mb-3 text-colorAccent md:text-colorSecondaryDark">
              Forgot Password
            </div>

            <p className="text-white md:text-black text-sm md:text-base my-3">
              Please Enter the OTP received on your email{" "}
              <span>{email ? maskEmail(email) : "Sample@gmail.com"}</span> below
            </p>
            <div>
              <Form>
                <div className="justify-content-center">
                  <Form.Item
                    name="OTP"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input
                      //   prefix={<ArrowForwardIcon />}
                      placeholder="OTP"
                      style={{ width: "70%" }}
                      value={otp}
                      onChange={handleOtpChange}
                      name="otp"
                    />
                  </Form.Item>
                  {/* <Button onClick={getverifyotp} className={classes.topupemail}>
                  Verify
                </Button> */}
                </div>

                <div className=" justify-content-center">
                  <Form.Item
                    name="New Username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                    ]}
                  >
                    <Input.Password
                      //   prefix={<LockPersonIcon />}
                      placeholder="New Password"
                      style={{ width: "70%" }}
                      value={newpassword}
                      onChange={handleNewPasswordChange}
                      name="newpassword"
                    />
                    <div className="checkboxess flex flex-wrap justify-evenly text-white md:text-black">
                      {[
                        "8 characters long",
                        "1 lowercase letter",
                        "1 uppercase letter",
                        "1 number digit",
                        "1 special character",
                      ].map((requirement, index) => (
                        <Row key={index}>
                          <Col xl={24}>
                            <Checkbox checked={checkboxes[index]} disabled />
                            <span style={{ marginLeft: 8 }}>{requirement}</span>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Form.Item>
                  {/* <Button onClick={getverifyotp} className={classes.topupemail}>
                  Verify
                </Button> */}
                </div>

                <div className=" justify-content-center">
                  <Form.Item
                    name="Confirm Username"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input.Password
                      //   prefix={<LockPersonIcon />}
                      placeholder="Confirm Password"
                      style={{ width: "70%" }}
                      value={confirmpassword}
                      onChange={handleConfirmPasswordChange}
                      name="confirmpassword"
                    />
                  </Form.Item>
                  {/* <Button onClick={getverifyotp} className={classes.topupemail}>
                  Verify
                </Button> */}
                </div>
                {/* <Button
                //   className={classes.topupemail}
                >
                  Verify OTP to Reset Password
                </Button> */}
                <button
                  // onClick={handleSubmit}
                  // disabled={!areAllCheckboxesChecked()}
                  onClick={getverifyotp}
                  // disabled={!isCheckboxChecked || isLoading}
                  className="rounded-full bg-colorAccent py-1 cursor-pointer hover:bg-colorAccent/70 px-10 my-4 text-white"
                >
                  Verify OTP to Reset Password
                </button>
              </Form>
            </div>

            <span className="spam mt-4 text-white md:text-black">
              Already Have An Account?&nbsp;
              <Link to="/" className="text-colorAccent underline ">
                Sign In Here
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
