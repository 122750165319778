import { Modal, Select, Tabs, Tag } from "antd";
import React, { Component, useEffect, useState, useRef } from "react";
import "../assets/css/style.css";
import AllBot1 from "../Components/AllBot1";
import axios from "axios";
import image1 from "../assets/CoinsNew/BTCUSDT.png";
import image2 from "../assets/CoinsNew/BNBUSDT.png";
import image3 from "../assets/CoinsNew/ETHUSDT.png";
import image4 from "../assets/CoinsNew/ETHUSDT.png";
import image5 from "../assets/CoinsNew/GALAUSDT.png";
import image6 from "../assets/CoinsNew/OCEANUSDT.png";
import image7 from "../assets/CoinsNew/ADAUSDT.png";
import image8 from "../assets/CoinsNew/SOLUSDT.png";
import TradesTable from "../Components/TradesTable";
import { TbFilterCog } from "react-icons/tb";
import { MdAccountBalanceWallet } from "react-icons/md";

const { TabPane } = Tabs;

function Trades() {
  const [isActive, setIsActive] = useState("");
  const [coinData, setCoinData] = useState({
    Futures_Coin: [],
    Spot_Coin: [],
  });
  const [exchnagelist, setexchnagelist] = useState([]);
  const [filteredBot, setFilteredBot] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [botData, setBotData] = useState([]);
  const [filter, setFilter] = useState("All");
  const [filterBot, setFilterBot] = useState([]);
  const [selectedBotID, setSelectedBotID] = useState("");
  const [filter2, setFilter2] = useState("All");
  
    const [isModalVisible1, setIsModalVisible1] = useState(false);

    const showModal1 = () => {
      setIsModalVisible1(true);
    };

    const handleCancel1 = () => {
      setIsModalVisible1(false);
    };

  const handleFilterClick = (filterType) => {
    setFilter(filterType);
    setSelectedOption("");
    setSelectedBotID("");
  };

  useEffect(() => {
    const masterfilter = botData.filter((item) => {
      return (
        (filter === "All" || item?.Trade_Type === filter) &&
        (!selectedOption || item?.Symbol === selectedOption) &&
        (!selectedBotID || item?.Name === selectedBotID)
      );
    });

    setFilterBot(masterfilter);
  }, [filter, selectedOption, selectedBotID, botData]);
  const [BotName, setVarBotID] = useState([]);

  const uniqueSymbolsSet = new Set();
  const uniqueSymbolsArray = [];
  const AllBotName = [];

  if (botData) {
    botData.forEach((item) => {
      const { Symbol, Name } = item;
      if (!uniqueSymbolsSet.has(Symbol)) {
        uniqueSymbolsSet.add(Symbol);
        uniqueSymbolsArray.push(Symbol);
      }

      if (!uniqueSymbolsSet.has(Name)) {
        uniqueSymbolsSet.add(Name);
        AllBotName.push(Name);
      }
    });
  }

  // const filterUniqueSymbols = (exchangeType) => {
  //   return uniqueSymbolsArray.filter((symbol) => {
  //     return (
  //       (exchangeType === "All" ||
  //         botData.some((item) => item.Trade_Type === exchangeType)) &&
  //       symbol === selectedOption
  //     );
  //   });
  // };

  const exchageName = botData?.filter((item) => item?.Trade_Type == filter);

  const filtrBotNameSet = new Set();
  const filtrBotName = [];

  if (exchageName.length > 0) {
    exchageName.forEach((item) => {
      const { Name } = item;
      if (!filtrBotNameSet.has(Name)) {
        filtrBotNameSet.add(Name);
        filtrBotName.push(Name);
      }
    });
  }

  const ViewBot = async (e) => {
    const formData = new FormData();
    formData.append("project", "FinGenius");
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "view_bot",
      data: formData,
    })
      .then((response) => {
        const botDataWithImages = response?.data?.Data?.map((botItem) => ({
          ...botItem,
          images: generateImageUrl(botItem.Symbol),
        }));

        setBotData(botDataWithImages);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    ViewBot();
  }, []);

  const bot_list = async () => {
    try {
      const formData = new FormData();
      formData.append("project", "FinGenius");
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "bot_list",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("response ==>>>", response);
      const data = response?.data?.Success || {};
      const allBot = [...new Set([...data?.FUTURES, ...data?.SPOT])];
      console.log("allBot ==>>>", allBot);

      setFilteredBot(allBot);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    bot_list();
  }, []);

  const generateImageUrl = (symbol) => {
    switch (symbol) {
      case "BTCUSDT":
        return image1;
      case "BNBUSDT":
        return image2;
      case "ETHUSDT":
        return image3;
      case "ETH":
        return image4;
      case "GALAUSDT":
        return image5;
      case "OCEANUSDT":
        return image6;
      case "ADAUSDT":
        return image7;
      case "SOLUSDT":
        return image8;
      default:
        return "";
    }
  };

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  function handleOptionChange(event) {
    const selectedOption = Array?.from(
      event.target.selectedOption,
      (option) => option.value
    );
    setSelectedOption(selectedOption);
  }

  const handleOptionChange1 = (event) => {
    setSelectedBotID(event.target.value);
  };

  // const filterBotNamesByExchange = (exchangeType) => {
  //   if (exchangeType === "All") {
  //     return AllBotName;
  //   }

  //   const filteredBotNames = AllBotName.filter((botName) => {
  //     const botDataForName = botData.find((item) => item.Name === botName);
  //     return botDataForName && botDataForName.Trade_Type === exchangeType;
  //   });

  //   return filteredBotNames;
  // };

  const trades_transactions = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "order_book",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data;
        const data = response?.data;
        const botIDs = data.Data?.map((item) => item.BotName) || [];

        // Use a Set to store unique Bot IDs and then convert it back to an array
        setVarBotID([...new Set(botIDs)]);
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    trades_transactions();
  }, []);

  const filterUniqueSymbols = () => {
    if (filter === "All") {
      return uniqueSymbolsArray;
    } else if (filter === "SPOT") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Trade_Type === "SPOT" && item.Symbol === symbol
        )
      );
    } else if (filter === "FUTURES") {
      return uniqueSymbolsArray.filter((symbol) =>
        botData.some(
          (item) => item.Trade_Type === "FUTURES" && item.Symbol === symbol
        )
      );
    }
  };

  // const filterBotNamesByExchange = () => {
  //   if (filter === "All") {
  //     return BotName;
  //   } else {
  //     return botData
  //       .filter((item) => item.Trade_Type === filter)
  //       .map((item) => item.BotName);
  //   }
  // };

  const filterBotNamesByExchange = () => {
    if (filter === "All") {
      return AllBotName;
    } else if (filter === "SPOT") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Trade_Type === "SPOT" && item.Name === botName
        )
      );
    } else if (filter === "FUTURES") {
      return AllBotName.filter((botName) =>
        botData.some(
          (item) => item.Trade_Type === "FUTURES" && item.Name === botName
        )
      );
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showOptions1, setShowOptions1] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRef1 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setShowOptions1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = filterUniqueSymbols(); 

  const handleOptionSelect = (option) => {
    const updatedOptions = [...selectedOption];

    if (updatedOptions.includes(option)) {
      // If option is already selected, remove it
      updatedOptions.splice(updatedOptions.indexOf(option), 1);
    } else {
      // If option is not selected, add it
      updatedOptions.push(option);
    }

    setSelectedOption(updatedOptions);
  };

  const handleOptionSelect1 = (option) => {
    const updatedOptions = [...selectedBotID];

    if (updatedOptions.includes(option)) {
      // If option is already selected, remove it
      updatedOptions.splice(updatedOptions.indexOf(option), 1);
    } else {
      // If option is not selected, add it
      updatedOptions.push(option);
    }

    setSelectedBotID(updatedOptions);
  };

  return (
    <>
      <header className=" container flex fixed shadow-neumorphic bg-colorPrimary   justify-end z-30  w-full Block lg:hidden ">
        <div className=" xl:hidden block ">
          <div type="primary" onClick={showModal1} className="">
            <TbFilterCog size={25} className="text-colorSecondaryDark" />
          </div>
          <Modal
            title="Status"
            visible={isModalVisible1}
            footer={false}
            onCancel={handleCancel1}
          >
            <div className="shadow-neumorphic bg-colorPrimary pt-2 rounded-3xl mb-2 ">
              <div className="grid gid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 lg:mx-5 mb-0 px-2 lg:px-4 ">
                {/* <div className="mb-4"> */}
                <div className="flex justify-center flex-col items-center lg:space-x-4 ">
                  <label htmlFor="selectOption3">Exchange</label>
                  <div className="p-2 ">
                    <div className="flex mb-4 items-center justify-end">
                      <button
                        className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md drop-shadow-xl ${
                          filter === "All"
                            ? "bg-colorSecondaryDark text-white border-2 border-white "
                            : "bg-white text-colbg-colorSecondaryDark"
                        }`}
                        onClick={() => handleFilterClick("All")}
                      >
                        All
                      </button>
                      <button
                        className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px] shadow-md ${
                          filter === "SPOT"
                            ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                            : "bg-white text-colbg-colorSecondaryDark"
                        }`}
                        // onClick={() => handleFilterClick("SPOT")
                        // }
                        onClick={() => {
                          handleFilterClick("SPOT");
                        }}
                      >
                        Spot
                      </button>
                      <button
                        className={`mr-2 px-4 text-sm py-1 rounded-full  shadow-md drop-shadow-xl ${
                          filter === "FUTURES"
                            ? "bg-colorSecondaryDark text-white border-2 border-white drop-shadow-xl"
                            : "bg-white text-colbg-colorSecondaryDark"
                        }`}
                        // onClick={() => handleFilterClick("FUTURES")}
                        onClick={() => {
                          handleFilterClick("FUTURES");
                        }}
                      >
                        Future
                      </button>
                    </div>
                  </div>
                </div>

                <div className="hidden flex-col items-center lg:space-x-4 ">
                  <label htmlFor="selectOption3">Type</label>
                  <div className="p-2 ">
                    <div className="flex mb-4 items-center justify-end">
                      <button
                        className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md drop-shadow-xl ${
                          filter2 === "All"
                            ? "bg-colorAccent text-white border-2 border-white "
                            : "bg-white text-colorAccent"
                        }`}
                        onClick={() => setFilter2("All")}
                      >
                        All
                      </button>
                      {/* <button
                  className={`px-1 mr-2 py-1 rounded-full text-sm w-[80px]  shadow-md drop-shadow-xl ${
                    filter2 === "PAPER"
                      ? "bg-colorAccent text-white border-2 border-white "
                      : "bg-white text-colorAccent"
                  }`}
                  onClick={() => setFilter2("PAPER")}
                >
                  Paper
                </button> */}

                      <button
                        className={`px-1 py-1 rounded-full text-sm w-[80px] shadow-md ${
                          filter2 === "LIVE"
                            ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                            : "bg-white text-colorAccent"
                        }`}
                        onClick={() => setFilter2("LIVE")}
                      >
                        Live
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  ref={dropdownRef}
                  className="flex justify-around items-center md:contents"
                >
                  <div className="flex flex-col items-center  lg:space-x-4 ">
                    <label htmlFor="selectedOption">Symbols for ALL</label>
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectedOption"
                            // value={selectedOption}
                            onClick={() => setShowOptions(!showOptions)}
                            className="bg-colorPrimary  shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                          >
                            Select Option..
                          </button>

                          {showOptions && (
                            <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                              {filterUniqueSymbols()?.map((option, index) => (
                                <label key={index} className="block p-2 ">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleOptionSelect(option)}
                                    className=" mr-2"
                                  />
                                  {option}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    ref={dropdownRef1}
                    className="flex flex-col items-center  lg:space-x-4 my-2 lg:my-0"
                  >
                    <label htmlFor="selectOption1">Bot Name</label>
                    <div className="p-2 ">
                      <div>
                        <div className="relative">
                          <button
                            id="selectOption1"
                            // value={selectedOption}
                            onClick={() => setShowOptions1(!showOptions1)}
                            className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-4 py-1 rounded-full"
                          >
                            Select Option..
                          </button>

                          {showOptions1 && (
                            <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                              {filterBotNamesByExchange()?.map(
                                (option, index) => (
                                  <label key={index} className="block p-2 ">
                                    <input
                                      type="checkbox"
                                      onChange={() =>
                                        handleOptionSelect1(option)
                                      }
                                      className=" mr-2"
                                    />
                                    {option}
                                  </label>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </header>

      <div className="shadow-neumorphic bg-colorPrimary pt-2 mx-5 mb-2 mt-3 lg:mt-0 hidden md:block">
        <div className="grid gid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 lg:mx-5 mb-0 px-2 lg:px-4 ">
          {/* <div className="mb-4"> */}
          <div className="flex justify-center flex-col items-center lg:space-x-4 ">
            <label htmlFor="selectOption3">Exchange</label>
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md drop-shadow-xl ${
                    filter === "All"
                      ? "bg-colorAccent text-white border-2 border-white "
                      : "bg-white text-colorAccent"
                  }`}
                  onClick={() => handleFilterClick("All")}
                >
                  All
                </button>
                <button
                  className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px] shadow-md ${
                    filter === "SPOT"
                      ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                      : "bg-white text-colorAccent"
                  }`}
                  // onClick={() => handleFilterClick("SPOT")
                  // }
                  onClick={() => {
                    handleFilterClick("SPOT");
                  }}
                >
                  Spot
                </button>
                <button
                  className={`mr-2 px-4 text-sm py-1 rounded-full  shadow-md drop-shadow-xl ${
                    filter === "FUTURES"
                      ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                      : "bg-white text-colorAccent"
                  }`}
                  // onClick={() => handleFilterClick("FUTURES")}
                  onClick={() => {
                    handleFilterClick("FUTURES");
                  }}
                >
                  Future
                </button>
              </div>
            </div>
          </div>

          <div className="hidden flex-col items-center lg:space-x-4 ">
            <label htmlFor="selectOption3">Type</label>
            <div className="p-2 ">
              <div className="flex mb-4 items-center justify-end">
                <button
                  className={`mr-2 px-1 py-1 rounded-full text-sm w-[80px]  shadow-md drop-shadow-xl ${
                    filter2 === "All"
                      ? "bg-colorAccent text-white border-2 border-white "
                      : "bg-white text-colorAccent"
                  }`}
                  onClick={() => setFilter2("All")}
                >
                  All
                </button>
                {/* <button
                  className={`px-1 mr-2 py-1 rounded-full text-sm w-[80px]  shadow-md drop-shadow-xl ${
                    filter2 === "PAPER"
                      ? "bg-colorAccent text-white border-2 border-white "
                      : "bg-white text-colorAccent"
                  }`}
                  onClick={() => setFilter2("PAPER")}
                >
                  Paper
                </button> */}

                <button
                  className={`px-1 py-1 rounded-full text-sm w-[80px] shadow-md ${
                    filter2 === "LIVE"
                      ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                      : "bg-white text-colorAccent"
                  }`}
                  onClick={() => setFilter2("LIVE")}
                >
                  Live
                </button>
              </div>
            </div>
          </div>

          <div
            ref={dropdownRef}
            className="flex justify-around items-center md:contents"
          >
            <div className="flex flex-col items-center  lg:space-x-4 ">
              <label htmlFor="selectedOption">Symbols for ALL</label>
              <div className="p-2 ">
                <div>
                  <div className="relative">
                    <button
                      id="selectedOption"
                      // value={selectedOption}
                      onClick={() => setShowOptions(!showOptions)}
                      className="bg-colorPrimary  shadow-neumorphicnew text-sm mt-1 px-5 py-1 rounded-full"
                    >
                      Select Option..
                    </button>

                    {showOptions && (
                      <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                        {filterUniqueSymbols()?.map((option, index) => (
                          <label key={index} className="block p-2 ">
                            <input
                              type="checkbox"
                              onChange={() => handleOptionSelect(option)}
                              className=" mr-2"
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={dropdownRef1}
              className="flex flex-col items-center  lg:space-x-4 my-2 lg:my-0"
            >
              <label htmlFor="selectOption1">Bot Name</label>
              <div className="p-2 ">
                <div>
                  <div className="relative">
                    <button
                      id="selectOption1"
                      // value={selectedOption}
                      onClick={() => setShowOptions1(!showOptions1)}
                      className="bg-colorPrimary shadow-neumorphicnew text-sm mt-1 px-4 py-1 rounded-full"
                    >
                      Select Option..
                    </button>

                    {showOptions1 && (
                      <div className="mt-2 absolute left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-40">
                        {filterBotNamesByExchange()?.map((option, index) => (
                          <label key={index} className="block p-2 ">
                            <input
                              type="checkbox"
                              onChange={() => handleOptionSelect1(option)}
                              className=" mr-2"
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="px-5 mt-16 md:mt-0">
          <TradesTable
            type={filter}
            coin={selectedOption}
            selectedBotID={selectedBotID}
            filter2={filter2}
          />
        </div>
      </div>
    </>
  );
}

export default Trades;
