import React from "react";
import { useState, useEffect } from "react";
import guide from "../assets/img/guideImg.png";
import connectLive2 from "../assets/img/connnectLive/step2.png";
import connectLive3 from "../assets/img/connnectLive/step3.png";
import connectLive4 from "../assets/img/connnectLive/step4.png";
import connectLive5 from "../assets/img/connnectLive/step5.png";
import connectLive6 from "../assets/img/connnectLive/step6.png";
import connectLive7 from "../assets/img/connnectLive/step7.jpg";
import connectLive8 from "../assets/img/connnectLive/step8.jpg";
import connectLive9 from "../assets/img/connnectLive/step9.jpg";
import connectLive10 from "../assets/img/connnectLive/step10.jpg";
import connectLive11 from "../assets/img/connnectLive/step11.jpg";
import connectLive12 from "../assets/img/connnectLive/step12.jpg";
import connectLive13 from "../assets/img/connnectLive/step13.jpg";
import connectpaper1 from "../assets/img/Paperapi/step1.jpg";
import connectpaper4 from "../assets/img/Paperapi/step4.jpg";
import connectpaper2 from "../assets/img/Paperapi/step2.jpg";
import connectpaper3 from "../assets/img/Paperapi/step3.jpg";
import bot1 from "../assets/img/risk/1.jpg";
import bot2 from "../assets/img/risk/2.jpg";
import bot3 from "../assets/img/risk/3.jpg";
import bot4 from "../assets/img/risk/4.jpg";
import bot5 from "../assets/img/risk/5.jpg";
import bot6 from "../assets/img/risk/6.jpg";
import b1 from "../assets/img/Bot/1.jpg";
import b2 from "../assets/img/Bot/2.jpg";
import tele1 from "../assets/img/telegram/1.jpg";
import tele2 from "../assets/img/telegram/3.jpg";
import tele3 from "../assets/img/telegram/2.jpg";
import { AiOutlineLogin } from "react-icons/ai";
import { BsPersonFillAdd } from "react-icons/bs";
import { AiFillApi } from "react-icons/ai";
import { TbPremiumRights } from "react-icons/tb";
import { BsGraphUpArrow } from "react-icons/bs";
import { RiRobot2Line } from "react-icons/ri";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { AiOutlineFundView } from "react-icons/ai";
import { BiLogoTelegram, BiSolidReport } from "react-icons/bi";
import vided1 from "../assets/img/loginVid.mp4";
import Signupvid from "../assets/img/Signup.mp4";
import login1 from "../assets/img/loginNew/step1.jpg";
import login2 from "../assets/img/loginNew/step2.jpg";
import login4 from "../assets/img/loginNew/step3.jpg";
import active2 from "../assets/img/activebot/step2.jpg";
import active3 from "../assets/img/activebot/step3.jpg";
import trades1 from "../assets/img/trades/step2.jpg";
import trades2 from "../assets/img/trades/step3.jpg";
import report2 from "../assets/img/report/step2.jpg";
import report3 from "../assets/img/report/step.3.png";
// import active3 from "../assets/img/trades/step3.jpg";
import singup1 from "../assets/img/singUpnew/step1.jpg";
import singup2 from "../assets/img/singUpnew/step2.jpg";
import singup4 from "../assets/img/singUpnew/step3.jpg";
import singup5 from "../assets/img/singUpnew/step4.jpg";
import sub1 from "../assets/img/subscription/step1.jpg";
import sub2 from "../assets/img/subscription/step2.jpg";
import sub3 from "../assets/img/subscription/step3.jpg";
import sub4 from "../assets/img/subscription/step4.jpg";
import viewAPi1 from "../assets/img/vewApi/step1.jpg";
import viewAPi2 from "../assets/img/vewApi/step2.jpg";
import viewAPi3 from "../assets/img/vewApi/step3.jpg";
import viewAPi4 from "../assets/img/vewApi/step4.jpg";
import { cardData } from "./data";
import logo from "../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { SiProbot } from "react-icons/si";
import { BsBarChart } from "react-icons/bs";
import { Drawer } from "antd";

const Guidecarddata = ({showFunc}) => {
  const [selectedTitle, setSelectedTitle] = useState("LOGIN PAGE");
  const [mappingData, setMappingData] = useState([]);
  const [listTech, setListTech] = useState();
  // const [showPage, setShowPage] = useState(true);
  const [open, setOpen] = useState(false);

  const { showGuide, showPage, onClose, setCheckTech } = showFunc;
  // console.log("checkTech" , checkTech)

  const primaryData = [
    {
      filterTitle: "LOGIN PAGE",
      title: "LOGIN PAGE",
      mappingData: [
        <p className="text-md leading-7 font-binance-plex">
          Step 1: Open the Login Page
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Click{" "}
          <a
            href="https://beta.fingenius.ai/"
            target="_blank"
            className=" text-colorAccent underline font-semiboldbold"
          >
            "Login Button”
          </a>{" "}
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3 : Access Your Account
        </p>,
        <div className="my-4">
          <video controls width="640" height="360">
            <source src={vided1} type="video/mp4" />
          </video>
        </div>,
        <p className="font-medium text-base leading-6 mt-2">
          1. Open the Login Page
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Visit the platform's{" "}
          <a
            href="https://beta.fingenius.ai/"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            login
          </a>{" "}
          page{" "}
        </p>,
        <img src={login1} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          2. Provide Your Credentials
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Enter your username or email address and your password in the
          designated fields.
        </p>,
        <img src={login2} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">3. Click Login:</p>,
        <p className="text-md font-binance-plex leading-7">
          Click the{" "}
          <span className="text-colorAccent  font-semiboldbold">"Login" </span>{" "}
          or{" "}
          <span className="text-colorAccent  font-semiboldbold">"Sign In"</span>{" "}
          button to submit your credentials for verification.
        </p>,
        // <img src={login3} alt="" className='my-4' />,
        <p className="font-medium text-base leading-6 mt-2">
          4. Access Your Account
        </p>,
        <p className="text-md font-binance-plex leading-7">
          If your credentials are correct, you'll be granted access to your
          account's dashboard.
        </p>,
        <img src={login4} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "SIGN UP PAGE",
      title: "SIGN UP PAGE",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1 :Access to Sign-Up Page
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2 :Fill in Basic Information (Username, email id, name, password,
          Confirm Password){" "}
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3 : Click{" "}
          <a
            href="https://beta.fingenius.ai/signup"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            "Sign Up Button"
          </a>
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4 : Verify Your Email & Enter the OTP"
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5 : Access Your New Account
        </p>,
        <div className="my-4">
          <video controls width="640" height="360">
            <source src={Signupvid} type="video/mp4" />
          </video>
        </div>,
        <p className="font-medium text-base leading-6 mt-2">
          1. Access the Sign-Up Page
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Go to the{" "}
          <a
            href="https://beta.fingenius.ai/signup"
            target="_blank"
            className="text-black"
          >
            registration page
          </a>{" "}
          of the platform.
        </p>,
        <img src={singup1} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          2. Fill in Basic Information
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Enter a unique username, email address, name, password, and confirm
          the password.
        </p>,
        <img src={singup2} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          3. Click Sign Up:{" "}
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Click the <span className="text-colorAccent ">"Sign Up"</span> button
          to start the registration process.
        </p>,
        // <img src={singup3} alt="" className='my-4' />,
        <p className="font-medium text-base leading-6 mt-2">
          4. Verify Your Email{" "}
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Check your email for a verification link and input the OTP (one-time
          password) to gain access to the platform.
        </p>,
        <img src={singup4} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          5. Access Your New Account
        </p>,
        <p className="text-md font-binance-plex leading-7">
          After email verification, you can log in with your chosen username or
          email address and password to access your new account.
        </p>,
        <img src={singup5} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "TOPUP",
      title: "HOW TO TOPUP",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1 :On the dashboard, locate and click the{" "}
          <span className="text-colorAccent ">"Top-Up"</span> button.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2 :Choose the cryptocurrency or coin you want to use for the
          payment.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3 :Enter the desired amount you wish to top up and then click the
          <span className="text-colorAccent ">"Pay Now"</span> button.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4 :After clicking{" "}
          <span className="text-colorAccent ">"Pay Now"</span>, you will receive
          a payment barcode.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5 :Proceed to make the payment as instructed by scanning or
          presenting the payment barcode.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 6 :Wait for confirmation of your payment.
        </p>,
      ],
    },
    {
      filterTitle: "BUY SUBSCRIPTION",
      title: "HOW TO BUY SUBSCRIPTION",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1 :Navigate to the dashboard of your account.
        </p>,
        <img src={sub1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2 :Click on the{" "}
          <span className="text-colorAccent ">"Subscription"</span> tab or
          option.
        </p>,
        <img src={sub2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3 :From the list of available subscriptions, choose the one you
          want to subscribe to.
        </p>,
        <img src={sub3} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 4 :Upon successful subscription, you will receive a pop-up
          confirmation message.
        </p>,
        <img src={sub4} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "CONNECT TO LIVE API",
      title: "HOW TO CONNECT TO LIVE API (BINANCE)",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          To allow your bot to interact with Binance, you will need to create an
          API Key. This key acts as a connection between Fingenius and the
          exchange, enabling your bot to perform tasks such as placing automated
          orders and accessing your balance for its calculations. Essentially,
          the API Key serves as a way for your bot to communicate with the
          exchange and execute the actions necessary for automated trading.
        </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   1. Creating Your Binance Account: Quick Steps
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   2. Getting Started with Binance API: Verification and Management
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   3. Creating a Binance API Key: Step-by-Step Instructions
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   4. Naming Your Binance API Key: Best Practices for Easy Management
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   5. Verifying Your Binance API Keys: Triple Verification for Enhanced
        //   Security
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   6. Enhancing Binance API Security: Whitelisting IP Addresses for Fund
        //   Protection
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   7. Configuring Binance API Settings for Secure and Controlled Trading
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   8. Linking Binance API Keys to Fingenius for Seamless Trading
        //   Integration
        // </p>,
        // <p className="font-medium text-base leading-6 mt-2">Step one</p>,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1. If you haven't already, go to Binance's website and{" "}
          <a
            href="https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
            target="_blank"
            className="text-colorAccent underline"
          >
            Create an Account
          </a>
          .
        </p>,
        // <p className="font-medium text-base leading-6 mt-2">Step two</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Verify your account and get started on the API by navigating
          to API Management.
        </p>,
        <img src={connectLive2} alt="" className="my-4" />,
        // <p className="font-medium text-base leading-6 mt-2">Step three</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3. Click on{" "}
          <span className="text-colorAccent">“Create API”</span>, select{" "}
          <span className="text-colorAccent">“System generated API Key”</span>
          and click on Next.
        </p>,
        <img src={connectLive3} alt="" className="my-4" />,
        // <p className="font-medium text-base leading-6 mt-2">Step four</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4. Start by naming the API something memorable such as{" "}
          <span className="text-colorAccent ">"Fingenius API"</span>.
        </p>,
        <img src={connectLive4} alt="" className="my-4" />,
        // <p className="font-medium text-base leading-6 mt-2">Step five</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5. To complete the puzzle, slide the puzzle piece into place.
          Afterwards, you must verify your API Keys by using all of the
          following methods: phone number verification code, email verification
          code, and authenticator code.
        </p>,
        <img src={connectLive5} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 6. Navigate to the dashboard.
        </p>,
        <img src={connectLive6} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 7. Click on <span className="text-colorAccent">"Add API"</span>
          and choose <span className="text-colorAccent">"Live API keys"</span>.
        </p>,
        <img src={connectLive7} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 8. Copy the provided IP addresses.
        </p>,
        <img src={connectLive8} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 9. Copy the provided IP addresses.
        </p>,
        <img src={connectLive9} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 10. Click "Confirm" to view all the IP addresses.
        </p>,
        <img src={connectLive10} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 11. Save your changes by clicking the "Save" button.
        </p>,
        <img src={connectLive11} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 12. Add the API Name, API Key, and Secret Key and click "Next" to
          complete the process.
        </p>,
        <img src={connectLive12} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 13. Check the box to confirm the condition. & Click the "Connect"
          button to proceed.
        </p>,
        <img src={connectLive13} alt="" className="my-4" />,

        <p className="text-md font-binance-plex leading-7">
          Step 15. Once the connection is established, a confirmation pop-up
          will appear.
        </p>,
        // <img src={connectLive15} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "VIEW API",
      title: "HOW TO VIEW API",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          1. Navigate to your dashboard.
        </p>,
        <img src={viewAPi1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          2. Locate and click on the{" "}
          <span className="text-colorAccent ">"API CREDENTIALS"</span> option in
          the dashboard.
        </p>,
        <img src={viewAPi2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          3. After selecting{" "}
          <span className="text-colorAccent ">"API CREDENTIALS"</span>, click on
          the <span className="text-colorAccent ">"View API"</span> option.
        </p>,
        <img src={viewAPi3} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          4. You will then be able to view and access your API.
        </p>,
        <img src={viewAPi4} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "COPYING A BOT",
      title: "HOW TO COPYING A BOT",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          1. Access your dashboard.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          2. Click on the <span className="text-colorAccent ">"Bots"</span>{" "}
          section in the dashboard.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          3. You will be presented with a list of all the bots available to you.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          4. Select the bot you want to copy.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          5. After selecting the bot, you will receive a pop-up notification via
          Telegram for daily updates and notifications.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 1: Go to the dashboard.
        </p>,
        <img src={b1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Choose "Bots."
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Pick the bot you want and click on the "Copy Bot" button.
        </p>,
        <img src={b2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Set up which Exchange and API you want to use.
        </p>,
        <img src={bot4} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 5: Select if you want Telegram Alert
        </p>,
        <img src={bot5} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 6: Aumotate Amount will come (If you choose a high-risk level,
          you'll use less money to protect against liquidation).
        </p>,
        <img src={bot6} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 7: Click the "OK" button, and a Confirmation popup will appear.
        </p>,
        <img src={sub1} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "CONNECT TO PAPER API",
      title: "HOW TO CONNECT TO PAPER API",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1. Copy the provided IP addresses.
        </p>,
        <img src={connectpaper1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on the{" "}
          <span className="text-colorAccent">"API CREDENTIALS"</span> button
          (ADD API).
        </p>,
        <img src={connectpaper2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. Provide the necessary information, including the API Name,
          Amount in ETH, Amount in BTC, Amount in USDT, and Amount in BNB. &
          Click the "Next" button to proceed.
        </p>,
        <img src={connectpaper4} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 4. Click the “NEXT" button.
        </p>,
        <img src={connectpaper3} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 5. and a Confirmation popup will appear.
        </p>,
        // <img src={connectpaper3} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "RISK MATRIX",
      title: "HOW TO SETTING YOUR RISK MATRIX",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1. Go to the dashboard and look for the "Bot" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        // <p className="text-md font-binance-plex leading-7">
        //   Step 2. Choose the bot you want and click "Copy Bot."
        // </p>,
        // <img src={bot2} alt="" className="my-4" />,
        // <p className="text-md font-binance-plex leading-7">
        //   Step 3. Set up which Exchange and API you want to use.
        // </p>,
        // <img src={bot3} alt="" className="my-4" />,
        // <p className="text-md font-binance-plex leading-7">
        //   Step 4. Select if you want Telegram Alert
        // </p>,
        // <img src={bot4} alt="" className="my-4" />,
        // <p className="text-md font-binance-plex leading-7">
        //   Step 5. Aumotate Amount will come (If you choose a high-risk level,
        //   you'll use less money to protect against liquidation).
        // </p>,
        // <img src={bot5} alt="" className="my-4" />,
        // <p className="text-md font-binance-plex leading-7">
        //   Step 6. Click "OK" to confirm, and your risk matrix will be set.
        // </p>,
        // <img src={bot6} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "TELEGRAM",
      title: "HOW TO CONNECT TO TELEGRAM",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Interrogation of Telegram ID Login with Username & Password
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 1. At right hand side corner Click on Telegram
        </p>,
        <img src={tele1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Open the Telegram and connect With Fingenius Bot
        </p>,
        <img src={tele2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. Click on the Start Button
        </p>,
        <img src={tele3} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "ACTIVE BOTS",
      title: "HOW TO SETTING YOUR RISK MATRIX",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1. Go to the dashboard and locate the "Bot" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on the "ACTIVE BOT" button.
        </p>,
        <img src={active2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. This will lead you to view your active bots details, which
          include the following information:
          <ul className="list-disc ml-14">
            <li>Date and Time</li>
            <li>Variable Bot ID</li>
            <li>API Name</li>
            <li>Exchange</li>
            <li>Exchange Type</li>
            <li>Trade Type</li>
            <li>Trading Symbol</li>
            <li>Investment Amount</li>
            <li>Telegram Alert Setting</li>
            <li>Status of the trade</li>
          </ul>
        </p>,
        <img src={active3} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "TRADES",
      title: "HOW TO SETTING YOUR RISK MATRIX",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1. Go to the dashboard and look for the "Trades" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on “TRADES” button
        </p>,
        <img src={trades1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. This will lead you to view your active bots details, which
          include the following information:
          <ul className="list-disc ml-14">
            <li>DateTime</li>
            <li>Bot ID</li>
            <li>API Name</li>
            <li>Trade Direction</li>
            <li>OrderID</li>
            <li>Side</li>
            <li>Type</li>
            <li>Quantity</li>
            <li>Price</li>
            <li>Order Type</li>
            <li>Exchange</li>
            <li>Symbol</li>
            <li>Exchange Type</li>
            <li>Status</li>
          </ul>
        </p>,
        <img src={trades2} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "REPORT",
      title: "HOW TO SETTING YOUR RISK MATRIX",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1. Go to the dashboard and look for the "Report" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on “REPORT” button
        </p>,
        <img src={report2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. You will get to sees your trade report
          <ul className="list-disc ml-14">
            <li>(Total - Number of trades</li>
            <li>Winning - Numbers of trades closed in profit</li>
            <li>Losing - Numbers of trades closed in loss</li>
            <li>Hit rate - </li>
            <li>Invested - Total number of amount invested</li>
            <li>Balance - Remaining amount balance</li>
            <li>Position -</li>
            <li>Profit - amount of profits</li>
            <li>Brokerage - brokerage charges amount</li>
            <li>Days - Numbers of Days)</li>
          </ul>
        </p>,
        <img src={report3} alt="" className="my-4" />,
      ],
    },
  ];

  useEffect(() => {
    const dofilte = primaryData.filter(
      (item) => item.filterTitle == selectedTitle
    );
    console.log("dofilte", dofilte);
    setMappingData(dofilte);
  }, [selectedTitle]);


  const handleClick = (index) => {
    setCheckTech(index);
    setListTech(index)
    showGuide();
    onClose()
  };

  return (
    <div className="bg-[#ebebeb]">
     

      <div className="col-span-2 ">
        <div className=" lg:bg-colorRight w-full  h-auto py-7 xl:rounded-3xl stickyexample">
          {/* <h4 className="text-gray-800 font-rubik font-semibold text-2xl mb-4 rubik px-6">
            Technical guide
          </h4> */}
          <div>
            <ul className=" ">
              {cardData.map((item, index) => {
                console.log("hooooo", item.jumpTo, typeof item.jumpTo);
                return (
                  <>
                    <li
                      // onClick={() => {
                      //   console.log("Hello world");
                      //   setShowPage(false);
                      //   setCheckTech(index);
                      //   setOpen(false)
                      //   showFunc()
                      // }}
                      onClick={() => handleClick(index)}
                      className={`text-black rubik font-medium py-1.5 cursor-pointer px-6 
                            ${
                              listTech == index && !showPage
                                ? " bg-[#f5f5f5] border-colorAccent border-l-4 text-colorAccent"
                                : " bg-white "
                            }
                            `}
                      key={index}
                    >
                      <a href={`#${item.jumpTo}`}>{item.title}</a>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guidecarddata;
