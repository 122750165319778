import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { Button, Tooltip, Drawer } from "antd";
import { TbHelpHexagonFilled } from "react-icons/tb";
import { PiInfo } from "react-icons/pi";
import CloseOutlined from '@ant-design/icons';

const Firstcard = (props) => {
  console.log("props ==>>", props.data)
  const [drawerState, setDrawerState] = useState({
    visible: false,
    tooltipTexts: [],
    activeIndex: -1,
    activetabtitle: "",
  });
  const [colorChanged, setColorChanged] = useState(false);

  console.log("drawerState ==>>", drawerState)
   const customCloseIcon = <span style={{ color: "red" }}>X</span>;

  const {
    title,
    amount,
    text,
    p,
    textColor,
    tooltips = [
      {
        title: "Deposit",
        description: "This fund allows users to deposit money that can be used to subscribe to various packages. Users can choose from a range of subscription options based on the funds available in their account.",
      },
      {
        title: "Balance",
        description: "This feature provides users with a comprehensive view of all available funds across all active APIs, giving them a complete picture of their financial resources at their disposal.",
      },
      {
        title: "Bot Alloted",
        description: "Funds allocated for automated trading by the bot within Fingenius are sourced from all connected APIs, ensuring that the bot has access to resources from multiple sources for efficient trading operations.",
      },
      {
        title: "Total Bot Profit %",
        description: "Total Bot Profit Percentage (Achieved): This metric represents the overall percentage of profit successfully generated by the trading bot.",
      }
    ]
  } = props.data;

  const toggleDrawer = (tooltipTexts, title) => {
    setDrawerState({
      visible: !drawerState.visible,
      tooltipTexts: tooltipTexts,
      activeIndex: -1,
      activetabtitle: title,
    });
    setColorChanged(false);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };



  return (
    <div>
      <div
        className={`transition-all transition-2s bg-colorPrimary shadow-neumorphic lg:h-36 text-colorSecondaryDark py-3 px-4 rounded-2xl`}
      >
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex items-center">
              <h1
                style={{ fontFamily: "Poppins, sans-serif" }}
                className="text-md font-semibold capitalize"
              >
                {title}
              </h1>
              <p className="text-xs font-semibold">{p}</p>
            </div>
            <div onClick={() => toggleDrawer(tooltips, title)}>
              <PiInfo
                size={20}
                className="cursor-help hover:scale-110 transition-all transition-5s"
              />
            </div>
          </div>
          <div></div>
          <div>
            <p
              className={`text-3xl font-semibold ${
                colorChanged ? "text-red-500" : ""
              }`}
            >
              {amount}
            </p>
            {/* <p className={`${textColor} text-xs italic`}>{text}</p> */}
          </div>
        </div>
      </div>
     

      <Drawer
        placement="right"
        onClose={() => toggleDrawer([])}
        visible={drawerState.visible}
        width={300}
        // closeIcon={customCloseIcon}
        headerStyle={{ display: "none" }}
        style={{ border: "0" }}
        closeIcon={<CloseOutlined />}
      >
        <div className="bg-colorPrimary h-auto min-h-full text-center">
          <h2 className="text-lg font-bold text-black bg-colorPrimary py-2">
            Help
          </h2>
          <div>
            {/* Find the matched item and render it at the top */}
            {drawerState?.tooltipTexts?.find(
              (item) => item.title === drawerState.activetabtitle
            ) && (
              <div>
                {drawerState.tooltipTexts
                  .filter((item) => item.title === drawerState.activetabtitle)
                  .map((item, index) => (
                    <div key={index}>
                      <div
                        className={` pb-4 pt-3 rounded-2xl mx-2 mb-6 border-colorAccent border-2 shadow shadow-neumorphic text-white bg-colorSecondaryDark`}
                      >
                        <h4 className="text-xl font-normal mb-2 pl-2 text-primaryColor text-left">
                          {item.title} :
                        </h4>
                        <p className="p-2 rounded-md text-black border-2 bg-white mx-2 border-colorAccent shadow-md leading-4 text-justify text-xs">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {/* Render the rest of the items */}
            {drawerState?.tooltipTexts
              ?.filter((item) => item.title !== drawerState.activetabtitle)
              .map((item, index) => (
                <div key={index}>
                  <div
                    className={`text-black border-2 mb-6 pb-4 pt-3 rounded-2xl mx-2 bg-colorPrimary shadow-neumorphic`}
                  >
                    <h4 className="text-xl font-normal mb-2 pl-2 text-primaryColor text-left">
                      {item.title} :
                    </h4>
                    <p className="p-2 rounded-md text-black border-2 bg-white mx-2 border-colorAccent shadow-neumorphic leading-4 text-justify text-xs">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Firstcard;
