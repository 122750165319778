import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { TbHelpHexagonFilled } from "react-icons/tb";
import { Drawer, Tooltip } from "antd";
import { PiInfo } from "react-icons/pi";
import DrawerComponent from "./DrawerComponent";

const ThirdCard = (props) => {
  React.useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
  }, []);

  const [drawerState, setDrawerState] = useState({
    visible: false,
    tooltipTexts: [],
    activeIndex: -1, // State to track the active tooltip index
    activetabtitle: "",
  });
  const [colorChanged, setColorChanged] = useState(false);

  console.log("drawerState ==>>", drawerState.tooltipTexts)

  // console.log("Rani", props);
  const {
    des,
    amount,
    color,
    title,
    Maintooltips = [
      {
        title: "Active API",
        description:
          "This term refers to the currently active Application Programming Interfaces (APIs) that are connected and integrated with the system, enabling seamless data exchange and functionality within the platform",
      },
      {
        title: "Avg. Profit (%)",
        description:
          "This metric represents the average percentage of profit generated by all currently active bots within the system. It provides an overview of the collective performance of automated processes and trading algorithms",
      },
      {
        title: "Open Position",
        description:
          "This term refers to the current, unsettled trading positions held by all active bots within the system. It provides an overview of the ongoing trades and investments made by automated processes and trading algorithms.",
      },
      {
        title: "Closed Trades",
        description:
          "This refers to the cumulative number of trades executed and subsequently closed by all currently active bots within the system. It provides insight into the overall trading activity and performance of automated processes",
      },
      {
        title: "Active Bots",
        description:
          "This term signifies the real-time data and status information of all the currently active bots within the Finginius system. It provides an overview of the operational state and performance of automated processes and trading algorithms",
      },
      {
        title: "Avg. Profit ($)",
        description:
          "This metric represents the mean financial gain or profit generated by all currently active bots within finginus",
      },
      {
        title: "Open Position ($)",
        description:
          "This represents the current monetary value of open positions held by all active bots within the system. It provides an overview of the financial exposure resulting from ongoing trading activities.",
      },
      {
        title: "Total Profit ($) ",
        description:
          "To calculate the total profit ($) from all the active bots in Fingenius, you would need to access the profit data for each individual bot and then sum them up. This would typically involve querying the profit records for each active bot and adding up the values. The specific process and commands for doing this would depend on the software or system you are using for managing your bots and their profits.",
      },
    ]
  } = props.data;
  const primaryColor = "bg-colorSecondaryDark";


  const toggleDrawer = (tooltipTexts, title) => {
    setDrawerState({
      visible: !drawerState.visible,
      tooltipTexts: tooltipTexts,
      activeIndex: -1,
      activetabtitle: title,
    });
    setColorChanged(false);
  };

  return (
    <div>
      <div
        className={`xl:h-24 h-20  2xl:h-auto ${color == "primaryColor"
            ? "nm-concave-blue-200-sm text-colorSecondaryDark border-solid border-2 border-colorSecondaryDark"
            : "nm-concave-lblue-200-sm text-colorSecondaryDark border-solid border-2 border-colorAccent"
          } p-4 relative rounded-xl`}
      >
        <div className="absolute top-2 right-2">
          <div onClick={() => toggleDrawer(Maintooltips, title)}>
            <PiInfo
              size={20}
              className="cursor-help hover:scale-110 transition-all transition-5s"
            />
          </div>
        </div>
        <div className="text-2xl font-semibold"> {amount}</div>
        <div className=" text-xs  font-medium capitalize">{title}</div>
      </div>


      {/* <Drawer
        placement="right"
        onClose={() => toggleDrawer([])}
        visible={drawerState.visible}
        width={300}
        headerStyle={{ display: "none" }}
        style={{ border: "0" }}
      >
        <div className="bg-colorPrimary h-auto min-h-full text-center">
          <h2 className="text-lg font-bold text-black bg-colorPrimary py-2">
            Help
          </h2>
          <div>
            {drawerState?.tooltipTexts?.find(
              (item) => item.title === drawerState.activetabtitle
            ) && (
              <div>
                {drawerState.tooltipTexts
                  .filter((item) => item.title === drawerState.activetabtitle)
                  .map((item, index) => (
                    <div key={index}>
                      <div
                        className={` pb-4 pt-3 rounded-2xl mx-2 mb-6 border-colorAccent border-2 shadow shadow-neumorphic text-white bg-colorSecondaryDark`}
                      >
                        <h4 className="text-xl font-normal mb-2 pl-2 text-primaryColor text-left">
                          {item.title} :
                        </h4>
                        <p className="p-2 rounded-md text-black border-2 bg-white mx-2 border-colorAccent shadow-md leading-4 text-justify text-xs">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {drawerState?.tooltipTexts
              ?.filter((item) => item.title !== drawerState.activetabtitle)
              .map((item, index) => (
                <div key={index}>
                  <div
                    className={`text-black border-2 mb-6 pb-4 pt-3 rounded-2xl mx-2 bg-colorPrimary shadow-neumorphic`}
                  >
                    <h4 className="text-xl font-normal mb-2 pl-2 text-primaryColor text-left">
                      {item.title} :
                    </h4>
                    <p className="p-2 rounded-md text-black border-2 bg-white mx-2 border-colorAccent shadow-neumorphic leading-4 text-justify text-xs">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Drawer> */}

      <DrawerComponent
        toggleDrawer={toggleDrawer}
        drawerState={drawerState}
        Maintooltips={Maintooltips}
      />
    </div>
  );
};

export default ThirdCard;
