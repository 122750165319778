import { Card, Input, Switch } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { TbArrowUp } from 'react-icons/tb';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';

function Authcopy() {
  const [changeOpt, setChangeOtp] = useState("");
  const [showact, setShowact] = useState(null)
  const [showactdec, setShowactdec] = useState(null)
  const [twofaDeatil, setTwofaDeatil] = useState('')
  const [hideQRCode, setHideQRCode] = useState(null);

  console.log("hideQRCode", hideQRCode)
  console.log("showact", showact)
  console.log("showactdec", showactdec)


  useEffect(() => {
    privateStatusTotp();
  }, []);

  const handle2fa = (e) => {
    setChangeOtp(e.target.value);
  };

  const handleSwitch = () => {
    if (!showact) {
      activeTotp()
    }
    setTimeout(() => {
      setShowact(!showact)
    }, 1000)
  }

  const privateStatusTotp = () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      project: "FinGenius"
    }

    const response = axios({
      method: "POST",
      headers: "accept: application/json",
      url: process.env.REACT_APP_API_PATH_USER + "private_status_totp",
      data: postdata,
    }).then(res => {
      console.log("privateStatusTotp ===>", res)
      setShowact(res?.data?.Success)
      setHideQRCode(res?.data?.Success)
      setShowactdec(res?.data?.Success)
    })
      .catch(err => console.log(err))
  }

  const activeTotp = () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      project: "FinGenius",
      status: true
    }

    const response = axios({
      method: 'POST',
      headers: {
        'accept': 'application/json'
      },
      url: process.env.REACT_APP_API_PATH_USER + "active_totp",
      data: postdata
    }).then(res => {
      console.log("res ===>", res)
      setTwofaDeatil(res?.data?.Success)
    })
      .catch(err => console.log(err))
  }

  const changeTotp = (value) => {

    console.log("value changeTotp", value)
   
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      jwt: jwt,
      project: "FinGenius",
      status: value,
      totp: changeOpt
    }
    const response = axios({
      method: 'POST',
      headers: {
        'accept': 'application/json'
      },
      url: process.env.REACT_APP_API_PATH_USER + "change_totp",
      data: postdata
    }).then(res => {
      console.log(res)
      privateStatusTotp();
      setShowact(!showact)
      setChangeOtp("")
    })
      .catch(err => console.log(err))
  }

  const handleDeactive = () => {
    console.log("handleDeactive")
      // setShowact(false)
      setTimeout(() => {
        setShowactdec(false)
      }, 300);
  }

  return (
    <div
      className={` ${showactdec ? "cardswitch" : ""}  ${
        showact ? "backdrop-blur-sm" : ""
      }`}
    >
      {!showact ? (
        <div>
          <div className="blur_bg"></div>
          <div className="blur_bg_text bg-white px-7 rounded-full">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={showact}
                onChange={handleSwitch}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Activate 2FA
              </span>
            </label>
          </div>
        </div>
      ) : null}
      {showactdec ? (
        <div className="">
          <div className="blur_bg"></div>
          <div className="blur_bg_text bg-white px-7 rounded-full">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={showact}
                onChange={handleDeactive}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Deactivate 2FA
              </span>
            </label>
          </div>
        </div>
      ) : null}
      <div
        className={`  justify-items-center gap-5 min-h-[378px] ${
          !hideQRCode ? "grid grid-cols-1 xl:grid-cols-2" : "grid grid-cols-1"
        }`}
      >
        {!hideQRCode && (
          <div className="my-4 w-2/3 flex  justify-center items-center py-5 px-8  flex-row  rounded-3xl  gap-4 ">
            <Card className="shadow-neumorphicnew bg-colorPrimary ">
              <QRCode
                value={twofaDeatil ? twofaDeatil?.URI : ""}
                renderAs="canvas"
              />
            </Card>
          </div>
        )}
        <div className="text-start flex flex-col items-center justify-center w-full ">
          {!hideQRCode && (
            <div className="border-2 border-gray-400 w-full  py-3 px-4 text-black  rounded-full text-sm xl:text-lg mb-4 text-center">
              {twofaDeatil?.Key}
            </div>
          )}
          <div className=" my-3 w-1/2">
            <Input
              placeholder="2FA"
              value={changeOpt}
              onChange={handle2fa}
              className="shadow-neumorphicnew bg-colorPrimary rounded-full"
            />
          </div>
          {!hideQRCode ? (
            <button
              className="text-white text-16px border-dashed border-2 w-1/2 py-1 mt-4 border-white bg-colorSecondaryDark shadow-neumorphic"
              onClick={() => changeTotp(true)}
            >
              <span>ACTIVATE 2FA</span>
            </button>
          ) : (
            <button
              className="text-white text-16px border-dashed border-2 w-1/2 py-1 mt-4 border-white bg-colorSecondaryDark shadow-neumorphic"
              onClick={() => changeTotp(false)}
            >
              <span>DEACTIVATE 2FA</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Authcopy;