import { useState } from "react";
import { Card, Switch, Timeline } from "antd";
import img1 from "../assets/custom_gradient.png";
import QRCode from "qrcode.react";
import axios from "axios";
import { Checkbox, Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { toast } from "react-toastify";
import TelegramID from "../Components/TelegramID";

function Setting() {
  const [isChecked, setIsChecked] = useState(false);
  const [viewTopt, setViewTopt] = useState();
  const [changeOpt, setChangeOtp] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [activeView, setActiveView] = useState("all");


  console.log("changeOpt", viewTopt)

  useEffect(() => {
    view_totp();
  }, []);

  const view_totp = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: { accept: "application/json" },
      url: process.env.REACT_APP_API_PATH_USER + "view_totp",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        console.log("response", response.data.Success);
        setViewTopt(response.data.Success);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const UpdatedTopt = () => {

  };

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };


  const handleViewChange = (view) => {
    setActiveView(view);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
      old_password: oldPassword,
      new_password: newPassword,
      con_password: confirmPassword,
    };

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      toast.error(
        "Password must contain at least 8 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character."
      );
    } else if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password must match.");
    } else {
      const response = axios({
        method: "POST",
        headers: "accept: application/json",
        url: process.env.REACT_APP_API_PATH_USER + "update_password",
        data: postdata,
      })
        .then(async (response) => {
          const res = await response?.data;
          setMessage("Password changed successfully!");
          setError("");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const requirementsMet = [
      newPassword.length >= 8,
      /[a-z]/.test(newPassword),
      /[A-Z]/.test(newPassword),
      /\d/.test(newPassword),
      /[@$!%*?&]/.test(newPassword),
    ];

    setCheckboxes(requirementsMet);
  }, [newPassword]);

  const checkPasswordRequirements = () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const requirementsMet = [
      newPassword.length >= 8,
      /[a-z]/.test(newPassword),
      /[A-Z]/.test(newPassword),
      /\d/.test(newPassword),
      /[@$!%*?&]/.test(newPassword),
    ];

    return regex.test(newPassword) && requirementsMet.every((req) => req);
  };

  const [checkboxes, setCheckboxes] = useState([
    false, // 8 characters
    false, // 1 lowercase letter
    false, // 1 uppercase letter
    false, // 1 number
    false, // 1 special character
  ]);

  const UpdatePassword = async (e) => {
    e.preventDefault();
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
      old_password: oldPassword,
      new_password: newPassword,
      con_password: confirmPassword,
    };

    const response = await axios({
      method: "POST",
      headers: "accept: application/json",
      url: process.env.REACT_APP_API_PATH_USER + "update_password",
      data: postdata,
    })
      .then(async (response) => {
        const res = await response?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handle2fa = (e) => {
    setChangeOtp(e.target.value)
  }

  const Hadleupdateotp = () => {
    const regexPattern = /^\d{6}$/;
    const isValidInput = regexPattern.test(changeOpt);

    console.log("isValidInput", isValidInput)

    if (isValidInput) {
      updateTotp()     
    }
  }

  const updateTotp = () => {
    const jwt = localStorage.getItem("jwt");  
       const postdata = {
        project : 'FinGinues',
        jwt : jwt,
        totp : changeOpt,
        status : viewTopt.TOTP_Activation
       }

       console.log("postdata" , postdata)

       const response = axios({
         method : 'POST',
         headers : 'accept: application/json',
         url: process.env.REACT_APP_API_PATH_USER + "change_totp",
         data : postdata
       }).then((res) => {
         console.log(res)
       }).catch((err) => {
        console.log(err)
       })
  }
 
  console.log("viewTopt?.TOTP_Activation" , viewTopt?.TOTP_Activation)

   const switched = false
  
  return (
    <>
      <div className=" container px-10 ">
        <div className="grid grid-cols-12 gap-10 mt-10">
          <div className=" col-span-6 flex items-center">

            <TelegramID />
          </div>
          <div className=" col-span-6">
            {/* <div className="flex flex-col items-center   "> */}
            <div className="">
              <div className="grid grid-cols-1 items-center justify-center ">
                <div className="border-2 bg-opacity-75  shadow  border-colorSecondaryDark bg-colorSecondaryDark py-5 px-8 flex flex-col items-center rounded-3xl   ">
                  <div className="border-2 border-gray-400  py-3 px-4 text-white  rounded-full text-2xl mb-4">
                    {viewTopt?.TOTP_Key}
                  </div>
                  {/* <img src={img1} alt="qr-code" /> */}
                  <Card className="bg-white">
                    <QRCode value={viewTopt?.URI} renderAs="canvas" />
                  </Card>
                  <div className=" my-3">
                    <Input
                      placeholder="2FA"
                      value={changeOpt}
                      onChange={handle2fa}
                    />
                  </div>
                  <div className=" flex flex-col gap-3">
                    <div className="items-center">
                      <Switch
                        className={`bg-[#db3e3e] block mx-auto text-white rounded-full  ${viewTopt?.TOTP_Activation ? "bg-green-500" : " bg-gray-500"
                          }`}
                        checked={viewTopt?.TOTP_Activation}
                        onChange={handleSwitchChange}
                      />
                    </div>

                    <button
                      className="text-white text-16px border-dashed border-2 py-1 px-4 border-white bg-black "
                      // onChange={UpdatedTopt}
                      onClick={Hadleupdateotp}
                    >
                      ACTIVATE 2FA
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-3/5 xl:w-3/5 rounded-3xl xxl:w-1/2 mx-auto mt-8 p-6 border-2 bg-colorSecondaryDark text-white bg-opacity-75  shadow items-center border-colorSecondaryDark">
          <h2 className="text-2xl italic font-semibold mb-4 text-center text-white">
            Update Password
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="relative mb-4">
              <label htmlFor="oldPassword" className="block mb-1">
                Old Password
              </label>
              <Input.Password
                placeholder="old password"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div className="relative mb-4">
              <label htmlFor="newPassword" className="block mb-1">
                New Password
              </label>
              <Input.Password
                placeholder="new password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <div className="checkboxes flex flex-wrap justify-evenly">
                {checkboxes.map((checked, index) => (
                  <div key={index}>
                    <Row>
                      <Col xl={24}>
                        <Checkbox checked={checked} disabled />
                        <span style={{ marginLeft: 8 }}>
                          {index === 0 && "8 characters long"}
                          {index === 1 && "1 lowercase letter"}
                          {index === 3 && "1 number digits"}
                          {index === 2 && "1 uppercase letter"}
                          {index === 4 && "1 special character"}
                        </span>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative mb-4">
              <label htmlFor="confirmPassword" className="block mb-1">
                Confirm Password
              </label>
              <Input.Password
                placeholder="confirm password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            {/* {error && <p className="mb-2 text-red-500">{error}</p>} */}
            <div className="flex justify-center items-center">
              <button
                type="submit"
                className="bg-colorPrimaryDark text-white px-4 py-2 rounded-full hover:bg-colorSecondary"
              >
                Change Password
              </button>
            </div>
            {message && <p className="mt-2 text-green-500">{message}</p>}
          </form>
        </div>
      </div>
    </>
  );
}

export default Setting;
