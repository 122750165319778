export function gettoken()
{
    let tokenA = null;
    if (localStorage.getItem('token')) {
       tokenA = localStorage.getItem('token')
    }
    const token =tokenA;
    return token;
}


export function shortify(wall)
{
    let a = wall.slice(0, 4)
    let b = wall.slice(-4)
    return a + "..." + b;
}


export function formatDate(paramdate)
{
    // let enddate = paramdate
    let finalenddate = paramdate.toISOString();
    const formattedendDate =
    paramdate.toISOString().slice(0, 10) +
      " " +
      finalenddate.slice(11, 19);
    return formattedendDate;
} 