import React, { useEffect, useState } from 'react'
import { Form } from 'react-router-dom';

const TopUpCard = (props) => {

    const [touched, setTouched] = useState(false);
    const [data , setData] = useState()
     const [error, setError] = useState("");

    const handleSearch = (e) => { 
      setTouched(true);
    };

     const { title, img, convert, price, color, button, amount, getdata,id } =
       props.data;

     const handleSubmit = () => {
       if (!data) {
         setError("This field is required");
       } else if (amount?.value && amount?.value < 50) {
         setError("Please enter an amount greater than or equal to 50");
       } else {
         setError("");
         // Proceed with the payment or other actions
         if (button && typeof button.onClick === "function") {
           button.onClick(id);
         }
       }
     };

      


  return (
    <div id={id}>
      <div
        className={`bg-colorPrimary h-[400px]  shadow-neumorphic shadow-md rounded-3xl p-4 ${
          color == "primaryColor"
            ? "nm-concave-blue-200-sm text-colorSecondaryDark border-solid border-2 border-colorSecondaryDark"
            : "nm-concave-lblue-200-sm text-colorSecondaryDark border-solid border-2 border-colorAccent"
        }`}
      >
        <div className="text-center">
          <div className="coin-img">
            <img src={img} alt="profile" className="mx-auto" />
            <h5 className="mt-2 text-xl font-semibold">{title}</h5>
          </div>
        </div>
        <div className="text-center mt-4">
          <h5 className="mb-0 text-gray-700">Price ($)</h5>
          <h3 className="mb-0 text-2xl font-semibold">{price}</h3>
        </div>
        <div className="text-center mt-4">
          <input
            id={id}
            name={title}
            required
            className="shadow-neumorphicnew focus:border-none  w-full px-4 py-2 rounded-lg border focus:outline-none focus:border-0 "
            type="number"
            placeholder="Enter Amount"
            value={amount?.value && data}
            onChange={(e, id) => {
              setData(e.target.value);
              amount?.onChange(e, id);
              handleSearch(e, id);
            }}
            // value={data}
            // onChange={(e) => {setData(e.target.value)}}
            min={50}
            InputProps={amount?.InputProps}
          />

          {touched && !data && (
            <div className="error-message mt-2 text-xs text-[red]">
              This field is required
            </div>
          )}

          {touched && data && amount?.value && amount?.value < 50 && (
            <div className="error-message mt-2 text-xs text-[red]">
              Please enter an amount greater than or equal to 50
            </div>
          )}
        </div>
        <div className="text-center mt-4">
          <h5>
            Convert <span className="text-green-500">{convert}</span>
          </h5>
          <div className="mt-2">
            <button
              className={`
            text-white bg-colorSecondaryDark shadow-neumorphic hover:border-colorSecondaryDark hover:text-colorSecondaryDark hover:bg-colorPrimary font-semibold py-2 px-4 rounded-lg transition-all duration-300 ease-in-out`}
              onClick={handleSubmit}
              disabled={button?.disabled}
            >
              PAY NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopUpCard;
