import React, { useEffect, useState } from "react";
import { Drawer, Switch, Tooltip } from "antd";
import { TbHelpHexagonFilled } from "react-icons/tb";
import axios from "axios";
import { toast } from "react-toastify";
import TelegramID from "../Components/TelegramID"; // Import the TelegramID component
import DrawerComponent from "./DrawerComponent";
import { PiInfo } from "react-icons/pi";

const Notifications = ({ telegramSwitchStatus, setTelegramSwitchStatus }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
   const [telegramIDError, setTelegramIDError] = useState("");
  const [toggle, setToggle] = useState({
    System: false,
    API: false,
    Fuel: false,
    Fund: false,
  });

  const [drawerState, setDrawerState] = useState({
    visible: false,
    tooltipTexts: [],
    activeIndex: -1,
    activetabtitle: "",
  });


  const toggleSwitch = async (type, value) => {
    setToggle((prevToggle) => ({
      ...prevToggle,
      [type]: value,
    }));
    await View_Notificationupdate(type, value);
    
  };

  useEffect(() => {
    View_Notification();
  }, []);

  const View_Notification = async (checked) => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "view_notification",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        console.log("response", response);
        const responseData = response.data;

        if (responseData.Success) {
          setToggle(responseData.Success);
        } else {
          toast.error(responseData.Error);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const View_Notificationupdate = async (type, value) => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
      status: value,
      type: type,
    };
    console.log("postData", postdata);

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "edit_notification",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        console.log("response", response);
        if (response) {
          console.log(response?.data?.Success);
          toast.success(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);
          setTelegramIDError("TelegramID not yet updated!");
          setTelegramSwitchStatus(false); // Turn off the switch
        }
        setToggle((prevToggle) => ({
          ...prevToggle,
          [type]: value,
        }));
      })
      .catch((err) => {
        toast.error(err.response?.data?.Error);
         
      });
  };

  const redirectToTelegramPage = () => {
    // Add your redirection logic here
    // For example, you can use React Router or window.location.href
    // Replace 'YOUR_TELEGRAM_PAGE_URL' with the actual URL
    window.location.href = "/dashboard";
  };

   const tooltips = [
     {
       id: "1",
       title: "System",
       description:
         "This fund allows users to deposit money that can be used to subscribe to various packages. Users can choose from a range of subscription options based on the funds available in their account.",
     },
     {
       id: "2",
       title: "API",
       description:
         "This feature provides users with a comprehensive view of all available funds across all active APIs, giving them a complete picture of their financial resources at their disposal.",
     },
     {
       id: "3",
       title: "Fuel",
       description:
         "Funds allocated for automated trading by the bot within Fingenius are sourced from all connected APIs, ensuring that the bot has access to resources from multiple sources for efficient trading operations.",
     },
     {
       id: "4",
       title: "Fund",
       description:
         "Total Bot Profit Percentage (Achieved): This metric represents the overall percentage of profit successfully generated by the trading bot.",
     },
   ];

   const toggleDrawer = (tooltipTexts, title) => {
     setDrawerState({
       visible: !drawerState.visible,
       tooltipTexts: tooltipTexts,
       activeIndex: -1,
       activetabtitle: title,
     });
   };


  return (
    <div className="md:space-y-1 md:px-6">
      <div className="border-b-2 border-solid border-t-0 text-colorSecondaryDark border-r-0 border-l-0 flex justify-between items-center">
        <div className="font-bold text-lg">Type</div>
        <div className="font-bold text-lg pr-5">Telegram</div>
      </div>
      {Object.entries(toggle).map(([type, status]) => (
        <div
          key={type}
          className={`border-b-2 border-dashed border-t-0 border-r-0 border-l-0 flex justify-between items-center py-2 `}
        >
          <div className="flex items-center">
            {type}
            <div className="ml-2" onClick={() => toggleDrawer(tooltips, type)}>
              <div onClick={() => toggleDrawer(tooltips, type)}>
                <PiInfo
                  size={20}
                  className="cursor-help hover:scale-110 transition-all transition-5s"
                />
              </div>
            </div>
          </div>
          <div>
            <Switch
              checked={status}
              value={status}
              className={`mr-10 ${status ? "off" : "on"}`}
              // className={`mr-10 ${
              //   status ? "bg-colorAccent" : "bg-colorSecondaryDark"
              // }`}
              onChange={(value) => toggleSwitch(type, value)}
              // onChange={(value) => {
              //   toggleSwitch(type, value);
              //   if (value) {
              //     redirectToTelegramPage();
              //   }
              // }}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </div>
        </div>
      ))}

      {telegramIDError && <div className="text-red-500">{telegramIDError}</div>}

      {telegramSwitchStatus && (
        <div className="py-4">
          <TelegramID />
        </div>
      )}

      <DrawerComponent toggleDrawer={toggleDrawer} drawerState={drawerState} />
    </div>
  );
};

export default Notifications;
