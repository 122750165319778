import React, { useState, useRef } from "react";
import Firstcard from "../Components/Firstcard";
import LastCard from "../Components/LastCard";
import Secondcrad from "../Components/Secondcrad";
import ThirdCard from "../Components/ThirdCard";
import Test from "../Layout/Test";
import GaugeChart from "react-gauge-chart";
import Chart from "react-apexcharts";
import { BiDownArrowAlt } from "react-icons/bi";
import { Segmented } from "antd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Modal, Checkbox, Button, Tabs } from "antd";
import SignatureCanvas from "react-signature-canvas";
import mypdf from "../assets/sample.pdf";
import PdfViewerMain from "../Components/PdfViwermain";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const Mainpage = () => {
 
  

  const [filter, setFilter] = useState("ALL");

  const handleFilterClick = (selectedFilter) => {
     if (selectedFilter !== prevFilter) {
       setFilter(selectedFilter);
       // Call your API functions here with the updated filter value
       total_trades();
       total_profit();
       setPrevFilter(selectedFilter); 
     }
  };

  const data = [
    // Your table data goes here
    // Each row should have a "type" property indicating "spot" or "future"
  ];

  // Filter the data based on the selected filter
  const filteredData =
    filter === "ALL" ? data : data.filter((item) => item?.type === filter);

  // pdf***********************
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [checkboxState1, setCheckboxState1] = useState(false);
  const [checkboxState2, setCheckboxState2] = useState(false);
  const [signatureVisible, setSignatureVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const signatureRef = useRef(null);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [deposit, setDeposit] = useState("");
    const [dashboard, setDashboard] = useState("");
    const [api, setapi] = useState("");
    const [apiTotal, setapiTotal] = useState("");
    const [trades, setTrades] = useState("");
    const [bot, setbot] = useState("");
    const [profit, setprofit] = useState("");
    const [brokerage, setBrokerage] = useState("");
    const [balance, setBalance] = useState("");
     const [prevFilter, setPrevFilter] = useState(null);
  // const handleOpenModal = () => {
  //   setIsModalVisible(true);
  // };

  useEffect(() => {
    bot_allotted();
    deposit_balance();
    api_balance();
    total_api();
    total_bots();
    total_trades();
    total_profit();
    total_brokerage();
    total_bot_balance();
  }, []);

  const bot_allotted = () => {
    const jwt = localStorage.getItem("jwt");
    axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH_ANALYTICS + "bot_allotted",
      data: {
        project: "FinGenius",
        jwt: jwt,
        type:"ALL",
      },
    })
      .then((response) => {
        const res = response?.data?.Success;
        setDeposit(res);
      })
      .catch((err) => console.log(err));
  };

   const api_balance = () => {
     const jwt = localStorage.getItem("jwt");
     axios({
       method: "POST",
       headers: {
         accept: "application/json",
       },
       url: process.env.REACT_APP_API_PATH_ANALYTICS + "api_balance",
       data: {
         project: "FinGenius",
         jwt: jwt,
         type: "ALL",
       },
     })
       .then((response) => {
         const res = response?.data?.Success;
         setapi(res);
       })
       .catch((err) => console.log(err));
   };

 const deposit_balance = () => {
   const jwt = localStorage.getItem("jwt");
   const response = axios({
     method: "POST",
     headers: {
       accept: "application/json",
     },
     url: process.env.REACT_APP_API_PATH_ANALYTICS + "deposit_balance",
     data: {
       project: "FinGenius",
       jwt: jwt,
     },
   })
     .then((res) => {
       const response = res?.data?.Success;
       console.log(response, "response----->");
       setDashboard(response);
     })
     .catch((err) => console.log(err));
 };

 const total_api = () => {
   const jwt = localStorage.getItem("jwt");
   const response = axios({
     method: "POST",
     headers: {
       accept: "application/json",
     },
     url: process.env.REACT_APP_API_PATH_ANALYTICS + "total_api",
     data: {
       project: "FinGenius",
       jwt: jwt,
       type: "ALL",
     },
   })
     .then((res) => {
       const response = res?.data?.Success;
       setapiTotal(response);
     })
     .catch((err) => console.log(err));
 };

 const total_bots = () => {
   const jwt = localStorage.getItem("jwt");
   const response = axios({
     method: "POST",
     headers: {
       accept: "application/json",
     },
     url: process.env.REACT_APP_API_PATH_ANALYTICS + "total_bots",
     data: {
       project: "FinGenius",
       jwt: jwt,
       type: "ALL",
     },
   })
     .then((res) => {
       const response = res?.data?.Success;
       setbot(response);
     })
     .catch((err) => console.log(err));
 };

  const total_trades = () => {
    const jwt = localStorage.getItem("jwt");
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH_ANALYTICS + "total_trades",
      data: {
        project: "FinGenius",
        jwt: jwt,
        type: "ALL",
        exchange: "ALL",
        exchange_type:filter,
      },
    })
      .then((res) => {
        const response = res?.data?.Success;
        setTrades(response);
      })
      .catch((err) => console.log(err));
  };

  const total_profit = () => {
    const jwt = localStorage.getItem("jwt");
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH_ANALYTICS + "total_profit",
      data: {
        project: "FinGenius",
        jwt: jwt,
        type: "ALL",
        exchange: "ALL",
        exchange_type: filter,
      },
    })
      .then((res) => {
        const response = res?.data?.Success;
        setprofit(response);
      })
      .catch((err) => console.log(err));
  };

   const total_brokerage = () => {
     const jwt = localStorage.getItem("jwt");
     const response = axios({
       method: "POST",
       headers: {
         accept: "application/json",
       },
       url: process.env.REACT_APP_API_PATH_ANALYTICS + "total_brokerage",
       data: {
         project: "FinGenius",
         jwt: jwt,
         type: "ALL",
         exchange: "ALL",
         exchange_type: filter,
       },
     })
       .then((res) => {
         const response = res?.data?.Success;
         console.log(response, "response");
         setBrokerage(response);
       })
       .catch((err) => console.log(err));
   };

    const total_bot_balance = () => {
      const jwt = localStorage.getItem("jwt");
      const response = axios({
        method: "POST",
        headers: {
          accept: "application/json",
        },
        url: process.env.REACT_APP_API_PATH_ANALYTICS + "total_bot_balance",
        data: {
          project: "FinGenius",
          jwt: jwt,
          type: "ALL",
          exchange: "ALL",
          exchange_type: filter,
        },
      })
        .then((res) => {
          const response = res?.data?.Success;
          console.log(response, "response");
          setBalance(response);
        })
        .catch((err) => console.log(err));
    };




  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleTabChange = (key) => {
    if (key !== activeTab) {
      // Check if the checkbox is checked before switching tabs
      if (
        (activeTab === "tab1" && checkboxState1) ||
        (activeTab === "tab2" && checkboxState2)
      ) {
        setActiveTab(key);
        setSignatureVisible(false); // Hide the signature canvas
        setNextButtonDisabled(true); // Disable the "Next" button when switching tabs
      } else {
        alert("Please check the checkbox to proceed to the next tab.");
      }
    }
  };

  const handleCheckboxChange1 = (e) => {
    setCheckboxState1(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setCheckboxState2(e.target.checked);
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.currentPage);
    setNextButtonDisabled(e.currentPage !== totalPages - 1); 
  };

  const handleNextButtonClick = (tab) => {
    setSignatureVisible(true); 
    setActiveTab(tab);
  };

  const handleBackButtonClick = () => {
    if (activeTab === "tab2") {
      setActiveTab("tab1");
    }
  };

  
  const totalPages = 10; 


  const handleSubmitform = () => {
    //  handleCloseModal();
     const jwt = localStorage.getItem("jwt");
     const signatureData = signatureRef.current.toDataURL();
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
      privacy_policy: true,
      tnc: true,
      sign: signatureData,
    };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER + "accept_policy",
      data: postdata,
      body: JSON.stringify({ signatureData }),
    })
      .then(async function (response) {
        const res = await response.data;

        if (res.Success === "Policies accepted successfully!") {
          // Show a success modal or handle success
          setModalVisible(false);
        } else {
          // Handle other responses if needed
          // Example: toast.error("Policies acceptance failed.");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.Error);
        // if (err.response.data.Error) {
        //   toast.error(err.response.data.Error);
        // }
      });
  };

  useEffect(() => {

    <Modal
      title="Success"
      visible={modalVisible}
      onOk={handleModalOk}
      onCancel={() => setModalVisible(false)}
      footer={[
        <button key="ok" className="py-1" type="primary" onClick={handleModalOk}>
          OK
        </button>,
      ]}
    >
      Modal content goes here.
    </Modal>;

  }, []);

  const handleModalOk = () => {
    setModalVisible(false);
  };

  const [modalVisible, setModalVisible] = useState(false);

  //  const handleOpenModal = async () => {

  //  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const options = {
    chart: {
      type: "bar",
      height: 440,
      stacked: true,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2000,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    bar: {
      dataLabels: {
        position: "top", // You can set the position of the labels (top, center, or bottom)
      },
    },
    toolbar: {
      show: false, // Hide the download option
    },
    menu: false,
    // menu: {
    //   show: false,
    //   download: false,
    // },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
        colors: {
          ranges: ["#032B43"],
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              "<div class='custom-tooltip' style='font-size: 10px;'>" +
              ["A", "B", "C", "D"][dataPointIndex] +
              "</div>"
            );
          },
        },
      },
    },
    fill: {
      colors: ["#59C3C3"],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#032B43"],
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -100,
      max: 100,
      labels: {
        show: false,
        formatter: function (val) { },
      },
    },
    xaxis: {
      categories: ["Balance", "Position", "Profit", "Brokerage"], // Provide actual labels here
      labels: {
        show: true,
        formatter: function (val) { },
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val).toFixed(2) + "%";
        },
      },
    },
  };

  const totalValue = parseFloat(balance.ALL) +
  (profit.ALL !== undefined && trades.ALL !== undefined
    ? (deposit?.USDT + profit.ALL - balance.ALL)
    : 0) +
  parseFloat(profit.ALL) +
  parseFloat(profit.ALL);

      const series = [
      {
      name:["a","b","c","d"],
      data: [
      ((parseFloat(balance.ALL) / totalValue) * 100).toFixed(2),
      ((parseFloat(profit.ALL !== undefined && trades.ALL !== undefined
      ? (deposit?.USDT + profit.ALL - balance.ALL)
      : 0) / totalValue) * 100).toFixed(2),
      ((parseFloat(profit.ALL) / totalValue) * 100).toFixed(2),
      ((parseFloat(brokerage.ALL) / totalValue) * 100).toFixed(2)
      ],
      },
      ];

      // const series = [
      //   {
      //     name: "Balance",
      //     data: [
      //       ((parseFloat(balance.ALL) / totalValue) * 100).toFixed(2),
      //       // ... other data points
      //     ],
      //   },
      //   {
      //     name: "Profit",
      //     data: [
      //       ((parseFloat(balance.ALL) / totalValue) * 100).toFixed(2),
      //       ((parseFloat(profit.ALL !== undefined && trades.ALL !== undefined
      //         ? (deposit?.USDT + profit.ALL - balance.ALL)
      //         : 0) / totalValue) * 100).toFixed(2),
      //     ],
      //   },
      //   // ... other series
      // ];
      

//   const totalValue = parseFloat(balance.ALL) +
//   (profit.ALL !== undefined && trades.ALL !== undefined
//     ? (deposit?.USDT + profit.ALL - balance.ALL)
//     : 0) +
//   parseFloat(profit.ALL) +
//   parseFloat(brokerage.ALL);

// const series = [
//   {
//     name: "Financial Metrics",
//     data: [
//       {
//         name: "Balance",
//         percentage: ((parseFloat(balance.ALL) / totalValue) * 100).toFixed(2),
//       },
//       {
//         name: "Position",
//         percentage: ((parseFloat(profit.ALL !== undefined && trades.ALL !== undefined
//           ? (deposit?.USDT + profit.ALL - balance.ALL)
//           : 0) / totalValue) * 100).toFixed(2),
//       },
//       {
//         name: "Profit",
//         percentage: ((parseFloat(profit.ALL) / totalValue) * 100).toFixed(2),
//       },
//       {
//         name: "Brokerage",
//         percentage: ((parseFloat(brokerage.ALL) / totalValue) * 100).toFixed(2),
//       },
//     ],
//   },
// ];




  const cardData = [
    {
      id: "1",
      title: "Deposit",
      amount:
        dashboard?.Wallet_Balance !== undefined
          ? `$ ${dashboard?.Wallet_Balance.toFixed(2).replace(
              /\B(?=(\d{3})+(?!\d\.))/g,
              ","
            )}`
          : "0.00",
      p: "(ACTUAL)",
      text: "0 % then the last month",
      tooltip: "Deposit",
      Description:
        "This fund allows users to deposit money that can be used to subscribe to various packages. Users can choose from a range of subscription options based on the funds available in their account.",
    },
    {
      id: "2",
      title: "Balance",
      p: "(ALL ACTIVATE API)",
      amount:
        api?.USDT !== undefined
          ? `$ ${api?.USDT.toFixed(2).replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")}`
          : "0.00",
      text: "0 % then the last month",
      color: "colorPrimary",
      tooltip: "Balance",
      Description:
        "This feature provides users with a comprehensive view of all available funds across all active APIs, giving them a complete picture of their financial resources at their disposal",
    },
    {
      id: "3",
      title: "Bot Allotted",
      amount:
        deposit?.USDT !== undefined
          ? `$ ${deposit?.USDT.toString().replace(
              /\B(?=(\d{3})+(?!\d\.))/g,
              ","
            )}`
          : "0",
      p: "(ALL ACTIVATE API)",
      text: "0 % then the last month",
      tooltip: "Bot Alloted Fund",
      Description:
        "Funds allocated for automated trading by the bot within Fingenius are sourced from all connected APIs, ensuring that the bot has access to resources from multiple sources for efficient trading operations.",
    },
    {
      id: "4",
      title: "Total Bot Profit %",
      amount:
        deposit?.USDT !== undefined && bot !== undefined
          ? `${((bot * 100) / deposit.USDT)
              .toFixed(4)
              .replace(/\B(?=(\d{3})+(?!\d\.))/g, "")}%`
          : "0.00",

      p: "(ACHIEVED)",
      text: "0 % then the last month",
      tooltip: "Total Bot Profit %",
      color: "colorPrimary",
      Description:
        "Total Bot Profit Percentage (Achieved): This metric represents the overall percentage of profit successfully generated by the trading bot.",
    },
  ];


  // const cardData = [
  //   {
  //     id: "1",
  //     title: "Deposit",
  //     // amount: `$ ${dashboard?.Wallet_Balance || "0"}`,
  //     amount:
  //       dashboard?.Wallet_Balance !== undefined
  //         ? `$ ${dashboard?.Wallet_Balance.toFixed(2)}`
  //         : "0.00",
  //     // Check if the value is defined
  //     p: "(ACTUAL)",
  //     text: "0 % then the last month",
  //     tooltip: "Deposit",
  //     Description:
  //       "This fund allows users to deposit money that can be used to subscribe to various packages. Users can choose from a range of subscription options based on the funds available in their account.",
  //   },
  //   {
  //     id: "2",
  //     title: "Balance",
  //     p: "(ALL ACTIVATE API)",
  //     amount: api?.USDT !== undefined ? `$ ${api?.USDT.toFixed(2)}` : "0.00",
  //     text: "0 % then the last month",
  //     color: "colorPrimary",
  //     tooltip: "Balance",
  //     Description:
  //       "This feature provides users with a comprehensive view of all available funds across all active APIs, giving them a complete picture of their financial resources at their disposal",
  //   },
  //   {
  //     id: "3",
  //     title: "Bot Allotted",
  //     amount:
  //       deposit?.USDT !== undefined ? `${deposit?.USDT}` : "0",
  //     p: "(ALL ACTIVATE API)",
  //     text: "0 % then the last month",
  //     tooltip: "Bot Alloted Fund",
  //     Description:
  //       "Funds allocated for automated trading by the bot within Fingenius are sourced from all connected APIs, ensuring that the bot has access to resources from multiple sources for efficient trading operations.",
  //   },
  //   {
  //     id: "4",
  //     title: "Total Bot Profit %",
  //     amount:
  //       deposit?.USDT !== undefined && bot !== undefined
  //         ? `${((bot * 100) / deposit.USDT).toFixed(4)}%`
  //         : "0.00",

  //     p: "(ACHIEVED)",
  //     text: "0 % then the last month",
  //     tooltip: "Total Bot Profit %",
  //     color: "colorPrimary",
  //     Description:
  //       "Total Bot Profit Percentage (Achieved): This metric represents the overall percentage of profit successfully generated by the trading bot.",
  //   },
  // ];


  const xdata = [
    {
      title: "Active API",
      amount: apiTotal !== undefined ? `${Number(apiTotal)}` : "0",
      tooltip: "Active API",
      color: "primaryColor",
      des: "This term refers to the currently active Application Programming Interfaces (APIs) that are connected and integrated with the system, enabling seamless data exchange and functionality within the platform",
    },
    {
      title: "Avg. Profit (%)",
      amount:
        profit?.ALL !== undefined &&
        trades?.ALL !== undefined &&
        trades.ALL !== 0
          ? `${((profit.ALL / trades.ALL) * 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")}%`
          : "0.00",
      tooltip: "Avg. Profit (%)",
      des: "This metric represents the average percentage of profit generated by all currently active bots within the system. It provides an overview of the collective performance of automated processes and trading algorithms",
    },

    {
      title: "Open Position",
      amount: bot !== undefined ? `${Number(bot * 2).toLocaleString()}` : "0",
      color: "primaryColor",
      tooltip: "Open Position",
      des: "This term refers to the current, unsettled trading positions held by all active bots within the system. It provides an overview of the ongoing trades and investments made by automated processes and trading algorithms.",
    },
    {
      title: "Closed Trades",
      amount: trades.ALL !== undefined ? `${Number(trades.ALL)}` : "0.00",
      tooltip: "Closed Trades",
      des: "This refers to the cumulative number of trades executed and subsequently closed by all currently active bots within the system. It provides insight into the overall trading activity and performance of automated processes",
    },
    {
      title: "Active Bots",
      amount: bot !== undefined ? `${Number(bot)}` : "0",
      tooltip: "Active Bots",
      des: "This term signifies the real-time data and status information of all the currently active bots within the Finginius system. It provides an overview of the operational state and performance of automated processes and trading algorithms",
    },
    {
      title: "Avg. Profit ($)",
      amount:
        typeof profit.ALL === "number" &&
        typeof trades.ALL === "number" &&
        trades.ALL !== 0
          ? `$ ${((profit.ALL / trades.ALL).toFixed(2).replace(/\B(?=(\d{3})+(?!\d\.))/g, ","))}`
          : "0.00",

      tooltip: "Avg. Profit ($)",
      color: "primaryColor",
      des: "This metric represents the mean financial gain or profit generated by all currently active bots within finginus",
    },
    {
      title: "Open Position ($)",
      amount:
        profit.ALL !== undefined && trades.ALL !== undefined
          ? `$ ${((deposit?.USDT + profit.ALL - balance.ALL)
              .toFixed(2).replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")
              .toLocaleString())}`
          : "0.00",
      tooltip: "Open Position ($)",
      des: "This represents the current monetary value of open positions held by all active bots within the system. It provides an overview of the financial exposure resulting from ongoing trading activities.",
    },
    {
      title: "Total Profit ($) ",
      amount:
        profit.ALL !== undefined
          ? `$ ${Number(profit.ALL).toFixed(2).replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")}`
          : "0.00",
      tooltip: "Total Profit ($)",
      color: "primaryColor",
      des: "To calculate the total profit ($) from all the active bots in Fingenius, you would need to access the profit data for each individual bot and then sum them up. This would typically involve querying the profit records for each active bot and adding up the values. The specific process and commands for doing this would depend on the software or system you are using for managing your bots and their profits.",
    },
  ];


  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

 

  return (
    <>
      {/* <button onClick={handleOpenModal}>Open PDF Modal</button> */}

      <Modal
        title="PDF Viewer with Signature"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane key="tab1">
            <div>
              <p>Page {currentPage + 1}</p>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                ...
              </Worker>

              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "500px",
                  width: "100%",
                }}
              >
                <Viewer
                  fileUrl={mypdf}
                  onPageChange={(e) => handlePageChange(e)}
                />
              </div>
            </div>
            <div>
              <Checkbox
                checked={checkboxState1 && currentPage === totalPages - 1}
                onChange={handleCheckboxChange1}
                disabled={currentPage !== totalPages - 1}
              >
                Accept terms and conditions for Tab 1
              </Checkbox>
            </div>
            <div>
              <button
                type="primary"
                className="py-1"
                onClick={() => handleNextButtonClick("tab2")}
                disabled={nextButtonDisabled}
              >
                Next
              </button>
            </div>
          </TabPane>

          <TabPane key="tab2">
            <div>
              <p>Page {currentPage + 1}</p>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                ...
              </Worker>

              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "500px",
                  width: "100%",
                }}
              >
                <Viewer
                  fileUrl={mypdf}
                  onPageChange={(e) => handlePageChange(e)}
                />
              </div>
            </div>
            <div>
              <Checkbox
                checked={checkboxState2 && currentPage === totalPages - 1}
                onChange={handleCheckboxChange2}
                disabled={currentPage !== totalPages - 1}
              >
                Accept terms and conditions for Tab 2
              </Checkbox>
            </div>
            <div>
              <button
                type="primary"
                onClick={handleBackButtonClick}
                className="py-1"
              >
                Back
              </button>
              <button
                type="primary"
                onClick={() => handleNextButtonClick("signature")}
                disabled={nextButtonDisabled}
                className="py-1"
              >
                Next
              </button>
            </div>
          </TabPane>

          <TabPane key="signature">
            <div>
              {signatureVisible && (
                <div>
                  <p>Subscriber dislaimer:</p>
                  <p>
                    I declare that I am aware of the provisions of Chapter 6 of
                    the GTC and that I assume full responsibility as defined
                    therein, and that I am aware of and expressly accept that
                    the Service Provider is not responsible in cases provided in
                    Chapter 5 of the GTC and in the Legal Statement, which was
                    previously read by me
                  </p>
                  <SignatureCanvas
                    ref={signatureRef}
                    canvasProps={{
                      width: 400,
                      height: 200,
                      className: "signature-canvas",
                    }}
                  />
                </div>
              )}
              <div>
                <button
                  type="primary"
                  onClick={handleSubmitform}
                  className="py-1"
                >
                  Submit
                </button>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>

      <div className="  w-full mt-5 md:mt-0">
        <div className="p-4 pt-0">
          <div className="flex gap-2 mb-4 items-center justify-end">
            <button
              className={`px-3 text-sm py-1 rounded-full w-24 shadow-sm ${
                filter === "ALL"
                  ? "bg-colorAccent text-white border-2 border-white "
                  : "bg-white text-colorAccent"
              }`}
              onClick={() => handleFilterClick("ALL")}
            >
              All
            </button>
            <button
              className={`px-3 text-sm py-1 rounded-full w-24 shadow-sm ${
                filter === "SPOT"
                  ? "bg-colorAccent text-white border-2 border-white"
                  : "bg-white text-colorAccent"
              }`}
              onClick={() => handleFilterClick("SPOT")}
            >
              Spot
            </button>
            <button
              className={`px-3 text-sm py-1 rounded-full w-24 shadow-sm ${
                filter === "FUTURE"
                  ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                  : "bg-white text-colorAccent"
              }`}
              onClick={() => handleFilterClick("FUTURE")}
            >
              Future
            </button>
          </div>

          <table className="w-full table-auto">
            <thead>
              <tr>{/* Add your table headers here */}</tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>{/* Render your table rows here */}</tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <PdfViewerMain /> */}

        <div className="grid grid-cols-1  xl:grid-cols-2 gap-6 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-3">
            {cardData.map((card, index) => (
              <Firstcard key={index} data={card} />
            ))}
          </div>

          <div className="bg-colorSecondaryDark rounded-2xl">
            <div className=" grid grid-cols-1  lg:grid-cols-2 gap-4 p-4 h-full">
              <div className="bg-colorAccent shadow-xl shadow-black rounded-3xl gap-1 justify-around items-start  md:p-4 px-2 mx-5   md:mx-3 my-2">
                <div className="px-6 pt-5 pb-3">
                  <div className="flex  flex-col justify-center items-center">
                    <div className="text-black ">
                      <div className="text-2xl text-center">Fuel Limit</div>
                      <h2 className="text-4xl font-extrabold">$ 0</h2>
                    </div>
                    <div className="relative">
                      <div className="text-3xl text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                        0 %
                      </div>
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["white"]}
                        arcWidth={0.1}
                        percent={0.0}
                        textColor={"white"}
                        hideText={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="bg-[#1a1f34] rounded-xl   gap-1 justify-around items-start py-4 px-4 mx-5 my-4"> */}

              <div className="bg-[#1a1f34] rounded-xl contents  gap-1 justify-center items-start  px-3 mx-5 my-4">
                <div className="flex items-center flex-col justify-center p-2">
                  <h1 className="text-white text-center">
                    You've used 80% of your fuel credit. When it hits 100%,
                    clear dues using the button below to keep services
                    uninterrupted.
                  </h1>
                  <button className="bg-colorAccent shadow-lg shadow-black mt-4 text-black w-full p-2 rounded-full mx-2">
                    Refill Wallet
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
          <div className="flex mx-3 flex-col xl:h-100 2xl:h-full  md:flex-row  bg-colorPrimary shadow-neumorphic rounded-2xl text-black shadow-lg px-1 my-2">
            {/* <div className=" w-full "> */}
            {/* <div className="flex justify-start flex-row gap-2 items-center "> */}
            <div className="w-full md:w-2/3">
              <div className=" text-left text-md text-black px-3 pt-2">
                Overall Report
              </div>
              <div className="-ml-5 -mt-2">
                {/* <Chart
                  options={options}
                  series={series}
                  type="bar"
                  height={200}
                /> */}
                {series && series.length > 0 ? (
                  <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={200}
                  />
                ) : (
                  <p>No data available for the chart.</p>
                )}
              </div>
            </div>
            <div className="text-[13px] gap-4 justify-start items-center flex-col w-full md:w-[33%] mb-3 px-1 md:mt-6">
              <div className="flex  items-center justify-start border-b-2 border-colorSecondaryDark/40 ">
                <p className="flex flex-col leading-3 justify-between w-full">
                  <div className="text-sm text-gray-600">Balance</div>{" "}
                  <span className="md:text-lg font-bold text-colorSecondaryDark">
                    ${" "}
                    {parseFloat(balance.ALL)
                      .toFixed(4)
                      .replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")}
                  </span>
                </p>
               
              </div>
              <div className="flex w-full  items-center justify-around border-b-2 border-colorSecondaryDark/40 ">
                <p className="flex flex-col leading-3 justify-around w-full">
                  <div className="text-sm text-gray-600">Position</div>{" "}
                  <span className="md:text-lg font-bold text-colorSecondaryDark">
                    {profit.ALL !== undefined && trades.ALL !== undefined
                      ? `$ ${(deposit?.USDT + profit.ALL - balance.ALL)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")}`
                      : "0.00"}
                  </span>
                </p>
              
              </div>
              <div className="flex  items-center justify-around border-b-2 border-colorSecondaryDark/40 ">
                <p className="flex flex-col leading-3 justify-between w-full">
                  <div className="text-sm text-gray-600">Profit</div>{" "}
                  <span className="md:text-lg font-bold text-colorSecondaryDark">
                    ${" "}
                    {parseFloat(profit.ALL)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")}
                  </span>
                </p>
              
              </div>
              <div className="flex  items-center justify-around border-b-2 border-colorSecondaryDark/40 ">
                <p className="flex flex-col leading-3 justify-between w-full">
                  <div className="text-sm text-gray-600">Brokerage</div>{" "}
                 <span className="md:text-lg font-bold text-colorSecondaryDark">
                  ${" "}
                  {parseFloat(brokerage.ALL)
                    .toFixed(4)
                    .replace(/\B(?=(\d{3})+(?!\d\.))/g, ",")}
                </span>

                </p>
                {/* <div className="flex items-center bg-purple-200 rounded-lg px-1 text-xs">
                  25.4% <BiDownArrowAlt />
                </div> */}
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>

          <div className="py-4 px-2 md:px-4 border-[#e7e7e7] border rounded-xl ">
            <div className="flex justify-between items-center"></div>
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4  gap-4 mt-4">
              {xdata.map((data, index) => (
                <ThirdCard key={index} data={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mainpage;
