import React, { useEffect, useState } from "react";
import { Button, Checkbox } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

function Forgetpassword() {
  const [value, setValue] = useState("");
  const [isLabelAbove, setIsLabelAbove] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleFocus = () => {
    setIsLabelAbove(true);
  };

  const handleBlur = () => {
    setIsLabelAbove(value !== "");
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [input, setInput] = useState({ otp: "" });
  const param = useParams();
  console.log(param.email);
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const usernameParam = params.get("username");
    if (usernameParam) {
      setUsername(usernameParam);
    }

     if (email) {
       setEmail(email);
     }
  }, []);

 

  const handleSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("project", "FinGenius");
    formData.append("username", username.toLowerCase());
    formData.append("page", "2");
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "get_otp",
      data: formData,
    })
      .then(async function (response) {
        const res = await response.data;
        console.log(response);
        if (res.Success) {
          toast.success(res.Success);
          // setEmail(res.email);
          navigate("/update_password", {
            state: {
              username: username,
              email: res.Email,
            },
          });
        } else {
          toast.error(res.Error);
        }
        
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
      });
    setLoading(false);
  };

  const handleOtpChange = (e) => {
    setUsername(e.target.value);
  };


  return (
    <>
      <div className="container mx-auto mt-10 px-2 flex justify-center items-center">
        <div className="w-full md:bg-white py-3 relative rounded-2xl md:shadow-lg overflow-hidden">
          <div className="text-center">
            <div className="text-center">
              <div className=" lg:text-3xl  mb-3 text-lg md:text-3xl font-semibold  text-colorAccent">
                Forgot your password?
              </div>
              <div>
                <a
                  href="#"
                  className="text-gray-50 md:text-gray-500 font-bold hover:underline"
                >
                  Don’t worry. We’ll send you an email to start resetting your
                  password.
                </a>
              </div>
              <div className="px-5 py-5  rounded-lg mt-5  md:w-9/12 mx-auto">
                {/* <FloatingLabelInput label="Email" /> */}

                <input
                  placeholder="Username"
                  name="username"
                  className="py-2 px-4 border border-slate-900 rounded-full focus:outline-none focus:border-colorSecondary w-full"
                  type="username"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onFocus={() => handleFocus("email")}
                  // onBlur={() => handleBlur("email")}
                  autoComplete="off"
                />

                {/* <input
                  type="username"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="peer border-none bg-transparent rounded-full placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 py-2 px-4 border border-slate-900  focus:border-blue-500 w-full bg-white "
                  placeholder="Username"
                /> */}

                {/* <span className="pointer-events-none absolute left-2  top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                  Username
                </span> */}

                <button
                  onClick={handleSubmit}
                  className="rounded-full py-1 bg-colorAccent text-white mt-4 cursor-pointer hover:bg-colorAccent/70 px-10 md:w-full "
                >
                  Reset password
                </button>
                <div className="mt-1 flex justify-between">
                  <a
                    href="/forgetusername"
                    className="text-colorAccent underline"
                  >
                    Forgot Username
                  </a>
                  <Link to="/" className="text-colorAccent underline">
                    {" "}
                    Sign In Here
                  </Link>
                  {/* <a
                    href="/forgetpassword"
                    className="text-colorAccent hover:underline"
                  >
                    {" "}
                    Forgot Password?
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgetpassword;
