import React, { useState, useEffect } from "react";
import { Button, Progress, Select, Form, InputNumber } from "antd";
import { Space, Switch } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import axios from "axios";
import { gettoken } from "../../Pages/Allfunction";
import FormItem from "antd/es/form/FormItem";

const { Option } = Select;
const selectAfter = (
  <Select defaultValue="%" className="bg-[#fff] rounded-lg">
    <Option value="%">%</Option>
    <Option value="$">$</Option>
  </Select>
);

function Buy({ coin, market, showbalance, tradetype }) {
  console.log(showbalance)
  console.log(coin)


  const [switchState, setSwitchState] = useState(false);
  const [progress, setProgress] = useState(0);
  const [CurrentPrice, setCurrentPrice] = useState([]);
  const [Prevprice, setPrevprice] = useState(0);
  const [isSwitch1Active, setIsSwitch1Active] = useState(false);
  const [isSwitch2Active, setIsSwitch2Active] = useState(false);
  const [Price, setPrice] = useState();
  const [Quantity, setQuantity] = useState();
  const [Amount, setAmount] = useState();


  useEffect(() => {
    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${coin.toLowerCase()}@ticker`);
    ws.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      setCurrentPrice(data);
      setPrevprice(CurrentPrice.c)
      console.log(CurrentPrice.c)
    });

    return () => {
      ws.close();
    };

  }, [coin, CurrentPrice]);
  let curreentordettype = localStorage.getItem('switchValue');

  const handleSwitchChange = (checked) => {
    setPrice('');
    setQuantity('');
    setAmount('');

    setSwitchState(checked);
    const switchValue = checked ? "market" : "limit";
    curreentordettype = localStorage.getItem('switchValue');
    localStorage.setItem("switchValue", switchValue);
  };

  const handlechange = (e) => {
    aaa(e.target.name, e.target.value)
  };

  function aaa(name, value) {
    let prices = 0
    if (curreentordettype === 'limit') {
      prices = Price
    } else {
      prices = Prevprice
    }
    if (name === 'Price') {
      if (curreentordettype === 'limit') {
        setPrice(value);

      }
    }
    if (name === 'Quantity') {
      setQuantity(value);
      setAmount(value * prices);
    }
    if (name === 'Amount') {
      setAmount(value);
      setQuantity(value / prices);
    }
  }


  const token = gettoken()

  const onFinish = (values) => {

    const data = { values }
    data.values.jwt = token
    data.values.Coin = coin
    data.values.Exchange = market ? market : "binance_spot"
    data.values.Price = Price
    data.values.Quantity = Quantity
    data.values.Amount = Amount
    const postData = data.values
    const trademain = tradetype ? tradetype : "PAPER"
    if (trademain === "PAPER" || trademain === undefined) {
      console.log('innnnnn1', trademain)

      if (curreentordettype === 'limit') {

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'papertrading/limit_buy',
          data: postData,
        }).then(async function (response) {
          console.log(response)
          if (response.data.data) {

            alert(response.data.data)
          }
          if (response.data.error) {
            alert(response.data.error)

          }

        });
      } else if (curreentordettype === 'market') {
        console.log('innnnnn2')

        const marketdata = { values };
        marketdata.values.Price = Prevprice;

        const marketData = marketdata.values;

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'papertrading/market_buy',
          data: marketData,
        }).then(async function (response) {
          console.log(response)
          if (response.data.data) {

            alert(response.data.data)
          }
          if (response.data.error) {
            alert(response.data.error)

          }

        });
      }
    }

    if (tradetype === "LIVE") {
      if (curreentordettype === 'limit') {

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'trading/limit_buy',
          data: postData,
        }).then(async function (response) {
          console.log(response)
          alert(response)

        });
      } else if (curreentordettype === 'market') {

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'trading/market_buy',
          data: postData,
        }).then(async function (response) {
          console.log(response)
          alert(response)

        });
      }
    }

  };


  const handleClick = (value) => {

    let per = showbalance * value / 100
    setProgress(value);
    setAmount(per);
    aaa('Amount', per)
  };
  useEffect(() => {
    localStorage.removeItem('switchValue')
    localStorage.setItem('switchValue', 'limit')
  }, []);

  return (
    <>
      <div className="m-1">
        <Form
          onFinish={onFinish}
        >
          <Space className=" rounded-sm">
            <div>
              <FormItem
              >
                <div className="flex mt-2 text-center">
                  <p>Limit</p>
                  <Switch
                    checkedChildren=""
                    className="mx-2 bg-colorSecondary"
                    unCheckedChildren=""
                    onChange={handleSwitchChange}
                  />
                  <p>Market</p>
                </div>
              </FormItem>
            </div>
            <div className="mx-4 text-center">

              {Number(CurrentPrice.c) > Number(Prevprice) ? <span className="text-success text-sm font-bold">{Number(CurrentPrice.c)} <ArrowUpOutlined /></span> : Number(CurrentPrice.c) < Number(Prevprice) ? <span className="text-danger text-sm font-bold">{Number(CurrentPrice.c)} <ArrowDownOutlined /></span> : <span className="text-sm font-bold">{Number(CurrentPrice.c) ? Number(CurrentPrice.c) : 0}</span>}

              <p className="text-[10px] text-green-500 font-bold">{CurrentPrice && CurrentPrice.p ? `${Number(CurrentPrice.p).toFixed(2)}` : 0}({CurrentPrice && CurrentPrice.P ? `${Number(CurrentPrice.P).toFixed(2)}%` : '0%'})</p>

            </div>
          </Space>
          {!switchState &&
            <FormItem
              name="Price"
              className="m-0"
              onChange={handlechange}
            >
              <p hidden>{Price}</p>
              <InputNumber size="small" className="w-100 p-[1px]" value={Price} name="Price" type="number" placeholder="Price" />
            </FormItem>}
          <FormItem
            name="Quantity"
            className="m-0"
            onChange={handlechange}
          >
            <p hidden>{Quantity}</p>
            <InputNumber  size="small" className="w-100 p-[1px] mt-1" value={Quantity} name="Quantity" type="number" placeholder="Quantity" />
          </FormItem>
          <FormItem
            name="Amount"
            className="m-0"
            onChange={handlechange}
            defaultValue={Amount}
          >
            <p hidden>{Amount}</p>
            <InputNumber  size="small" className="w-100 p-[1px] mt-1" value={Amount} name="Amount" type="number" placeholder="Amount" />
          </FormItem>
          <Progress percent={progress} />
          <div className="flex justify-center">
            <Button size="small" className="mx-2 border-2 border-solid border-colorSecondary " onClick={() => handleClick(25)} value={25}>
              25
            </Button>
            <Button size="small" className="mx-2 border-2 border-solid border-colorSecondary " onClick={() => handleClick(50)} value={50}>
              50
            </Button>
            <Button size="small" className="mx-2 border-2 border-solid border-colorSecondary " onClick={() => handleClick(75)} value={75}>
              75
            </Button>
            <Button
              className="mx-2 border-2 border-solid border-colorSecondary "size="small"
              onClick={() => handleClick(100)}
              value={100}
            >
              max
            </Button>
          </div>
          <div className="mt-2">
            <div className="bg-orange-200 p-2 rounded-lg ">
              <div className="flex items-center justify-between">
                Stop Loss
                <Switch
                  checked={isSwitch1Active}
                  onChange={(checked) => setIsSwitch1Active(checked)}
                  className="mx-2 bg-[#d4d4d4]"
                />
              </div>
              {isSwitch1Active && (
                <div className="mt-3">
                  <FormItem
                    name="stoploss"
                    className="m-0"
                  >
                    <InputNumber type="number" className="w-100" addonAfter={selectAfter} />
                  </FormItem>
                </div>
              )}
            </div>

            <div className="bg-orange-200 p-2 rounded-lg mt-2">
              <div className="flex items-center justify-between">
                Take profit
                <Switch
                  checked={isSwitch2Active}
                  onChange={(checked) => setIsSwitch2Active(checked)}
                  className="mx-2 bg-[#d4d4d4]"
                />
              </div>
              {isSwitch2Active && (
                <div className="mt-3">
                  <FormItem
                    name="takeprofit"
                    className="m-0"
                  >
                    <InputNumber type="number" className="w-100" addonAfter={selectAfter} />
                  </FormItem>
                </div>
              )}
            </div>
          </div>
          <div className="text-center">
            <Button className="bg-colorSecondaryDark text-white mt-3 mb-3" htmlType="submit">Buy</Button>
          </div>

        </Form>

      </div>
    </>
  );
}

export default Buy;
