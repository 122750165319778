export function getToken() {
  let tokendetails = null;
  if (localStorage.getItem("JWT")) {
    tokendetails = localStorage.getItem("JWT");
  }
  const jwt = tokendetails;
  return jwt;
}

export function removesession() {
  localStorage.removeItem("JWT");
}
