import React, { useState } from 'react'
import { Button, Card, Form, Input, QRCode, Tooltip, message } from 'antd';
import { CopyOutlined } from "@ant-design/icons";
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


function PaymentGateway() {
  const [payment, setPayment] = useState("");
  const location = useLocation();
  const connectloctState = location.state?.Wallet_Address;
  const connectloctState1 = location.state?.Amount;
  const connectloctState2 = location.state?.Asset;
  const connectloctState3 = location.state?.TransID;
  console.log(connectloctState, "confirmButtonText");

  console.log("connectloctState2", connectloctState);
  const splitAsset = connectloctState2 ? connectloctState2.split("_") : [];
  console.log("splitAsset", connectloctState);
  console.log("Received state:", {
    connectloctState,
    connectloctState1,
    connectloctState2,
    connectloctState3,
  });
  console.log("Location Object:", location);

  // In the PaymentGateway component
  console.log("Received state:", location.state);

  const [inputValue, setInputValue] = useState(connectloctState);

  const [twofaDeatil, setTwofaDeatil] = useState("");

  function handleCopy() {
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
        message.success("Text copied to clipboard.");
      })
      .catch((error) => {
        message.error("Copy failed. Please try again.");
      });
  }

 
  const navigate = useNavigate();
  const ViewPackagePayment = async () => {
    const jwt = localStorage.getItem("jwt");
    const formData = {
      project: "FinGenius",
      jwt: jwt,
      transid: connectloctState3,
    };
    // const formData = new FormData();

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_PAY + "submit_payment",
      data: formData,
    })
      .then(async function (response) {
        const res = response.data.Success;
        toast.success(res);
        if (res === "Payment received successfully!") {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  return (
    <div>
      <div className="container ">
        <div className="flex justify-center items-center">
          <div className="bg-colorPrimary shadow-neumorphic1 rounded-3xl h-auto w-full xl:w-1/2 p-3">
            <div>
              <div className="text-3xl text-center font-semibold py-2 text-colorSecondaryDark">
                You have selected a {splitAsset[1]} Network
              </div>
              <div className="text-center px-2 py-1 text-md">
                {/* Deposit funds will be credited to your account upon successful
                payment approval by our team. Any changes in the deposited
                amount will be reflected accordingly. */}
                Scan the QR code below or Deposit the ${connectloctState1}{" "}
                {splitAsset[0]}  {splitAsset[1]}&nbsp; into the below address
                and wait for network confirmation.
              </div>
            </div>
            <div className="flex justify-center ">
              <div className=" text-md list-disc my-1 border-2 w-44 text-center text-colorSecondaryDark border-colorSecondary border-dotted  font-semibold mt-1 px-2 rounded-full">
                ${connectloctState1} &nbsp;
                {splitAsset[0]}
              </div>
            </div>
            <div className="text-center flex justify-center">
              <Card className="shadow-neumorphicnew bg-colorPrimary ">
                <QRCode
                  value={connectloctState ? connectloctState : ""}
                  renderAs="canvas"
                />
              </Card>
              {/* <img src={img1} alt="payment" /> */}
            </div>
            <div className="text-center py-3">
              {" "}
              Send Your payment to the Provided wallet address
            </div>

            <div className="grid grid-cols-1 px-3 gap-2 py-2 items-center justify-center justify-items-center">
              <div className="w-full md:w-2/3 text-sm font-semibold  ">
                <div className="flex justify-center items-center rounded-3xl px-4 py-2 bg-colorPrimary shadow-neumorphicnew ">
                  <input
                    className=" appearance-none  bg-transparent w-full  text-black leading-tight focus:outline-none "
                    value={connectloctState}
                    readOnly
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{ background: "transparent" }}
                  ></input>
                  <Tooltip title="Copy">
                    <CopyOutlined
                      onClick={handleCopy}
                      style={{
                        color: "#000",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
                <span
                  id="copiedMessage"
                  style={{ display: "none", color: "green" }}
                >
                  Copied
                </span>
              </div>
            </div>
            <div className="text-center text-xs pb-1 text-[red]">
              Only Deposit {splitAsset[0]} {splitAsset[1]} into above address,
              depositing any other currency will result in loss of funds.
            </div>
            <div className="text-center text-sm">
              Need help? Contact :- support@fingenius.ai
            </div>
            <div className="text-center text-xs xl:text-base py-3">
              You can close this window anytime without impacting your payment
              process.
            </div>

            <div className="flex justify-center pt-2 gap-3"></div>
            <div className="flex md:flex-row flex-col justify-between mx-3">
              <Link to="/dashboard/deposit">
                <button
                  // onClick={getverifyotp}
                  className="bg-colorAccent my-2 lg:my-0 text-white py-1 mr-1 px-2 rounded-lg hover:text-colorSecondary hover:border-2 hover:border-colorSecondary hover:bg-white"
                >
                  Cancel
                </button>
              </Link>
              <button
                onClick={ViewPackagePayment}
                className="bg-colorAccent text-white py-1 px-2 rounded-lg hover:text-colorSecondary hover:border-2 hover:border-colorSecondary hover:bg-white"
              >
                Amount Transfered to Above Address
              </button>
            </div>
            {/* <p>
              Send Your payment to the Provided wallet address
              0xEA1F91021BeD0EC78CA9de789BcBd4dDcD62C4
            </p>
            <p>
              Send Your payment to the Provided wallet address
              0xEA1F91021BeD0EC78CA9de789BcBd4dDcD62C4
            </p>
            <p>
              You can close this window anytime without impacting your payment
              process.
            </p>
            <p>CountDown: 18 seconds</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentGateway