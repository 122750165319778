import React, { useState } from "react";
// import img1 from "../assets/images/23.png";
import img1 from "../assets/CoinsNew/BTCUSDT.png";
import { Checkbox, Form, Input, Modal, Select, Slider, Tooltip } from "antd";
import { CiEdit } from "react-icons/ci"
import { BsPencil, BsRobot } from "react-icons/bs";
import { VscGraphLine } from "react-icons/vsc";
import { Switch } from "antd";

import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import TelegramModel from "./TelegramModel";
const { Option } = Select;

function AllBot1({ myProp, onPropChange }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRisk, setSelectedRisk] = useState("MEDIUM");
  const [botData, setBotData] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState("");
  const [optionsForSelect2, setOptionsForSelect2] = useState([]);
  const [exchangeError, setExchangeError] = useState(false);
  const [optionError, setOptionError] = useState(false);
  const [countError, setCountError] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [internalState, setInternalState] = useState(myProp.avg_profite);
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [botPercentError, setBotPercentError] = useState(false);
  const [PercentError, setPercentError] = useState(false);

  const [percent, setPercent] = useState('');
  const [dollar, setDollar] = useState('');

  const [botpercent, setBotPercent] = useState('');
  const [botdollar, setBotDollar] = useState('');
  // const handlePercentChange = (event) => {
  //   const newValue = event.target.value;
  //   setPercent(newValue);
  //   // Calculate dollar based on percent and count
  //   if (newValue !== '') {
  //     const calculatedDollar = (parseFloat(newValue) / 100) * parseFloat(count);
  //     setDollar(calculatedDollar.toFixed(2)); // Adjust decimal places as needed
  //   } else {
  //     setDollar('');
  //   }
  // };

  // const handleDollarChange = (event) => {
  //   const newValue = event.target.value;
  //   setDollar(newValue);
  //   // Calculate percent based on dollar and count
  //   if (newValue !== '') {
  //     const calculatedPercent = (parseFloat(newValue) / parseFloat(count)) * 100;
  //     setPercent(calculatedPercent.toFixed(2)); // Adjust decimal places as needed
  //   } else {
  //     setPercent('');
  //   }
  // };


  // const handleBotPercentChange = (event) => {
  //   const newValue = event.target.value;
  //   setBotPercent(newValue);
  //   // Calculate dollar based on percent and count
  //   if (newValue !== '') {
  //     const calculatedDollar = (parseFloat(newValue) / 100) * parseFloat(count);
  //     setBotDollar(calculatedDollar.toFixed(2)); // Adjust decimal places as needed
  //   } else {
  //     setBotDollar('');
  //   }
  // };

  // const handleBotDollarChange = (event) => {
  //   const newValue = event.target.value;
  //   setBotDollar(newValue);
  //   // Calculate percent based on dollar and count
  //   if (newValue !== '') {
  //     const calculatedPercent = (parseFloat(newValue) / parseFloat(count)) * 100;
  //     setBotPercent(calculatedPercent.toFixed(2)); // Adjust decimal places as needed
  //   } else {
  //     setBotPercent('');
  //   }
  // };

  if (internalState !== myProp.avg_profite) {
    setInternalState(myProp.avg_profite);
  }

  const { Name, VarBotID, Trade_Type, Symbol, images, id } = myProp;

 

  const showModal = (id) => {
    setIsModalVisible(true);
    clearAllSelectOptions();
    exchage_bot_list();
    ViewBotprice();
  };

  const handleOk = () => {
    setIsModalVisible(false);
    clearAllSelectOptions();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    clearAllSelectOptions();
  };

  const [selectedOption, setSelectedOption] = useState("");

  const [riskLevels, setRiskLevels] = useState([]);

  riskLevels.forEach((level) => {
    marks[level] = level;
  });

  useEffect(() => {
    if (!isModalVisible) {
      setIsTermsModalVisible(false);
    }
  }, [isModalVisible]);

  const jwt = localStorage.getItem("jwt");
  const [exchnagelist, setexchnagelist] = useState([]);

  const ViewBotprice = async (sliderValue) => {
    const postData = {
      project: process.env.REACT_APP_PROJECT,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_USER_NEW + "investment_matrix",
        postData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setBotData(response?.data?.Success);
    } catch (err) {
      console.error(err);
    }
  };

  const exchage_bot_list = async () => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
        exchange_type: Trade_Type,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_API + "exchange_api_options",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setexchnagelist(response?.data?.Success);
    } catch (err) {
      console.error(err);
    }
  };

  const exchage_bot_list_view = async (selectedValue) => {
    try {
      const postdata = {
        project: process.env.REACT_APP_PROJECT,
        jwt: jwt,
        exchange_type: Trade_Type,
        exchange: selectedValue,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_PATH_API + "apiname_options",
        postdata,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setOptionsForSelect2(response.data.Success);
    } catch (err) {
      console.error(err);
    }
  };

  const clearAllSelectOptions = () => {
    setSelectedExchange("");
    setSelectedOption("");
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;

    if (event.target.id === "selectOption") {
      setSelectedExchange(selectedValue);

      exchage_bot_list_view(selectedValue);
    } else if (event.target.id === "selectOption2") {
      setSelectedOption(selectedValue);
    }
  };
  const [error, setError] = useState(null);

  const view_modelbot = async (e) => {
    e.preventDefault();
    setError(null);

    if (!selectedExchange) {
      setExchangeError(true);
    } else {
      setExchangeError(false);
    }

    if (!selectedOption) {
      setOptionError(true);
    } else {
      setOptionError(false);
    }

    if (!count || isNaN(count)) {
      setCountError(true);
    } else {
      setCountError(false);
    }

    if (!exchangeError && !optionError && !countError) {
      const postData = {
        jwt: jwt,
        project: process.env.REACT_APP_PROJECT,
        bot_id: VarBotID,
        api_name: selectedOption,
        invest_type: selectedRisk,
        amount: parseInt(count),
        base: 0,
        basel: 0,
        bases: 0,
        order_count: 0,
        order_countl: 0,
        order_counts: 0,
        vol_mul: 0,
        vol_mull: 0,
        vol_muls: 0,
        lev: 0,
        order_type_open: "",
        order_type_close: "",
        order_type_openl: "",
        order_type_closel: "",
        order_type_opens: "",
        order_type_closes: "",
        exchange: selectedExchange,
        exchange_type: Trade_Type,
        take_profit_percent: 0,
        loss_percent: 0,
        loss_percent_mul: 0,
        take_profit_percentl: 0,
        loss_percentl: 0,
        loss_percent_mull: 0,
        take_profit_percents: 0,
        loss_percents: 0,
        loss_percent_muls: 0,
        telegram_alert: isChecked,
        // total_profit_limit: botpercent,
        // total_loss_limit: percent
      };

      if (botpercent) {
        postData.total_profit_limit = Number(botpercent);
      }

      if (percent) {
        postData.total_loss_limit = Number(percent);
      }
      axios({
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_PATH_BOT + "create",
        data: postData,
      })
        .then(async function (response) {
          setIsTermsModalVisible(false);
          const res = await response.data;
          if (res?.Success === "Bot activated successfully!") {
            setCount(res?.Success);
            setIsTermsModalVisible(false);
            setIsModalVisible(false);
            toast(res?.Success);
          } else {
            setError(res?.Error);
            toast.error(res?.Error);
            setIsTermsModalVisible(false);
          }
        })
        .catch((err) => {
          setError(err.response.data.Error);
          toast.error(err.response.data.Error);
          setIsTermsModalVisible(false);
        });
    }
  };

  const sliderValues = {
    SPOT: { LOW: 1270, MEDIUM: 1135, HIGH: 250 },
    FUTURES: { LOW: 127, MEDIUM: 113.5, HIGH: 25 },
  };

  const marks = {
    0: {
      label: "LOW",

      style: {
        color: "#032B43",
      },
    },
    50: {
      label: "MEDIUM",

      style: {
        color: "#032B43",
      },
    },
    100: {
      label: "HIGH",

      style: {
        color: "#f00",
      },
    },
  };

  useEffect(() => {
    setSliderValue(0);
    setSelectedRisk("LOW");
  }, [Trade_Type]);

  const handleSliderChange = (value) => {
    setSliderValue(value);

    if (value === 50) {
      setSelectedRisk("MEDIUM");
    } else if (value === 100) {
      setSelectedRisk("HIGH");
    } else {
      setSelectedRisk("LOW");
    }
  };

  const initialCount = sliderValues[Trade_Type][selectedRisk];
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    const updatedValue = sliderValues[Trade_Type][selectedRisk];
    setCount(updatedValue);
  }, [selectedRisk, Trade_Type]);

  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal1 = () => {
    setIsModalOpen(true);
  };
  const handleOk1 = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);
  };

  const showModalNew = () => {
    let hasErrors = false;

    if (!selectedExchange) {
      setExchangeError(true);
      hasErrors = true;
    } else {
      setExchangeError(false);
    }

    // if (!botpercent || isNaN(parseFloat(botpercent)) || parseFloat(botpercent) <= 0) {
    //   setBotPercentError(true);
    //   toast.error("Take Profit Limit must be greater than 0");
    // } else {
    //   setBotPercentError(false);
    // }

    if (botpercent && botpercent.trim() !== '') {
      const botpercentValue = parseFloat(botpercent);
      if (isNaN(botpercentValue) || botpercentValue <= 0) {
        setBotPercentError(true);
        toast.error("Take Profit Limit must be a valid number greater than 0");
        hasErrors = true;
      } else {
        setBotPercentError(false);
      }
    } else {
      // Handle case where botpercent is empty
      setBotPercentError(true); // Assuming you want to show an error for an empty input
      // hasErrors = true;
    }

    if (percent && percent.trim() !== '') {
      const percentValue = parseFloat(percent);
      if (isNaN(percentValue) || percentValue <= 0) {
        setPercentError(true);
        toast.error("Take Profit Limit must be a valid number greater than 0");
        hasErrors = true;
      } else {
        setPercentError(false);
      }
    } else {
      // Handle case where percent is empty
      setPercentError(true); // Assuming you want to show an error for an empty input
      // hasErrors = true;
    }

    
    // if (!percent || isNaN(percent) || percent <= 0) {
    //   setPercentError(true);
    //   toast.error("Drawdown must be greater than 0");
    // } else {
    //   setPercentError(false);
    // }

    if (!selectedOption) {
      setOptionError(true);
      hasErrors = true;
    } else {
      setOptionError(false);
    }

    if (!count || isNaN(count)) {
      setCountError(true);
      hasErrors = true;
    } else {
      setCountError(false);
    }

    if (!hasErrors) {
      setIsTermsModalVisible(true);
    } else {
      setError("Please fill in all the required fields.");
    }
  };

  const TermsModal = ({ visible, onCancel }) => {
    const [isAgreed, setIsAgreed] = useState(false);

    const handleCheckboxChange = (e) => {
      setIsAgreed(e.target.checked);
    };

    const handleSuccessSubmit = () => {
      onCancel();
    };


    return (
      <Modal
        title={
          <div className="text-colorSecondaryDark text-center">
            Terms & Conditions For Copy Bot
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
      >
        <div className="border-2 border-colorSecondary my-2 px-2 py-1 rounded-lg border-dashed">
          <div className="flex justify-between items-center">
            <div className="font-semibold">
              <span className="font-normal">Bot Name: </span>
              {Name}
            </div>
            <div className="font-semibold">
              {" "}
              <span className="font-normal">Symbol: </span>
              {Symbol}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-semibold">
              {" "}
              <span className="font-normal">Min Investment:</span> ${" "}
              {/* {sliderValues[Trade_Type]?.LOW} */}
              {count}
            </div>
            {/* <div className="font-semibold">
              {" "}
              <span className="font-normal">Select Exchange:</span>{" "}
              {selectedExchange}
            </div> */}
            <div className="font-semibold">
              {" "}
              <span className="font-normal">Select API:</span> {selectedOption}
            </div>
          </div>
        </div>
        <div className="font-semibold my-1">Minimum Balance Requirement:</div>
        <ul className="list-disc ml-4">
          <li>
            Users must maintain a minimum balance as specified by the platform.
          </li>
          <li>
            Falling below this minimum may result in the suspension of bot
            services.
          </li>
          <li>
            The company bears no responsibility for losses and liquidation if
            the balance is not maintained according to the allocated funds.
          </li>
          <li>Trades depend on exchange approval. If trades are restricted or fail to execute, responsibility lies with the exchange.</li>
        </ul>
        <div className="font-semibold my-1">No Manual Trading:</div>
        <ul className="list-disc ml-4">
          <li>
            Users are strictly prohibited from engaging in manual trading.
          </li>
          <li>
            All trading activities should be exclusively conducted through the
            automated bot.
          </li>
        </ul>

        <div className="border-2 border-colorSecondary  my-2 px-2 py-1 rounded-lg border-dashed">
          <Checkbox
            className={isAgreed ? "red-checkbox" : ""}
            onChange={handleCheckboxChange}
          />
          &nbsp; By utilizing our services, you confirm your agreement with
          these terms and conditions. Failure to comply may result in the
          suspension of services and liquidation of assets, and the company will
          not be held responsible for resulting losses.
        </div>

        <div className="flex justify-end items-center">
          <button
            onClick={() => setIsTermsModalVisible(false)}
            className="active hover:border-neonPurple  text-white w-28 font-bold px-5 py-2 text-sm rounded-2xl ml-5"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={(e) => {
              if (isAgreed) {
                view_modelbot(e);
                handleSuccessSubmit();
              } else {
                alert("Please agree to the terms before submitting.");
              }
            }}
            className={`bg-neonPurple  hover:bg-neonPurple text-white w-28 font-bold px-5 py-2 text-sm rounded-2xl ml-5 ${!isAgreed ? "opacity-50 cursor-not-allowed" : ""
              }`}
            disabled={!isAgreed}
          >
            Submit
          </button>
        </div>
      </Modal>
    );
  };
  // Minimum investment value
  const minInvestment = sliderValues[Trade_Type][selectedRisk];

  // const handlePercentChange = (event) => {
  //   const newPercent = event.target.value;
  //   setPercent(newPercent);
  //   calculateDollarValue(count, newPercent);
  // };

  // const handleDollarChange = (event) => {
  //   const newDollar = event.target.value;
  //   setDollar(newDollar);
  //   calculatePercentValue(count, newDollar);
  // };

  // const handleBotPercentChange = (event) => {
  //   const newBotPercent = event.target.value;
  //   setBotPercent(newBotPercent);
  //   calculateBotDollarValue(count, newBotPercent);
  // };

  // const handleBotDollarChange = (event) => {
  //   const newBotDollar = event.target.value;
  //   setBotDollar(newBotDollar);
  //   calculateBotPercentValue(count, newBotDollar);
  // };

  // const handleDollarChange = (event) => {
  //   const newDollar = event.target.value;
  //   if (newDollar === '' || (!isNaN(newDollar) && newDollar >= 0)) {
  //     setDollar(newDollar);
  //     calculatePercentValue(count, newDollar);
  //   }
  // };

  const handlePercentChange = (event) => {
    const newPercent = event.target.value;
    if (
      newPercent === '' ||
      (!isNaN(newPercent) && newPercent >= 0 && newPercent <= 100)
    ) {
      setPercent(newPercent);
      setPercentError(false);
      calculateDollarValue(count, newPercent);
    }else {
      setPercentError(true);
    }
  };
  
  const handleDollarChange = (event) => {
    const newDollar = event.target.value;
    if (newDollar === '' || (!isNaN(newDollar) && newDollar >= 0 && parseFloat(newDollar) <= parseFloat(count))) {
      setDollar(newDollar);
      calculatePercentValue(count, newDollar);
    }
  };

  const handleBotPercentChange = (event) => {
    const newBotPercent = event.target.value;
    if (newBotPercent === '' || (!isNaN(newBotPercent) && newBotPercent > 0 && newBotPercent <= 100)) {
      setBotPercent(newBotPercent);
      setBotPercentError(false);
      calculateBotDollarValue(count, newBotPercent);
    } else {
      setBotPercentError(true);
    }
  };

  const handleBotDollarChange = (event) => {
    const newBotDollar = event.target.value;
    if (newBotDollar === '' || (!isNaN(newBotDollar) && newBotDollar >= 0) ) {
      setBotDollar(newBotDollar);
      calculateBotPercentValue(count, newBotDollar);
    }
  };


  const calculateDollarValue = (count, percent) => {
    if (!count || !percent) {
      setDollar('');
      return;
    }

    const adjustedAmount = parseFloat(count) * (1 - parseFloat(percent) / 100);
    setDollar(adjustedAmount.toFixed(2));
  };

  const calculatePercentValue = (count, dollar) => {
    if (!count || !dollar) {
      setPercent('');
      return;
    }

    const drawdownPercent = ((parseFloat(count) - parseFloat(dollar)) / parseFloat(count)) * 100;
    setPercent(drawdownPercent.toFixed(2));
  };

  const calculateBotDollarValue = (count, botpercent) => {
    if (!count || !botpercent) {
      setBotDollar('');
      return;
    }

    const adjustedAmount = parseFloat(count) * (1 + parseFloat(botpercent) / 100);
    setBotDollar(adjustedAmount.toFixed(2));
  };

  const calculateBotPercentValue = (count, botdollar) => {
    if (!count || !botdollar) {
      setBotPercent('');
      return;
    }

    const takeProfitPercent = ((parseFloat(botdollar) - parseFloat(count)) / parseFloat(count)) * 100;
    setBotPercent(takeProfitPercent.toFixed(2));
  };


  console.log(images,"allBot")

  return (
    <>
      <div className="contents mb-5">
        <div>
          <div>
            <div
              className={` bg-colorPrimary text-black md:h-40 px-6 relative rounded-xl mt-4 shadow-neumorphic`}
            >
              <div className="">
                <BsRobot className="opacity-30 text-colorPrimary absolute right-10 top-0 overflow-hidden h-full w-3/5" />
              </div>
              <div className="flex  items-center justify-between mt-4 mb-2 relative ">
                <h1 className="text-md mt-2 font-extrabold">{Name}</h1>

                <div className="flex mt-2">
                  <div className="text-black shadow-sm border-2 border-colorAccent text-[10px] px-3 font-bold py-1 rounded-full ">
                    {VarBotID}
                  </div>
                  <div className="text-black shadow-sm border-2 border-colorAccent text-[10px] px-3 font-bold py-1 rounded-full ml-2 ">
                    {Trade_Type}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 justify-between text-black relative items-center  pb-2">
                <div className="font-bold text-black">
                  <div>
                    <p className="text-xs ">Average Profit%</p>
                    <p className="text-[green] text-lg sm:text-4xl">
                      {internalState}%
                    </p>
                  </div>
                </div>
                <div className="flex gap-2 items-center  font-bold">
                  <p className="text-sm lg:text-md md:text-lg sm:text-2xl">{Symbol}</p>
                  <img src={images} alt="coin" className="w-14 mx-auto" />
                </div>
              </div>
              <div className="flex md:justify-between md:flex-row flex-col  text-black relative items-center pb-3">
                <div className="flex-col items-center font-bold">
                  <div className="flex-col items-center justify-center">
                  
                    <div className=" flex gap-2 mb-1 sm:mb-0">
                      {/* {
                        checkKey.reverse().map(item => {
                          return (
                            <div onClick={() => filterObjdata(item)} className={` py-1 rounded-md px-3 2xl:px-2 text-sm border border-dashed border-colorSecondaryDark  ${selectPro == item ? " bg-colorAccent  text-white border-[green]  " : ""}`}>
                              {item}
                            </div>
                          )
                        })
                      } */}
                    </div>
                  </div>
                </div>
                <div className="flex-col  items-center justify-center">
                  <button
                    className=" hover:bg-white shadow-md shadow-neumorphic shadow-black border-white bg-colorSecondaryDark font-bold lg:px-2 2xl:px-2 px-4  py-2 text-sm text-white  rounded-lg mr-0 border-2 hover:text-colorSecondaryDark border-solid hover:border-colorSecondaryDark "
                    onClick={() => {
                      showModal(id);
                    }}
                  >
                    Copy Bot
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <TelegramModel
            isModalOpen={isModalOpen}
            showModal1={showModal1}
            handleOk1={handleOk1}
            handleCancel1={handleCancel1}
          />
        )}
      <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          closeIcon={null}
          className="bg-colorSecondaryDark rounded-3xl border-4 border-white px-0 text-white"
          title={
            <div className="rounded-lg p-4  font-bold flex items-center justify-between">
              <div>
                <div className="flex items-end mb-2 justify-start gap-2">
                  <BsRobot className="text-colorSecondaryDark text-4xl" />
                  <div className="text-2xl font-bold "> {Name}</div>
                </div>
                <div className="flex mt-2">
                  <div className="text-black shadow-sm border-2 border-neonPurple text-[10px] px-3 font-bold py-1 rounded-full ">
                    {VarBotID}
                  </div>
                  <div className="text-black shadow-sm border-2 border-neonPurple text-[10px] px-3 font-bold py-1 rounded-full ml-2 ">
                    {Trade_Type}
                  </div>
                </div>
              </div>
              <div className="text-xs text-gray-500">
                <img src={images} alt="coin" className="w-14" />
                <p>{Symbol}</p>
              </div>
            </div>
          }
        >
          <div className="relative -mt-6 overflow-hidden">
            <div className="md:border-l-2 md:border-t-0 md:border-r-0 border-b-0 md:border-dashed md:border-colorSecondary ml-2 md:pl-6 justify-start mt-5 items-center flex flex-col w-full md:gap-5">
              <div className="w-11/12 mr-auto ">
                <div className="">
                  <div className="grid grid-cols-2 gap-6 justify-items-center items-end md:items-center p-[20px] shadow-neumorphic ">
                    <div>
                      <p className=" text-black text-center text-sm">
                        Select Exchange
                      </p>
                      <select
                        id="selectOption"
                        className="bg-colorPrimary  boder-2 text-black shadow-neumorphic1 mt-1 px-2 md:px-4 py-1 rounded-full"
                        onChange={handleOptionChange}
                        value={selectedExchange}
                      >
                        <option value="">Select...</option>
                        {exchnagelist?.map((option) => (
                          <option
                            className="rounded-3xl"
                            key={option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      {exchangeError && (
                        <div className="text-red-500 text-xs">
                          Please select an exchange.
                        </div>
                      )}
                    </div>

                    <div>
                      <p className=" text-black text-center">Select API</p>

                      <select
                        id="selectOption2"
                        className="bg-colorPrimary text-black shadow-neumorphic1 mt-1 px-2 md:px-4  py-1 rounded-full"
                        onChange={handleOptionChange}
                        value={selectedOption}
                      >
                        <option value="">Select...</option>
                        {optionsForSelect2?.map((option) => (
                          <option
                            className="rounded-3xl"
                            key={option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      {optionError && (
                        <div className="text-red-500 text-xs">
                          Please select an API.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="my-4 bg-white px-3  md:px-4 py-2 rounded-lg">
                  <div className="flex items-center justify-between">
                    <div className="text-colorSecondaryDark">Risks Matrix</div>
                    <div className="flex items-center">
                      <Tooltip title="Coming Soon">
                      <label className="mr-2 text-black text-xs">
                        Telegram Alert:{" "}
                      </label>
                     
                      <Switch
                        type="checkbox"
                        checked={isChecked}
                        // onChange={toggleSwitch}
                        className={isChecked ? "off" : "on"}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />
                       </Tooltip>
                    </div>
                  </div>

                  <Slider
                    value={sliderValue}
                    onChange={(value) => {
                      handleSliderChange(value);
                      setCount(value);
                    }}
                    step={null}
                    defaultValue={0}
                    marks={marks}
                  />
                  <div className="text-black flex justify-center items-center">
                    <div className={`border-2 text-sm md:text-lg rounded-3xl p-1 md:p-0 md:px-7  ${count < minInvestment ? 'border-red-500' : 'border-[#ccc]/30'} `}>
                      Min Investment :{sliderValues[Trade_Type][selectedRisk]}{" "}
                      USDT
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <p className="text-[red]">
                      Once Invested, amount cannot be changed
                    </p>
                  </div>
                </div>
                <div className="text-start my-4">
                  <Input
                    id="count"
                    name="count"
                    type="text"
                    suffix="USDT"
                    className="modalinpu"
                     pattern="\d*"
                    value={count}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      // if (newValue === '' || (!isNaN(newValue) && newValue >= 0)) {
                        setCount(newValue);
                        calculateDollarValue(newValue, percent);
                        calculatePercentValue(newValue, dollar);
                        calculateBotDollarValue(newValue, botpercent);
                        calculateBotPercentValue(newValue, botdollar);
                      // }
                    }}
                    placeholder="Enter amount"
                  />
                  {countError && (
                    <div className="text-red-500 text-xs">
                      Please enter a amount.
                    </div>
                  )}
                </div>

                <div className="flex justify-center gap-4">
                  <div>
                    <Tooltip title="Drawdown in trading is the decline from a peak in the value of your investment or trading account to a lower point.">  <div className="text-colorSecondaryDark text-center">Drawdown</div></Tooltip>
                    <div className="text-start flex my-4">
                      {/* <Input
                        id="percent"
                        name="percent"
                        type="text"
                        suffix="%"
                        className="modalinput"
                        value={percent}
                        onChange={handlePercentChange}
                        placeholder="Enter percentage"
                      />
                      <Input
                        id="dollar"
                        name="dollar"
                        type="text"
                        suffix="$"
                        className="modalinput"
                        value={dollar}
                        onChange={handleDollarChange}
                        placeholder="Enter dollar amount"
                      /> */}
                      <Input
                        id="percent"
                        name="percent"
                        type="text"
                        suffix="%"
                        className="modalinput"
                        value={percent}
                        onChange={handlePercentChange}
                        placeholder="Enter percentage"
                         pattern="\d*"
                      />
                      <Input
                        id="dollar"
                        name="dollar"
                        type="text"
                        suffix="$"
                        className="modalinput"
                        value={dollar}
                        onChange={handleDollarChange}
                        placeholder="Enter dollar amount"
                         pattern="\d*"
                      />
                    </div>
                  </div>

                  <div className="border-l-2 border-black border-dashed"> </div>

                  <div className=" ">
                    <Tooltip title="Take profit is a preset profit limit where the bot automatically closes a trade to lock in gains.">    <div className="text-colorSecondaryDark text-center">
                      Bot Take Profit
                    </div></Tooltip>
                    <div className="text-start flex  my-4">
                      <Input
                        id="botpercent"
                        name="botpercent"
                        type="text"
                        suffix="%"
                        className="modalinput"
                        value={botpercent}
                        onChange={handleBotPercentChange}
                        placeholder="Enter percentage"
                        pattern="\d*"
                      />
                      <Input
                        id="botdollar"
                        name="botdollar"
                        type="text"
                        suffix="$"
                        className="modalinput"
                        value={botdollar}
                        onChange={handleBotDollarChange}
                        placeholder="Enter dollar amount"
                        pattern="\d*"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <p className="text-black">
                    Bot stops when drawdown or take profit is reached.

                  </p>
                </div>
                <div className="flex items-center justify-around">
                  <div
                    className="bg-white border-solid cursor-pointer border border-neonPurple text-black font-bold px-5 py-2 text-sm rounded-2xl"
                    onClick={handleCancel}
                  >
                    Cancel
                  </div>
                  <button
                    className="bg-colorSecondaryDark hover:bg-colorSecondaryDark text-white w-24 font-bold px-5 py-2 text-sm rounded-2xl ml-5"
                    onClick={showModalNew}
                  >
                    Next
                  </button>
                  <TermsModal
                    visible={isTermsModalVisible}
                    onCancel={() => setIsTermsModalVisible(false)}
                    onSubmit={view_modelbot}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal> 
      </div>
    </>
  );
}

export default AllBot1;
