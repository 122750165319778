import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";

function MarketDepth({ coin }) {
  const [askOrders, setAskOrders] = useState([]);
  const [bidOrders, setBidOrders] = useState([]);

  useEffect(() => {
    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${coin.toLowerCase()}@depth5@100ms`);

    ws.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      const asks = data.asks.map((ask) => ({
        price: Number(ask[0]),
        quantity: Number(ask[1]),
      }));
      const bids = data.bids.map((bid) => ({
        price: Number(bid[0]),
        quantity: Number(bid[1]),
      }));
      setAskOrders(asks);
      setBidOrders(bids);
    });

    return () => {
      ws.close();
    };
  }, [coin]);

  return (
    <div className="p-1">
      <div className="">
        {bidOrders && bidOrders?.map((bids) => {
          return (
            <Tooltip placement="left" title={bids?.price}>
              <div className="flex justify-between border border-solid p-1 border-slate-200">
                <div className="text-sm text-red-600">{bids?.price}</div>
                <div className="text-sm text-red-600">{bids?.quantity}</div>
              </div>
            </Tooltip>
          );
        })}
      </div>
      <div className="mt-2">
        {askOrders && askOrders?.map((ask) => {
          return (
            <Tooltip placement="left" title={ask?.price}>
              <div className="flex justify-between border border-solid p-1 border-slate-200">
                <div className="text-sm  text-[#0ecb81]">{ask?.price}</div>
                <div className="text-sm  text-[#0ecb81]">{ask?.quantity}</div>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}

export default MarketDepth;
