import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import img1 from "../assets/Api/BINANCEFUTURES.png";
import img2 from "../assets/Api/BINANCESPOT.png";
import img5 from "../assets/Api/BYBITFUTURES.png";
import img6 from "../assets/Api/BYBITSPOT.png";
import img3 from "../assets/Api/KUCOINFUTURES.png";
import img4 from "../assets/Api/KUCOINSPOT.png";
import img11 from "../assets/coins/BINANCEFUTURES.png";
import img21 from "../assets/coins/BINANCESPOT.png";
import img51 from "../assets/coins/BYBITFUTURES.png";
import img61 from "../assets/coins/BYBITSPOT.png";
import img31 from "../assets/coins/KUCOINFUTURES.png";
import img41 from "../assets/coins/KUCOINSPOT.png";
import axios from "axios";
import { toast } from "react-toastify";
import TelegramModel from "./TelegramModel";
import SkeltorLoader from "./SkeltorLoader";


function ExchangeItem({
  exchangeName,
  imgSrc,
  imgSrc2,
  onLiveClick,
  onPaperClick,
  currentStepIndex,
  profile,
}) {
  const Navigate = useNavigate();
  console.log(currentStepIndex, "kkk");

  const [stepsData, setStepsData] = useState([
    { title: "Choose Exchange", completed: false },
    { title: "Setup Keys", completed: false },
    { title: "Connect", completed: false },
  ]);

  const handleStepClick = (stepIndex) => {
    const updatedStepsData = stepsData.map((step, index) => ({
      ...step,
      completed: index === 0,
    }));
    setStepsData(updatedStepsData);
  };

  const isSetupKeysStep = currentStepIndex === 1;

  

  return (
    <div className="grid grid-cols-6 w-full shadow-neumorphic rounded-lg">
      <div className="col-span-4 bg-colorPrimary flex lg:py-6  py-8 px-2 rounded-tl-lg rounded-bl-lg">
        <img src={imgSrc} alt="logo" className="" />
      </div>
      <div className="col-span-2 bg-colorSecondaryDark flex flex-col justify-center items-center rounded-tr-lg rounded-br-lg">
        <button
          className={`mt-4 py-1 px-3 text-sm  w-24 shadow-sm 2xl:px-8 md:px-7 lg:px-8  text-white rounded-3xl bg-colorSecondary hover:text-colorSecondaryDark hover:border-black shadow-white hover:bg-white hover:border-none ${
            isSetupKeysStep ? "checked" : ""
          }`}
          onClick={onLiveClick}
        >
          LIVE
        </button>
        <button
          className={`mt-2 px-3 text-sm  w-24 shadow-sm py-1 lg:px-6 md:px-5 mb-5 text-white rounded-3xl bg-colorSecondary hover:text-colorSecondaryDark hover:border-black shadow-white hover:bg-white hover:border-none ${
            isSetupKeysStep ? "checked" : ""
          }`}
          onClick={onPaperClick}
        >
          PAPER
        </button>
      </div>
    </div>
  );
}

function Exchange_pre() {
 
 const [stepsData, setStepsData] = useState([
   { title: "Choose Exchange", completed: false },
   { title: "Setup Keys", completed: false },
   { title: "Connect", completed: false },

 ]);
  const [exchangeData, setExchangeData] = useState([]);
  console.log("exchangeData ==>>>" , exchangeData)
  const navigate = useNavigate();
  const [Packageview1, setPackageview1] = useState([]);
  const [profile, setProfile] = useState("");

  const showSpotConnectionDetails = () => {
    navigate("/dashboard/api_settings/Spotconnectiondetails");
  };

  const [loading, setLoading] = useState(true);

 


  const ViewPackageView = async () => {
    const jwt = localStorage.getItem("jwt");
  
    const postData = {
      project: "FinGenius",
      jwt: jwt,
    };
    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_SUB + "view_subscription",
      data: postData,
    })
      .then(async function (response) {
        if (response?.data?.Details) {
          setPackageview1(response?.data?.Details);
          console.log(response?.data?.Details, "fjhfjhfh");
        }
       
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
      });
  };


   const View_Profile = async () => {
     const jwt = localStorage.getItem("jwt");
     const postdata = {
       project: "FinGenius",
       jwt: jwt,
     };

     const response = await axios({
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       url: process.env.REACT_APP_API_PATH_USER + "view_profile",
       data: postdata,
     })
       .then(async (res) => {
         const response = await res;
         console.log("response", response?.data?.Success);
         if (response) {

           setProfile(response?.data?.Success);
         } else {
           toast.error(response?.data?.Error);
         }
         
       })
       .catch((err) => {
         console.log("err", err);
         toast.error(err?.response?.data?.Error);
         if (err?.response?.data?.Error === "JWT Expired") {
           setTimeout(() => {
             localStorage.clear();
             window.location.href = "/";
           }, 5000);
         }
       });
   };

   useEffect(() => {
     View_Profile();
     ViewPackageView();
   }, []);

   const [isModalOpen, setIsModalOpen] = useState(false);
   const showModal1 = () => {
     setIsModalOpen(true);
   };
   const handleOk1 = () => {
     setIsModalOpen(false);
   };
   const handleCancel1 = () => {
     setIsModalOpen(false);
   };

  


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_PATH_USER_NEW + "exchange",
          { project: "FinGenius" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = response?.data?.Success || {};

        const images = [
          { name: "BINANCEFUTURES", img: img1, imgnew: img11 },
          { name: "BINANCESPOT", img: img2, imgnew: img21 },
          { name: "KUCOINSPOT", img: img4, imgnew: img31 },
          { name: "KUCOINFUTURES", img: img3, imgnew: img41 },
          { name: "BYBITFUTURES", img: img5, imgnew: img51 },
          { name: "BYBITSPOT", img: img6, imgnew: img61 },
        ];

        

        const filteredImages = images?.filter((image) => {
          const exchangeName = image.name.split(/(SPOT|FUTURES)/)[0];
          const exchangeTypes = responseData[exchangeName] || [];
          return (
            exchangeTypes.includes("SPOT") && exchangeTypes.includes("FUTURES")
          );
        });

        setExchangeData(filteredImages);
        setLoading(false);
        
      } catch (error) {
        toast.error(error?.response?.data?.Error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);
  
  
  
  const handleStepClick = (stepIndex, imgSrc) => {
    const updatedStepsData = stepsData.map((step, index) => ({
      ...step,
      completed: index === stepIndex,
    }));
    setStepsData(updatedStepsData);
    
    navigate("/dashboard/api_settings/Spotconnectiondetails", {
      state: { status: true, imgSrc },
    });
  };
  
  const images = [
    { name: "BINANCEFUTURES", img: img1, imgnew: img11 },
    { name: "BINANCESPOT", img: img2, imgnew: img21 },
    { name: "KUCOINSPOT", img: img4, imgnew: img31 },
    { name: "KUCOINFUTURES", img: img3, imgnew: img41 },
    { name: "BYBITFUTURES", img: img5, imgnew: img51 },
    { name: "BYBITSPOT", img: img6, imgnew: img61 },
  ];
  
const targetNames =
  "BINANCEFUTURES,BINANCESPOT,KUCOINSPOT,KUCOINFUTURES,BYBITFUTURES,BYBITSPOT"; 
const targetNamesArray = targetNames.split(",");

const filteredImages1 = images
  .filter((item) => targetNamesArray.includes(item.name))
  .map((item) => item.imgnew);

console.log(filteredImages1, "Filtered Images");


  console.log(
    images.map((item) => item.imgnew),
    "Filtered Images"
  );

  

  const handleExchangeItemClick = (
    exchangeName,
    filteredImages,
    selectedImage,
    filteredImages1,
    destinationImageName
  ) => {
    const { name, img, imgnew } = selectedImage;
    const [exchange, type] = exchangeName.split(/(SPOT|FUTURES)/);
    const types = type || "";
     if (!profile || !profile.TelegramID) {
      //  toast.error("Telegram ID is blank. Please update your Telegram ID.");
        setIsModalOpen(true);
       return;
     }

      if (Packageview1.Live_API <= 0) {
    toast.error("LIVE API limit reached. You cannot add more.");
    return;
  }
    navigate(`/dashboard/api_settings/Spotconnectiondetails`, {
      state: {
        status: true,
        imgSrc: img,
        filteredImages: filteredImages,
        exchangeName: exchange,
        exchangeTypes: types,
        filteredImages1: filteredImages1,
        [destinationImageName]: imgnew,
        exchangeData : exchangeData
      },
    });
  };

  const handleExchangeItemClick1 = (
    exchangeName,
    filteredImages,
    img,
    images,
    imgSrc2
  ) => {
    const [exchange, type] = exchangeName.split(/(SPOT|FUTURES)/);
    const types = type || "";

    if (!profile || !profile.TelegramID) {
      // toast.error("Telegram ID is blank. Please update your Telegram ID.");
        setIsModalOpen(true);
      return;
    }

    
    navigate(`/dashboard/api_settings/Paperfutureconnectiondetails`, {
      state: {
        status: true,
        imgSrc: img,
        images,
        filteredImages,
        exchangeName: exchange,
        exchangeTypes: types,
        imgSrc2: images,
        exchangeData : exchangeData
      },
    });
  };

  

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2   xl:grid-cols-3  gap-5">
        {loading
          ? Array.from({ length: 2 }).map((_, index) => (
              <div className="grid grid-cols-6 w-full shadow-neumorphic rounded-lg">
              <div className="col-span-4 h-36 bg-colorPrimary flex lg:py-3  py-8 px-2 rounded-tl-lg rounded-bl-lg">
                <SkeltorLoader />
              </div>
               <div className="col-span-2 bg-colorSecondaryDark flex flex-col justify-center items-center rounded-tr-lg rounded-br-lg">
               </div>
              </div>
            ))
          : (exchangeData &&
            exchangeData?.map((exchange) => {
              return (
                <div key={exchange.name}>
                  <ExchangeItem
                    imgSrc={exchange.img}
                    imgSrc2={exchange.imgnew}
                    exchangeName={exchange.name}
                    exchangeTypes={exchange.type}
                    onLiveClick={() =>
                      handleExchangeItemClick(
                        exchange.name,
                        exchange.img,
                        exchange.imgnew
                      )
                    }
                    onPaperClick={() =>
                      handleExchangeItemClick1(
                        exchange.name,
                        exchange.img,
                        exchange.imgnew
                      )
                    }
                  />
                </div>
              );
            }))}
      </div>
      {isModalOpen && (
        <TelegramModel
          isModalOpen={isModalOpen}
          showModal1={showModal1}
          handleOk1={handleOk1}
          handleCancel1={handleCancel1}
        />
      )}
    </div>
  );
}

export default Exchange_pre;
