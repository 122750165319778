import React from "react";
import img1 from "../assets/Profile/5.png";

const Loader = () => {
  return (
    <main class=" min-h-screen bg-gradient-to-tr from-colorPrimary to-colorAccent  text-fg flex flex-col items-center justify-center">
      {/* <div class="preloader animate-largePopOut bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-full shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-1 1 2 shadow-inset--1 -1 2 w-48 h-48 relative">
        <div class="preloader__square animate-smallPopOut1 animate-popInOut bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
        <div class="preloader__square animate-smallPopOut2 animate-move2 bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
        <div class="preloader__square animate-smallPopOut3 animate-move3 bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
        <div class="preloader__square animate-smallPopOut4 animate-move4 bg-gradient-conic from-shade4 via-shade1 to-shade1 via-shade4 rounded-0.5 shadow-inset-0.15 0.15 0.15 shadow-inset--0.15 -0.15 0.15 shadow-inset-0.25 0.25 0.5 shadow-inset--0.25 -0.25 0.5 absolute top-10 left-10 w-12 h-12"></div>
      </div> */}
      {/* <div> */}
      <div className=" w-56 px-3 py-5 text-center">
        <img
          src={img1}
          className=" bg-colorPrimary w-36 mx-auto rotate rounded-full"
        />
        {/* </div> */}
        <div class="status animate-fadeIn text-center text-colorSecondaryDark text-xl mt-3">
          Loading.....
          <span class="status__dot animate-appear1 inline-block"></span>
          <span class="status__dot animate-appear2 inline-block"></span>
          <span class="status__dot animate-appear3 inline-block"></span>
        </div>
      </div>
    </main>
  );
};

export default Loader;
