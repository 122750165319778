import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import axios from "axios";
import { FaRegEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";


const DepositTran = (props) => {
 

  const [dataLog, setDataLog] = useState();

  const tableData = props.data

   const navigate = useNavigate();

  useEffect(() => {
    setDataLog(tableData)
  }, [tableData])

 const handlesubmit = (record) => {
   navigate("/dashboard/payment", {
     state: {
       Receiver_Wallet_Address:record.Receiver_Wallet_Address,
       Amount: 15,
       Asset: record?.Asset,
       TransID: record?.TransID,
     },
   });
 };

  const sourceDataTwo = [];

  if (dataLog) {
    for (let i = 0; i < dataLog.length; i++) {
 const amount = parseFloat(dataLog[i].Amount);

      sourceDataTwo.push({
        key: i,
        DateTime: dataLog[i].DateTime,
        Status: dataLog[i].Status,
        TransID: dataLog[i].TransID,
        IP: dataLog[i].IP,
        Receiver_Wallet_Address: dataLog[i].Receiver_Wallet_Address,
        Asset: dataLog[i].Asset.replace(/_/g, " "),
        Amount: `$${isNaN(amount) ? "0.00" : amount.toFixed(2)}`,
        Price: dataLog[i].Price,
      });
    }
  }

  const resSourceDataTwo = [...sourceDataTwo].reverse();


  const columns = [
    {
      title: "TransID",
      dataIndex: "TransID",
    },
    {
      title: "DateTime",
      dataIndex: "DateTime",
    },
    {
      title: "Wallet Address",
      dataIndex: "Receiver_Wallet_Address",
    },
    {
      title: "Asset",
      dataIndex: "Asset",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
    },
    {
      title: "Price",
      dataIndex: "Price",
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (text, record) => {
       
        return (
          <>
            {text === "PENDING" &&
              record &&
              record?.Receiver_Wallet_Address && (
                <span className="text-[red]">
                  <div className="flex  justify-start items-center gap-3">
                    Pending {/* console.log(record) */}
                    <Tooltip title="Pay Again">
                      <button onClick={() => handlesubmit(record)}>
                        <LiaMoneyCheckAltSolid
                          size={25}
                          className="text-black rounded-full bg-white shadow-neumorphic1 p-1"
                        />
                      </button>
                    </Tooltip>
                  </div>
                </span>
              )}
            {text !== "PENDING" && (
              <span className={text === "SUCCESS" ? "text-[green]" : ""}>
                {text}
              </span>
            )}
          </>
        );
      },
    },
  ];

   const isMobile = useMediaQuery({ maxWidth: 1050 });

   const pageSize = isMobile ? 10 : 22;

  return (
    <div className=" mx-auto w-full">
      <Table
        columns={columns}
        pagination={{
          pageSize,
          showSizeChanger: false,
          position: ["topRight", "bottomRight"],
        }}
        dataSource={resSourceDataTwo}
      />
    </div>
  );
};
export default DepositTran;
