import React, { useState, useEffect } from "react";
import { Button, Progress, Select, Form, InputNumber } from "antd";
import { Space, Switch } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import axios from "axios";
import { gettoken } from "../../Pages/Allfunction";
import FormItem from "antd/es/form/FormItem";
const { Option } = Select;
const selectAfter = (
  <Select defaultValue="%" className="bg-[#fff] rounded-lg">
    <Option value="%">%</Option>
    <Option value="$">$</Option>
  </Select>
);
function Sell({ coin, market, showbalance, tradetype }) {
  const [switchState, setSwitchState] = useState(false);
  const [progress, setProgress] = useState(0);
  const [CurrentPrice, setCurrentPrice] = useState([]);
  const [Prevprice, setPrevprice] = useState(0);
  const [Price, setPrice] = useState();
  const [Quantity, setQuantity] = useState();
  const [Amount, setAmount] = useState();


  useEffect(() => {
    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${coin.toLowerCase()}@ticker`);

    ws.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      setCurrentPrice(data);
      setPrevprice(CurrentPrice.c)
    });

    return () => {
      ws.close();
    };

  }, [coin, CurrentPrice]);


  let curreentordettype = localStorage.getItem('switchValuesell');

  const handleSwitchChange = (checked) => {
    setPrice('');
    setQuantity('');
    setAmount('');


    setSwitchState(checked);
    const switchValuesell = checked ? "market" : "limit";
    localStorage.setItem("switchValuesell", switchValuesell);

  };
  const handlechange = (e) => {
    aaa(e.target.name, e.target.value)
  };

  function aaa(name, value) {
    let prices = 0
    if (curreentordettype === 'limit') {
      prices = Price
    } else {
      prices = Prevprice
      // setPrice('');
    }
    if (name === 'Price') {
      if (curreentordettype === 'limit') {
        setPrice(value);

      }
    }
    if (name === 'Quantity') {
      setQuantity(value);
      setAmount(value * prices);
    }
    if (name === 'Amount') {
      setAmount(value);
      setQuantity(value / prices);
    }
  }


  const token = gettoken()

  const onFinish = (values) => {
    console.log('Success:', market);
    const data = { values }
    console.log('values')
    console.log(values)
    data.values.jwt = token
    data.values.Coin = coin
    data.values.Exchange = market ? market : "binance_spot"
    data.values.Price = Price
    data.values.Quantity = Quantity
    data.values.Amount = Amount
    const postData = data.values
    console.log('postData')
    console.log(postData)
    const trademain = tradetype ? tradetype : "PAPER"

    if (trademain === "PAPER") {
      if (curreentordettype === 'limit') {

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'papertrading/limit_sell',
          data: postData,
        }).then(async function (response) {
          console.log(response.data, 'sss');
          if (response.data.data) {
            alert(response.data.data, "PAPER & limit");
            console.log(response.data.data, "PAPER & limit");

          }
          if (response.data.error) {
            alert(response.data.error);

          }
        });
      } else if (curreentordettype === 'market') {

        const marketdata = { values };
        marketdata.values.Price = Prevprice;

        const marketData = marketdata.values;

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'papertrading/market_sell',
          data: marketData,
        }).then(async function (response) {

          if (response.data.data) {
            alert(response.data.data, "PAPER & market");
            console.log(response.data.data, "PAPER & market");

          }
          if (response.data.error) {
            alert(response.data.error, "PAPER & market");

          }

          console.log(response.data, 'sss');
        });
      }
    }
    if (trademain === "LIVE") {

      if (curreentordettype === 'limit') {

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'trading/limit_sell',
          data: postData,
        }).then(async function (response) {
          if (response.data.data) {
            alert(response.data.data);
            console.log(response.data.data, "LIVE & limit_sell");

          }
          if (response.data.error) {
            alert(response.data.error);

          }
          console.log(response.data);
        });
      } else if (curreentordettype === 'market') {

        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: process.env.REACT_APP_API_PATH + 'trading/market_sell',
          data: postData,
        }).then(async function (response) {
          if (response.data.data) {
            alert(response.data.data);
            console.log(response.data.data, "LIVE & market_sell");

          }
          if (response.data.error) {
            alert(response.data.error);

          }
          console.log(response.data);
        });
      }
    }
  };



  const handleClick = (value) => {
    console.log('value')
    console.log(value)

    let per = showbalance * value / 100
    console.log(showbalance, value, 'helooooo')
    setProgress(value);
    console.log('per')
    console.log(per)
    setQuantity(per);
    setAmount(per * Price)
    aaa('Quantity', per)
  };

  useEffect(() => {
    localStorage.removeItem('switchValuesell')
    localStorage.setItem('switchValuesell', 'limit')
  }, []);
  return (
    <>

      <div className="m-1">
        <Form
          onFinish={onFinish}
        >

          <Space className=" rounded-sm">
            <div>
              <FormItem
              >
                <div className="flex mt-2 text-center">
                  <p>Limit</p>
                  <Switch
                    checkedChildren=""
                    className="mx-2 bg-colorSecondary"
                    unCheckedChildren=""
                    onChange={handleSwitchChange}
                  />
                  <p>Market</p>
                </div>
              </FormItem>

            </div>

            {/* <div className="mx-4 text-center">

              {Number(CurrentPrice.c) > Number(Prevprice) ? <span className="text-success text-lg font-bold">{Number(CurrentPrice.c)} <ArrowUpOutlined /></span> : Number(CurrentPrice.c) < Number(Prevprice) ? <span className="text-danger text-lg font-bold">{Number(CurrentPrice.c)} <ArrowDownOutlined /></span> : <span className="text-lg font-bold">{Number(CurrentPrice.c) ? Number(CurrentPrice.c) : 0}</span>}

              <p className="text-sm text-green-500 font-bold">{CurrentPrice && CurrentPrice.p ? `${Number(CurrentPrice.p).toFixed(2)}` : 0}({CurrentPrice && CurrentPrice.P ? `${Number(CurrentPrice.P).toFixed(2)}%` : '0%'})</p>

            </div> */}
            <div className="mx-4 text-center">

              {Number(CurrentPrice.c) > Number(Prevprice) ? <span className="text-success text-sm font-bold">{Number(CurrentPrice.c)} <ArrowUpOutlined /></span> : Number(CurrentPrice.c) < Number(Prevprice) ? <span className="text-danger text-sm font-bold">{Number(CurrentPrice.c)} <ArrowDownOutlined /></span> : <span className="text-sm font-bold">{Number(CurrentPrice.c) ? Number(CurrentPrice.c) : 0}</span>}

              <p className="text-[10px] text-green-500 font-bold">{CurrentPrice && CurrentPrice.p ? `${Number(CurrentPrice.p).toFixed(2)}` : 0}({CurrentPrice && CurrentPrice.P ? `${Number(CurrentPrice.P).toFixed(2)}%` : '0%'})</p>

            </div>
          </Space>
          {!switchState &&
            <FormItem
              name="Price"
              className="m-0"
              onChange={handlechange}
            >
              <p hidden>{Price}</p>
              <InputNumber size="small" className="w-100 mt-3" value={Price} name="Price" type="number" placeholder="Price" />
            </FormItem>}
          <FormItem
            name="Quantity"
            className="m-0"
            onChange={handlechange}
          >
            <p hidden>{Quantity}</p>
            <InputNumber size="small" className="w-100 mt-3" value={Quantity} name="Quantity" type="number" placeholder="Quantity" />
          </FormItem>
          <div>
            <p className="text-sm mx-1"> Available Quantity:121321</p>
          </div>
          <Progress percent={progress} />
          <div className="flex justify-center">
            <Button size="small" className="mx-2 border-2 border-solid border-colorSecondary" onClick={() => handleClick(25)} value={25}>
              25
            </Button>
            <Button size="small" className="mx-2 border-2 border-solid border-colorSecondary" onClick={() => handleClick(50)} value={50}>
              50
            </Button>
            <Button size="small" className="mx-2 border-2 border-solid border-colorSecondary" onClick={() => handleClick(75)} value={75}>
              75
            </Button>
            <Button
              className="mx-2 border-2 border-solid border-colorSecondary" size="small"
              onClick={() => handleClick(100)}
              value={100}
            >
              max
            </Button>
          </div>
          <FormItem
            name="Amount"
            className="m-0"
            onChange={handlechange}
            defaultValue={Amount}
          >
            <p hidden>{Amount}</p>
            <InputNumber size="small" className="w-100 mt-1" value={Amount} name="Amount" type="number" placeholder="amount" />
          </FormItem>


          <div className="text-center">
            <Button className="bg-colorSecondary text-white mt-3 mb-3" htmlType="submit">Sell</Button>
          </div>

        </Form>

      </div>
    </>
  );
}

export default Sell;
