import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Check from "../Components/Check";
import Mainpage from "../Pages/Mainpage";
import AuthWrapper from "../Layout/AuthWrapper";
import Signup from "../Pages/Signup";
import Login from "../Pages/Login";
import Forgetpassword from "../Pages/Forgetpassword";
import Forgetusername from "../Pages/Forgetusername";
import Test from "../Layout/Test";
import Api_Setting from "../Pages/Api_Setting";
import Preffred_exchanges from "../Components/Preffred_exchanges";
import SelectedExchangs from "../Components/SelectedExchange";
import Spotconnectiondetails from "../Pages/Spotconnectiondetails";
import Futureconnectionform from "../Pages/Futureconnectionform";
import PaperSpotconnectiondetails from "../Pages/PaperSpotconnectiondetails";
import Paperfutureconnectiondetails from "../Pages/Paperconnectiondetails";
import Connectionfailed from "../Pages/Connectionfailed";
import ConnectionLoder from "../Pages/ConnectionLoder";
import APi_details from "../Pages/APi_details";
import Fuel from "../Pages/Fuel";
import Subscirptions from "../Pages/Subscirptions";
import Setting from "../Pages/Setting";
import ChangePassword from "../Components/ChangePAssword";
import ConfirmForgetpassword from "../Pages/ConfirmForgetpassword";
import Report from "../Pages/Report";
import EmailVerify from "../Pages/EmailVerify";
import ConfirmUsername from "../Pages/ConfirmUsername";
import Dashboard from "../Layout/Dashborad";
import Balance from "../Pages/Balance";
import Profile from "../Pages/Profile";
import { Activebotnew } from "../Components/Activebotnew";
import { Transactions } from "../Pages/Transactions";
import Guide from "../Pages/Guide";
import TopUP from "../Pages/TopUP";
import Transactionnew from "../Components/Transactionnew";
import Logs from "../Pages/Logs";
import Support from "../Pages/Support";
import Botmainpagecopy from "../Pages/Botmainpagecopy";
import ActiveBot from "../Pages/ActiveBot";
import Trades from "../Pages/Trades";
import Reportcopy from "../Pages/Reportcopy";
import ProtectedRoute from "./ProtectRouter";
import PaymentGateway from "../Components/PaymentGateway";
import TradeKeen from "../Pages/TradeKeen";

const Routerpage = () => {

  const [preventcoins, setpreventcoins] = useState("");
  const [preventmarkets, setpreventmarket] = useState("");
  const [preventshowbalanceasset, setpreventshowbalanceasset] = useState("");
  const [preventtradetype, setpreventtradetype] = useState("");
  const [preventcoinarray, setpreventcoinarray] = useState("");

  let changepreventcoin = (coin) => {
    setpreventcoins(coin);
  };

  let changepreventmarket = (market) => {
    setpreventmarket(market);
  };
  let changepreventshowbalance = (shbl) => {
    setpreventshowbalanceasset(shbl);
  };
  let changepreventtradetype = (tradetype) => {
    console.log(tradetype)

    setpreventtradetype(tradetype);
  };
  let changepreventcoinarray = (coinarray) => {

    setpreventcoinarray(coinarray);
  };
  return (
    <>
      <Router>
        <Routes>
          <Route path="/test" element={<Test />} />
          <Route path="/" element={<AuthWrapper />}>
            <Route index element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgetpassword" element={<Forgetpassword />} />
            <Route path="/forgetusername" element={<Forgetusername />} />
            <Route path="/email_verify" element={<EmailVerify />} />
            <Route path="/submit" element={<ConfirmUsername />} />

            <Route
              path="/update_password"
              element={<ConfirmForgetpassword />}
            />
          </Route>
          <Route path="/guide" element={<Guide />} />
          <Route path="/dashboard/guide" element={<Guide />} />
          <Route
            path="/dashboard"
            // element={<Dashboard />}
            element={<ProtectedRoute Component={Dashboard} />}
          >
            <Route
              index
              // element={<Mainpage />}
              element={<ProtectedRoute Component={Mainpage} />}
            />
            <Route
              exact
              path="/dashboard/profile"
              element={<ProtectedRoute Component={Profile} />}
            />
            <Route
              exact
              path="/dashboard/check"
              element={<ProtectedRoute Component={Check} />}
            />
            <Route
              exact
              path="/dashboard/trades"
              element={<ProtectedRoute Component={Trades} />}
            />
            <Route
              exact
              path="/dashboard/tradekeen"
              // element={<ProtectedRoute Component={TradeKeen} />}
              element={
                <TradeKeen
                  coin={preventcoins}
                  market={preventmarkets}
                  showbalance={preventshowbalanceasset}
                  tradetype={preventtradetype}
                  preventcoin={changepreventcoin}
                  preventmarket={changepreventmarket}
                  preventshowbalance={changepreventshowbalance}
                  preventtradetype={changepreventtradetype}
                  preventcoinarray={changepreventcoinarray}
                />
              }
            /> 
            <Route
              exact
              path="/dashboard/deposit"
              element={<ProtectedRoute Component={TopUP} />}
            />
            <Route
              exact
              path="/dashboard/botmain"
              element={<ProtectedRoute Component={Botmainpagecopy} />}
              // element=<Botmainpagecopy />
            />
            <Route
              exact
              path="/dashboard/APi_details"
              element={<ProtectedRoute Component={APi_details} />}
            ></Route>
            <Route
              exact
              path="/dashboard/report"
              element={<ProtectedRoute Component={Reportcopy} />}
            />
            <Route
              exact
              path="/dashboard/transactiontable"
              element={<ProtectedRoute Component={Transactionnew} />}
            />
            <Route
              exact
              path="/dashboard/logs"
              element={<ProtectedRoute Component={Logs} />}
            />
            <Route
              exact
              path="/dashboard/support"
              element={<ProtectedRoute Component={Support} />}
              // element=<Support />
            />
            <Route
              exact
              path="/dashboard/subscriptions"
              element={<ProtectedRoute Component={Subscirptions} />}
              // element=<Subscirptions />
            />
            <Route
              exact
              path="/dashboard/profile"
              element={<ProtectedRoute Component={Setting} />}
              // element=<Setting />
            />
            <Route
              exact
              path="/dashboard/change_password"
              element={<ProtectedRoute Component={ChangePassword} />}
              // element=<ChangePassword />
            />
            <Route
              exact
              path="/dashboard/activebot"
              element={<ProtectedRoute Component={ActiveBot} />}
              // element=<ActiveBot />
            />
            <Route
              exact
              path="/dashboard/payment"
              element={<ProtectedRoute Component={PaymentGateway} />}
              // element=<ActiveBot />
            />
            <Route
              exact
              path="/dashboard/fuel"
              element={<ProtectedRoute Component={Fuel} />}
              // element=<Fuel />
            />
            <Route exact path="/dashboard/balance" element=<Balance /> />
            <Route
              exact
              path="/dashboard/api_settings"
              element={<ProtectedRoute Component={Api_Setting} />}
              // element=<Api_Setting />
            >
              <Route
                index
                exact
                element={<ProtectedRoute Component={Preffred_exchanges} />}
                // element=<Preffred_exchanges />
              />
              <Route
                exact
                path="/dashboard/api_settings/SelectedExchange"
                element={<ProtectedRoute Component={SelectedExchangs} />}
                // element=<SelectedExchangs />
              />
              <Route
                exact
                path="/dashboard/api_settings/Spotconnectiondetails"
                element={<ProtectedRoute Component={Spotconnectiondetails} />}
                // element=<Spotconnectiondetails />
              />
              <Route
                exact
                path="/dashboard/api_settings/Futureconnectionform"
                element={<ProtectedRoute Component={Futureconnectionform} />}
                // element=<Futureconnectionform />
              />
              <Route
                exact
                path="/dashboard/api_settings/Paperfutureconnectiondetails"
                element={
                  <ProtectedRoute Component={Paperfutureconnectiondetails} />
                }
                // element=<Paperfutureconnectiondetails />
              />
              <Route
                exact
                path="/dashboard/api_settings/PaperSpotconnectiondetails"
                element={
                  <ProtectedRoute Component={PaperSpotconnectiondetails} />
                }
                // element=<PaperSpotconnectiondetails />
              />
              <Route
                exact
                path="/dashboard/api_settings/ConnectionLoder"
                element={<ProtectedRoute Component={ConnectionLoder} />}
                // element=<ConnectionLoder />
              />
              <Route
                exact
                path="/dashboard/api_settings/Connectionfailed"
                element={<ProtectedRoute Component={Connectionfailed} />}
                // element=<Connectionfailed />
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routerpage;
