

import React, { useState , useEffect} from "react";
import SwipeableViews from "react-swipeable-views";
import DepositTran from "../Components/DepositTran";
import axios from "axios";


const Logs = () => {
  const [index, setIndex] = useState(0);
  const [dataLog, setDataLog] = useState([]);
  console.log("dataLog", dataLog.data);

  const handleChangeIndex = (newIndex) => {
    setIndex(newIndex);
  };

   const getLogs = () => {
    const itemValue = localStorage.getItem("jwt");
    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH_BOT_LOG + "login_log",
      // url: "https://dapib.stashack.cloud:5500/log/login_log",
      data: {
        project: "FinGenius",
        jwt: itemValue,
      },
    })
      .then((res) => {
        console.log(res.data.Success)
        setDataLog(res.data.Success)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLogs()
  }, [])

  return (
    <>
      <div className="">
        <div className="bg-colorPrimary shadow-neumorphic xl:w-1/3 mx-1 px-2  md:mx-10 mt-5 md:mt-3 w-full">
          <div className="flex px-5 py-2 mobilescroll">
            {/* <div
              className={`w-1/2 py-2 px-3 text-center cursor-pointer ${
                index === 0
                  ? "active-tab bg-colorPrimary shadow-neumorphicnew text-colorSecondaryDark  rounded-3xl px-5 border-t-4 border-colorAccent"
                  : "text-black border-none"
              }`}
              onClick={() => setIndex(0)}
            >
              System
            </div> */}
          
            <div
              className={`w-1/2 py-2 px-3 text-center cursor-pointer ${
                index === 0
                  ? "active-tab bg-colorPrimary shadow-neumorphicnew text-colorSecondaryDark  rounded-3xl px-5 border-t-4 border-colorAccent"
                  : "text-black border-none"
              }`}
              onClick={() => setIndex(3)}
            >
              Login
            </div>
          </div>
        </div>
        <SwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          className="mt-5 mx-4 md:mx-10"
        >
          <div>
            <DepositTran data={dataLog} />
          </div>
          <div>
            <DepositTran />
          </div>
          <div>
            <DepositTran />
          </div>
          <div>
            <DepositTran />
          </div>
          
        </SwipeableViews>
      </div>
    </>
  );
};

export default Logs;
