import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ViewReport = (props, image, filter1) => {
  const donutOptions = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const value = context.parsed || 0;
            return `${value}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 2000,
      easing: "easeInOutQuad",
    },
  };

  const colors = [];

  const options = {
    chart: {
      type: "bar",
      height: 440,
      stacked: true,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2000,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          "<div class='custom-tooltip' style='font-size: 10px;'>" +
          ["A", "B", "C", "D", "E"][dataPointIndex] +
          "</div>"
        );
      },
      style: {
        fontSize: "11px",
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
        colors: {
          ranges: colors,
        },
        // colors: ['#91ea92', '#33FF57', '#5733FF', '#33FFFF', '#FF33FF']
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    yaxis: {
      min: -100,
      max: 100,
      title: {
        // text: 'Age',
      },
      labels: {
        show: false, // Set this to false to hide y-axis labels
        formatter: function (val) {
          // You can customize the formatter if needed
          // return Math.abs(val) + "%";
        },
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return Math.abs(val).toFixed(2) + "%";
        },
      },
    },
    // title: {
    //   text: 'Mauritius population pyramid 2011'
    // },
    xaxis: {
      categories: ["", "", "", ""],
      // title: {
      //   text: 'Percent'
      // },
      labels: {
        show: false,
        formatter: function (val) {
          // return Math.abs(Math.round(val)) + "%"
        },
      },
    },
  };

  const {
    Api,
    BotName,
    BalanceL,
    BalanceS,
    Symbol,
    Exchange,
    Exchange_Type,
    HitRateL,
    HitRateS,
    InvestedL,
    InvestedS,
    LosingTradesL,
    LosingTradesS,
    TotalBrokerageL,
    TotalBrokerageS,
    TotalProfitL,
    TotalProfitPercentL,
    TotalProfitPercentS,
    TotalProfitS,
    TotalTradesL,
    TotalTradesS,
    WinningTradesL,
    WinningTradesS,
    imageToDisplay,
    DaysSinceStart,
    profit,
    TotalBrokerage,
    Invested,
    TotalProfit,
    LosingTrades,
    WinningTrades,
    Balance,
  } = props.data;



  // Total Trade Calculation
  const totalTardeCal =
    Exchange_Type == "FUTURES"
      ? (
          WinningTradesL +
          WinningTradesS +
          LosingTradesL +
          LosingTradesS
        ).toFixed(2)
      : (WinningTrades + LosingTrades).toFixed(2);
    // WinningTradesL + WinningTradesS + LosingTradesL + LosingTradesS;
  // console.log("totalTarderCal ==>>", totalTardeCal)
  const totalTrader = isNaN(totalTardeCal) ? 0 : totalTardeCal;
  // console.log("totalTrader ==>>", totalTrader)

  // Winning Trade Calculation
  const winningTradeCal =
    Exchange_Type == "FUTURES"
      ? (WinningTradesL + WinningTradesS)
      : WinningTrades;
  const winningTrade = isNaN(winningTradeCal) ? 0 : winningTradeCal;



  // Losing Trade Calculation
  const LosingTradeCal =
    Exchange_Type == "FUTURES" ? LosingTradesL + LosingTradesS : LosingTrades;
  const LosingTrade = isNaN(LosingTradeCal) ? 0 : LosingTradeCal;

  // Trades Hit rate Calculation
  const hitRateCal =
    Exchange_Type == "FUTURES"
      ? (WinningTradesL || 0 + WinningTradesS || 0) /
        (WinningTradesL ||
          0 + WinningTradesS ||
          0 + LosingTradesL ||
          0 + LosingTradesS ||
          0)
      : (WinningTrades || 0) /
        (WinningTrades || 0 + LosingTrades || 0);
   ;
  const hitRate = isNaN(hitRateCal) ? 0 : hitRateCal;

  // invested calculation
  const investedCal = InvestedL + InvestedS;
  const invested = isNaN(investedCal) ? 0 : investedCal;

  //invested Balance calculation
  const BalanceCal = (BalanceL + BalanceS).toFixed(2);
  const balance = isNaN(BalanceCal) ? 0 : BalanceCal;

  //invested Position calculation
  const positionCal = (
    InvestedL +
    InvestedS +
    (TotalProfitL + TotalProfitS) -
    (BalanceL + BalanceS)
  ).toFixed(2);
  const Position = isNaN(positionCal) ? 0 : positionCal;

  // inVested position calculation
  const profiteCal = (TotalProfitL + TotalProfitS).toFixed(2);
  const profite = isNaN(profiteCal) ? 0 : profiteCal;

  const checkBalancedata =
    Exchange_Type == "FUTURES"
      ? (BalanceL + BalanceS).toFixed(2)
      : Balance.toFixed(2);
  const BalanceData = isNaN(checkBalancedata) ? 0 : checkBalancedata;

  const checkpositiondata =
    Exchange_Type == "FUTURES"
      ? (
          InvestedL +
          InvestedS +
          (TotalProfitL + TotalProfitS) -
          (BalanceL + BalanceS)
        ).toFixed(2)
      : (Invested + TotalProfit - Balance).toFixed(2);
  const positionData = isNaN(checkpositiondata) ? 0 : checkpositiondata;

  const checkprofitdata =
    Exchange_Type == "FUTURES"
      ? (TotalProfitL + TotalProfitS).toFixed(2)
      : TotalProfit.toFixed(2);
  const profitData = isNaN(checkprofitdata) ? 0 : checkprofitdata;

  // invested Brokerage calculation
  const brokerageCal =
    Exchange_Type == "FUTURES"
      ? (TotalBrokerageL + TotalBrokerageS).toFixed(2)
      : TotalBrokerage.toFixed(2);
  const brokerageFutures = isNaN(brokerageCal) ? 0 : brokerageCal;
  const checkBrokearge =
    Exchange_Type == "FUTURES"
      ? TotalBrokerageL + TotalBrokerageS
      : TotalBrokerage;
  const checkInvested =
    Exchange_Type == "FUTURES" ? InvestedL + InvestedS : Invested;
  const checkProfit =
    Exchange_Type == "FUTURES" ? TotalProfitL + TotalProfitS : TotalProfit;
  const checkBalance =
    Exchange_Type == "FUTURES" ? BalanceL + BalanceS : Balance;

  console.log("checkBrokearge", checkBrokearge);
  console.log("TotalBrokerage", TotalBrokerage);

  // checktrades Brokerage calculation

 const checktrades =
   Exchange_Type === "FUTURES"
     ? WinningTradesL + WinningTradesS
     : WinningTrades;

     const checktradeslossing =
       Exchange_Type === "FUTURES"
         ? LosingTradesL + LosingTradesS
         : LosingTrades;


  return (
    <>
      <div className="">
        <div className="bg-colorPrimary shadow-neumorphic rounded-lg shadow-2xl py-2 ">
          <div className="mx-4 ">
            <div className="pt-2">
              <div className="flex justify-around bg-colorSecondaryDark items-center text-white shadow-neumorphic   py-[10px]  rounded-[10px]">
                <div className="flex flex-col justify-around w-1/2 px-2 gap-1">
                  <h1 className=" text-sm font-semibold text-center capitalize">
                    API : {Api}
                  </h1>
                  <div className="flex px-3 gap-2  justify-between   w-full items-center">
                    <p className=" text-xs xl:text-sm">{BotName}</p>
                    <p className="text-xs xl:text-sm">{Symbol}</p>
                  </div>
                </div>
                <div className="pr-2">
                  <img
                    src={imageToDisplay}
                    alt="logo"
                    className="w-[150px] h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-around bg-colorPrimary py-1 shadow-neumorphicnew shadow-md text-[#212121] items-center rounded-[10px] pb-2 my-2">
              <div>
                <p className=" text-lg flex flex-col ">
                  {" "}
                  <span className="text-center  font-medium">PROFIT</span>{" "}
                  <div className="text-center">
                    <span
                      className={`text-center text-3xl font-semibold ${
                        profit < 0 ? "text-[#f00]" : "text-[#38913a]"
                      }`}
                    >
                      {profit}%
                    </span>
                  </div>
                </p>
              </div>

              <div className=" w-1/5">
                <p className="text-center font-bold text-base">Trades</p>
                <p className="text-xs leading-3 flex items-center justify-between ">
                  <div className="text-center">Total:&nbsp;</div>
                  <div className=" font-bold text-xs text-center">
                    {totalTrader}
                  </div>
                </p>

                <p className="text-xs leading-3 text-center flex justify-between items-center">
                  Winning:{" "}
                  <div className=" font-bold text-xs text-center">
                    {winningTrade}
                  </div>
                </p>

                <p className="text-xs leading-3 text-center  flex justify-between items-center">
                  Losing
                  <div className=" font-bold text-xs text-center">
                    {LosingTrade}
                  </div>
                </p>
                {/* <p className="text-xs leading-3 text-center  flex justify-between items-center">
                  Hit rate:{" "}
                  <div className=" font-bold text-xs text-c">
                    {WinningTradesL ||
                      WinningTradesS ||
                      LosingTradesL ||
                      LosingTradesS
                      ? parseFloat(
                        (WinningTradesL + WinningTradesS) /
                        (WinningTradesL +
                          WinningTradesS +
                          LosingTradesL +
                          LosingTradesS)
                      )
                      : 0}
                  </div>
                </p> */}
                <p className="text-xs leading-3 text-center  flex justify-between items-center">
                  Hit rate:{" "}
                  <div className=" font-bold text-xs text-c">{hitRate}</div>
                </p>
              </div>

              <div className="w-[50] h-[50] flex justify-center">
                <Doughnut
                  data={{
                    labels: ["WinningTrades", "LoosingTrades"],
                    datasets: [
                      {
                        // data: [10 + 100, 0 + -3],
                        data: [
                          // WinningTradesL + WinningTradesS,
                          checktrades,
                          checktradeslossing,
                          // LosingTradesL + LosingTradesS,
                        ],
                        backgroundColor: [
                          "rgba(14, 220, 83, 0.6)",
                          "rgba(255, 99, 132, 0.6)",
                        ],
                        borderColor: [
                          "rgba(14, 220, 83, 0.6)",
                          "rgba(255, 99, 132, 0.6)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={donutOptions}
                  style={{ width: "75px" }}
                />
                {/* <Doughnut data={data} /> */}
              </div>
            </div>

            <div className="flex flex-col gap-2 bg-colorPrimary py-1 shadow-neumorphicnew  rounded-md px-1 my-2">
              <div className=" w-full ">
                <div className="-mb-4 text-center text-sm">
                  Invested : <b>${checkInvested}</b>
                </div>
                <div className="flex justify-center gap-2 items-center mt-1">
                  <div className=" w-[40%]">
                    <div className="-ml-5 -mt-2">
                      <ReactApexChart
                        options={options}
                        series={[
                          {
                            name: "",
                            data: [
                              checkBalance > 0
                                ? (
                                    (checkBalance / checkInvested) *
                                    100
                                  ).toFixed(2)
                                : 0,
                              checkInvested + checkProfit - checkBalance > 0
                                ? (
                                    ((checkInvested +
                                      checkProfit -
                                      checkBalance) /
                                      checkInvested) *
                                    100
                                  ).toFixed(2)
                                : 0,
                              checkProfit > 0
                                ? ((checkProfit / checkInvested) * 100).toFixed(
                                    2
                                  )
                                : 0,
                              checkBrokearge > 0
                                ? (
                                    (checkBrokearge / checkInvested) *
                                    100
                                  ).toFixed(2)
                                : 0,
                            ],
                          },
                          {
                            name: "",
                            data: [
                              checkBalance < 0
                                ? -(
                                    (checkBalance / checkInvested) *
                                    100
                                  ).toFixed(2)
                                : 0,
                              checkInvested < 0
                                ? -(
                                    (checkInvested / checkInvested) *
                                    100
                                  ).toFixed(2)
                                : 0,
                              checkProfit < 0
                                ? -(
                                    (checkProfit / checkInvested) *
                                    100
                                  ).toFixed(2)
                                : 0,
                              checkBrokearge < 0
                                ? -(
                                    (checkBrokearge / checkInvested) *
                                    100
                                  ).toFixed(2)
                                : 0,
                            ],
                          },
                        ]}
                        type="bar"
                        height={117}
                      />
                    </div>
                  </div>

                  <div className="text-[13px]  flex gap-1 justify-between items-center  flex-col w-2/5">
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Balance:</div>{" "}
                      <span className=" font-bold">{BalanceData}</span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Position:</div>{" "}
                      <span className=" font-bold">{positionData}</span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Profit:</div>{" "}
                      <span className=" font-bold">{profitData}</span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Brokerage:</div>{" "}
                      <span className=" font-bold">{brokerageFutures}</span>
                    </p>
                    <p className="-mt-1 flex gap-5 justify-between w-full">
                      <div className="text-xs">Days:</div>{" "}
                      <span className=" font-bold">
                        {DaysSinceStart || "0"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
