import React, { useState, useEffect, useContext } from "react";
import ConnectContext from "./ConnectContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {  PiInfo } from "react-icons/pi";
import DrawerComponent from "../Components/DrawerComponent";
import { Radio, Tabs } from "antd";

function Paperconnectiondetails() {
  const location = useLocation();
  const [apiName, setApiName] = useState();
  const { parentState, updateParentState } = useContext(ConnectContext);

  const [balance, setBalance] = useState({
    BNB: "",
    BTC: "",
    ETH: "",
    USDT: "",
  });
  const [exchangeData, setExchangeData] = useState(null);
  const [exchImg, setExchImg] = useState(null);
  const [balanceErrors, setBalanceErrors] = useState({
    BNB: "",
    BTC: "",
    ETH: "",
    USDT: "",
  });
  const [apiNameError, setApiNameError] = useState("");
   const [drawerState, setDrawerState] = useState({
     visible: false,
     tooltipTexts: [],
     activeIndex: -1, // State to track the active tooltip index
     activetabtitle: "",
   });
 

  const navigate  = useNavigate();

  useEffect(() => {
    if (location.state && location.state.exchangeData) {
      const { exchangeData: exchangeDataFromLocation, filteredImages } = location.state;
      
      const exchangeImg = exchangeDataFromLocation.filter(item => item.img === filteredImages);

      if (exchangeImg.length > 0) {
        setExchImg(exchangeImg[0]);
      }

      setExchangeData(exchangeDataFromLocation);
    }
  }, [location.state]);


 const imgSrc = location.state ? location.state.filteredImages || "" : "";
 const imgSrc4 = location.state ? location.state.filteredImages || "" : "";
 const imgSrc2 = location.state ? location.state.exchangeName || "" : "";

 const exchange = location.state ? location.state.exchangeName || "" : "";
 const types = location.state ? location.state.exchangeTypes || "" : "";
  const [api, setAPI] = useState({
    api_name: "",
    type: "",
    exchange: exchange,
    balance: {
      BTC: Number(balance.BTC),
      BNB: Number(balance.BNB),
      ETH: Number(balance.ETH),
      USDT: Number(balance.USDT),
    },
  });

   const handleNavigate = () => {
     navigate("/dashboard/api_settings/ConnectionLoder", {
       state: {
         api_name: apiName,
         exchange: exchange,
         exchange_type: types,
         balance: {
           BTC: Number(balance.BTC),
           BNB: Number(balance.BNB),
           ETH: Number(balance.ETH),
           USDT: Number(balance.USDT),
         },
         type: "PAPER",
         key: "",
         secret: "",
       },
     });
   };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setBalance({
      ...balance,
      [name]: value,
    });
  };

  const handleSuccessButtonClick = (text) => {
    Swal.fire({
      title: "Success!",
      text: text,
      icon: "success",
      confirmButtonText: "OK",
      customClass: {
        confirmButton: "custom-ok-button-class",
      },
      buttonsStyling: false,
    }).then(() => {
      navigate("/dashboard/botmain");
    });
  };
  

  const ViewBot_info = async (e) => {
    const jwt = localStorage.getItem("jwt");
   
   const apiName = document.getElementById("apiName").value;
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
      api_name: apiName,
      key: "",
      secret: "",
      type: "PAPER",
      exchange: exchange,
      exchange_type: types,
      balance: {
        BTC: Number(balance.BTC),
        BNB: Number(balance.BNB),
        ETH: Number(balance.ETH),
        USDT: Number(balance.USDT),
      },
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_API + "create_api",
      data: postdata,
    })
      .then((response) => {
        const res = response?.data;
        if (res?.Success) {
        handleSuccessButtonClick(res?.Success);

          toast.success(res?.Success);
        } else {
          toast.error(res.Error);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
      });
  };

  const required = apiName || 
  balance.ETH.length !== 0 ||
  balance.BTC.length !== 0 ||
  balance.USDT.length !== 0 ||
  balance.BNB.length !== 0;


const handleValidationAndSubmit = () => {
  console.log("i am clicked")
  updateParentState("Clicked");
  setApiNameError("");
  setBalanceErrors({
    BNB: "",
    BTC: "",
    ETH: "",
    USDT: "",
  });

  let isValid = true;

  if (!apiName) {
    setApiNameError("Api Name is required");
    isValid = false;
  }

  const balanceFields = ["BNB", "BTC", "ETH", "USDT"];
  balanceFields.forEach((field) => {
    if (!balance[field]) {
      setBalanceErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${field} is required`,
      }));
      isValid = false;
    }
  });

  if (isValid) {
    ViewBot_info();
  
  }
};

const tooltips = [
  {
    id: "1",
    title: "Api Name",
    description:
      "Begin by giving your Paper API a distinctive name. This ensures easy recognition and management of your virtual trading account.",
  },
  {
    id: "2",
    title: "Amount in (ETH)",
    description:
      "In the Paper API, you can allot virtual funds in ETH. This allocation represents the virtual capital you'll use for paper trading.Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
  },
  {
    id: "3",
    title: "Amount in (BTC)",
    description:
      "In the Paper API, you can allot virtual funds in BTC. This allocation represents the virtual capital you'll use for paper trading.Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
  },
  {
    id: "4",
    title: "Amount in (USDT)",
    description:
      "In the Paper API, you can allot virtual funds in USDT. This allocation represents the virtual capital you'll use for paper trading.Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
  },
  {
    id: "5",
    title: "Amount in (BNB)",
    description:
      "In the Paper API, you can allot virtual funds in BNB. This allocation represents the virtual capital you'll use for paper trading.Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
  },
];



const toggleDrawer = (tooltipTexts, title) => {
  setDrawerState({
    visible: !drawerState.visible,
    tooltipTexts: tooltipTexts,
    activeIndex: -1,
    activetabtitle: title,
  });
  // setColorChanged(false);
};


  return (
    <>
      <div className="text-center my-4">
        <div className="text-slate-900 text-lg font-black mt-2">
          Set up your keys
        </div>
        <div className="font-light text-sm">
          Create your API keys and Securely Connect your Exchange to FinGenius
        </div>
      </div>

      <div className="grid lg:grid-cols-2">
        <div className="lg:flex w-full justify-end">
          <form
            onSubmit={ViewBot_info}
            className="bg-colorPrimary  rounded px-8 pt-6 pb-8  md:w-[480px] md:me-28 shadow-neumorphic"
          >
            <div className="items-center">
              <div className=" rounded-full align items-center border-solid drop-shadow-xl flex  p-1 justify-between mb-3  mx-2 text-black">
                <img
                  src={exchImg?.img}
                  alt="binance"
                  className="mr-2 w-full md:w-[150px] bg-transparent py-1 rounded-lg shadow-neumorphic1 px-2"
                />

                {/* <div className=""> */}
              </div>
            </div>
            <div className="mb-4">
              <div className="flex justify-between items-center ">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="apiName"
                >
                  Api Name <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Api Name")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>
              <input
                id="apiName"
                maxLength={10}
                value={apiName}
                onChange={(e) => setApiName(e.target.value)}
                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Api Name"
              />
              {apiNameError && (
                <div className="text-red-500 text-xs px-2">{apiNameError}</div>
              )}
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="amountETH"
                >
                  Amount in (ETH) <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Amount in (ETH)")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>
              <input
                id="amountETH"
                value={balance.ETH} // Set the value from state
                onChange={handleInputChange}
                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                name="ETH"
                placeholder="Amount in (ETH)"
              />
              {balanceErrors.ETH && (
                <div className="text-red-500 text-xs px-2">
                  {balanceErrors.ETH}
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center justify-between">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="amountBTC"
                >
                  Amount in (BTC) <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Amount in (BTC)")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>
              <input
                value={balance.BTC} // Set the value from state
                onChange={handleInputChange}
                name="BTC"
                id="amountBTC"
                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                placeholder="Amount in (BTC)"
              />
              {balanceErrors.BTC && (
                <div className="text-red-500 text-xs px-2">
                  {balanceErrors.BTC}
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center justify-between">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="amountUSDT"
                >
                  Amount in (USDT) <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Amount in (USDT)")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>
              <input
                value={balance.USDT} // Set the value from state
                onChange={handleInputChange}
                name="USDT"
                id="amountUSDT"
                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                placeholder="Amount in (USDT)"
              />
              {balanceErrors.USDT && (
                <div className="text-red-500 text-xs px-2">
                  {balanceErrors.USDT}
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center justify-between justify-between">
                <label
                  className="block text-gray-700 text-xs font-bold mb-2"
                  htmlFor="amountBNB"
                >
                  Amount in (BNB) <span className="text-[red]">*</span>
                </label>
                <div onClick={() => toggleDrawer(tooltips, "Amount in (BNB)")}>
                  <PiInfo
                    size={20}
                    className="cursor-help hover:scale-110 transition-all transition-5s"
                  />
                </div>
              </div>
              <input
                value={balance.BNB}
                onChange={handleInputChange}
                name="BNB"
                id="amountBNB"
                className="shadow appearance-none border rounded-2xl w-full py-2 px-3 shadow-neumorphicnew bg-colorPrimary  text-black leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                placeholder="Amount in (BNB)"
              />
              {balanceErrors.BNB && (
                <div className="text-red-500 text-xs px-2 ">
                  {balanceErrors.BNB}
                </div>
              )}
            </div>

            <div className="items-end flex justify-end">
              <button
                className="bg-[#3b3b3b] rounded-3xl text-white  mx-2 hover:bg-colorAccent hover:text-black font-bold py-2 px-4  focus:outline-none focus:shadow-outline"
                type="button"
              >
                <Link to="/dashboard/api_settings">Prev</Link>
              </button>
              <button
                className="bg-[#3b3b3b] rounded-3xl text-white hover:bg-colorAccent hover:text-black font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleValidationAndSubmit}
              >
                Next
                {/* <Link to="/dashboard/api_settings/Connectionfailed">Next</Link> */}
              </button>
            </div>
          </form>
          <div className="w-1 md:h-[80%] mt-6 border-opacity-95 bg-black"></div>
        </div>

        {/* <div className=''></div> */}
        <div className="text-center items-center mt-24 ">
          <img
            src={exchImg?.imgnew}
            alt="binance"
            className="w-36 inline-block"
          />
          <p className="text-lg">How to create your api key on binance ? </p>
          <button type="button" className="p-2 bg-colorPrimary rounded-xl mt-5">
            <Link
              to={
                exchange === "KUCOIN"
                  ? "https://www.kucoin.com/support/360015102174"
                  : "https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
              }
              target="blank"
            >
              Learn How
            </Link>
          </button>
        </div>
      </div>
      <DrawerComponent toggleDrawer={toggleDrawer} drawerState={drawerState} />
    </>
  );
}

export default Paperconnectiondetails;
