import React, {  useEffect, useState } from "react";
import {Table, Button, Switch } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { DeleteOutlined } from "@ant-design/icons";
export const Activebotnew = () => {
  const jwt = localStorage.getItem("jwt");
  const [apilistdata, setapilistdata] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const expandedRowRender = (record) => {
    const renderNestedTable = (data) => {
      const columns = [
        {
          title: "Asset",
          dataIndex: "asset",
          key: "asset",
        },
        ...Object.keys(data).map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
        })),
      ];
  
      const dataSource = [
        {
          key: "data",
          asset: "Data",
          ...data,
        },
      ];
  
      return <Table size="medium" columns={columns} dataSource={dataSource} pagination={false} />;
    };
  
    const columns = [
      {
        title: "Asset",
        dataIndex: "asset",
        key: "asset",
      },
      ...Object.keys(record.Initial).map((asset) => ({
        title: asset,
        dataIndex: asset,
        key: asset,
      })),
    ];
  
    const dataSource = [
      {
        key: "Initial",
        asset: "Initial",
        ...record.Initial,
      },
      {
        key: "Invested",
        asset: "Invested",
        ...record.Invested,
      },
      {
        key: "Balance",
        asset: "Balance",
        ...record.Balance,
      },
    ];
    return (
      <Table
        size="medium"
        columns={columns}
        className="newtable"
        dataSource={dataSource}
        pagination={false}
      />
    );
  };
  const defaultColumns = [
    {
      title: "API ID",
      dataIndex: "APIID",
      key: "APIID",
      editable: true,
      width: "20%",
    },
    {
      title: "Exchange",
      dataIndex: "Exchange",
      key: "Exchange",
      editable: true,
      width: "20%",
    },
    // {
    //   title: "Type",
    //   dataIndex: "Type",
    //   key: "Type",
    //   width: "10%",
    //   editable: true,
    // },
    {
      title: "Exchange Type",
      dataIndex: "Exchange_Type",
      key: "Exchange_Type",
      editable: true,
      width: "20%",
    },
    {
      title: "API Name",
      dataIndex: "API_Name",
      key: "API_Name",
      editable: true,
      width: "20%",
    },

    {
      title: "API Key",
      dataIndex: "Key",
      key: "Key",
      editable: true,
      width: "15%",
      render: (text) => (
        <span>
          {text.length > 10
            ? `${text.slice(0, 4)}......${text.slice(-6)}`
            : text}
        </span>
      ),
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: "10%",
      editable: true,
      className: "w-7",

      render: (text, record) => (
        <>
          <div className="justify-center items-center ">
            <div>
              <Switch
                checkedChildren="START"
                unCheckedChildren="PAUSE"
                className={record.Status === "ACTIVE" ? "off" : "on"}
                checked={record.Status === "ACTIVE"}
                // checked={record.Status === "ACTIVE" ? true : false}
                onChange={(checked) => onswitchChange(record, checked)}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Action",
      width: "10%",
      key: "action",
      render: (_, record) => (
        <>
          <button
            className="border-none mb-0 py-1"
            onClick={() => handleDelete(record)}
          >
            <DeleteOutlined size={18} />
          </button>
        </>
      ),
    },
  ];


  const handleDelete = (record) => {
    let postData = {
      jwt: jwt,
      api_name: record.API_Name,
      project: "FinGenius",
    };
    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_API + "delete_api",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res.Success) {
          //   getallrole();
          // getapiDetails();
          view_totp()
          toast.success(res.Success);
        } else if (res.Error) {
         toast.error(res.Error);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.Error);
        // getapiDetails();
      });
  };


  const onswitchChange = (record, checked) => {
    const newStatus = checked ? "ACTIVE" : "INACTIVE";
    const updatedDataSource = dataSource.map((item) =>
      item.API_Name === record.API_Name ? { ...item, Status: newStatus } : item
    );
    setDataSource(updatedDataSource);
    const postData = {
      jwt: jwt,
      api_name: record.API_Name,
      status: newStatus,
      project: "FinGenius",
    };

    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_API + "change_api_status",
      data: postData,
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          toast.success(res?.Success);
          handleSave();
        } else if (res.Error) {
          toast.error(res.Error);
        }
      })
      .catch((err) => {
    
        toast.error(err.response.data.Error);
      });
  };

  const allColumns = [...defaultColumns];

  const columns = allColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        width: 10 ,
        handleSave,
      }),
    };
  });

  const generateUniqueKeys = (data) => {
    return data.map((item, index) => `${item.APIID}_${index}`);
  };


  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const view_totp = async () => {
    const postData = { jwt: jwt, project: "FinGenius" }
    axios({
      method: 'POST',
      headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_API_PATH_API + 'api_list',
      data: postData,
    }).then(async function (res) {
      const response = res?.data
      // const updatedData = response.Success?.map((item) => ({
      //   ...item,
      //   isActive: item.Status === "ACTIVE",
      // }));
      const updatedData = response.Success?.map((item, index) => ({
        ...item,
        isActive: item.Status === "ACTIVE",
        key: `${item.APIID}_${index}`, // Generate a unique key for each record
      }));
      setapilistdata(updatedData);
      setDataSource(updatedData);
    }).catch((err) => {
    });
  }

  useEffect(() => {
    view_totp()
  }, []);

  return (
    <>
      <div className="">
        <div className="w-full mt-5 ">
          <Table
            size="small"
            className="w-full py-0 table-newres  !h-auto"
            columns={columns}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: generateUniqueKeys(dataSource),
            }}
            dataSource={dataSource}
            pagination={true}
            // scroll={{
            //   x: 1500,
            //   y: 500,
            // }}
          />
        </div>
      </div>
    </>
  );
};
