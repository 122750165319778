import { Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

function SubTable() {
    const [filteredData, setFilterData] = useState([]);
const columns = [
  {
    title: "TRANS ID",
    dataIndex: "TransID",
    key: "TransID",
    width: "20%",
  },
  {
    title: "DATE",
    dataIndex: "DateTime",
    key: "DateTime",
    width: "20%",
  },
  {
    title: "Expiry Date",
    dataIndex: "ExpireyDate",
    key: "ExpireyDate",
    width: "15%",
  },

  {
    title: "Subscription Name",
    dataIndex: "Subscription_Name",
    key: "Subscription_Name",
    width: 300,
  },
  {
    title: "Price",
    dataIndex: "Price",
    key: "Price",
    width: "10%",
  },

  {
    title: "STATUS",
    dataIndex: "Status",
    key: "Status",
    width: 100,
    render: (text) => (
      <span style={{ color: parseFloat(text) > 100 ? "red" : "green" }}>
        {text}
      </span>
    ),
  },
];

const rows = [];

if (filteredData) {
  for (let i = 0; i < filteredData.length; i++) {
    const dateParts = filteredData[i]?.DateTime.split(" "); 
    const date = dateParts[0].split("-"); 
    const time = dateParts[1].split(":");

    const day = parseInt(date[0], 10);
    const month = parseInt(date[1], 10) - 1; 
    const year = parseInt(date[2], 10);
    const hours = parseInt(time[0], 10);
    const minutes = parseInt(time[1], 10);

    const activationDate = new Date(year, month, day, hours, minutes);

    const expirationDate = new Date(activationDate);
    expirationDate.setFullYear(expirationDate?.getFullYear() + 1);

    const expirationDateString = `${(expirationDate?.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${expirationDate
      ?.getDate()
      .toString()
      .padStart(2, "0")}-${expirationDate?.getFullYear()}`;

    console.log(expirationDateString);
    const expirationDateMoment = moment(expirationDateString, "MM-DD-YYYY");
    const formattedExpirationDate = expirationDateMoment.format("DD-MM-YYYY");

    rows.push({
      key: i,
      DateTime: moment(filteredData[i]?.DateTime, "DD-MM-YYYY HH:mm").format(
        "DD-MM-YYYY"
      ),
      ExpireyDate: formattedExpirationDate,
      // DateTime: moment(filteredData[i]?.DateTime).format("DD-MM-YYYY"),
      // ExpireyDate: moment(expirationDateString).format("DD-MM-YYYY"),
      TransID: filteredData[i]?.TransID,
      Subscription_Name: filteredData[i]?.Subscription_Name,
      Price: filteredData[i]?.Price,
      Status: filteredData[i]?.Status ? filteredData[i]?.Status : "UNSUCCESS",
    });
  }
}

const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};

const subscription_transactions = async () => {
  const jwt = localStorage.getItem("jwt");
  const formData = new FormData();
  formData.append("project", "FinGenius");
  formData.append("jwt", jwt);
  const response = await axios({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: process.env.REACT_APP_API_PATH_SUB + "subscription_transactions",
    data: formData,
  })
    .then(async function (response) {
      const res = await response?.data.Success;
      // if (res?.Success) {
      const sortedData = res.sort(
        (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
      );
      setFilterData(sortedData.reverse());
      // setFilterData(res?.Success);
      // ViewPackageBuy(res?.Success.Subscription_Name);
      // }
    })
    .catch((err) => {
      console.log(err?.response?.data?.Error);
    });
};

useEffect(() => {
  subscription_transactions();
}, []);

  return (
    <div>
      <div className="mt-7  mx-auto w-full bg-colorPrimary shadow-neu">
        <Table
          className="table-newres"
          columns={columns}
          dataSource={rows}
          onChange={onChange}
          pagination={{ pageSize: 5 }}
        />
      </div>
    </div>
  );
}

export default SubTable