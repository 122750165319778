import React from "react";
import { useState, useEffect } from "react";
import guide from "../assets/img/guideImg.png";
import connectLive2 from "../assets/img/connnectLive/step2.png";
import connectLive3 from "../assets/img/connnectLive/step3.png";
import connectLive4 from "../assets/img/connnectLive/step4.png";
import connectLive5 from "../assets/img/connnectLive/step5.png";
import connectLive6 from "../assets/img/connnectLive/step6.png";
import connectLive7 from "../assets/img/connnectLive/step7.jpg";
import connectLive8 from "../assets/img/connnectLive/step8.jpg";
import connectLive9 from "../assets/img/connnectLive/step9.jpg";
import connectLive10 from "../assets/img/connnectLive/step10.jpg";
import connectLive11 from "../assets/img/connnectLive/step11.jpg";
import connectLive12 from "../assets/img/connnectLive/step12.jpg";
import connectLive13 from "../assets/img/connnectLive/step13.jpg";
import connectLive14 from "../assets/img/connnectLive/step14.jpg";
import connectpaper1 from "../assets/img/Paperapi/step1.jpg";
import connectpaper4 from "../assets/img/Paperapi/step4.jpg";
import connectpaper2 from "../assets/img/Paperapi/step2.jpg";
import connectpaper3 from "../assets/img/Paperapi/step3.jpg";
import connectpaper5 from "../assets/img/Paperapi/step5.jpg";
import bot1 from "../assets/img/risk/1.jpg";
import bot2 from "../assets/img/risk/2.jpg";
import bot3 from "../assets/img/risk/3.jpg";
import bot4 from "../assets/img/risk/4.jpg";
import risk1 from "../assets/img/risk/risk1.jpg";
import risk2 from "../assets/img/risk/risk2.jpg";
import riskspot from "../assets/img/risk/Spotrisk.png";
import riskfuture from "../assets/img/risk/futurerisk.png";
import bot5 from "../assets/img/risk/5.jpg";
import bot6 from "../assets/img/risk/6.jpg";
import b1 from "../assets/img/Bot/1.jpg";
import b2 from "../assets/img/Bot/2.jpg";
import b3 from "../assets/img/Bot/4.jpg";
import tele1 from "../assets/img/telegram/1.jpg";
import tele2 from "../assets/img/telegram/3.jpg";
import tele3 from "../assets/img/telegram/2.jpg";
import { AiOutlineLogin } from "react-icons/ai";
import { BsPersonFillAdd } from "react-icons/bs";
import { AiFillApi } from "react-icons/ai";
import { TbPremiumRights } from "react-icons/tb";
import { BsGraphUpArrow } from "react-icons/bs";
import { RiRobot2Line } from "react-icons/ri";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { AiOutlineFundView } from "react-icons/ai";
import { BiLogoTelegram, BiSolidReport, BiMenuAltRight } from "react-icons/bi";
import vided1 from "../assets/img/loginVid.mp4";
import Signupvid from "../assets/img/Signup.mp4";
import login1 from "../assets/img/loginNew/step1.jpg";
import login2 from "../assets/img/loginNew/step2.jpg";
import login3 from "../assets/img/loginNew/stepnew.png";
import login4 from "../assets/img/loginNew/step3.jpg";
import active2 from "../assets/img/activebot/step2.jpg";
import active3 from "../assets/img/activebot/step3.jpg";
import trades1 from "../assets/img/trades/step2.jpg";
import trades2 from "../assets/img/trades/step3.jpg";
import report2 from "../assets/img/report/step2.jpg";
import report3 from "../assets/img/report/step.3.png";
// import active3 from "../assets/img/trades/step3.jpg";
import singup1 from "../assets/img/singUpnew/step1.jpg";
import singup2 from "../assets/img/singUpnew/step2.jpg";
import singup4 from "../assets/img/singUpnew/step3.jpg";
import singup5 from "../assets/img/singUpnew/step4.jpg";
import singup0 from "../assets/img/singUpnew/step0.jpg";
import sub1 from "../assets/img/subscription/step1.jpg";
import sub2 from "../assets/img/subscription/step2.jpg";
import sub3 from "../assets/img/subscription/step3.jpg";
import sub4 from "../assets/img/subscription/step4.jpg";
import viewAPi1 from "../assets/img/vewApi/step1.jpg";
import viewAPi2 from "../assets/img/vewApi/step2.jpg";
import viewAPi3 from "../assets/img/vewApi/step3.jpg";
import viewAPi4 from "../assets/img/vewApi/step4.jpg";
import { cardData } from "./data";
import logo from "../assets/img/logo.png";
import short from "../assets/images/short.png";
import { useNavigate } from "react-router-dom";
import { SiProbot } from "react-icons/si";
import { BsBarChart } from "react-icons/bs";
import { Drawer } from "antd";
import Guidecarddata from "./Guidecarddata";

const Guide = () => {
  const [selected, setSelected] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState("LOGIN PAGE");
  const [mappingData, setMappingData] = useState([]);
  const [checkTech, setCheckTech] = useState();
  const [showPage, setShowPage] = useState(true);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  console.log("selected", selected);
  console.log("selectedTitle", selectedTitle);
  console.log("checkTechcheckTech", checkTech);
  console.log("showPage", showPage);

  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawer2 = () => {
    setOpen2(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onClose2 = () => {
    setOpen2(false);
  };
  const topics = [
    {
      topic: "SIGN UP PAGE",
      icon: <BsPersonFillAdd className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "LOGIN PAGE",
      icon: <AiOutlineLogin className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "TOPUP",
      icon: <BsGraphUpArrow className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "BUY SUBSCRIPTION",
      icon: <TbPremiumRights className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "TELEGRAM",
      icon: <BiLogoTelegram className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "CONNECT TO LIVE API",
      icon: (
        <PiPlugsConnectedBold className="text-xl text-colorSecondaryDark" />
      ),
    },
    {
      topic: "CONNECT TO PAPER API",
      icon: <AiFillApi className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "VIEW API",
      icon: <AiOutlineFundView className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "COPYING A BOT",
      icon: <RiRobot2Line className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "RISK MATRIX",
      icon: <TbPremiumRights className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "ACTIVE BOTS",
      icon: <SiProbot className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "TRADES",
      icon: <BsBarChart className="text-xl text-colorSecondaryDark" />,
    },
    {
      topic: "REPORT",
      icon: <BiSolidReport className="text-xl text-colorSecondaryDark" />,
    },
  ];

  const primaryData = [
    {
      filterTitle: "LOGIN PAGE",
      title: "LOGIN PAGE",
      mappingData: [
        <p className="text-md leading-7 font-binance-plex">
          Step 1: Open the Login Page
        </p>,
        <p className="text-md leading-7 font-binance-plex">
          Step 2: Provide Your Credentials
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Click{" "}
          <a
            href="https://beta.fingenius.ai/"
            target="_blank"
            className=" text-colorAccent underline font-semiboldbold"
          >
            Login:
          </a>{" "}
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Access Your Account
        </p>,
        <div className="my-4">
          <video controls width="640" height="360">
            <source src={vided1} type="video/mp4" />
          </video>
        </div>,
        <p className="font-medium text-base leading-6 mt-2">
          1. Open the Login Page
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Visit the platform's{" "}
          <a
            href="https://beta.fingenius.ai/"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            login
          </a>{" "}
          page{" "}
        </p>,
        <img src={login1} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          2. Provide Your Credentials
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Enter your username or email address and your password in the
          designated fields.
        </p>,
        <img src={login3} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">3. Click Login:</p>,
        <p className="text-md font-binance-plex leading-7">
          Click the{" "}
          <span className="text-colorAccent  font-semiboldbold">"Login" </span>{" "}
          button to submit your credentials for verification.
        </p>,
        <img src={login2} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          4. Access Your Account
        </p>,
        <p className="text-md font-binance-plex leading-7">
          If your credentials are correct, you'll be granted access to your
          account's{" "}
          <span className="text-colorAccent">fingenius Dashboard</span>.
        </p>,
        <img src={login4} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "SIGN UP PAGE",
      title: "SIGN UP PAGE",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access the{" "}
          <a
            href="https://beta.fingenius.ai/signup"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            Sign-Up
          </a>{" "}
          Page
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Fill in Basic Information
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Click{" "}
          <a
            href="https://beta.fingenius.ai/signup"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            Sign Up:
          </a>
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Verify Your Email
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5: Access Your New Account
        </p>,
        <div className="my-4">
          <video controls width="640" height="360">
            <source src={Signupvid} type="video/mp4" />
          </video>
        </div>,
        <p className="font-medium text-base leading-6 mt-2">
          1. Access the Sign-Up Page
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Go to the{" "}
          <a
            href="https://beta.fingenius.ai/signup"
            target="_blank"
            className="text-black"
          >
            registration page
          </a>{" "}
          of the platform.
        </p>,
        <img src={singup1} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          2. Fill in Basic Information
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Choose a unique username, enter your email, name, password, and
          confirm your password. Click 'Verify' to access the platform.
        </p>,
        <img src={singup0} alt="" className="my-4" />,

        <p className="font-medium text-base leading-6 mt-2">
          3. Click Sign Up:{" "}
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Click the <span className="text-colorAccent ">"Sign Up"</span> button
          to start the registration process.
        </p>,
        <img src={singup2} alt="" className="my-4" />,

        <p className="font-medium text-base leading-6 mt-2">
          4. Verify Your Email{" "}
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Check your email for a verification link and input the OTP (one-time
          password) to gain access to the platform.
        </p>,
        <img src={singup4} alt="" className="my-4" />,
        <p className="font-medium text-base leading-6 mt-2">
          5. Access Your New Account
        </p>,
        <p className="text-md font-binance-plex leading-7">
          After email verification, you can log in with your chosen username or
          email address and password to access your new account.
        </p>,
        <img src={singup5} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "TOPUP",
      title: "HOW TO TOPUP",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1 :On the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span>, locate
          and click the <span className="text-colorAccent ">"Top-Up"</span>{" "}
          button.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2 :Choose the cryptocurrency or coin you want to use for the
          payment.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3 :Enter the desired amount you wish to top up and then click the
          <span className="text-colorAccent ">"Pay Now"</span> button.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4 :After clicking{" "}
          <span className="text-colorAccent ">"Pay Now"</span>, you will receive
          a payment barcode.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5 :Proceed to make the payment as instructed by scanning or
          presenting the payment barcode.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 6 :Wait for confirmation of your payment.
        </p>,
      ],
    },
    {
      filterTitle: "BUY SUBSCRIPTION",
      title: "HOW TO BUY SUBSCRIPTION",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access Your Account Dashboard
        </p>,

        <p className="text-md font-binance-plex leading-7">
          Step 2: Select the Tab
          <a
            href="https://beta.fingenius.ai/subscription"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            'Subscription'
          </a>
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Choose Your Preferred Subscription
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Confirmation Message for Successful Subscription
        </p>,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1: Navigate to the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> of your
          account.
        </p>,
        <img src={sub1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Click
          <span className="text-colorAccent ">"Subscription"</span>, then select
          the subscription you wish to subscribe to from the list.
        </p>,
        <img src={sub2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Select the 'Buy' button to purchase a subscription.
        </p>,
        <img src={sub3} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Upon successful subscription, you will receive a pop-up
          confirmation message.
        </p>,
        <img src={sub4} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "CONNECT TO LIVE API",
      title: "HOW TO CONNECT TO LIVE API (BINANCE)",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Create a{" "}
          <a
            href="https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
            target="_blank"
            className="text-colorSecondary underline font-semiboldbold"
          >
            Binance
          </a>{" "}
          Account
        </p>,

        <p className="text-md font-binance-plex leading-7">
          Step 2: Verify Your Account and Access{" "}
          <a
            href="https://beta.fingenius.ai/dashboard/api_settings"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            API Management
          </a>
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Create a New API Key
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Name the API."
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5: Verify the API Keys
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 6: Access the Dashboard
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 7: Add Live API Keys."
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 8: Copy Provided IP Addresses
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 9: Copy Provided IP Addresses (Duplicate)
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 10: Confirm and View IP Addresses
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 11: Save Changes
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 12: Enter API Details and Proceed
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 13: Confirm Conditions and Connect
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 14: Confirmation Pop-Up Upon Successful Connection
        </p>,

        <p className="text-md font-binance-plex leading-7 mt-3">
          To allow your bot to interact with Binance, you will need to create an
          API Key. This key acts as a connection between{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> and the
          exchange, enabling your bot to perform tasks such as placing automated
          orders and accessing your balance for its calculations. Essentially,
          the API Key serves as a way for your bot to communicate with the
          exchange and execute the actions necessary for automated trading.
        </p>,

        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1. If you haven't already, go to Binance's website and{" "}
          <a
            href="https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
            target="_blank"
            className="text-colorSecondary underline"
          >
            Create an Account
          </a>
          .
        </p>,
        // <p className="font-medium text-base leading-6 mt-2">Step two</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Verify your account and get started on the API by navigating
          to API Management.
        </p>,
        <img src={connectLive2} alt="" className="my-4" />,
        // <p className="font-medium text-base leading-6 mt-2">Step three</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3. Click on{" "}
          <span className="text-colorAccent">“Create API”</span>, select{" "}
          <span className="text-colorAccent">“System generated API Key”</span>
          and click on Next.
        </p>,
        <img src={connectLive3} alt="" className="my-4" />,
        // <p className="font-medium text-base leading-6 mt-2">Step four</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4. Start by naming the API something memorable such as{" "}
          <span className="text-colorAccent ">
            "<span className="text-colorAccent">fingenius Dashboard</span> API"
          </span>
          .
        </p>,
        <img src={connectLive4} alt="" className="my-4" />,
        // <p className="font-medium text-base leading-6 mt-2">Step five</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5. To complete the puzzle, slide the puzzle piece into place.
          Afterwards, you must verify your API Keys by using all of the
          following methods: phone number verification code, email verification
          code, and authenticator code.
        </p>,
        <img src={connectLive5} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 6. Navigate to the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span>.
        </p>,
        <img src={connectLive6} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 7. Click on <span className="text-colorAccent">"Add API"</span>
          and choose <span className="text-colorAccent">"Live API keys"</span>.
        </p>,
        <img src={connectLive7} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 8. Copy the provided IP addresses.
        </p>,
        <img src={connectLive8} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 9. Copy the provided IP addresses.
        </p>,
        <img src={connectLive9} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 10. Click "Confirm" to view all the IP addresses.
        </p>,
        <img src={connectLive10} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 11. Save your changes by clicking the "Save" button.
        </p>,
        <img src={connectLive11} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 12. Add the API Name, API Key, and Secret Key and click "Next" to
          complete the process.
        </p>,
        <img src={connectLive12} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 13. Check the box to confirm the condition. & Click the "Connect"
          button to proceed.
        </p>,
        <img src={connectLive13} alt="" className="my-4" />,

        <p className="text-md font-binance-plex leading-7">
          Step 14. Once the connection is established, a confirmation pop-up
          will appear.
        </p>,
        <img src={connectLive14} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "VIEW API",
      title: "HOW TO VIEW API",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access Your Dashboard
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Locate and Access API Credentials
        </p>,
        <p className="text-md font-binance-plex leading-7">Step 3: View API</p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Access Your API
        </p>,

        <p className="text-md font-binance-plex leading-7 mt-3">
          1. Navigate to your{" "}
          <span className="text-colorAccent">fingenius Dashboard</span>.
        </p>,
        <img src={viewAPi1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          2. Locate and click on the{" "}
          <span className="text-colorAccent ">"API CREDENTIALS"</span> option in
          the <span className="text-colorAccent">fingenius Dashboard</span>.
        </p>,
        <img src={viewAPi2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          3. After selecting{" "}
          <span className="text-colorAccent ">"API CREDENTIALS"</span>, click on
          the <span className="text-colorAccent ">"View API"</span> option.
        </p>,
        <img src={viewAPi3} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          4. You will then be able to view and access your API.
        </p>,
        <img src={viewAPi4} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "COPYING A BOT",
      title: "HOW TO COPYING A BOT",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access the Dashboard
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Choose and Copy Your Preferred Bot
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Configure Exchange and API Settings.
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Select Telegram Alert Preferences"
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5: Set Automated Amount (Based on Risk Level)
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 6: Confirm Your Choices
        </p>,
        // <p className="text-md font-binance-plex leading-7 mt-3">
        //   1. Access your{" "}
        //   <span className="text-colorAccent">fingenius Dashboard</span>.
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   2. Click on the <span className="text-colorAccent ">"Bots"</span>{" "}
        //   section in the{" "}
        //   <span className="text-colorAccent">fingenius Dashboard</span>.
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   3. You will be presented with a list of all the bots available to you.
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   4. Select the bot you want to copy.
        // </p>,
        // <p className="text-md font-binance-plex leading-7">
        //   5. After selecting the bot, you will receive a pop-up notification via
        //   Telegram for daily updates and notifications.
        // </p>,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1: Go to the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span>.
        </p>,
        <img src={b1} alt="" className="my-4" />,

        <p className="text-md font-binance-plex leading-7">
          Step 2: Pick the bot you want and click on the "Copy Bot" button.
        </p>,
        <img src={b2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Set up which Exchange and API you want to use.
        </p>,
        <img src={bot4} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Select if you want Telegram Alert
        </p>,
        <img src={bot5} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 5: Aumotate Amount will come (If you choose a high-risk level,
          you'll use less money to protect against liquidation).
        </p>,
        <img src={bot6} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 6: Click the "Start" button, and a Confirmation popup will
          appear.
        </p>,
        <img src={b3} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "CONNECT TO PAPER API",
      title: "HOW TO CONNECT TO PAPER API",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Copy Provided IP Addresses
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Add API and Access API CREDENTIALS
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Provide the Required Information and Proceed
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 4: Continue to the Next Step
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 5: Confirmation Popup
        </p>,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1. Copy the provided IP addresses.
        </p>,
        <img src={connectpaper1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on the{" "}
          <span className="text-colorAccent">"API CREDENTIALS"</span> button
          (ADD API).
        </p>,
        <img src={connectpaper2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. Provide the necessary information, including the API Name,
          Amount in ETH, Amount in BTC, Amount in USDT, and Amount in BNB. &
          Click the "Next" button to proceed.
        </p>,
        <img src={connectpaper4} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 4. Click the “NEXT" button.
        </p>,
        <img src={connectpaper3} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 5. and a Confirmation popup will appear.
        </p>,
        <img src={connectpaper5} alt="" className="my-4" />,
      ],
    },

    {
      filterTitle: "RISK MATRIX",
      title: "HOW TO SETTING YOUR RISK MATRIX",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access the Dashboard and Locate the 'Bot' Section
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Copy Your Chosen Bot
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Choose Your Risk Metrics
        </p>,

        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1. Go to the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> and look
          for the "Bot" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 2. Pick the bot you want and click on the{" "}
          <a
            href="https://beta.fingenius.ai/dashboard/botmain"
            target="_blank"
            className="text-colorAccent  font-semiboldbold"
          >
            {" "}
            "Copy Bot"
          </a>{" "}
          button.
        </p>,
        <img src={b2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 3. Select your risk metrics
        </p>,
        <img src={risk1} alt="" className="my-4" />,
        <p className="text-lg font-bold text-center font-binance-plex leading-7 mt-3">
          What is the risk parameter for spot?
        </p>,
        <div className="flex justify-around">
          <div>
            <img src={riskspot} alt="" className="my-10" />,
            <img src={riskfuture} alt="" className="my-2" />,
          </div>
          <div>
            <div>
              <h4 className="mt-3 font-bold">
                Select your risk level for spot:
              </h4>
              <ul>
                <li className="text-sm">
                  <b>Low: </b>To get started, just copy the bot and invest a
                  minimum of 1270 USDT for low risk. Our algorithm strategies
                  are set to protect liquidation till the market collapses up to
                  127%.
                  <br></br> <b>Medium:</b>
                  To get started, just copy the bot and invest a minimum of 1135
                  USDT for medium risk. Our algorithm strategies are set to
                  protect liquidation till the market collapses up to 113.5%.{" "}
                  <br></br>
                  <b>High:</b> To get started, just copy the bot and invest a
                  minimum of 250 USDT for high risk. Our algorithm strategies
                  are set to protect liquidation till the market collapses up to
                  25%.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-3 font-bold">
                What is the risk parameter for future?
              </h4>
              <ul>
                <li className="text-sm">
                  <b>Low:</b> To get started, just copy the bot and invest a
                  minimum of 127 USDT for low risk. Our algorithm strategies are
                  set to protect liquidation till the market collapses up to
                  127%.
                  <br></br>
                  <b>Medium:</b>
                  To get started, just copy the bot and invest a minimum of
                  113.5 USDT for medium risk. Our algorithm strategies are set
                  to protect liquidation till the market collapses up to 113.5%.
                  <br></br>
                  <b>High:</b> To get started, just copy the bot and invest a
                  minimum of 25 USDT for high risk. Our algorithm strategies are
                  set to protect liquidation till the market collapses up to
                  25%.
                </li>
              </ul>
            </div>
          </div>
        </div>,
      ],
    },
    {
      filterTitle: "TELEGRAM",
      title: "HOW TO CONNECT TO TELEGRAM",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access{" "}
          <a
            href="https://beta.fingenius Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent underline font-semiboldbold"
          >
            Telegram
          </a>{" "}
          in the Top Right Corner
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: Connect with{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> Bot
        </p>,

        <p className="text-md font-binance-plex leading-7">
          Step 3: Start the Interaction
        </p>,

        <p className="text-md font-binance-plex leading-7 mt-3">
          Interrogation of{" "}
          <a
            href="https://beta.fingenius Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent  font-semiboldbold"
          >
            Telegram ID{" "}
          </a>
          Login with Username & Password
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 1. At right hand side corner Click on{" "}
          <a
            href="https://beta.fingenius Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent  font-semiboldbold"
          >
            Telegram
          </a>
        </p>,
        <img src={tele1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Open the{" "}
          <a
            href="https://beta.fingenius Dashboard.ai/telegram"
            target="_blank"
            className="text-colorAccent  font-semiboldbold"
          >
            Telegram
          </a>{" "}
          and connect With{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> Bot
        </p>,
        <img src={tele2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. Click on the Start Button
        </p>,
        <img src={tele3} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "ACTIVE BOTS",
      title: "HOW TO VIEW YOUR ACTIVE BOTS",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access the Dashboard and Find the 'Bot' Section
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: View Active Bots
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Details of Your Active Bots
        </p>,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1. Go to the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> and
          locate the "Bot" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on the "ACTIVE BOT" button.
        </p>,
        <img src={active2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. This will lead you to view your active bots details, which
          include the following information:
          <ul className="list-disc ml-14">
            <li>
              Date and Time: The date and time when a particular transaction
              occurred.
            </li>
            <li>Variable Bot ID: A unique identifier for a trading bot.</li>
            <li>
              API Name: The name of the application programming interface (API)
              used for communication or data exchange.
            </li>
            <li>
              Exchange: The name of the trading platform or marketplace where
              trading activities occur.
            </li>
            <li>
              Exchange Type: The type of trading exchange, such as
              cryptocurrency exchange.
            </li>
            <li>
              Trade Type: The specific type of trade, such as buy, sell, or
              hold.
            </li>
            <li>
              Trading Symbol: The symbol or ticker representing a particular
              financial asset.
            </li>
            <li>
              Investment Amount: The money invested in a particular trade or
              transaction.
            </li>
            <li>
              Telegram Alert Setting: The configuration for receiving alerts
              (via the Telegram messaging platform).
            </li>
            <li>
              Status of the Trade: The current status of a trade, which can be
              open, closed, pending, or other relevant states.
            </li>
          </ul>
        </p>,
        <img src={active3} alt="" className="my-4" />,
      ],
    },

    {
      filterTitle: "TRADES",
      title: "HOW TO MONITOR YOUR TRADES",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1: Access the Dashboard and Find the 'Trades' Section
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: View Trades
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Details of Your Active Bots
        </p>,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1. Go to the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> and look
          for the "Trades" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on “TRADES” button
        </p>,
        <img src={trades1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. This will lead you to view your active bots details, which
          include the following information:
          <ul className="list-disc ml-14">
            <li>
              DateTime: The date and time when a particular event or transaction
              occurred.
            </li>
            <li>Bot ID: A unique identifier for a trading bot.</li>
            <li>
              API Name: The name of the (API) used for communication or data
              exchange.
            </li>
            <li>
              Trade Direction: The direction of the trade, such as "buy" or
              "sell."
            </li>
            <li>OrderID: A unique identifier for a specific order or trade</li>
            <li>
              Side: The side of the trade, which can be "long" or "short" in the
              context of trading.
            </li>
            <li>
              Type: The type of trade or order, e.g., market order, limit order,
              stop order.
            </li>
            <li>
              Quantity: The quantity or number of units of an asset involved in
              the trade.
            </li>
            <li>
              Price: The price at which the trade was executed or the price of
              the order.
            </li>
            <li>
              Order Type: The type of order, e.g., market, limit, stop, or other
              order types.
            </li>
            <li>
              Exchange: The name of the trading platform or marketplace where
              the trade occurred.
            </li>
            <li>
              Symbol: The symbol or ticker representing a specific financial
              asset or instrument.
            </li>
            <li>
              Exchange Type: The type of trading exchange, such as
              cryptocurrency exchange.
            </li>
            <li>
              Status: The current status or condition of the trade, such as
              "open," "closed," or "canceled."
            </li>
          </ul>
        </p>,
        <img src={trades2} alt="" className="my-4" />,
      ],
    },
    {
      filterTitle: "REPORT",
      title: "HOW TO ACCESS YOUR REPORTS",
      mappingData: [
        <p className="text-md font-binance-plex leading-7">
          Step 1:Access the Dashboard and Find the 'Report' Section."
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 2: View Reports
        </p>,
        <p className="text-md font-binance-plex leading-7">
          Step 3: Access Your Trade Report
        </p>,
        <p className="text-md font-binance-plex leading-7 mt-3">
          Step 1. Go to the{" "}
          <span className="text-colorAccent">fingenius Dashboard</span> and look
          for the "Report" section.
        </p>,
        <img src={bot1} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 2. Click on “REPORT” button
        </p>,
        <img src={report2} alt="" className="my-4" />,
        <p className="text-md font-binance-plex leading-7">
          Step 3. You will get to sees your trade report
          <ul className="list-disc ml-14">
            <li>
              Total: The total number or quantity used in the context of trades.
            </li>
            <li>
              Winning: The number of trades or positions closed with a profit.
            </li>
            <li>
              Losing: The number of trades or positions that have been closed
              with a loss.
            </li>
            <li>
              Hit rate: The percentage of trades or positions with positive
              returns.
            </li>
            <li>
              Invested: The total amount of money invested in various trades or
              investments.
            </li>
            <li>
              Balance: The remaining funds or assets after accounting for trades
              and investments.
            </li>
            <li>
              Position: The current active trades or open positions in a trading
              portfolio.
            </li>
            <li>
              Profit: The amount of profit or gain generated from trading
              activities.
            </li>
            <li>
              Brokerage: The amount paid in brokerage charges for executing
              trades or transactions.
            </li>
            <li>
              Days: The total days used to track trading or investment duration.
            </li>
          </ul>
        </p>,
        <img src={report3} alt="" className="my-4" />,
      ],
    },
  ];

  useEffect(() => {
    const dofilte = primaryData.filter(
      (item) => item.filterTitle == selectedTitle
    );
    console.log("dofilte", dofilte);
    setMappingData(dofilte);
  }, [selectedTitle]);

  const targetElementStyle = {
    display: "block",
    position: "relative",
    top: "-150px",
    visibility: "hidden",
  };

  const finGuideStyle = {
    display: "block",
    position: "relative",
    top: "-180px",
    visibility: "hidden",
  };
  
  const showGuide = () => {
    setShowPage(false)
  }

  return (
    <div className=" bg-[#ebebeb]">
      <div
        className="xl:block  hidden h-[510px] bg-no-repeat flex justify-center items-center "
        style={{ backgroundImage: `url(${guide})` }}
      >
        <div className="lg:pt-28 ">
          <p class="borderText text-center">Document Details </p>
          <p className=" text-center rubik text-lg ">
            Home <span className="font-medium">&#8594;</span>{" "}
            <span className="font-medium">Document Details</span>
          </p>
        </div>
      </div>
      <Drawer
        title="Technical guide"
        placement="right"
        onClose={onClose}
        open={open}
      >
            <Guidecarddata showFunc={{showGuide, showPage, onClose , setCheckTech}}  />
        {/* <Guidecarddata /> */}
      </Drawer>

      <div className=" min-h-screen  2xl:w-[85%] mx-auto h-auto ">
        <div className=" lg:h-20 w-full  flex items-center justify-between sticky_navbar_guide lg:bg-transparent bg-slate-50">
          <div className="mx-1 text-2xl font-semibold text-colorSecondaryDark flex items-center gap-2">
            <div className="lg:w-[160px] w-12 lg:w-20 h-[40px] bg-colorSecondaryDark ">
              <div className=" flex justify-center items-center h-full">
                <img
                  src={logo}
                  alt=""
                  className="px-2  lg:block hidden"
                  onClick={() => navigate("/")}
                />
                <img
                  src={short}
                  alt=""
                  className="px-2 w-12 lg:hidden block"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
            Guide
          </div>
          <div className="lg:hidden block">
            <div
              className="text-colorSecondaryDark  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-colorSecondaryDark hover:text-white transition-all transition-5s"
              onClick={showDrawer2}
            >
              <BiMenuAltRight size={40} />
            </div>
            <Drawer
              title="Technical guide"
              placement="right"
              onClose={onClose2}
              open={open2}
            >
              <div className="px-2">
                <div
                  className="text-colorSecondaryDark underline rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-colorSecondaryDark hover:text-white transition-all transition-5s"
                  onClick={() => {
                    navigate("/")
                    onClose2()
                  }}
                >
                  Login
                </div>
                <div
                  className="text-colorSecondaryDark  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-colorSecondaryDark hover:text-white transition-all transition-5s"
                  onClick={() => {
                    showDrawer()
                    onClose2()
                  }}
                >
                  Technical guide
                </div>
                <div
                  className=" text-colorSecondaryDark hover:bg-colorSecondaryDark hover:text-white rounded-lg px-3 py-2 text-lg  cursor-pointer"
                  onClick={() => {
                    navigate("/signup")
                    onClose2()
                  }}
                >
                  Signup
                </div>
              </div>
            </Drawer>
          </div>
          <div className="lg:flex hidden   items-center gap-3 ">
            <div
              className="text-colorSecondaryDark underline rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-colorSecondaryDark hover:text-white transition-all transition-5s"
              onClick={() => navigate("/")}
            >
              Login
            </div>
            <div
              className="text-colorSecondaryDark  xl:hidden md:block hidden  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-colorSecondaryDark hover:text-white transition-all transition-5s"
              onClick={showDrawer}
            >
              Technical guide
            </div>
            <div
              className="bg-colorSecondaryDark text-white rounded-lg px-3 py-2 text-lg  cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Signup
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 min-h-screen h-auto ">
          <div className="col-span-2 ">
            <div className=" bg-white py-14 rounded-3xl xl:w-auto md:w-[60px] w-[55px] stickyexample">
              <ul className="border-r border-[#d5d5d5]">
                {topics.map((item, index) => {
                  const formattedTopic = item.topic
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                  return (
                    <li
                      onClick={() => {
                        setSelected(index);
                        setSelectedTitle(item.topic);
                        setShowPage(true);
                      }}
                      key={index}
                      className={`${
                        index == selected && showPage
                          ? " bg-[#f5f5f5] border-colorAccent border-l-4 text-colorAccent"
                          : " bg-white"
                      }
                        text-base font-medium text-black capitalize py-3 rubik text-14 leading-20 lg:pl-6  pl-3 cursor-pointer`}
                    >
                      <a href="#finGuide">
                        <span className=" flex items-center  md:gap-2">
                          <span
                            className={`flex items-center md:gap-2 ${
                              index === selected && showPage
                                ? ""
                                : "text-colorAccent"
                            }`}
                          >
                          {item.icon}
                          </span>
                          <span className="xl:block hidden">
                            {formattedTopic}
                          </span>
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className=" col-span-8 lg:w-[95%] w-[100%]  mt-10 mx-auto max-h- h-auto overflow-y-auto">
            <div className=" mb-10">
              {showPage && (
                <div>
                  <p
                    className="text-colorSecondaryDark text-3xl font-medium"
                    id="finGuide"
                    style={finGuideStyle}
                  >
                   Fingenius  Beginner's Guide
                  </p>
                  <p
                    className="text-colorSecondaryDark text-3xl font-medium"
                    id="finGuide"
                  >
                    Fingenius Beginner's Guide
                  </p>
                  <p className="mt-1 mb-6 ">
                    Welcome to <span className="text-colorAccent">Fingenius Dashboard</span>! Kickstart your bot journey with quick
                    and easy steps:
                  </p>
                  {mappingData.map((item, index) => {
                    return (
                      <>
                        <div
                          className="bg-colorPrimary min-h-[300px] h-auto "
                          key={index}
                        >
                          <div className=" w-[95%] mx-auto ">
                            <p className="font-medium text-2xl leading-6  mb-4 text-colorSecondaryDark ">
                              {item.title}
                            </p>
                            {item?.mappingData?.map((itemData) => {
                              return itemData;
                            })}
                          </div>
                        </div>
                      </>
                    );})}
                </div>
              )}

              {!showPage && (
                <div className=" mb-10">
                  <h1 className="text-colorSecondaryDark text-3xl mt-10 font-medium">
                    <span className="text-colorAccent">fingenius Dashboard</span> Technical guide
                  </h1>
                  {cardData.map((item, index) => {
                    return (
                      <>
                        <div
                          className="font-medium "
                          id={item.jumpTo}
                          style={targetElementStyle}
                        >
                          {item.title}
                        </div>
                        <div
                          className={`px-4  ${
                            checkTech == index
                              ? "bg-colorPrimary  shadow-neumorphicnew py-2 border-2 rounded-3xl"
                              : ""
                          }`}
                        >
                          <div
                            className={`font-medium text-base leading-6 mt-2 ${
                              checkTech == index
                                ? "text-colorAccent "
                                : "text-colorSecondaryDark"
                            }`}
                            id={item.jumpTo}
                          >
                            {item.title}
                          </div>
                          <p className="text-sm leading-7">
                            {item.description}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="hidden xl:block col-span-2 ">
            {/* <div className=" bg-colorRight h-auto py-7 rounded-3xl stickyexample">
              <h4 className="text-gray-800 font-rubik font-semibold text-2xl mb-4 rubik px-6">
                Technical guide
              </h4>
              <div>
                <ul className=" ">
                  {cardData.map((item, index) => {
                    console.log("hooooo", item.jumpTo, typeof item.jumpTo);
                    return (
                      <>
                        <li
                          onClick={() => {
                            console.log("Hello world");
                            setShowPage(false);
                            setCheckTech(index);
                          }}
                          className={`text-black rubik font-medium py-2 cursor-pointer px-6 
                            ${
                              checkTech == index && !showPage
                                ? " bg-[#f5f5f5] border-colorAccent border-l-4"
                                : " bg-white"
                            }
                            `}
                          key={index}
                        >
                          <a href={`#${item.jumpTo}`}>{item.title}</a>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div> */}
            <div className="stickyexample">
            <Guidecarddata showFunc={{showGuide, showPage,onClose , setCheckTech}}  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
