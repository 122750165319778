import React, { useEffect, useState } from "react";
import {
  InfoCircleOutlined,
  UserOutlined,
  CopyOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  AiFillSignal,
  AiFillYoutube,
  AiFillWindows,
  AiFillSetting,
  AiFillRedditCircle,
  AiOutlineLogin,
} from "react-icons/ai";
import logo1 from "../assets/images/short.png";
import { TbBrandTelegram } from "react-icons/tb";
import { DownOutlined } from "@ant-design/icons";
import { BsFillChatDotsFill, BsFillFuelPumpDieselFill, BsFilterLeft } from "react-icons/bs";

import {
  Button,
  Col,
  Input,
  Menu,
  Row,
  Space,
  Tooltip,
  message,
  Dropdown,
  Image,
  Avatar,
} from "antd";
// import logo from '../../../assets/images/logo.png'
import logo from "../assets/images/logo.png";
// import '../../../assets/css/Navbar.css';
// import "../../assets/css/Navbar.css";
import { BsFillBellFill } from "react-icons/bs";
import { FaMoneyCheckAlt, FaUserAlt } from "react-icons/fa";
import { Drawer } from "antd";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import axios from "axios";
import { BiLogOutCircle, BiSolidBookBookmark } from "react-icons/bi";
import { toast } from "react-toastify";
import { MdCurrencyExchange } from "react-icons/md";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import ButtonApi from "../Components/ButtonApi";
import { PiPlugsFill } from "react-icons/pi";
// import { getToken } from "../../authfunctions";
// import Sidebar from "./Sidebar";

function Navbar({
  
  handleLogout,
  openSide,
  setOpenSide,
  selectedImage,
}) {
  const [inputValue, setInputValue] = useState("");
  const [apilistdata, setapilistdata] = useState([]);
  const [Packageview1, setPackageview1] = useState([]);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [dashboard, setDashboard] = useState("");
    const [botData, setBotData] = useState([]);
  const Navigate = useNavigate();
  const [isMobileDrawerVisible, setIsMobileDrawerVisible] = useState(false);

  const location = useLocation();
  const jwt = localStorage.getItem("jwt");

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
  };
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const customCloseIcon = <span style={{ color: "red" }}>X</span>;

  const sidebarItems = [
    {
      title: "PANEL",
      path: "/dashboard",
      icon: <AiFillWindows size={25} />,
    },
    {
      title: "API Credentials",
      icon: <PiPlugsFill size={25} />,
      subItems: [
        {
          title: "Add API",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/api_settings",
        },
        { title: "View API", path: "/dashboard/balance" },
      ],
    },
    {
      title: "BOTS",

      icon: <AiFillRedditCircle size={25} />,
      subItems: [
        {
          title: "Bots",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/botmain",
        },
        {
          title: "Active Bot",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/activebot",
        },
        {
          title: "Trades",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/trades",
        },

        { title: "Reports", path: "/dashboard/report" },
      ],
    },
    {
      title: "Transactions",
      path: "/dashboard/transactiontable",
      icon: <MdCurrencyExchange size={25} />,
    },
    {
      title: "Subscriptions",
      path: "/dashboard/subscriptions",
      icon: <FaMoneyCheckAlt size={25} />,
    },
    {
      title: "Logs",
      path: "/dashboard/logs",
      icon: <AiOutlineLogin size={25} />,
    },
    {
      title: "Support",
      path: "/dashboard/support",
      icon: <BsFillChatDotsFill size={25} />,
    },
    // {
    //   title: "Fuel",
    //   path: "/dashboard/fuel",
    //   icon: <BsFillFuelPumpDieselFill size={25} />,
    // },
    {
      title: "Profile",
      path: "/dashboard/profile",
      icon: <AiFillSetting size={25} />,
      
    },
  ];

  const onClose = () => {
    console.log();
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    message.success("Logout Successfully.");
    window.location.href = "/";
    // Navigate('/')
  };

  const items = [
    {
      label: (
        <Link to="/dashboard/profile" className="flex items-center">
          <UserOutlined className="text-lg mr-2" />
          My Profile
        </Link>
      ),
      key: "0",
    },
  
    {
      type: "divider",
    },
    {
      label: (
        <button
          className="w-full flex items-center justify-between rounded-lg px-2 py-1 bg-colorAccent text-colorPrimary"
          onClick={logout}
        >
          <BiLogOutCircle size={25} className="mr-2" />
          Logout
        </button>
      ),
      key: "3",
    },
  ];

  const [profile, setProfile] = useState("");

  useEffect(() => {
    View_Profile();
  }, []);

  const View_Profile = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "view_profile",
      data: postdata,
    })
      .then(async (res) => {
        const response = await res;
        console.log("response", response?.data?.Success);
        if (response) {
          setProfile(response?.data?.Success);
        } else {
          toast.error(response?.data?.Error);  
        }
        if (response?.data?.Error === "Session Expired") {
          localStorage.clear();
          window.location.href = "/";
          Navigate("/");
        } 
      })
      .catch((err) => {
        
        if (err?.response?.data?.Error === "Session Expired") {
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
            Navigate("/");
            message.error(err?.response?.data?.Error);
          }, 100);
        } 
        
      });
  };

    useEffect(() => {
      deposit_balance();
      
    }, []);

    const deposit_balance = () => {
      const jwt = localStorage.getItem("jwt");
      const response = axios({
        method: "POST",
        headers: {
          accept: "application/json",
        },
        url: process.env.REACT_APP_API_PATH_ANALYTICS + "deposit_balance",
        data: {
          project: "FinGenius",
          jwt: jwt,
        },
      })
        .then((res) => {
          const response = res?.data?.Success;
          console.log(response, "response----->");
          setDashboard(response);
        })
        .catch((err) => {
        
        if (err?.response?.data?.Error === "Session Expired") {
            localStorage.clear();
            window.location.href = "/";
            Navigate("/");
            message.error(err?.response?.data?.Error);
        } 
        
      });
    
    };

 const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
   const handleCloseDrawer = () => {
     if (isDrawerOpen) {
       setIsDrawerOpen(false);
     }
   };


    const telegram = async () => {
      const jwt = localStorage.getItem("jwt");
      const postdata = {
        project: "FinGenius",
        jwt: jwt,
      };

      const response = await axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_BOT_TELE + "generate_telegram_link",
        data: postdata,
      })
        .then(async function (response) {
          const res = await response?.data.Success;
           window.open(res, "_blank");
        })
        .catch((err) => {
          console.log(err?.response?.data?.Error);
        });
    };

    

    
  return (
    <>
      <div>
        <nav class="bg-white z-50 fixed w-full shadow-sm shadow-colorSecondaryDark border-gray-200 dark:bg-white">
          <div class=" flex flex-wrap md:flex-nowrap  md:flex-row items-center justify-between mx-auto ">
            <div
              className={`hidden md:flex bg-colorSecondaryDark py-4 w-40 md:w-56 justify-center items-center text-black ${
                openSide ? "md:w-[245px]" : "md:w-[67px]"
              }`}
            >
              {openSide ? (
                <>
                  <div className=" flex justify-center items-center ">
                    <Link to="/dashboard">
                      <img src={logo} className=" w-36  ml-0 " alt="Logo" />
                      {/* <span className=" text-white text-sm">Beta</span> */}
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={logo1}
                    className={` w-10  ml-0 ${!openSide && ""}`}
                    alt="Logo"
                  />
                </>
              )}
            </div>

            {/* mobileview--------------->>>>>>>>>>>> */}

            <div
              className={`md:hidden flex bg-colorSecondaryDark py-2 pt-5 w-40 md:w-56 justify-center  items-center text-black   
             `}
            >
              <img
                src={logo}
                className={`cursor-pointer w-36 md:w-48 ml-0 `}
                alt="Logo"
              />
            </div>

            <div className=" md:hidden flex justify-center items-center py-1  gap-2">
              <div className="text-center mt-auto "></div>
              {!profile.TelegramID ? (
                <div>
                  <button
                    onClick={telegram}
                    className="text-sm text-colorAccent "
                  >
                    <TbBrandTelegram
                      size={35}
                      className="text-[#69c3c4] border-2 border-colorAccent/20 px-1 py-0 rounded-lg"
                    />
                  </button>
                </div>
              ) : null}

              <Link to="/dashboard/deposit">
                <button className="shadow-neumorphic hover:border-colorSecondaryDark hover:text-colorSecondaryDark hover:bg-colorPrimary font-semibold py-2 px-4 rounded-lg transition-all duration-300 ease-in-out bg-colorSecondaryDark text-white  ">
                  TopUP
                </button>
              </Link>

              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a
                  onClick={(e) => e.preventDefault()}
                  className="md:w-fit cursor-pointer mx-auto hidden items-center justify-between rounded-full px-2 py-1 bg-white border border-solid hover:bg-gray-100 text-colorSecondaryDark"
                >
                  <Space>
                    <Avatar
                      src={profile.Profile_Image}
                      className="rounded-full w-10 h-10"
                    />
                  </Space>
                </a>
              </Dropdown>
            </div>

            <button
              data-collapse-toggle="navbar-default"
              type="button"
              onClick={showDrawer}
              class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div className="flex justify-between items-center w-[calc(104%-150px)] pr-5 mx-auto">
              <div
                class="hidden w-full mr-auto md:block md:w-auto"
                id="navbar-default"
              >
                <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white ">
                  <BsFilterLeft
                    size={45}
                    className={`absolute cursor-pointer top-1 w-100 mt-1  text-black rounded-full ${
                      !openSide ? "-scale-y-100 -rotate-180" : ""
                    }`}
                    onClick={() => setOpenSide(!openSide)}
                  />
                </ul>
                <div className="ml-12 font-semibold text-colorSecondaryDark">
                  Welcome {profile.Username}!
                </div>
              </div>
              <div></div>
              <div className="hidden xl:block">
                <ButtonApi />
              </div>

              <div className="hidden md:flex justify-center items-center py-2 md:gap-5">
                <div className="text-center mt-auto "></div>
                <div>
                  <Link to="/guide" target="_blank">
                    <BiSolidBookBookmark
                      size={30}
                      className="text-colorSecondaryDark transform translate-x-0 transition-transform hover:translate-x-2  animate-bounce"
                    />
                    <p className="text-[10px]">Guide</p>
                  </Link>
                </div>
                <div className="bg-colorPrimary shadow-neumorphic1 rounded-lg px-2 py-0.5">
                  <div className="text-sm  text-colorSecondaryDark font-semibold">
                    Deposit{" "}
                  </div>
                  <p className="text-[green] text-sm">
                  
                    ${" "}
                    {dashboard?.Wallet_Balance?.toFixed(3).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                  </p>
                </div>
                {/* <div className=" ">
                  <TbBrandTelegram className="text-[#69c3c4]" /> */}
                {!profile.TelegramID ? (
                  <div>
                    <button
                      onClick={telegram}
                      className="text-sm  text-colorAccent border-2 border-colorAccent px-2 py-1 rounded-lg"
                    >
                      <div className="flex justify-center items-center gap-1">
                        <TbBrandTelegram className="text-[#69c3c4]" />
                        Telegram
                      </div>
                    </button>
                  </div>
                ) : null}

                {/* </div> */}
                <Link to="/dashboard/deposit">
                  <button className="shadow-neumorphic hover:border-colorSecondaryDark hover:text-colorSecondaryDark hover:bg-colorPrimary font-semibold py-2 px-4 rounded-lg transition-all duration-300 ease-in-out bg-colorSecondaryDark text-white  ">
                    TopUP
                  </button>
                </Link>
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="md:w-fit cursor-pointer mx-auto flex items-center justify-between  px-2  bg-white   hover:bg-gray-100 text-colorSecondaryDark"
                  >
                    <Space>
                      {/* <UserOutlined />
                      Username */}

                      <Avatar
                        // width={70}
                        // height={70}
                        // value={profile.Profile_Image}
                        src={profile.Profile_Image}
                        className="rounded-full w-10 h-10"
                      />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>

        {/* Drawer component */}

        <Drawer
          title=<div className="text-sm  my-3 text-center flex-none text-white lg:flex-grow font-semibold md:hidden block">
            {/* Welcome Sneha! API req */}
            Welcome {profile.Username}!
          </div>
          headerStyle={{ background: "#001529", color: "#fff" }}
          placement="right"
          onClose={onClose}
          closeIcon={customCloseIcon}
          open={open}
          className="justify-end"
        >
          <Menu
            selectedKeys={[location?.pathname]}
            mode="vertical"
            theme="dark"
            className="w-full p-0 h-screen"
          >
            <div className="sidebar-wrapper">
              <div className="sidebar text-white">
                <ul>
                  {sidebarItems?.map((item, index) => (
                    <li
                      key={index}
                      className={`menu-item  ${
                        location.pathname === item.path ? "active" : ""
                      }`}
                    >
                      <Link to={item.path} onClick={handleCloseDrawer}>
                        <div
                          onClick={() => {
                            if (item.subItems) {
                              if (openSubMenu === index) {
                                setOpenSubMenu(null);
                                // onClose();
                              } else {
                                setOpenSubMenu(index);
                              }
                            }

                            if (!item.subItems) {
                              console.log("item.subItems", item.subItems);
                              setTimeout(() => {
                                onClose();
                              }, 300);
                            }
                          }}
                          className={`items-center flex w-full justify-start px-5 ${
                            location.pathname === item.path
                              ? `md:rounded-xl rounded-none hover:md:shadow-[0px_0px_2px_#eeeeee] md:shadow-md md md:shadow-colorPrimary/50 md:border-1 border-transparent md:hover:border-colorPrimary cursor-pointer  md:h-12 h-12 transition-all flex justify-center items-center w-full mx-auto md:bg-transparent bg-colorPrimary/30`
                              : ""
                          }`}
                        >
                          <div className="flex items-center justify-between  w-full">
                            <div className="flex items-center justify-evenly py-3 ">
                              <span className="item-icon  text-colorPrimary mb-1">
                                {item.icon}
                              </span>
                              <span className=" uppercase item-title ml-5">
                                {item.title}
                              </span>
                            </div>
                            <div>
                              {item.subItems && (
                                <span
                                  className={`arrow-icon  ${
                                    openSubMenu === index ? "open" : ""
                                  }`}
                                >
                                  {/* &#9660; */}
                                  {openSubMenu === index ? "\u25B2" : "\u25BC"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                      {item.subItems && (
                        <ul
                          className={`sub-menu ${
                            openSubMenu === index ? "open" : ""
                          }`}
                        >
                          {item.subItems.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className={`items-center flex w-full justify-start p-5 h-5 hover:text-colorPrimary cursor-pointer ${
                                location.pathname === subItem.path
                                  ? `md:rounded-xl rounded-none hover:md:shadow-[0px_0px_2px_#eeeeee] md:shadow-md md md:shadow-colorPrimary/50 md:border-1 border-transparent md:hover:border-colorPrimary cursor-pointer  md:h-12 h-12 transition-all flex  items-center w-full mx-auto md:bg-transparent bg-colorPrimary/30`
                                  : ""
                              }`}
                            >
                              <Link to={subItem.path} onClick={onClose}>
                                {subItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="footer bottom-4 fixed flex flex-col justify-center -mt-14 w-full">
                  {/* Logout Button */}
                  <div className="text-center mt-auto py-4">
                    <button
                      className="bg-colorAccent text-white py-5 px-4 rounded-md mx-auto"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </div>
                  {/* Copyright */}
                  <div className="text-center">
                    <p className="text-white">
                      © All right reserved @FinGenius 2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Menu>
          {/* <Sidebar
            handleLogout={handleLogout}
            sidebarItems={sidebarItems}
            openSide={openSide}
            handleSubmenuClick={handleSubmenuClick}
            submenuOpen={submenuOpen}
            BiLogOutCircle={BiLogOutCircle}
          /> */}

          {/* <Sidebar /> */}
        </Drawer>
      </div>
    </>
  );
}

export default Navbar;
