import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button,  Typography, Row, Col, message } from "antd";
// import img from "../../assets/image/fontlogo.png";
import axios from "axios";

// import { toast } from "react-toastify";
import Lottie from "lottie-react";
import ant from "../Lotties.json";
// import { useStyles } from "../Header/HeaderStyles";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const { Title } = Typography;

const EmailVerify = () => {

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  //  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
   const inputRefs = useRef([...Array(6)].map(() => React.createRef()));
   // ... (your existing functions)

  //  const handleOtpChange = (index, value) => {
  //    console.log("handleOtpChange called with index:", index, "value:", value);
  //    const newOtp = [...otp];
  //    newOtp[index] = value;
  //    console.log("New OTP:", newOtp);
  //    setOtp(newOtp);

  //    // Focus on the next input box
  //    if (value !== "" && index < 5) {
  //      inputRefs.current[index + 1].current.focus();
  //    }
  //  };


  const [input, setInput] = useState({ otp: "" });
  const param = useParams();
  console.log(param.email);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  console.log(email);
  const username = searchParams.get("username");

  const handleSubmit = async (e) => {

    const formData = new FormData();
      formData.append("project", "FinGenius");
    formData.append("username", username);
    formData.append("page", "1");
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "get_otp",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        console.log(response);
        if (res?.Success) {
          toast.success(res?.Success);
        } else {
          toast.error(res?.Error);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
  };

  useEffect(() => {
    // Check if the function has already been called during this session
    const hasBeenCalled = localStorage.getItem("hasCalledHandleSubmit");

    if (!hasBeenCalled) {
      // Call the handleSubmit function only once when the component is mounted
      handleSubmit();

      // Set a flag in local storage to indicate that it has been called
      localStorage.setItem("hasCalledHandleSubmit", "true");
    }
  }, []);


  const getverifyotp = async (e) => {
    console.log("e.target.value");
    console.log(input);
    const formData = new FormData();
      formData.append("project", "FinGenius");
    formData.append("username", username);
    formData.append("page", "1");
    formData.append("otp", otp);
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_USER + "verify_otp",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;

        if (res?.Success) {
         setInput(res);
         toast.success(res?.Success);
         window.location.href="/";
         Navigate("/")
        } else {
          toast.error(res?.Error)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Error);
      });
    setLoading(false);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  return (
    <>
      <div className="grid  ">
        <div className="login-register  mx-4  align-items-center">
          <div className="container111 md:bg-white w-full md:rounded-2xl md:shadow-lg md:shadow-colorAccent py-2 px-10 text-black">
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-colorSecondaryDark">
                {" "}
                Check Your Email
              </h1>
              <div className="block md:hidden text-sm text-white my-2">
                <p className="text-2xl font-semibold my-2 text-white">
                  {" "}
                  Check Your Email
                </p>
                <p>
                  We have sent a registration email to&nbsp;
                  {email ? email : "Sample@gmail.com"}, which you should receive
                  in few minutes. Kindly enter the OTP recieved over the email
                  here..{" "}
                </p>
              </div>

              <div className="flex justify-center">
                <Lottie
                  // className="text-center align-items-center"
                  alignItems="center"
                  style={{ width: "50%" }}
                  animationData={ant}
                />
              </div>
              <p className="hidden md:block">
                We have sent a registration email to{" "}
                {email ? email : "Sample@gmail.com"}, which you should receive
                in few minutes. Kindly enter the OTP recieved over the email
                here..{" "}
              </p>
              <Typography
                className="text-center text-white my-2 md:text-black"
                label={3}
              >
                Enter OTP
              </Typography>
              <div className="flex justify-center">
                <Form.Item
                  name="Get OTP"
                  rules={[
                    { required: true, message: "Please enter your name" },
                  ]}
                >
                  <input
                    // prefix={<MailOutlined />}
                    placeholder="Enter OTP"
                    // style={{ width: "100%" }}
                    value={otp}
                    type="number"
                    onChange={handleOtpChange}
                    className="py-1 px-4 border rounded-lg outline-none border-colorSecondary px-10 md:w-full"
                    name="otp"
                  />
                </Form.Item>
                <button
                  onClick={getverifyotp}
                  className="bg-colorAccent py-1 text-white"
                >
                  Verify
                </button>
              </div>
              <button
                onClick={handleSubmit}
                className="bg-colorAccent py-1 text-white"
              >
                Resend OTP
              </button>
            </div>
            <div className="text-center ">
              <span className="spam md:text-sm mt-1 text-white  text-xs md:text-black">
                Please check your spam folder if you do not see the email in
                your inbox.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="grid">
        <div className="login-register mx-4 align-items-center">
          <div className="container111 md:bg-white w-full md:rounded-2xl md:shadow-lg md:shadow-colorAccent py-2 px-10 text-black">
            <div className="text-center">
              <Typography
                className="text-center"
                label={3}
                style={{ color: "#fff" }}
              >
                Enter OTP
              </Typography>
              <div className="flex justify-center">
                {otp.map((digit, index) => (
                  <Form.Item key={index}>
                    <Input
                      ref={inputRefs.current[index]}
                      key={index}
                      placeholder="0"
                      style={{ width: "10%", marginRight: "5px" }}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      maxLength={1}
                      type="number"
                    />
                  </Form.Item>
                ))}
              </div>
              <Button
                onClick={getverifyotp}
                className="bg-colorAccent text-white"
              >
                Verify
              </Button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default EmailVerify;
