import React, { useState } from "react";

function Switchbutton() {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  return (
    <label className="bg-ececec p-0 m-0 relative w-full h-full">
      <button
        type="checkbox"
        className={`appearance-none absolute border-8 border-ececec bg-white w-auto h-auto rounded-full shadow-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
          isChecked ? "checked" : ""
        }`}
        checked={isChecked}
        onChange={toggleCheckbox}
      />
      <span className="font-awesome-icon absolute text-5xl text-black transform -translate-x-1/2 -translate-y-1/2">
        {isChecked ? <span className="text-sm text-red-500">Hello</span> : ""}
      </span>
    </label>
  );
}

export default Switchbutton;
