import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GoBook } from "react-icons/go";



function Signup() {
  // const [value, setCheck] = useState({
  //   username: "",
  //   emailid: "",
  //   name: "",
  //   password: "",
  //   conf_password: "",
  // });
  const [check, setCheck] = useState({
    username: "",
    emailid: "",
    name: "",
    password: "",
    conf_password: "",
  });
  const [isLabelAbove, setIsLabelAbove] = useState({
    username: false,
    emailid: false,
    password: false,
    conf_password: false,
    name: false,
  });
  const [hasScrolledDisclaimer, setHasScrolledDisclaimer] = useState(false);
  const [username, setUsername] = useState("");
  const [checkpasswordvisibility, setCheckPasswordVisibility] =
    useState("hidden");

   const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);

     const handleFocusPasswordInput = () => {
       setIsPasswordInputFocused(true);
       handleFocus("password");
     };

     const handleBlurPasswordInput = () => {
       setIsPasswordInputFocused(false);
       handleBlur("password");
     };

  const handleFocus = (name) => {
    setIsLabelAbove((prevState) => ({ ...prevState, [name]: true }));
    setCheckPasswordVisibility("block");
  };
  const handleBlur = (name) => {
    setIsLabelAbove((prevState) => ({
      ...prevState,
      [name]: check[name] !== "",
    }));
    setCheckPasswordVisibility("hidden");
  };
  const [checkboxes, setCheckboxes] = useState([
    false, // 8 characters
    false, // 1 lowercase letter
    false, // 1 uppercase letter
    false, // 1 number
    false, // 1 special character
  ]);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setCheck((prevState) => ({
      ...prevState,
      password: e.target.value,
    }));
  };

  const handleconf_passwordChange = (e) => {
    const newconf_password = e.target.value;
    setCheck((prevState) => ({
      ...prevState,
      conf_password: newconf_password,
    }));
  };

  useEffect(() => {
    const requirementsMet = [
      password.length >= 8,
      /[a-z]/.test(password),
      /[A-Z]/.test(password),
      /\d/.test(password),
      /[@$!%*?&]/.test(password),
    ];
    setCheckboxes(requirementsMet);
  }, [password]);

  const passwordMatch = password === check.conf_password;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheck((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, emailid, password, conf_password, name } = check;
    console.log(check);
    const userData = {
      project: "FinGenius",
      username: username.toLowerCase(),
      name: name.toLowerCase(),
      email: emailid,
      password: password,
      con_password: conf_password,
    };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_USER + "register",
      data: userData,
    })
      .then(async function (response) {
        const res = await response;
        console.log(res);
        if (res) {
          message.success(res.data.Success);
          navigate(`/email_verify?email=${emailid}&username=${username}`);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  const handleScroll = (e) => {
    const scrollableDiv = e.target;
    const scrollBottom =
      scrollableDiv.scrollHeight -
      scrollableDiv.clientHeight -
      scrollableDiv.scrollTop;

    // Define a threshold value (e.g., 20) to determine if the user has scrolled to the bottom.
    const threshold = 20;

    if (scrollBottom <= threshold) {
      setHasScrolledDisclaimer(true);
    }
  };

  async function handleusername(e) {
    console.log(e.target.name);
    if (e.target.name == "username") {
      const username = e.target.value.trim().toLowerCase();
      setCheck({ ...check, username });

      if (username === "") {
        setUsername("");
        return;
      }

      try {
        const response = await getusername(username);

        console.log(response);
        setUsername(response);
      } catch (error) {
        throw error;
      }
    }
  }

  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const username = e.target.value.trim().toLowerCase();
    setCheck({
      ...check,
      [name]: username,
    });

    if (username !== "") {
      getusername(username);
    }

    // if (check.username) {
    //   getusername();
    // }

  };

  //  const handleInputChange = (e) => {
  //    const { name, value } = e.target;

  //    const username = value.trim().toLowerCase();
  //    setCheck({
  //      ...check,
  //      [name]: username,
  //    });

  //    if (username) {
  //      getusername();
  //    }
  //  };

  const getusername = async (username) => {
    const postData = {
      project: "FinGenius",
      username: username,
    };

    console.log(postData, "postData");

    console.log("postData ==>>" , postData)

    const response = await axios({
      method: "POST",
      headers: "accept: application/json",
      url: process.env.REACT_APP_API_PATH_USER + "username",
      data: postData,
    })
      .then((response) => {
        console.log("getusername ==>>", response);
        setApiResponse(response.data.Success);
        // if (response.data.Success === "Username is available!") {
        //   setIsUsernameAvailable({
        //     message: "Username is available",
        //     available: true,
        //   });
        // } else if (response.data.Error === "Username is already taken!") {
        //   setIsUsernameAvailable({
        //     message: "Username is already taken",
        //     available: false,
        //   });
        // } else {
        //   setIsUsernameAvailable(null);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [apiResponse, setApiResponse] = useState(null);
  console.log("apiResponse ==>>", apiResponse)
  //  function getusername(username) {
  //   //  const formData = new FormData();
  //   //  formData.append("project", "FinGenius");
  //   //  formData.append("username", username);
  //   const postData = {
  //     project : "FinGenius",
  //     username : username,
  //   } 

  //    console.log(postData, "postData");
  //    return axios
  //      .post(process.env.REACT_APP_API_PATH_USER + "username", postData, {
  //        headers: { "Content-Type": "multipart/form-data" },
  //      })
  //      .then((response) => {
  //        // Check if the response indicates username availability
  //        const res = response.data.Success;
  //        console.log(res, "hfhgg");
  //         if (response.data.Success) {
  //           return response.data.Success;
  //         } else {
  //           return response.data.Error;
  //         }
  //      })
  //      .catch((error) => {
  //        console.err(error) // Propagate the error for further handling
  //      });
  //  }

const [showPassword, setShowPassword] = useState(false);

const handleTogglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};


 const areAllCheckboxesChecked = checkboxes.every((checked) => checked);

  return (
    <>
      <div className="container mx-auto md:mt-20 px-2">
        <div className="flex justify-center items-center h-full">
          <div className="flex justify-center w-full md:bg-white  relative md:rounded-2xl md:shadow-lg md:shadow-colorAccent overflow-hidden">
            <div className="md:mx-auto mt-2 justify-center w-full">
              <div className="text-center">
                <div className="md:text-3xl font-semibold mb-3 hidden md:block">
                  Create an account
                </div>
                <div className="hidden md:block">
                  Already have an account?{" "}
                  <Link to="/" className="text-colorAccent underline">
                    Log in
                  </Link>
                </div>
                <div className="px-5 py-5 rounded-lg mt-5 md:w-9/12 mx-auto">
                  <div className="relative my-4">
                    <input
                      id="username"
                      placeholder="Username"
                      name="username"
                      type="text"
                      className="py-2 px-4 border  rounded-full outline-none border-colorSecondary w-full"
                      value={check.username}
                      onChange={(e) => handleInputChange(e)}
                      // onChange={handleChange}
                      onFocus={() => handleFocus("username")}
                      onBlur={() => handleBlur("username")}
                      autoComplete="off"
                    />
                    {/* <p className={`text-left ml-4 mt-2 ${apiResponse ? ' text-green-500' : 'text-red-600'}`}>{apiResponse}</p> */}
                    <span
                      className={
                        check.username === "" ? "text-black" : "text-black"
                      }
                    >
                      {apiResponse !== null ? apiResponse.message : ""}
                    </span>
                    <span
                      className={
                        isUsernameAvailable !== null &&
                        isUsernameAvailable.available
                          ? "text-green-500"
                          : "text-red-500"
                      }
                    >
                      {isUsernameAvailable !== null
                        ? isUsernameAvailable.message
                        : ""}
                    </span>

                    <span
                      className={
                        check.username === "" ? "text-black" : "text-black"
                      }
                    >
                      {apiResponse !== null ? apiResponse.message : ""}
                    </span>
                  </div>

                  <div className="relative my-4">
                    <input
                      placeholder="Email ID"
                      id="emailid"
                      name="emailid"
                      type="text"
                      className="py-2 px-4 border  rounded-full outline-none border-colorSecondary w-full"
                      value={check.emailid}
                      onChange={handleChange}
                      onFocus={() => handleFocus("emailid")}
                      onBlur={() => handleBlur("emailid")}
                      autoComplete="off"
                    />
                  </div>

                  <div className="relative my-4">
                    <input
                      placeholder="Name"
                      id="name"
                      name="name"
                      type="text"
                      className="py-2 px-4 border   rounded-full outline-none border-colorSecondary w-full"
                      value={check.name}
                      onChange={handleChange}
                      onFocus={() => handleFocus("name")}
                      onBlur={() => handleBlur("name")}
                      autoComplete="off"
                    />
                  </div>

                  <div className="relative  my-4">
                    <input
                      placeholder="Password"
                      id="password"
                      className="py-2 px-4 border  rounded-full outline-none border-colorSecondary w-full"
                      value={password}
                      onChange={handlePasswordChange}
                      type={showPassword ? "text" : "password"}
                      onFocus={handleFocusPasswordInput}
                      onBlur={handleBlurPasswordInput}
                      autoComplete="off"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center  text-sm leading-5">
                      {showPassword ? (
                        <FaEyeSlash
                          onClick={handleTogglePasswordVisibility}
                          className="h-6 text-gray-700"
                        />
                      ) : (
                        <FaEye
                          onClick={handleTogglePasswordVisibility}
                          className="h-6 text-gray-700"
                        />
                      )}
                    </div>
                  </div>

                  {/* <div className="relative my-4">
                    <Input.Password
                      placeholder="Password"
                      id="Password"
                      type="password" // Change this from 'text' to 'password'
                      className="py-2 px-4 border  rounded-full outline-none border-colorSecondary w-full"
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={() => handleFocus("password")}
                      onBlur={() => handleBlur("password")}
                      autoComplete="off"
                    />
                  </div> */}

                  <div className="relative  my-4">
                    <input
                      placeholder="Confirm Password"
                      id="conf_password"
                      type={showPassword ? "text" : "password"}
                      className={`py-2 px-4 border  rounded-full outline-none border-colorSecondary w-full ${
                        passwordMatch ? "" : "border-red-500"
                      }`}
                      value={check.conf_password}
                      onChange={handleconf_passwordChange}
                      onFocus={() => handleFocus("conf_password")}
                      onBlur={() => handleBlur("conf_password")}
                      autoComplete="off"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      {showPassword ? (
                        <FaEyeSlash
                          onClick={handleTogglePasswordVisibility}
                          className="h-6 text-gray-700"
                        />
                      ) : (
                        <FaEye
                          onClick={handleTogglePasswordVisibility}
                          className="h-6 text-gray-700"
                        />
                      )}
                    </div>
                  </div>

                  <div
                    className={`mt-4 mb-4 grid grid-cols-2 ${
                      isPasswordInputFocused ? "" : "hidden"
                    }`}
                  >
                    {checkboxes.map(
                      (checked, index) =>
                        !checked && (
                          <div key={index}>
                            <div className="text-center text-white md:text-black">
                              <span className="mx-1 text-start">
                                <Checkbox
                                  checked={checked}
                                  disabled
                                  className="mx-2"
                                />
                                {index === 0 && "8 characters long"}
                                {index === 1 && "1 lowercase letter"}
                                {index === 3 && "1 number digits"}
                                {index === 2 && "1 uppercase letter"}
                                {index === 4 && "1 special character"}
                              </span>
                            </div>
                          </div>
                        )
                    )}
                  </div>

                  {/* <div className="mt-4 mb-4 grid grid-cols-2 ">
                    {checkboxes.map(
                      (checked, index) =>
                        !checked && (
                          <div key={index} className={checkpasswordvisibility}>
                            <div className=" text-center text-white md:text-black">
                              <span className="mx-1 text-start">
                                <Checkbox
                                  checked={checked}
                                  disabled
                                  className="mx-2"
                                />
                                {index === 0 && "8 characters long"}
                                {index === 1 && "1 lowercase letter"}
                                {index === 3 && "1 number digits"}
                                {index === 2 && "1 uppercase letter"}
                                {index === 4 && "1 special character"}
                              </span>
                            </div>
                          </div>
                        )
                    )}
                  </div> */}

                  <button
                    onClick={handleSubmit}
                    // disabled={!isCheckboxChecked || isLoading}
                    className="rounded-full bg-colorAccent py-1  cursor-pointer hover:bg-colorAccent/70 px-10 md:w-full text-white"
                  >
                    <span className="mb-5 p-0">Signup</span>
                  </button>
                </div>
              </div>
              <div className="flex md:hidden text-white justify-center">
                Already have an account?{" "}
                <Link to="/" className="text-colorAccent underline">
                  {" "}
                  &nbsp;Log in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
