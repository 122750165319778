import React from "react";
import GaugeChart from "react-gauge-chart";
import { TbMoneybag } from "react-icons/tb";
import { BsWallet2 } from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";
import { PiTimer } from "react-icons/pi";
import { Activebotnew } from "../Components/Activebotnew";

const data = [
  { name: "John Doe", rating: 4 },
  { name: "Jane Smith", rating: 3 },
  { name: "Bob Johnson", rating: 5 },
  { name: "Samantha Williams", rating: 2 },
  { name: "Michael Brown", rating: 4 },
];
const InfoBox = ({ infograpicImageStart, title, infograpicImageEnd }) => {
  return (
    <div className="flex gap-5 items-center justify-center   p-2 rounded-lg">
      <div className="bg-black rounded-full w-7 h-7 p-4 text-[white] flex items-center justify-center">
        {infograpicImageStart}
      </div>
      <div className="flex  justify-between items-center w-full text-start">
        <div className="flex flex-col items-start">
          <h2 className="text-xl font-semibold ">{title}</h2>
        </div>
        {infograpicImageEnd}
      </div>
    </div>
  );
};

const fuelData = [
  {
    title: "Payment Done",
    value: "$ 0",
    icon: <TbMoneybag fill="#0c2b44" />,
  },
  {
    title: "Payment Pending",
    value: "$ 0",
    icon: <GiPayMoney fill="#0c2b44" />,
  },
  { title: "Day's Left", value: "3", icon: <PiTimer fill="#0c2b44" /> },
  { title: "Balance", value: "$ 0", icon: <BsWallet2 fill="#0c2b44" /> },
];

function Fuel() {
  return (
    <>
      <div className="container ">
        <div className="flex lg:flex-row flex-col justify-between items-center ">
          <div className="lg:w-8/12 w-full mt-12 md:mt-0">
            <div className="mb-6 lg:p-2 lg:px-2 px-4  bg-colorSecondaryDark text-white text-md rounded-md p-2 text-center">
              You've used <span className="font-extrabold">80% </span> of your
              fuel credit. When it{" "}
              <span className="font-extrabold">hits 100%</span>, clear dues
              using the button below to keep services uninterrupted.
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1 gap-4  md:px-0 px-4 ">
              {fuelData.map((index) => (
                <div
                  className="bg-colorPrimary shadow-neumorphic overflow-hidden relative from-colorSecondaryDark via-colorSecondaryDark to-colorSecondaryDark/10 p-5 rounded-xl text-black md:h-40"
                  key={index}
                >
                  <div className="h-full w-full flex flex-col justify-end items-start">
                    <div className="text-7xl hidden md:flex justify-end w-full text-white colorSecondaryDark left-0 top-0 opacity-20 absolute rotate-12 ">
                      {index.icon}
                    </div>
                    <div className="text-4xl md:hidden flex justify-end w-full text-white">
                      {index.icon}
                    </div>
                    <div className="">
                      <p className="text-3xl font-bold text-colorSecondaryDark">{index.value}</p>
                      <div className="text-sm">{index.title}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 lg:w-4/12 w-full">
            <div className=" bg-gradient-to-r  from-colorSecondaryDark  to-colorSecondaryDark text-white shadow-lg shadow-black rounded-3xl ">
              <div className="px-6 pt-5 pb-3">
                <div className="flex md:flex-row flex-col justify-center items-center">
                  <div className="text-white">
                    <div className="text-2xl text-center">Fuel Limit</div>
                    <h2 className="text-4xl font-extrabold">$ 159</h2>
                  </div>
                  <div className="relative">
                    <div className="text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                      87%
                    </div>
                    <GaugeChart
                      id="gauge-chart3"
                      nrOfLevels={1}
                      colors={["white"]}
                      arcWidth={0.1}
                      percent={0.8}
                      textColor={"white"}
                      hideText={true}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center  p-2">
                <button className="bg-[white] text-black w-full p-2 rounded-full mx-2">
                  Refill Wallet
                </button>
                {/* <div className="flex justify-center items-center rounded-2xl bg-white gap-4 p-4">
                  <div className="text-orange-400 p-2 text-2xl rounded-full bg-white">
                    <AiOutlineStar />
                  </div>
                  <div>
                    Lorem Ipsum is Lorem Ipsum Lorem Ipsum is Lorem Ipsum
                  </div>
                  <div className="bg-orange-400 p-2 text-2xl rounded-full text-white">
                    <AiFillCaretRight />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Activebotnew  />
      {/* <div className="absolute bottom-0 w-screen h-screen  z-0 "></div> */}
    </>
  );
}

export default Fuel;
