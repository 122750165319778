import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Space, Table, Tooltip } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { gettoken } from '../../Pages/Allfunction';

const PortfolioTable = (tradebookdata) => {
  const [portfolio, setportfolio] = useState([])
  const token = gettoken()
  useEffect(() => {

    const ws = new WebSocket(`wss://dapib.stashack.cloud:8765?jwt=${token}`,);

    // ws.addEventListener('open', (data) => {
    //   // Send the JWT token as a message to the server
    //   console.log('data', data);
    // });

    ws.addEventListener('message', (event) => {
      setportfolio(JSON.parse(event.data))
    });

    return () => {
      ws.close();
    };
  }, []);


  const data10 = [{
    "coins": [
      "BTCUSDT"
    ],
    "total_size": {
      "BTCUSDT": 5.0
    },
    "inorder_units": {},
    "available_units": {
      "BTCUSDT": 5.0
    },
    "current_prices": {
      "BTCUSDT": 27867.74
    },
    "current_average": {
      "BTCUSDT": 28391.15777777778
    },
    "current_investement": {
      "BTCUSDT": 141955.7888888889
    },
    "current_PNL": {
      "BTCUSDT": -2617.088888888884
    },
    "current_PNLP": {
      "BTCUSDT": -1.8435943397400454
    },
    "current_value": {
      "BTCUSDT": 139338.7
    },
    "Past_PNL": {
      "BTCUSDT": 1753.288888889365
    },
    "past_purchase": {
      "BTCUSDT": 2412637.761111111
    },
    "past_sale": {
      "BTCUSDT": 2414391.0500000003
    }
  }]
  const data = portfolio



  const dataSource = [];
if (portfolio && portfolio.coins && portfolio.coins.length > 0) {
  for (let i = 0; i < portfolio.coins.length; i++) { // loop only once
    dataSource.push({
      key: i,
            name: portfolio.coins[i]?portfolio.coins[i]:0.00,
            totalSize: portfolio.total_size[portfolio.coins[i]]?portfolio.total_size[portfolio.coins[i]]:0.00,
            availableUnits: portfolio.available_units[portfolio.coins[i]]?portfolio.available_units[portfolio.coins[i]]:0.00,
            inorderUnits: portfolio.inorder_units[portfolio.coins[i]] ? portfolio.inorder_units[portfolio.coins[i]] : 0.00,
            currentAverage: portfolio.current_average[portfolio.coins[i]]?portfolio.current_average[portfolio.coins[i]]:0.00,
            currentInvestment: portfolio.current_investement[portfolio.coins[i]]?portfolio.current_investement[portfolio.coins[i]]:0.00,
            currentPNL: portfolio.current_PNL[portfolio.coins[i]]?portfolio.current_PNL[portfolio.coins[i]]:0.00,
            currentValue: portfolio.current_value[portfolio.coins[i]]?portfolio.current_value[portfolio.coins[i]]:0.00,
    });
  }
}

  const columns = [
    {
      title: 'COIN',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'TOTAL SIZE',
      dataIndex: 'totalSize',
      key: 'totalSize',
    },
    {
      title: 'AVL SIZE',
      dataIndex: 'availableUnits',
      key: 'availableUnits',
    },
    {
      title: 'IN-ORDER',
      dataIndex: 'inorderUnits',
      key: 'inorderUnits',
    },
    {
      title: 'AVG PRICE',
      dataIndex: 'currentAverage',
      key: 'currentAverage',
    },
    {
      title: 'INVESTED',
      dataIndex: 'currentInvestment',
      key: 'currentInvestment',
    },
    {
      title: 'PNL',
      dataIndex: 'currentPNL',
      key: 'currentPNL',
    },
    {
      title: 'CURRENT VALUE',
      dataIndex: 'currentValue',
      key: 'currentValue',
    },
  ];


  // console.log(dataSource)
  return (
    <>
      <Table
      size='small'
        dataSource={dataSource?dataSource:""}
        columns={columns}
        pagination={false}
        rowKey="name"
      />
    </>
  );
};
export default PortfolioTable;