import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";

const DepositTran = (props) => {
 

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataLog, setDataLog] = useState();

  const tableData = props.data

  useEffect(() => {
    setDataLog(tableData)
  }, [tableData])

  
  const sourceDataTwo = [];

  console.log("dataLog ===>>", dataLog)

  if (dataLog) {
    for (let i = 0; i < dataLog.length; i++) {
      sourceDataTwo.push({
        key: i,
        DateTime: dataLog[i].DateTime,
        Status: dataLog[i].Status,
        TransID: dataLog[i].TransID,
        IP: dataLog[i].IP
      });
    }
  }

  const resSourceDataTwo = [...sourceDataTwo].reverse();


  const columns = [
    {
      title: "TransID",
      dataIndex: "TransID",
    },
    {
      title: "DateTime",
      dataIndex: "DateTime",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
    {
      title: "IP",
      dataIndex: "IP",
    },
  ];

  return (
    <div className="mt-7  mx-auto w-full">
      <Table columns={columns} dataSource={resSourceDataTwo} />
    </div>
  );
};
export default DepositTran;
