import { Button, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import SkeltorLoader from './SkeltorLoader';
import { useMediaQuery } from "react-responsive";

function TradesTable(filter) {
  const [filteredData, setFilterData] = useState([]);
  const [pagesize, setpagesize] = useState(20);
  const [pagesizeopt, setpagesizeopt] = useState();
  const [loading, setLoading] = useState(true); 


  const columns = [
    {
      title: "DateTime",
      dataIndex: "DateTime",
      // width: "30%",
      
    },
    {
      title: "Exchange",
      dataIndex: "Exchange",
      // width: "20%",
    },
    // {
    //   title: "Type",
    //   dataIndex: "Type",
    //   // width: "10%",
    // },
    {
      title: "API Name",
      dataIndex: "API_Name",
      // width: "20%",
    },
    {
      title: "Exchange Type",
      dataIndex: "Exchange_Type",
      // width: "20%",
    },
    {
      title: "BOT Name",
      dataIndex: "BotName",
      // width: "20%",
    },
    {
      title: "Symbol",
      dataIndex: "Symbol",
      // width: "40%",
    },
    {
      title: "Order Type",
      dataIndex: "Order_Type",
      // width: "40%",
    },
    {
      title: "OrderID",
      dataIndex: "OrderID",
      // width: "40%",
    },
    {
      title: "Trade Direction",
      dataIndex: "Trade_Direction",
      // width: "40%",
    },
    {
      title: "Side",
      dataIndex: "Side",
      // width: "40%",
    },
    {
      title: "Price",
      dataIndex: "Price",
      // width: "40%",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      // width: "40%",
    },
    {
      title: "Status",
      dataIndex: "Status",
      // width: "40%",
    },
  ];

  const rows = [];

  if (filteredData) {
    let filteredRows = filteredData;

    if (filter.type && filter?.type !== "All") {
      filteredRows = filteredData.filter(
        (item) => item.Exchange_Type === filter.type
      );
    }
    if (filter.filter2 && filter?.filter2 !== "All") {
      filteredRows = filteredData.filter(
        (item) => item.Type === filter.filter2
      );
    }

    if (filter.coin && filter.coin.length > 0) {
      filteredRows = filteredRows.filter((item) =>
        filter.coin.includes(item.Symbol)
      );
    }

    if (filter.selectedBotID && filter.selectedBotID.length > 0) {
      filteredRows = filteredRows.filter((item) =>
        filter.selectedBotID.includes(item.BotName)
      );
    }

    // if (filter.coin) {
    //   filteredRows = filteredRows.filter((item) => item.Symbol === filter.coin);
    // }

    // if (filter.botid) {
    //   filteredRows = filteredRows.filter(
    //     (item) => item.BotName === filter.botid
    //   );
    // }

    for (let i = 0; i < filteredRows.length; i++) {
      const unixTimestamp = filteredData[i]?.DateTime;
      const date = new Date(unixTimestamp);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;
      rows.push({
        key: i,
        DateTime: formattedDateTime,
        API_Name: filteredRows[i]?.API_Name,
        Trade_Direction: filteredRows[i]?.Trade_Direction,
        OrderID: filteredRows[i]?.OrderID,
        Side: filteredRows[i]?.Side,
        Type: filteredRows[i]?.Type,
        Quantity: filteredRows[i]?.Quantity,
        Price: filteredRows[i]?.Price,
        Order_Type: filteredRows[i]?.Order_Type,
        Exchange: filteredRows[i]?.Exchange,
        Symbol: filteredRows[i]?.Symbol,
        Status: filteredRows[i]?.Status,
        Exchange_Type: filteredRows[i]?.Exchange_Type,
        BotName: filteredRows[i]?.BotName,
      });
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log(
      pagination,
      filters,
      sorter,
      extra,
      "pagination, filters, sorter, extra"
    );
    setpagesize(pagination.pageSize);
    // setpagesizeopt([30, 50, 100]);
    // console.log("params", pagination, filters, sorter, extra);
  };

  const trades_transactions = async () => {
    const jwt = localStorage.getItem("jwt");
    const postdata = {
      project: "FinGenius",
      jwt: jwt,
    };

    const response = await axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_BOT + "order_book",
      data: postdata,
    })
      .then(async function (response) {
        const res = await response?.data?.Data;
        const sortedData = res?.sort(
          (a, b) => new Date(b?.DateTime) - new Date(a?.DateTime)
        );

        setFilterData(sortedData);
         setLoading(false);
      
      })
      .catch((err) => {
        console.log(err?.response?.data?.Error);
         setLoading(false);
      });
  };


  useEffect(() => {
    trades_transactions(); 
  }, []);

   const isMobile = useMediaQuery({ maxWidth: 1050 }); 

  //  const pageSize = isMobile ? 10 : 22;

   const pageSizeOptions = [ 30, 50, 100];

 

  return (
    <div>
      <div className="mt-2   table-newres ">
        {loading ? (
          <div className="w-full h-40 rounded-tr-3xl rounded-tl-3xl bg-colorPrimary shadow-neumorphic ">
            <div className="w-full h-14 bg-colorSecondaryDark ">
              <SkeltorLoader />
            </div>
          </div>
        ) : (
          <Table
            className="overflow-x-scroll 2xl:overflow-x-hidden overflow-y-hidden"
            columns={columns}
            dataSource={rows}
            onChange={onChange}
            pagination={{
              pageSize: pagesize,
              pageSizeOptions: pageSizeOptions,
              // showSizeChanger: true,
              position: ["topRight","bottomRight"],
            }}
          />
        )}
      </div>
    </div>
  );
}

export default TradesTable;