import React, { useEffect, useRef, useState } from "react";
// import "../Header/Background.css";
// import { Button, Col, Input, Row, message } from "antd";
// import { Grid, TextField } from "@material-ui/core";

// import { InputAdornment, IconButton } from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
import coin1 from "../assets/coins/1.png";
import coin2 from "../assets/coins/2.png";
import coin3 from "../assets/coins/3.png";
import coin4 from "../assets/coins/4.png";
import coin5 from "../assets/coins/5.png";
import coin6 from "../assets/coins/6.png";
import TopUpCard from "../Components/TopUpCard";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Fa500Px, FaIcons } from "react-icons/fa";
import { toast } from "react-toastify";

// import "../Header/HeaderStyles";
// import { toast } from "react-toastify";

function TopUP() {
  const [searchText, setSearchText] = useState("");

  const [current, setCurrent] = useState("");
  const [convertAmount, setConvertAmount] = useState({});
  const [totalPrice, setTotalPrice] = useState({});
  const [messageError, setMessageError] = useState("");
  const [amountBTC, setAmountBTC] = useState("");
  const [amountBNB, setAmountBNB] = useState("");
  let convertAmountObj = {};
  let totalPriceObj = {};
  const [selectedInput, setSelectedInput] = useState(null);
  const inputRefs = useRef([]);

  function handleSearch(e, id) {
    console.log(e.target.value, e.target.id, e.target.name, "i am e");
    const searchAmount = parseFloat(e.target.value);
    setSearchText(e.target.value);
    if (e.target.value < 1) {
      setMessageError("Please enter an amount greater than or equal to 01");
    } else {
      setMessageError("");
    }

    if (!isNaN(searchAmount) && Object.keys(current).length > 0) {
      // Rest of your code
      setConvertAmount(convertAmountObj);
      setTotalPrice(totalPriceObj);
    }

    // if (e.target.value < 50) {
    //   message.error("Please enter the amount greater than 50");
    // }
    console.log(current, "i am");
    if (!isNaN(searchAmount) && Object.keys(current).length > 0) {
      const convertPriceBTC = searchAmount / current?.BTC;
      const convertPriceBNBBSC = searchAmount / current?.BNB;
      const convertPriceETH = searchAmount / current?.ETH;
      const convertPriceUSDTERC20 = searchAmount / current?.USDT_ERC20;
      const convertPriceUSDTBEP20 = searchAmount / current?.USDT_BEP20;
      const convertPriceUSDTTRC20 = searchAmount / current?.USDT_TRC20;
      const convertPriceLTCT = searchAmount / current?.LTCT;

      const convertAmountObj = {
        BTC: !isNaN(convertPriceBTC) ? convertPriceBTC?.toFixed(4) : "",
        BNB: !isNaN(convertPriceBNBBSC) ? convertPriceBNBBSC?.toFixed(4) : "",
        ETH: !isNaN(convertPriceETH) ? convertPriceETH?.toFixed(4) : "",
        LTCT: !isNaN(convertPriceLTCT) ? convertPriceLTCT?.toFixed(4) : "",
        USDT_BEP20: !isNaN(convertPriceUSDTBEP20)
          ? convertPriceUSDTBEP20.toFixed(4)
          : "",
        USDT_ERC20: !isNaN(convertPriceUSDTERC20)
          ? convertPriceUSDTERC20.toFixed(4)
          : "",
        USDT_TRC20: !isNaN(convertPriceUSDTTRC20)
          ? convertPriceUSDTTRC20.toFixed(4)
          : "",
      };

      const totalPriceObj = {
        BTC: !isNaN(convertPriceBTC)
          ? (convertPriceBTC * current?.BNBBSC).toFixed(4)
          : "",
        BNB: !isNaN(convertPriceBNBBSC)
          ? (convertPriceBNBBSC * current?.BNBBSC).toFixed(4)
          : "",
        ETH: !isNaN(convertPriceETH)
          ? (convertPriceETH * current?.ETH).toFixed(4)
          : "",
        ETH: !isNaN(convertPriceETH)
          ? (convertPriceETH * current?.ETH).toFixed(4)
          : "",
        USDT_BEP20: !isNaN(convertPriceUSDTBEP20)
          ? (convertPriceUSDTBEP20 * current?.USDT_BEP20).toFixed(4)
          : "",
        USDT_ERC20: !isNaN(convertPriceUSDTERC20)
          ? (convertPriceUSDTERC20 * current?.USDT_ERC20).toFixed(4)
          : "",
        LTCT: !isNaN(convertPriceLTCT)
          ? (convertPriceLTCT * current?.LTCT).toFixed(4)
          : "",
      };

      setConvertAmount(convertAmountObj);
      setTotalPrice(totalPriceObj);
    }
  }
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handletopup = (coin) => {
    const jwt = localStorage.getItem("jwt");

    if (coin == "BTC") {
      if (isLoading) return;
      setIsLoading(true);
      const postdata = {
        project: "FinGenius",
        jwt: jwt,
        asset: coin,
        price: Number(current?.USDT_TRC20),
        amount: Number(convertAmount?.USDT_TRC20),
      };
      const response = axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_PAY + "create_transaction",
        data: postdata,
      })
        .then(async function (response) {
          const res = await response?.data;
          if (res?.Success) {
            setCurrent(res?.Success);
            navigate("/dashboard/payment", {
              state: {
                Wallet_Address: res.Success.Receiver_Wallet_Address,
                Asset: res.Success.Asset,
                Amount: res.Success.Amount,
                TransID: res.Success.TransID,
              },
            });
          }
          // setTimeout(() => {
          //   window.location.reload();
          //   setIsLoading(false);
          // }, 3000);
        })
        .catch((error) => {
          toast.error(error.response.data.Error);
        });
    }
    if (coin == "BNB") {
      if (isLoading) return;
      setIsLoading(true);
      const postdata = {
        project: "FinGenius",
        jwt: jwt,
        asset: coin,
        price: Number(current?.BNB),
        amount: Number(convertAmount?.BNB),
      };
      const response = axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_PAY + "create_transaction",
        data: postdata,
      })
        .then(async function (response) {
          const res = await response?.data;
          if (res?.Success) {
            setCurrent(res?.Success);
            navigate("/dashboard/payment", {
              state: {
                Wallet_Address: res.Success.Receiver_Wallet_Address,
                Asset: res.Success.Asset,
                Amount: res.Success.Amount,
                TransID: res.Success.TransID,
              },
            });
          }
          //  setTimeout(() => {
          //    window.location.reload();
          //    setIsLoading(false);
          //  }, 3000);
        })
        .catch((error) => {
          toast.error(error.response.data.Error);
        });
      setLoading(false);
    }

    if (coin == "ETH") {
      if (isLoading) return;
      setIsLoading(true);

      const postdata = {
        project: "FinGenius",
        jwt: jwt,
        asset: coin,
        price: Number(current?.ETH),
        amount: Number(convertAmount?.ETH),
      };
      const response = axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_PAY + "create_transaction",
        data: postdata,
      })
        .then(async function (response) {
          const res = await response?.data;
          if (res?.Success) {
            setCurrent(res?.Success);
            navigate("/dashboard/payment", {
              state: {
                Wallet_Address: res.Success.Receiver_Wallet_Address,
                Asset: res.Success.Asset,
                Amount: res.Success.Amount,
                TransID: res.Success.TransID,
              },
            });
          }
          // setTimeout(() => {
          //   window.location.reload();
          //   setIsLoading(false);
          // }, 3000);
        })
        .catch((error) => {
          toast.error(error.response.data.Error);
        });
    }
    if (coin == "USDT_BEP20") {
      if (isLoading) return;
      setIsLoading(true);

      const postdata = {
        project: "FinGenius",
        jwt: jwt,
        asset: coin,
        price: Number(current?.USDT_BEP20),
        amount: Number(convertAmount?.USDT_BEP20),
      };
      const response = axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_PAY + "create_transaction",
        data: postdata,
      })
        .then(async function (response) {
          const res = await response?.data;
          console.log("Transaction Success:", res);
          if (res?.Success) {
            setCurrent(res?.Success);
            navigate("/dashboard/payment", {
              state: {
                Wallet_Address: res.Success.Receiver_Wallet_Address,
                Asset: res.Success.Asset,
                Amount: res.Success.Amount,
                TransID: res.Success.TransID,
              },
            });
          }
          // setTimeout(() => {
          //   window.location.reload();
          //   setIsLoading(false);
          // }, 3000);
        })
        .catch((error) => {
          toast.error(error.response.data.Error);
        });
    }

    // if (coin == "USDT_BEP20") {
    //   if (isLoading) return;
    //   setIsLoading(true);
    //   const postdata = {
    //     project: "FinGenius",
    //     jwt: jwt,
    //     asset: coin,
    //     price: Number(current?.USDT_BEP20),
    //     amount: Number(convertAmount?.USDT_BEP20),
    //   };
    //   const response = axios({
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     url: process.env.REACT_APP_API_PATH_PAY + "create_transaction",
    //     data: postdata,
    //   })
    //     .then(async function (response) {
    //       const res = await response?.data;
    //       if (res?.Success) {
    //         setCurrent(res?.Success);
    //         navigate("/dashboard/payment", {
    //           state: {
    //             Wallet_Address: res?.Success?.Wallet_Address,
    //             Asset: res?.Success?.Asset,
    //             Amount: res?.Success?.Amount,
    //           },
    //         });
    //       }
    //       // setTimeout(() => {
    //       //   window.location.reload();
    //       //   setIsLoading(false);
    //       // }, 3000);
    //     })
    //     .catch((error) => {
    //       toast.error(error?.response?.data?.Error);
    //     });
    // }
    if (coin == "USDT_TRC20") {
      if (isLoading) return;
      setIsLoading(true);
      const postdata = {
        project: "FinGenius",
        jwt: jwt,
        asset: coin,
        price: Number(current?.USDT_TRC20),
        amount: Number(convertAmount?.USDT_TRC20),
      };
      const response = axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_PAY + "create_transaction",
        data: postdata,
      })
        .then(async function (response) {
          const res = await response?.data;
          if (res?.Success) {
            setCurrent(res?.Success);
            console.log(res.Success);
            navigate("/dashboard/payment", {
              state: {
                Wallet_Address: res.Success.Receiver_Wallet_Address,
                Asset: res.Success.Asset,
                Amount: res.Success.Amount,
                TransID: res.Success.TransID,
              },
            });
          }
          // setTimeout(() => {
          //   window.location.reload();
          //   setIsLoading(false);
          // }, 3000);
        })
        .catch((error) => {
          console.log(error?.response?.data?.Error);
        });
    }
    if (coin == "USDT_ERC20") {
      if (isLoading) return;
      setIsLoading(true);
      const postdata = {
        project: "FinGenius",
        jwt: jwt,
        asset: coin,
        price: Number(current?.USDT_ERC20),
        amount: Number(convertAmount?.USDT_ERC20),
      };
      const response = axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH_PAY + "create_transaction",
        data: postdata,
      })
        .then(async function (response) {
          const res = await response?.data;
          if (res?.Success) {
            setCurrent(res?.Success);
            navigate("/dashboard/payment", {
              state: {
                Wallet_Address: res.Success.Receiver_Wallet_Address,
                Asset: res.Success.Asset,
                Amount: res.Success.Amount,
                TransID: res.Success.TransID,
              },
            });
          }
          // setTimeout(() => {
          //   window.location.reload();
          //   setIsLoading(false);
          // }, 3000);
        })
        .catch((error) => {
          toast.error(error.response.data.Error);
        });
    }
  };
  const currentprice = () => {
    const formData = {
      project: "FinGenius",
    };

    const response = axios({
      method: "POST",
      headers: {
        accept: "application/json",
      },
      url: process.env.REACT_APP_API_PATH_PAY + "live_prices",
      data: formData,
    })
      .then(async function (response) {
        const res = await response?.data;
        if (res?.Success) {
          setCurrent(res?.Success);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    currentprice();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const getdatafunc = (data) => {
    console.log("dataaaaa", data);
  };

  console.log(Number(convertAmount?.USDT_BEP20), "convertAmount");

  const cardData = [
    {
      id: 1,
      img: coin4,
      title: "USDT(BEP20)",
      price: (parseFloat(current?.USDT_BEP20) || 0).toFixed(2),

      convert: (
        <span style={{ color: "green" }}>
          {isNaN(Number(convertAmount?.USDT_BEP20))
            ? "0.00"
            : Number(convertAmount?.USDT_BEP20).toFixed(4)}
        </span>
      ),
      button: {
        onClick: () => handletopup("USDT_BEP20"),
        disabled: messageError || searchText < 1|| isLoading,
        loading: isLoading,
      },
      amount: {
        value: searchText,
        onChange: handleSearch,
        messageError: messageError,
        InputProps: {
          endAdornment: (
            <Fa500Px position="end">
              <FaIcons>
                <Fa500Px position="start">$</Fa500Px>
              </FaIcons>
            </Fa500Px>
          ),
        },
      },
    },
    {
      id: 2,
      img: coin6,

      title: "USDT(ERC20)",
      price: (parseFloat(current?.USDT_ERC20) || 0).toFixed(2),
      convert: (
        <span style={{ color: "green" }}>
          {isNaN(Number(convertAmount?.USDT_ERC20))
            ? "0.00"
            : Number(convertAmount?.USDT_ERC20).toFixed(4)}
        </span>
      ),
      button: {
        // Ensure that the 'button' property is defined
        onClick: () => handletopup("USDT_ERC20"),
        disabled: messageError || searchText < 1|| isLoading,
        loading: isLoading,
      },
      amount: {
        value: searchText,
        onChange: handleSearch,
        messageError: messageError,
        InputProps: {
          endAdornment: (
            <Fa500Px position="end">
              <FaIcons>
                <Fa500Px position="start">$</Fa500Px>
              </FaIcons>
            </Fa500Px>
          ),
        },
      },

      color: "primaryColor",
    },
    {
      id: 3,
      img: coin5,
      title: "USDT(TRC20)",
      price: (parseFloat(current?.USDT_TRC20) || 0).toFixed(2),
      getdata: getdatafunc,

      convert: (
        <span style={{ color: "green" }}>
          {isNaN(Number(convertAmount?.USDT_TRC20))
            ? "0.00"
            : Number(convertAmount?.USDT_TRC20).toFixed(4)}
        </span>
      ),
      button: {
        // Ensure that the 'button' property is defined
        onClick: () => handletopup("USDT_TRC20"),
        disabled: messageError || searchText < 1|| isLoading,
        loading: isLoading,
      },
      amount: {
        value: searchText,
        onChange: handleSearch,
        messageError: messageError,
        InputProps: {
          endAdornment: (
            <Fa500Px position="end">
              <FaIcons>
                <Fa500Px position="start">$</Fa500Px>
              </FaIcons>
            </Fa500Px>
          ),
        },
      },
    },

    // {
    //   id: "4",
    //   title: "BTC",
    //   img: coin2,
    //   getdata: getdatafunc,
    //   price: (parseFloat(current?.BTC) || 0).toFixed(2),
    //   color: "primaryColor",
    //   convert: (
    //     <span style={{ color: "green" }}>
    //       {isNaN(Number(convertAmount?.BTC))
    //         ? "0.00"
    //         : Number(convertAmount?.BTC).toFixed(4)}
    //     </span>
    //   ),
    //   button: {
    //     // Ensure that the 'button' property is defined
    //     onClick: () => handletopup("BTC"),
    //     disabled: messageError || searchText < 1|| isLoading,
    //     loading: isLoading,
    //   },
    //   amount: {
    //     value: searchText,
    //     onChange: handleSearch,

    //     InputProps: {
    //       endAdornment: (
    //         <Fa500Px position="end">
    //           <FaIcons>
    //             <Fa500Px position="start">$</Fa500Px>
    //           </FaIcons>
    //         </Fa500Px>
    //       ),
    //     },
    //   },
    // },
    {
      id: 4,
      title: "BNB",
      img: coin1,
      price: (parseFloat(current?.BNB) || 0).toFixed(2),
      getdata: getdatafunc,
      color: "primaryColor",

      convert: (
        <span style={{ color: "green" }}>
          {isNaN(Number(convertAmount?.BNB))
            ? "0.00"
            : Number(convertAmount?.BNB).toFixed(4)}
        </span>
      ),
      button: {
        // Ensure that the 'button' property is defined
        onClick: () => handletopup("BNB"),
        disabled: messageError || searchText < 1|| isLoading,
        loading: isLoading,
      },
      amount: {
        value: searchText,
        onChange: handleSearch,
        messageError: messageError,
        InputProps: {
          endAdornment: (
            <Fa500Px position="end">
              <FaIcons>
                <Fa500Px position="start">$</Fa500Px>
              </FaIcons>
            </Fa500Px>
          ),
        },
      },
    },
    {
      id: 5,
      title: "ETH",
      img: coin3,

      // price:
      //   current?.ETH !== undefined ? `$ ${current?.ETH?.toFixed(2)}` : "0.00",
      price: (parseFloat(current?.ETH) || 0).toFixed(2),

      convert: (
        <span style={{ color: "green" }}>
          {isNaN(Number(convertAmount?.ETH))
            ? "0.00"
            : Number(convertAmount?.ETH).toFixed(4)}
        </span>
      ),
      button: {
        // Ensure that the 'button' property is defined
        onClick: () => handletopup("ETH"),
        disabled: messageError || searchText < 1|| isLoading,
        loading: isLoading,
      },
      amount: {
        value: searchText,
        onChange: handleSearch,

        InputProps: {
          endAdornment: (
            <Fa500Px position="end">
              <FaIcons>
                <Fa500Px position="start">$</Fa500Px>
              </FaIcons>
            </Fa500Px>
          ),
        },
      },
    },
  ];

  const [touched, setTouched] = useState(false);
  const [data, setData] = useState();

  const handleSearch1 = (e) => {
    setTouched(true);
  };

  return (
    <>
      <div className="px-5 overflow-y-hidden ">
        <Link to="/dashboard">
          <AiOutlineArrowLeft size={25} />
        </Link>
        {/* <button className="bg-colorSecondaryDark text-white py-1 px-4 rounded-full">Back</button> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 2xl:grid-cols-6 mx-4 gap-6 mt-6">
        {cardData.map((card, index) => (
          <React.Fragment>
            <TopUpCard id={index} data={card} />
          </React.Fragment>
        ))}
        <div></div>
      </div>

      <h4 className="mt-6 text-center  text-colorSecondaryDark font-extrabold text-lg">
        Payment Guidelines
      </h4>
      <p className="text-sm text-center mb-6">
        To make a secure and hassle-free payment, follow these simple steps:
      </p>

      <div className=" p-5 bg-colorPrimary shadow-neumorphicnew m-7 mt-0 mb-0">
        <p className="text-[15px]">
          <p className="font-semibold text-xs text-colorSecondaryDark">
            Step 1
          </p>
          <span className="font-semibold text-md text-colorSecondaryDark">
            {" "}
            Cryptocurrency:
          </span>{" "}
          Choose your preferred cryptocurrency and enter the amount.
        </p>
        <p className="text-[15px]">
          <p className="font-semibold text-xs text-colorSecondaryDark">
            Step 2
          </p>
          <span className="font-semibold text-md text-colorSecondaryDark">
            Generate Unique Address:
          </span>
          Our system creates a unique payment address and barcode for you.
        </p>
        <p className="text-[15px]">
          <p className="font-semibold text-xs text-colorSecondaryDark">
            Step 3
          </p>
          <span className="font-semibold text-md text-colorSecondaryDark">
            Initiate Payment:
          </span>
          Use your wallet to send funds by copying the address or scanning the
          QR code. A timer will start.
        </p>
        <p className="text-[15px]">
          <p className="font-semibold text-xs text-colorSecondaryDark">
            Step 4
          </p>
          <span className="font-semibold text-md text-colorSecondaryDark">
            Complete Payment:
          </span>
          Ensure payment is sent before the timer expires. Track transaction
          status on the Deposit Transaction page.
        </p>
        <p className="text-[15px]">
          <p className="font-semibold text-xs text-colorSecondaryDark">
            Step 5
          </p>
          <span className="font-semibold text-md text-colorSecondaryDark">
            Confirmation:
          </span>
          Once confirmed on the blockchain, Click "Paid," submit the transaction
          ID, and wait for admin approval (10-45 minutes).
        </p>
        <p className="text-[15px]">
          <p className="font-semibold text-xs text-colorSecondaryDark">
            Step 6
          </p>
          <span className="font-semibold text-md text-colorSecondaryDark">
            Timeout/Insufficient Funds:
          </span>
          Receive an email for unconfirmed or insufficient funds. Follow
          instructions to claim within 8 hours. Contact us for issues.
        </p>
      </div>
    </>
  );
}

export default TopUP;
