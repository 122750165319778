import { Drawer } from "antd";
import { useState } from "react";
import BotNames from "../Components/BotNames";
import { IoMdStar } from "react-icons/io";

const categories = ["All", "A", "B", "C"];

function Bots() {
  const [category, setCategory] = useState("All");
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="flex space-x-1">
        {categories.map((cat) => (
          <div
            onClick={() => {
              setCategory(cat);
            }}
            className={`px-3 bg-slate-400 text-white text-[10px] p1-1 flex-shrink-0 border rounded-md border-black cursor-pointer ${
              cat === category && "bg-black text-white"
            }`}
          >
            {cat}
          </div>
        ))}
      </div>
      <div className="md:container mx-auto ">
        {BotNames.filter(
          (element) => element.category === category || category === "All"
        ).map((data) => (
          <div className="my-2 bg-gray-100 rounded-md ">
            <div className="border border-solid rounded-md shadow-sm mb-2 p-2">
              <div className="flex justify-between items-center mb-2">
                <div className="font-bold text-md">{data.title}</div>
                <div className="font-bold text-md flex items-center bg-white p-1 py-0 border border-slate-200 rounded-lg">
                  <IoMdStar className="text-yellow-400" />
                  {data.rating}
                </div>
              </div>
              <div className="flex justify-between items-center mb-2">
                <div className="">
                  <div className="text-green-400">
                    <span className="text-lg">{data.preDecimal}</span>.
                    <span>{data.postDecimal} %</span>
                  </div>
                  <div className="text-xs text-gray-500">3y Returns</div>
                </div>
                <div className="hidden text-green-400">
                  <span className="text-lg">{data.preDecimal}</span>.
                  <span>{data.postDecimal} %</span>
                </div>
                <div>
                  <div
                    onClick={showDrawer}
                    className="px-2 py-1  text-sm mb-0 bg-colorSecondary hover:bg-white text-slate-50 hover:text-colorSecondary border-slate-50 cursor-pointer hover:border-colorSecondary rounded-full"
                    size="small"
                  >
                    {data.btnName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </div>
  );
}

export default Bots;
