import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { toast } from "react-toastify";
import img1 from "../assets/Api/BINANCEFUTURES.png";
import img2 from "../assets/Api/BINANCESPOT.png";
import img5 from "../assets/Api/BYBITFUTURES.png";
import img6 from "../assets/Api/BYBITSPOT.png";
import img3 from "../assets/Api/KUCOINFUTURES.png";
import img4 from "../assets/Api/KUCOINSPOT.png";

const SubscriptionPackage = (props) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  
  const stColour = props.onBuyClick1;

  const {
    _id,
    Name,
    Live_Bots,
    Paper_Bots,
    Profit_Percent,
    Paper_API,
    Validity,
    Live_API,
    Price,
    color,
    button,
    isMatch,
    Exchange,
    Allotted
  } = props.data;

//  const formattedAllotted = `${Allotted.toLocaleString(
//    "en-US",
//    {
//      maximumFractionDigits: 3,
//      minimumFractionDigits: 3,
//    }
//  )}`;
const formattedAllotted = `${Allotted.toLocaleString(
  "en-US",
  {
    maximumFractionDigits: 3,
    minimumFractionDigits: 0,
  }
).replace(/(\.[0-9]*[1-9])0*$/, "$1")}`;



  const mapCardPara = [
    { para: `Activate ${Live_Bots} live bots` },
    { para: `Activate ${Paper_Bots} paper bots` },
    { para: `Earn ${100 - Profit_Percent}% of the profits` },
    { para: ` Test on ${Paper_API} Paper APIs` },
    { para: `Rest Assured for 1 ${Validity}.` },
    { para: `Connect ${Live_API} Live API` },
    { para: `Trade on ${Exchange} Exchange ` },
    { para: `Max fund Allotted  $${formattedAllotted}` },
  ];

 


  const bgColorClass = isMatch
    ? "nm-concave-blue-200-sm text-colorSecondaryDark border-solid border-2 border-colorSecondaryDark"
    : "bg-colorAccent";

  const images = [
    { name: "BINANCEFUTURES", img: img1 },
    { name: "BINANCESPOT", img: img2 },
    { name: "KUCOINSPOT", img: img4 },
    { name: "KUCOINFUTURES", img: img3 },
    { name: "BYBITFUTURES", img: img5 },
    { name: "BYBITSPOT", img: img6 },
  ];



  return (
    <>
      <div className="md:mt-0 md:mb-3 mt-3  mb-7 px-2 sm:px-2 lg:px-2 xl:px-0 w-full  ">
        <section className="text-gray-600 body-font overflow-hidden">
          <div className="container px-5 xl:py-24 mx-auto">
            <div className=" flex justify-end "></div>
            <div className={`-m-4 items-center justify-center `}>
              
              <div className="p-4  w-full md:h-2/3  xl:h-1/2  transform  transition-transform duration-300 hover:-translate-y-2">
                <div
                  key={_id}
                  className={`h-full p-6 rounded-lg border-2 shadow-neumorphic  bg-colorPrimary  flex flex-col relative overflow-hidden shadow-xl 
                     ${bgColorClass} 
                      p-6 rounded-xl`}
                >
                  <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    {Name}
                  </h2>
                  <h1 className="lg:text-4xl text-5xl 2xl:text-5xl text-gray-900 leading-none flex items-center pb-4  ">
                    <span className="text-3xl">${Price}</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">
                      /{Validity}
                    </span>
                  </h1>
                  <div
                    className={`w-full h-[2px] mb-2 ${
                      color ? "bg-colorSecondaryDark/30 " : "bg-colorAccent/30 "
                    }`}
                  ></div>
                  {mapCardPara.map((item) => {
                    return (
                      <p className="flex items-center text-sm xl:text-md text-gray-600 my-1 ">
                        <span className="w-4 h-4 mr-2 inline-flex items-center  justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 6L9 17l-5-5"></path>
                          </svg>
                        </span>
                        {item.para}
                      </p>
                    );
                  })}

                  <div className=" flex gap-4 mt-auto">
                    <button
                      className={`flex items-center shadow-neumorphic mt-4 justify-center text-white  border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded-full ${
                        (isMatch
                          ? "bg-colorSecondaryDark "
                          : "bg-colorAccent cursor-pointer") +
                        (buttonClicked
                          ? "opacity-0 cursor-not-allowed "
                          : "cursor-not-allowed") // Add the cursor-not-allowed class when the button is clicked
                      }`}
                      onClick={() => stColour()}
                      disabled={isMatch || buttonClicked}
                    >
                      BUY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SubscriptionPackage;
