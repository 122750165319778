import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const login = localStorage.getItem("jwt");
  useEffect(() => {
    if (!login) {
      navigate("/");
    }
  }, [login, navigate]);

  return <>{login ? <Component key={login} /> : null} </>;
};

export default ProtectedRoute;
