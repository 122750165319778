import React, { useRef, useState } from "react";
import SplitPane from "react-split-pane";
import { Tabs, Row, Col, Drawer, Input, Button, Collapse, Table, Typography, Menu, Dropdown, Switch, Select, Layout } from "antd";
import OpenOrderTable from "../Components/Spot/OpenOrderTable";
import TradeHistoryTable from "../Components/Spot/TradeHistoryTable";
import CloseOrderTable from "../Components/Spot/CloseOrderTable";
import PortfolioTable from "../Components/Spot/PortfolioTable";
import Chart from "../Components/Spot/Chart";
import BuySell from "../Components/Spot/BuySell";
import Bots from "../Pages/FilterBotz";
import MarketDepth from "../Components/Spot/MarketDepth";
import { useEffect } from "react";
import { gettoken } from "./Allfunction";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
const { Panel } = Collapse;
let tvScriptLoadingPromise;

const { Header } = Layout;

const onSearch = (value) => {
  // console.log("search:", value);
};


const TradeKeen = ({ coin,market, showbalance,tradetype, preventcoin, preventcoinarray, preventmarket, preventshowbalance, preventtradetype }) => {
  const [boxes, setBoxes] = useState([]);
  const [tradebookdata, settradebookdata] = useState([]);
  const [links] = useState([]);
  // const [coins, setcoins] = useState([]);
  const [active_exch, setactive_exch] = useState([]);
  const [Getbalances, setGetbalances] = useState([]);
  const [showBalance, setshowBalance] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [trade, settrade] = useState('PAPER');
  const [selectedexchange, setselectedexchange] = useState('binance_spot');
  const [selectedCoin, setSelectedCoin] = useState("BTCUSDT");

  const [coins, setCoin] = useState('BTCUSDT'); // default coin
  const onLoadScriptRef = useRef();

  coin = coin ? coin : "BTCUSDT";
  const handleCanvasDrop = (event) => {
    event.preventDefault();
    const boxId = event.dataTransfer.getData("boxId");
    const canvas = event.target.getBoundingClientRect();
    const x = event.clientX - canvas.left;
    const y = event.clientY - canvas.top;
    setBoxes([...boxes, { id: boxId, x, y }]);
  };


  
  const handleSwitchChange = (checked) => {
    var exchan  = checked === true ? 'PAPER' : "LIVE"
     settrade(exchan)
      getactiveexchange();
      preventtradetype(exchan)
      GetBalance();
  }

  const coinsss = [
    { value: "BTCUSDT", label: "BTCUSDT" },
    { value: "ETHUSDT", label: "ETHUSDT" },
    { label: "BNBUSDT" },
    { label: "XRPUSDT" },
    { label: "SOLUSDT" },
    { label: "ADAUSDT" },
    { label: "DOGEUSDT" },
    { label: "DOTUSDT" },
    { label: "LTCUSDT" },
    { label: "AVAXUSDT" },
    { label: "MATICUSDT" },
    { label: "LINKUSDT" },
    { label: "UNIUSDT" },
    { label: "XMRUSDT" },
    { label: "ATOMUSDT" },
    { label: "ETCUSDT" },
    { label: "ALGOUSDT" },
    { label: "THETAUSDT" },
    { label: "FILUSDT" },
    { label: "EGLDUSDT" },
    { label: "XLMUSDT" },
    { label: "HBARUSDT" },
    { label: "NEARUSDT" },
    { label: "SANDUSDT" },
    { label: "KLAYUSDT" },
    { label: "AXSUSDT" }
  ];


  const onChangecoins = (value) => {
    setSelectedCoin(value);

    let balance = 0;
    if (value && Getbalances) {
      Object.entries(Getbalances).forEach(([key, val]) => {
        if (value.includes(key)) {
          balance = val;
          console.log(balance, "balance");
          setshowBalance(balance);
          preventshowbalance(balance);

          if (["USDT", "BUSD", "ETH", "BNB", "BTC"].some((suffix) => value.includes(suffix))) {
            const parts = value.split(/(USDT|BUSD|ETH|BNB|BTC)/).filter(Boolean);
            preventcoinarray(parts);
          }
        }
      });
    }
    preventcoin(value);
  };
  var maintrade = trade;
  console.log(maintrade,"outside")
  
  
  const getactiveexchange = () => {
    
    console.log(maintrade,"inside")

    const token = gettoken();
    var postData;
    if (trade === "LIVE") {
      postData = { jwt: token, Type: trade };
    }
    else if (trade === "PAPER" || trade == undefined) {
      postData = { jwt: token, Type: trade };
    }

    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH + "user/active_exchanges",
      data: postData,
    }).then(async function (response) {
      const res = await response.data;
      const mainres = response.data.data;
      if (res.data) {
        setactive_exch(res.data);
        // getcoins(mainres);
      } else if (res.error) {
      }
    });
  };

  // const getcoins = (market) => {
  //   const postData = {
  //     Exchange: "binance_spot",
  //   };
  //   const response = axios({
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     url: process.env.REACT_APP_API_PATH + "trading/get_coins",
  //     data: postData,
  //   }).then(async function (response) {
  //     const res = await response.data;
  //     const rescoins = await response.data.data;
  //     setcoins(res.data);

  //     if (res.data) {
  //       setcoins(res.data);
  //     } else if (res.error) {
  //       setcoins("");
  //     }
  //   });
  // };

  const GetBalance = async (value) => {
    const token = gettoken();
    const postData = { jwt: token, Exchange: value };
    if (trade == "LIVE") {
      axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH + "trading/get_balance",
        data: postData,
      }).then(async function (response) {
        setGetbalances(response.data.data);
      });
    } else if (trade == "PAPER") {
      axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API_PATH + "papertrading/get_balance",
        data: postData,
      }).then(async function (response) {

        setGetbalances(response.data.data);
      });
    }

  };
  const onChangemarket = (value) => {

    preventmarket(value);
    setSelectedOption(value);
    setselectedexchange(value)
    GetBalance(value)
    getactiveexchange();

  };

  useEffect(() => {
    let val = "binance_spot"
    GetBalance(val);
    // getcoins();

  }, []);

  useEffect(() => {
    getactiveexchange();

  }, [trade]);

  useEffect(() => {
    onChangecoins("BTCUSDT")
  }, [coins]);


  // const optionscoins = coins.map((coin) => {
  //   return {
  //     value: coin.Coin,
  //     label: coin.Coin,
  //   };
  // });

  const optionscoins = Array.isArray(coins) ? coins.map((coin) => ({
    value: coin.Coin,
    label: coin.Coin,
  })) : [];

  const optionsexch = active_exch && active_exch?.map((exchange) => {
    return {
      value: exchange,
      label: <Link className="no-underline" to={`/${exchange}`}>
        {exchange.split("_").join(" ")}
      </Link>
    };
  }, []);

  const Navigate = useNavigate()


  const handleCanvasDragOver = (event) => {
    event.preventDefault();
  };

  const [open, setOpen] = useState(false);
  const onChange = (key) => {
    setIsCollapsed(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  const [socket, setSocket] = useState(null);

  const token = gettoken()
  useEffect(() => {

    const ws = new WebSocket(`wss://dapib.stashack.cloud:8765?jwt=${token}`,);

    ws.addEventListener('message', (event) => {
      settradebookdata(event.data)
    });

    return () => {
      ws.close();
    };
  }, [ token]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: <Input />,
    },
    {
      key: "2",
      name: "John",
      age: <Input />,
    },
    {
      key: "3",
      name: "John",
      age: <Input />,
    },
    {
      key: "5",
      name: "John",
      age: <Input />,
    },
  ];
  
  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Bot Name",
      dataIndex: "age",
      key: "age",
    },
  ];
  const items = [
    {
      key: "1",
      label: `Open Order`,
      children: <OpenOrderTable  data={tradebookdata} />,
    },
    {
      key: "2",
      label: `Portfolio`,
      children: <PortfolioTable  data={tradebookdata} />,
    },
    {
      key: "3",
      label: `Trade History`,
      children: <TradeHistoryTable   data={tradebookdata} />,
    },
    {
      key: "4",
      label: `Close Order`,
      children: <CloseOrderTable   data={tradebookdata} />,
    },
  ];


  const coinPriceData = [
    {
      title: "Bitcoin Price ( BTC )",
      coinVal: "$ 30822.50",
      price: "2.80%",
    },
    {
      title: "Etherium Price ( BTC )",
      coinVal: "$ 987654.55",
      price: "99.20%",
    },
  ];

  const [ellipsis, setEllipsis] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const [showMore, setShowMore] = useState(true);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const displayedNewsData = showMore ? newsData : newsData.slice(0, 5);

  const { Text } = Typography;
  const newview = () => {
    const response = axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_PATH_NEW + "coinnews",
    }).then(async function (response) {
      console.log(response);
      const res = await response.data.News;
      console.log("res ass api");
      if (res) {
        setNewsData(res);
      }
    });
  };

  useEffect(() => {
    newview();
  }, []);




  const Chartfunction = () => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => onLoadScriptRef.current = null;

    function createWidget() {
      if (document.getElementById('tradingview_72617') && 'TradingView' in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: coin,
          width: "100%",
          interval: "D",
          timezone: "Etc/UTC",
          theme: "light",
          style: "1",
          locale: "in",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_72617",
          height: "100%",
          events: {
            onSymbolChange: function(symbol) {
              setCoin(symbol);
            }
          }
        });
      }
    }
  }

  useEffect(() => {
    Chartfunction();
  }, [coin]);

  useEffect(() => {
    // Fetch coins or set coins data here
    // For example:
    setCoin([
      { value: "BTCUSDT",Coin: "BTCUSDT" },
      { value: "ETHUSDT",Coin: "ETHUSDT" },
      {Coin: "BNBUSDT" },
      {Coin: "XRPUSDT" },
      {Coin: "SOLUSDT" },
      {Coin: "ADAUSDT" },
      {Coin: "DOGEUSDT" },
      {Coin: "DOTUSDT" },
      {Coin: "LTCUSDT" },
      {Coin: "AVAXUSDT" },
      {Coin: "MATICUSDT" },
      {Coin: "LINKUSDT" },
      {Coin: "UNIUSDT" },
      {Coin: "XMRUSDT" },
      {Coin: "ATOMUSDT" },
      {Coin: "ETCUSDT" },
      {Coin: "ALGOUSDT" },
      {Coin: "THETAUSDT" },
      {Coin: "FILUSDT" },
      {Coin: "EGLDUSDT" },
      {Coin: "XLMUSDT" },
      {Coin: "HBARUSDT" },
      {Coin: "NEARUSDT" },
      {Coin: "SANDUSDT" },
      {Coin: "KLAYUSDT" },
      {Coin: "AXSUSDT" }
    ]);
  }, []);

  return (
    <div className="bg-white shadow-neumorphicnew container mx-auto">

        <div className="w-full h-14 shadow-neumorphic bg-colorPrimary my-2">
        <Header
        className="bg-white lg:block hidden m-0 h-[50px] border-1 " 
        style={{ position: "sticky", top: 0, zIndex: 1, width: "100" }}
      >
        <Menu
          className="w-100vw m-0 p-0 h-0 border-1 "
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={[
            {
              key: 1,
              className: "xl:w-[500px] lg:w-[500px] mt-0",
              label: (
                <div className="flex justify-center align-center">
                  <Select
                  size="small"
                    showSearch
                    placeholder="Select a Market"
                    optionFilterProp="children"
                    onChange={onChangemarket}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="lg:w-[10vw] mt-3 "
                    defaultValue={"binance_spot"}
                    options={optionsexch}
                  />
                </div>
              ),
            },
            {
              key: 2,
              className: "xl:w-[250px] lg:w-[450px] mt-0",
              label: (
                <div className="flex justify-center align-center border-none">
                  <Select
                    size="small"
                    showSearch
                    placeholder="Select a Coin"
                    optionFilterProp="children"
                    onChange={onChangecoins}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="lg:w-[10vw] mt-3 border-none"
                    options={optionscoins}
                    defaultValue={"BTCUSDT"}
                  />
                </div>
              ),
            },
            {
              key: 3,
              className: "xl:w-[8vw] mt-0",
              label: (
             

                  <Switch
                  
                    defaultChecked
                    checkedChildren="Paper Trade"
                    unCheckedChildren="Live Trade"
                    onChange={handleSwitchChange}
                    className="bg-colorSecondary mb-3"
                  />
              ),
            },
          ]}
        />
      </Header>
        </div>

      
      <Row>
        <div
          style={{
            flex: 2,
            backgroundColor: "lightblue",
            height: "400px",
            width: "100%",
            position: "relative",
          }}
          onDrop={handleCanvasDrop}
          onDragOver={handleCanvasDragOver}
        >
          {boxes.map((box) => (
            <div
              key={box.id}
              style={{
                backgroundColor: "white",
                width: "100px",
                height: "100px",
                position: "absolute",
                left: box.x,
                top: box.y,
              }}
            >
              <div style={{ position: "absolute", top: "10px", left: "10px" }}>
                Santoshi
              </div>
            </div>
          ))}
          {links.map((link) => (
            <svg key={`${link.box1Id}-${link.box2Id}`}>
              <line
                x1={boxes.find((box) => box.id === link.box1Id).x + 50}
                y1={boxes.find((box) => box.id === link.box1Id).y + 50}
                x2={boxes.find((box) => box.id === link.box2Id).x + 50}
                y2={boxes.find((box) => box.id === link.box2Id).y + 50}
                style={{ stroke: "black", strokeWidth: 2 }}
              />
            </svg>
          ))}
        </div>
        <Col xs={24} md={20}>
          <div style={{ height: "100vh" }}>
            <SplitPane
              split="horizontal"
              minSize={100}
              maxSize={-100}
              defaultSize={"50%"}
            >
              <div className="simulationDiv">
                {/* <Chart coin={coin} /> */}
                <div className='tradingview-widget-container'>
                <div id='tradingview_72617' />
              </div>
              </div>
              <div className="statisticsDiv">
                <div>
                  <Row className="border">
                    <Col xs={24} md={8} className="text-center">
                      <div className="jz-fs-1 mb-2">Investment : 123321</div>
                      <div className="jz-fs-1 mb-2">Balance : 123321</div>
                    </Col>
                    <Col xs={24} md={8} className="text-center">
                      <div className="jz-fs-1">Trade Value</div>
                      <div className="jz-fs-2">987654321</div>
                    </Col>
                    <Col xs={24} md={8} className="text-center">
                      <div className="jz-fs-1 mb-2">PnL% : 123321</div>
                      <div className="jz-fs-1 mb-2">PnL : 123321</div>
                    </Col>
                  </Row>
                  <div className="p-1">
                    <Tabs defaultActiveKey="2" items={items} />
                  </div>
                </div>
              </div>
            </SplitPane>
          </div>
        </Col>
        <Col md={4}>
          <div className={isCollapsed ? "hidden" : ""}>
            <BuySell coin={coin} market={market} showbalance={showbalance} tradetype={tradetype}/>
          </div>
          <Collapse
            onChange={(onChange, handleCollapse)}
          >
            <Panel header="Bot" key="1">
              <Input placeholder="Select Your BOT" className="mt-3 mb-2" />
              <Bots />
            </Panel>
          </Collapse>
          <Collapse className="jr_collapse" defaultActiveKey={1}>
            <Panel header="Market Dept" key="1">
              <MarketDepth coin={coin} />
            </Panel>
          </Collapse>
          <Drawer
            title="BotName"
            placement="left"
            onClose={onClose}
            open={open}
          >
            <Table
              size="small"
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
            <div className="text-right">
              <Button size="small" className="bg-colorSecondary text-white mt-3">
                START
              </Button>
            </div>
          </Drawer>
        </Col>
      </Row>
    </div>
  );
};

export default TradeKeen;
